import {
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

type ModalWrapperProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  subTitle: string;
  children: React.JSX.Element;
};

const ModalWrapper = ({
  open,
  onClose,
  title,
  subTitle,
  children,
}: ModalWrapperProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "10px",
          width: "600px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
          background: "url(/background-images/modal-header.png)",
          mixBlendMode: "plus-darker",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignContent={"flex-start"}
          spacing={"10px"}
        >
          <Typography
            sx={{
              color: "var(--Components-Enabled-BG, #FFF)",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              textTransform: "capitalize",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              color: "var(--Text-On-Dark-Backgrounds-Main, #B4C3CE)",
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            {subTitle}
          </Typography>
        </Stack>
        <IconButton
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon sx={{ width: "20px", height: "20px", color: "#FFFFFF" }} />
        </IconButton>
      </DialogTitle>
      {children}
    </Dialog>
  );
};

export default ModalWrapper;
