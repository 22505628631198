import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getUrl } from "aws-amplify/storage";
import { InvoiceType } from "../models";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const generatePassword = (length: number = 8): string => {
  const upperCaseChars: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowerCaseChars: string = "abcdefghijklmnopqrstuvwxyz";
  const numberChars: string = "0123456789";
  const specialChars: string = "!@#$%^&*()_+[]{}|;:,.<>?";

  const allChars: string =
    upperCaseChars + lowerCaseChars + numberChars + specialChars;

  let password: string = "";

  password += upperCaseChars[Math.floor(Math.random() * upperCaseChars.length)];
  password += lowerCaseChars[Math.floor(Math.random() * lowerCaseChars.length)];
  password += numberChars[Math.floor(Math.random() * numberChars.length)];
  password += specialChars[Math.floor(Math.random() * specialChars.length)];

  for (let i = 4; i < length; i++) {
    password += allChars[Math.floor(Math.random() * allChars.length)];
  }

  password = password
    .split("")
    .sort(() => 0.5 - Math.random())
    .join("");

  return password;
};

const useIsWidthDown = (breakpoint: any) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(breakpoint));
};

const getSignedUrl = async (path: string) => {
  const signedUrl = await getUrl({
    path,
    options: {
      validateObjectExistence: true,
    },
  });

  return signedUrl.url.href;
};

const createInvoiceNumber = ({
  invoiceType,
  records,
}: {
  invoiceType: InvoiceType;
  records: number;
}): string => {
  const typeMapping: { [key in InvoiceType]: string } = {
    [InvoiceType.LOCAL_FROZEN_AND_FRESH]: "LOC/FRZ",
    [InvoiceType.FRESH_EXPORT]: "FE",
    [InvoiceType.DRY_FISH]: "DF",
    [InvoiceType.CHILL_EXPORT]: "CE",
    [InvoiceType.DEFAULT]: "",
  };

  const typeCode = typeMapping[invoiceType] || "DEFAULT"; // Fallback in case of an unexpected invoiceType

  const datePart = moment().format("YY/MM/DD");
  const recordNumber = records + 1;

  return `IC/${typeCode}/${datePart}-00${recordNumber}`;
};

const generatePDF = async (
  contentRef: React.MutableRefObject<HTMLDivElement | null>,
  invoiceNumber: string
) => {
  if (!contentRef.current) return;

  try {
    const content = contentRef.current;

    const contentWidth = content.scrollWidth;
    const contentHeight = content.scrollHeight;

    const canvas = await html2canvas(content, {
      scale: 2, // Higher scale for better quality
      useCORS: true,
      logging: true,
      width: contentWidth,
      height: contentHeight,
    });

    const imgData = canvas.toDataURL("image/png", 1.0);

    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth(); // 210mm
    const pdfHeight = pdf.internal.pageSize.getHeight(); // 297mm

    const imgHeight = (canvas.height * pdfWidth) / canvas.width;

    let position = 0;

    while (position < imgHeight) {
      pdf.addImage(
        imgData,
        "PNG",
        0,
        -position,
        pdfWidth,
        imgHeight,
        "",
        "FAST"
      );

      position += pdfHeight;

      if (position < imgHeight) pdf.addPage();
    }

    pdf.save(`invoice-${invoiceNumber}-${moment().format("YYYY-MM-DD")}.pdf`);
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};

const getInvoiceTypeLabel = (invoiceType: InvoiceType) => {
  switch (invoiceType) {
    case InvoiceType.LOCAL_FROZEN_AND_FRESH:
      return "Local Frozen and Fresh";
    case InvoiceType.FRESH_EXPORT:
      return "Fresh Export";
    case InvoiceType.DRY_FISH:
      return "Dry Fish";
    case InvoiceType.CHILL_EXPORT:
      return "Chill Export";
    default:
      return "";
  }
};

export {
  generatePassword,
  useIsWidthDown,
  getSignedUrl,
  createInvoiceNumber,
  generatePDF,
  getInvoiceTypeLabel,
};
