import {
  Avatar,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getSignedUrl, UserCardProps } from "../../utils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const UserCard = ({
  name,
  email,
  designation,
  profilePicture,
  userId,
  id,
}: UserCardProps) => {
  const navigate = useNavigate();

  const [signedProfilePicture, setSignedProfilePicture] = useState<string>("");

  useEffect(() => {
    const fetchSignedUrl = async () => {
      if (profilePicture) {
        const signedUrl = await getSignedUrl(profilePicture);
        setSignedProfilePicture(signedUrl);
      }
    };

    fetchSignedUrl();
  }, [profilePicture]);

  return (
    <Card
      component={"div"}
      onClick={() => {
        navigate(`./${id}`);
      }}
      sx={{ cursor: "pointer" }}
    >
      <CardContent sx={{ padding: "20px" }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
        >
          <Avatar
            src={signedProfilePicture}
            sx={{ width: "70px", height: "70px" }}
          />
          <Button
            startIcon={
              <CheckCircleIcon
                sx={{ width: "12px", height: "12px", color: "#43A047" }}
              />
            }
          >
            <Typography
              sx={{
                color: "var(--success-main, #43A047)",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "100%",
                textTransform: "none",
              }}
            >
              Verified
            </Typography>
          </Button>
        </Stack>
        <Stack
          direction={"column"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          mt="10px"
          spacing={"5px"}
        >
          <Typography
            sx={{
              color: "var(--components-cta-text-icon, #1C2940)",
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              color: "var(--components-enabled-text-stroke-icon, #646D7C)",
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontSize: "11px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            {designation}
          </Typography>
          <Typography
            sx={{
              color: "var(--components-enabled-text-stroke-icon, #646D7C)",
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontSize: "11px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              textTransform: "uppercase",
            }}
          >
            {userId}
          </Typography>
          <Typography
            sx={{
              color: "var(--components-enabled-text-stroke-icon, #646D7C)",
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontSize: "11px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            {email}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default UserCard;
