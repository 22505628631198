import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import moment from "moment";
import DetailsCard from "../components/Common/DetailsCard";
import InventoryManagementTableBody from "../components/Inventory/InventoryManagementTableBody";
import UploadDialog from "../components/Common/UploadDialog";
import { uploadData } from "aws-amplify/storage";
import Swal from "sweetalert2";
import { LazyInventory } from "../models";
import { fetchAllInventoryItems, saveInventory } from "../services";

const acceptedFileTypes = [".csv", ".xlsx"];

const InventoryManagement = () => {
  const [openDropZone, setOpenDropZone] = useState(false);

  const [inventories, setInventories] = useState<LazyInventory[]>([]);
  const [loadingInventories, setLoadingInventories] = useState(false);
  const [summaryCardDetails, setSummaryCardDetails] = useState<{
    totalWeight: number;
    mostStock: {
      specie: string;
      weight: number;
    };
    unsortedStockTotalWeight: number;
  }>({
    totalWeight: 0,
    mostStock: {
      specie: "",
      weight: 0,
    },
    unsortedStockTotalWeight: 0,
  });

  const fetchInventories = async () => {
    try {
      setLoadingInventories(true);

      const { rows, summaryCardDetails } = await fetchAllInventoryItems();

      setInventories(rows);
      setSummaryCardDetails(summaryCardDetails);

      setLoadingInventories(false);
    } catch (error) {
      console.log("🚀 ~ fetchInventories ~ error:", error);
    }
  };

  const uploadInventoryFile = async (file: File) => {
    try {
      setOpenDropZone(false);
      const fileSavingPath = `public/inventory/${moment().format(
        "YYYY-MM-DD"
      )}/${moment().toISOString() + "_" + file.name}`;

      const { path } = await uploadData({
        path: fileSavingPath,
        data: file,
        options: {
          onProgress: ({ transferredBytes, totalBytes }) => {
            if (totalBytes) {
              const progress = Math.round(
                (transferredBytes / totalBytes) * 100
              );

              if (!Swal.isVisible()) {
                Swal.fire({
                  title: "Uploading",
                  html: `Uploading <strong>${file.name}</strong> <br/> <b>${progress}%</b>`,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                });
              } else {
                Swal.update({
                  html: `Uploading <strong>${file.name}</strong> <br/> <b>${progress}%</b>`,
                });
              }
            }
          },
        },
      }).result;

      const { success } = await saveInventory(path);
      console.log("🚀 ~ uploadInventoryFile ~ success:", success);

      Swal.close();

      setOpenDropZone(false);
    } catch (error) {
      console.log("🚀 ~ uploadInventoryFile ~ error:", error);
    }
  };

  const tableHeaders = [
    "CCCS/Client",
    "Specie",
    "SCOW",
    "Weight (kg)",
    "Client",
    "BC CCCS",
    "Vessel/Container",
    "Entered to CS",
    "First Enter To CS",
  ];

  useEffect(() => {
    fetchInventories();
  }, []);

  return (
    <Box px={{ xs: "none", md: "20px" }}>
      <Container maxWidth="xl" disableGutters sx={{ mt: "76px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Stack
              direction={"row"}
              width={"100%"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <Typography
                sx={{
                  color: "var(--components-cta-text-icon, #1C2940)",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "120%",
                  textTransform: "capitalize",
                }}
              >
                Inventory
              </Typography>
              <Button
                onClick={() => {
                  setOpenDropZone(true);
                }}
                sx={{
                  borderRadius: "6px",
                  background: "var(--Components-CTA-BG, #4CE6FA)",
                  ":hover": {
                    background: "var(--Components-CTA-BG, #4CE6FA)",
                  },
                  padding: "10px",
                }}
                startIcon={<UploadFileIcon sx={{ color: "#1C2940" }} />}
              >
                <Typography
                  sx={{
                    color: "var(--components-cta-text-icon, #1C2940)",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  Upload New Inventory CSV
                </Typography>
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} container spacing={"20px"} mt="30px" mb={"30px"}>
            {[
              {
                cardTitle: "Total Weight",
                subTitle: `on Stock`,
                lastUpdatedDate: `${moment().format("YYYY-MM-DD HH:mm")}`,
                amount: `${summaryCardDetails.totalWeight} Kg`,
              },
              {
                cardTitle: `${summaryCardDetails.mostStock.specie}`,
                subTitle: `Most Stocks`,
                lastUpdatedDate: `${moment().format("YYYY-MM-DD HH:mm")}`,
                amount: `${summaryCardDetails.mostStock.weight} Kg`,
              },
              {
                cardTitle: "Unsorted",
                subTitle: `Stock`,
                lastUpdatedDate: `${moment().format("YYYY-MM-DD HH:mm")}`,
                amount: `${summaryCardDetails.unsortedStockTotalWeight} Kg`,
              },
            ].map((data, index) => (
              <Grid item xs={12} md={4} key={index}>
                <DetailsCard
                  cardTitle={data.cardTitle}
                  subTitle={data.subTitle}
                  lastUpdatedDate={data.lastUpdatedDate}
                  amount={data.amount}
                />
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((header, index) => (
                      <TableCell align="left" key={index}>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "120%",
                            textTransform: "capitalize",
                          }}
                        >
                          {header}
                        </Typography>
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingInventories ? (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Stack
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          width={"100%"}
                          padding={"20px"}
                        >
                          <CircularProgress />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ) : inventories.length > 0 ? (
                    inventories.map((data, index) => (
                      <InventoryManagementTableBody row={data} key={index} />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Stack
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          width={"100%"}
                          padding={"20px"}
                        >
                          <Typography
                            sx={{
                              color:
                                "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                              fontSize: "11px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "120%",
                              textTransform: "capitalize",
                            }}
                          >
                            No Records Available
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <UploadDialog
          open={openDropZone}
          setOpen={setOpenDropZone}
          title={"Upload Inventory CSV"}
          save={async (file: File) => {
            await uploadInventoryFile(file);
          }}
          acceptedFiles={acceptedFileTypes}
        />
      </Container>
    </Box>
  );
};

export default InventoryManagement;
