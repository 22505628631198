import { DropzoneDialog, DropzoneDialogProps } from "mui-file-dropzone";
import { IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export interface UploadDialogProps
  extends Omit<DropzoneDialogProps, "showAlerts"> {
  open: boolean;
  setOpen: any;
  title: string;
  save: (file: File) => void;
  acceptedFiles: string[];
}

const UploadDialog = ({
  open,
  setOpen,
  title,
  save,
  acceptedFiles,
  ...props
}: UploadDialogProps) => {
  const toggleFormAndDropzone = (field?: string, dialogTitle?: string) => {
    setOpen(false);
  };

  return (
    <DropzoneDialog
      open={open}
      onSave={(files) => {
        save(files[0]);
      }}
      dialogTitle={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
          sx={{
            background: "#F1F5F8",
            borderRadius: "5px",
            p: "10px",
          }}
        >
          <Typography
            sx={{ fontSize: "12px", fontWeight: 700, lineHeight: "100%" }}
            color="#0D3C60"
          >
            {title}
          </Typography>
          <IconButton
            sx={{ width: "20px", height: "20px", color: "#586874" }}
            size="small"
            onClick={() => {
              toggleFormAndDropzone();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      }
      submitButtonText="Confirm File Upload"
      dropzoneText="Drag and drop a file or click here to upload."
      acceptedFiles={acceptedFiles}
      filesLimit={1}
      showPreviews={true}
      maxFileSize={10485760}
      showAlerts={true}
      dialogProps={{
        open,
        PaperProps: {
          sx: {
            maxWidth: "unset",
            width: "50%",
            position: "absolute",
            zIndex: "10000",
            borderRadius: "15px",
            [`& .MuiButton-root`]: {
              background: "#2779F5",
              border: " 0.7px solid #2779F5",
              textTransform: "none",
              color: "#ffffff",
              height: "32px",
              boxShadow: "none",
              fontWeight: "700",
              fontSize: "11px",
              lineHeight: "161%",
              letterSpacing: "0.15px",
              ":hover": {
                boxShadow: "none",
                background: "#2779F5",
                border: " 0.7px solid #2779F5",
                textTransform: "none",
                color: "#ffffff",
              },
            },
            [`& .Mui-disabled`]: {
              background: "#ffffff !important",
              border: " 0.7px solid #2779F5",
              color: "#2779F5 !important",
              fontWeight: "700",
              fontSize: "11px",
              lineHeight: "161%",
              letterSpacing: "0.15px",
            },
            [`& .MuiDialogActions-root`]: {
              padding: "20px",
            },
            [`& .MuiTypography-subtitle1`]: {
              display: "none",
            },
            [`& .MuiDialogContent-root`]: {
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              alignItems: "center",
              [`& .MuiSvgIcon-root`]: {
                color: "#2779F5",
              },
            },
            [`& .MuiTypography-h5`]: {
              fontSize: "12px",
              lineHeight: "161%",
              letterSpacing: "0.15px",
              color: "#586874",
            },
            [`& .css-1agvk75`]: {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            },
            [`& .css-fksjaj`]: {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            },
          },
        },
      }}
      onClose={() => {
        toggleFormAndDropzone();
      }}
      fileObjects={[]}
      {...props}
    />
  );
};

export default UploadDialog;
