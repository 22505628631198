import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Grid as SwiperGrid } from "swiper/modules";
import { getInvoiceTypeLabel, InvoiceExtended, useIsWidthDown } from "../utils";
import MenuCard from "../components/Common/MenuCards";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import DataGridColumnData from "../components/Common/DataGridColumnData";
import DataGridColumnHeader from "../components/Common/DataGridColumnHeader";
import moment from "moment";
import LocalInvoice from "../components/Invoice/LocalInvoice";
import ExportInvoice from "../components/Invoice/ExportInvoice";
import LocalInvoicePreview from "../components/Invoice/LocalInvoicePreview";
import ExportInvoicePreview from "../components/Invoice/ExportInvoicePreview";
import {
  fetchAllInvoices,
  fetchInvoice,
  fetchInvoiceCustomerTemplates,
} from "../services";
import { InvoiceType, LazyCustomers } from "../models";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LocalInvoiceRowPreview from "../components/Invoice/LocalInvoiceRowPreview";
import ExportInvoiceRowPreview from "../components/Invoice/ExportInvoiceRowPreview";
import {
  fetchChillExportWithinDay,
  fetchDryFishExportCountWithinDay,
  fetchFreshExportInvoicesCountWithinDay,
  fetchLocalInvoicesCountWithinDay,
} from "../services/common.service";
import CustomerTemplates from "../components/Invoice/CustomerTemplates";
import LocalInvoiceFromTemplate from "../components/Invoice/LocalInvoiceFromTemplate";
import ExportInvoiceFromTemplate from "../components/Invoice/ExportInvoiceFromTemplate";

const InvoiceManagement = () => {
  const [openInvoiceTypeOne, setOpenInvoiceTypeOne] = useState(false);
  const [openInvoiceTypeOnePreview, setOpenInvoiceTypeOnePreview] =
    useState(false);
  const [openInvoiceTypeTwo, setOpenInvoiceTypeTwo] = useState(false);
  const [invoiceTypeTwoModalData, setInvoiceTypeTwoModalData] = useState<{
    title: string;
    subTitle: string;
    invoiceType: InvoiceType;
  }>({
    title: "",
    subTitle: "",
    invoiceType: InvoiceType.DEFAULT,
  });
  const [openInvoiceTypeTwoPreview, setOpenInvoiceTypeTwoPreview] =
    useState(false);
  const [loadingInvoices, setLoadingInvoices] = useState(false);
  const [selectedInvoice, setSelectedInvoice] =
    useState<InvoiceExtended | null>(null);
  const [loadingSelectedInvoice, setLoadingSelectedInvoice] = useState(false);

  const [openRowPreviewTypeOne, setOpenRowPreviewTypeOne] = useState(false);
  const [rowPreviewDataTypeOne, setRowPreviewDataTypeOne] =
    useState<InvoiceExtended | null>(null);

  const [openRowPreviewTypeTwo, setOpenRowPreviewTypeTwo] = useState(false);
  const [rowPreviewDataTypeTwo, setRowPreviewDataTypeTwo] =
    useState<InvoiceExtended | null>(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState<InvoiceExtended[]>([]);
  const [nextToken, setNextToken] = useState<string | null | undefined>(null);

  const [localInvoiceCount, setLocalInvoiceCount] = useState(0);
  const [freshExportCount, setFreshExportCount] = useState(0);
  const [dryFishExportCount, setDryFishExportCount] = useState(0);
  const [chillExportCount, setChillExportCount] = useState(0);

  const [openInvoiceTemplates, setOpenInvoiceTemplates] = useState(false);
  const [invoiceTemplates, setInvoiceTemplates] = useState<LazyCustomers[]>([]);
  const [selectedCustomerTemplate, setSelectedCustomerTemplate] =
    useState<LazyCustomers | null>(null);

  const [openLocalInvoiceFromTemplates, setOpenLocalInvoiceFromTemplates] =
    useState(false);
  const [openExportInvoiceFromTemplate, setOpenExportInvoiceFromTemplate] =
    useState(false);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "id",
      hide: true,
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      sortable: true,
      disableColumnMenu: false,
      flex: 1,
      minWidth: 250,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Invoice Number" />,
    },
    {
      field: "poReference",
      headerName: "PO Reference",
      sortable: true,
      disableColumnMenu: false,
      flex: 1,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="PO Reference" />,
    },
    {
      field: "poValue",
      headerName: "PO Value",
      sortable: true,
      disableColumnMenu: false,
      flex: 1,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="PO Value" />,
    },
    {
      field: "totalAmount",
      headerName: "Invoice Value",
      sortable: true,
      disableColumnMenu: false,
      flex: 1,
      renderCell: (params) => (
        <DataGridColumnData content={params.value.toLocaleString("en-US")} />
      ),
      renderHeader: () => <DataGridColumnHeader headerName="Invoice Value" />,
    },
    {
      field: "consignee",
      headerName: "Consignee",
      sortable: true,
      disableColumnMenu: false,
      flex: 1,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Consignee" />,
    },
    {
      field: "invoiceDate",
      headerName: "Invoice Date",
      sortable: true,
      disableColumnMenu: false,
      flex: 1,

      renderCell: (params) => (
        <DataGridColumnData
          content={moment(params.value).format("DD/MM/YYYY")}
        />
      ),
      renderHeader: () => <DataGridColumnHeader headerName="Invoice Date" />,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      sortable: true,
      disableColumnMenu: false,
      flex: 1,
      renderCell: (params) => (
        <DataGridColumnData
          content={moment(params.value).format("DD/MM/YYYY")}
        />
      ),
      renderHeader: () => <DataGridColumnHeader headerName="Due Date" />,
    },
    {
      field: "",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          width="100%"
        >
          <IconButton
            onClick={() => {
              if (
                params.row.invoiceType === InvoiceType.LOCAL_FROZEN_AND_FRESH
              ) {
                setRowPreviewDataTypeOne(params.row);
                setOpenRowPreviewTypeOne(true);
              } else {
                setRowPreviewDataTypeTwo(params.row);
                setOpenRowPreviewTypeTwo(true);
              }
            }}
          >
            <VisibilityIcon
              sx={{ width: "16px", height: "16px", color: "#646D7C" }}
            />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const fetchInvoices = async ({
    pageSize = 10,
    nextToken = null,
  }: {
    pageSize: number;
    nextToken: null | string | undefined;
  }) => {
    try {
      setLoadingInvoices(true);

      const {
        rows,
        rowCount,
        nextToken: newNextToken,
      } = await fetchAllInvoices({ limit: pageSize, nextToken });

      setRows(rows);
      setTotalRows(rowCount);
      setNextToken(newNextToken);

      setLoadingInvoices(false);
    } catch (error) {
      console.log("🚀 ~ fetchInvoices ~ error:", error);
    }
  };

  const fetchCreatedInvoice = async (id: string) => {
    try {
      setLoadingSelectedInvoice(true);

      const invoice = await fetchInvoice(id);

      setSelectedInvoice(invoice);

      setLoadingSelectedInvoice(false);
    } catch (error) {
      console.log("🚀 ~ fetchCreatedInvoice ~ error:", error);
    }
  };

  const fetchInvoiceMetaData = async () => {
    try {
      const { localInvoiceCount } = await fetchLocalInvoicesCountWithinDay();

      setLocalInvoiceCount(localInvoiceCount);

      const { freshExportCount } =
        await fetchFreshExportInvoicesCountWithinDay();

      setFreshExportCount(freshExportCount);

      const { dryFishExportCount } = await fetchDryFishExportCountWithinDay();

      setDryFishExportCount(dryFishExportCount);

      const { chillExportCount } = await fetchChillExportWithinDay();

      setChillExportCount(chillExportCount);
    } catch (error) {
      console.log("🚀 ~ fetchInvoiceMetaData ~ error:", error);
    }
  };

  const fetchInvoiceTemplates = async () => {
    try {
      const { templates } = await fetchInvoiceCustomerTemplates();

      setInvoiceTemplates(templates);
    } catch (error) {
      console.log("🚀 ~ fetchInvoiceTemplates ~ error:", error);
    }
  };

  useEffect(() => {
    fetchInvoices({ pageSize, nextToken });
    fetchInvoiceMetaData();
    fetchInvoiceTemplates();
  }, [page, pageSize]);

  return (
    <Box px={{ xs: "none", md: "20px" }}>
      <Container maxWidth="xl" disableGutters sx={{ mt: "76px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Stack
              direction={"row"}
              width={"100%"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <Typography
                sx={{
                  color: "var(--components-cta-text-icon, #1C2940)",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "120%",
                  textTransform: "capitalize",
                }}
              >
                Invoices
              </Typography>
              <Button
                sx={{
                  borderRadius: "6px",
                  background: "var(--Components-CTA-BG, #4CE6FA)",
                  ":hover": {
                    background: "var(--Components-CTA-BG, #4CE6FA)",
                  },
                  padding: "10px",
                }}
                onClick={() => {
                  setOpenInvoiceTemplates(true);
                }}
              >
                <Typography
                  sx={{
                    color: "var(--components-cta-text-icon, #1C2940)",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  Invoice Templates
                </Typography>
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} mb={"20px"} mt="30px">
            <Swiper
              navigation={true}
              grid={{
                rows: 1,
              }}
              grabCursor={true}
              modules={[Navigation, SwiperGrid]}
              className="mySwiper"
              slidesPerView={useIsWidthDown("md") ? 1 : 4.5}
            >
              {[
                {
                  cardTitle: "Local Frozen & Fresh",
                  cardSubTitle: "Generate an invoice",
                  openForm: () => {
                    setOpenInvoiceTypeOne(true);
                  },
                  backgroundColor: "#00C9E3",
                },
                {
                  cardTitle: "Fresh export",
                  cardSubTitle: "Generate an invoice",
                  openForm: () => {
                    setInvoiceTypeTwoModalData({
                      title: "Fresh export",
                      subTitle: "Create a New Invoice",
                      invoiceType: InvoiceType.FRESH_EXPORT,
                    });
                    setOpenInvoiceTypeTwo(true);
                  },
                  backgroundColor: "#198794",
                },
                {
                  cardTitle: "Chill export",
                  cardSubTitle: "Generate an invoice",
                  openForm: () => {
                    setInvoiceTypeTwoModalData({
                      title: "Chill export",
                      subTitle: "Create a New Invoice",
                      invoiceType: InvoiceType.CHILL_EXPORT,
                    });
                    setOpenInvoiceTypeTwo(true);
                  },
                  backgroundColor: "#2779F5",
                },
                {
                  cardTitle: "Dry fish",
                  cardSubTitle: "Generate an invoice",
                  openForm: () => {
                    setInvoiceTypeTwoModalData({
                      title: "Dry fish",
                      subTitle: "Create a New Invoice",
                      invoiceType: InvoiceType.DRY_FISH,
                    });
                    setOpenInvoiceTypeTwo(true);
                  },
                  backgroundColor: "#F57C00",
                },
              ].map((card, index) => (
                <SwiperSlide
                  key={index}
                  style={{ maxWidth: "calc(100% / 3 - 20px)" }}
                >
                  <MenuCard
                    cardTitle={card.cardTitle}
                    cardSubTitle={card.cardSubTitle}
                    openForm={card.openForm}
                    backgroundColor={card.backgroundColor}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
          <Grid item xs={12} mb={"20px"} mt="30px">
            <Box sx={{ height: "550px" }}>
              <DataGrid
                columns={columns}
                rows={rows}
                checkboxSelection={false}
                page={page}
                onPageSizeChange={(newPageSize: number) => {
                  setPageSize(newPageSize);
                }}
                onPageChange={(newPage: number) => {
                  setPage(newPage);
                }}
                experimentalFeatures={{ newEditingApi: true }}
                pageSize={pageSize}
                paginationMode="server"
                rowCount={totalRows}
                loading={loadingInvoices}
                sx={{
                  [`& .MuiDataGrid-row.active`]: {
                    background: "#F1F5F8",
                  },
                  "& .MuiDataGrid-row.expired": {
                    backgroundColor: "#FEF2E6",
                  },
                }}
                getRowClassName={(params) => {
                  if (moment(params.row.dueDate).isBefore(moment())) {
                    return "expired";
                  } else {
                    return "active";
                  }
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <LocalInvoice
        open={openInvoiceTypeOne}
        onClose={() => {
          setOpenInvoiceTypeOne(false);
        }}
        openPreview={() => {
          setOpenInvoiceTypeOnePreview(true);
        }}
        fetchCreatedInvoice={fetchCreatedInvoice}
        currentInvoiceNumber={localInvoiceCount}
        fetchInvoiceMetaData={fetchInvoiceMetaData}
      />
      <ExportInvoice
        open={openInvoiceTypeTwo}
        onClose={() => {
          setOpenInvoiceTypeTwo(false);
        }}
        title={invoiceTypeTwoModalData.title}
        subTitle={invoiceTypeTwoModalData.subTitle}
        invoiceType={invoiceTypeTwoModalData.invoiceType}
        openPreview={() => {
          setOpenInvoiceTypeTwoPreview(true);
        }}
        fetchCreatedInvoice={fetchCreatedInvoice}
        currentInvoiceNumber={
          invoiceTypeTwoModalData.invoiceType === InvoiceType.FRESH_EXPORT
            ? freshExportCount
            : invoiceTypeTwoModalData.invoiceType === InvoiceType.DRY_FISH
            ? dryFishExportCount
            : chillExportCount
        }
        fetchInvoiceMetaData={fetchInvoiceMetaData}
      />
      <LocalInvoicePreview
        open={openInvoiceTypeOnePreview}
        onClose={() => {
          setOpenInvoiceTypeOnePreview(false);
        }}
        loading={loadingSelectedInvoice}
        data={selectedInvoice}
        fetchInvoices={fetchInvoices}
        paginationData={{ page, pageSize, nextToken }}
      />
      <ExportInvoicePreview
        open={openInvoiceTypeTwoPreview}
        onClose={() => {
          setOpenInvoiceTypeTwoPreview(false);
        }}
        loading={loadingSelectedInvoice}
        fetchInvoices={fetchInvoices}
        data={selectedInvoice}
        paginationData={{ page, pageSize, nextToken }}
      />
      <LocalInvoiceRowPreview
        open={openRowPreviewTypeOne}
        onClose={() => {
          setOpenRowPreviewTypeOne(false);
        }}
        title={rowPreviewDataTypeOne?.invoiceNumber ?? ""}
        subTitle="Invoice #"
        data={rowPreviewDataTypeOne}
      />
      <ExportInvoiceRowPreview
        open={openRowPreviewTypeTwo}
        onClose={() => {
          setOpenRowPreviewTypeTwo(false);
        }}
        title={rowPreviewDataTypeTwo?.invoiceNumber ?? ""}
        subTitle="Invoice #"
        data={rowPreviewDataTypeTwo}
      />
      <CustomerTemplates
        open={openInvoiceTemplates}
        onClose={() => {
          setOpenInvoiceTemplates(false);
        }}
        customers={invoiceTemplates}
        openLocalInvoiceForm={() => {
          setOpenLocalInvoiceFromTemplates(true);
        }}
        openExportInvoiceForm={() => {
          setOpenExportInvoiceFromTemplate(true);
        }}
        setSelectedCustomerTemplate={setSelectedCustomerTemplate}
      />
      <LocalInvoiceFromTemplate
        open={openLocalInvoiceFromTemplates}
        onClose={() => {
          setOpenLocalInvoiceFromTemplates(false);
        }}
        openPreview={() => {
          setOpenInvoiceTypeOnePreview(true);
        }}
        fetchCreatedInvoice={fetchCreatedInvoice}
        currentInvoiceNumber={localInvoiceCount}
        fetchInvoiceMetaData={fetchInvoiceMetaData}
        customer={selectedCustomerTemplate}
      />
      <ExportInvoiceFromTemplate
        open={openExportInvoiceFromTemplate}
        onClose={() => {
          setOpenExportInvoiceFromTemplate(false);
        }}
        title={getInvoiceTypeLabel(
          (selectedCustomerTemplate?.invoiceType as InvoiceType) ??
            InvoiceType.DEFAULT
        )}
        subTitle={"Create a New Invoice"}
        openPreview={() => {
          setOpenInvoiceTypeTwoPreview(true);
        }}
        fetchCreatedInvoice={fetchCreatedInvoice}
        currentInvoiceNumber={
          selectedCustomerTemplate?.invoiceType === InvoiceType.FRESH_EXPORT
            ? freshExportCount
            : selectedCustomerTemplate?.invoiceType === InvoiceType.DRY_FISH
            ? dryFishExportCount
            : chillExportCount
        }
        fetchInvoiceMetaData={fetchInvoiceMetaData}
        customer={selectedCustomerTemplate}
      />
    </Box>
  );
};

export default InvoiceManagement;
