import { generateClient } from "aws-amplify/api";
import { getVoucher, listVouchers, searchVouchers } from "../graphql/queries";
import { LazyVoucher, VoucherRedeemData, VoucherStatus } from "../models";
import { EVoucher, VoucherTemplate } from "../utils";
import { createVoucher, updateVoucher } from "../graphql/mutations";
import { customListVouchers } from "../graphql/customQueries";
import moment from "moment";
import {
  SearchableSortDirection,
  SearchableVoucherSortableFields,
} from "../API";

const fetchAllEVouchers = async ({
  userId,
  limit,
  nextToken,
}: {
  userId: string;
  limit: number;
  nextToken: string | null | undefined;
}) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        searchVouchers: { items: recordCount },
      },
    } = await client.graphql({
      query: customListVouchers,
      variables: {
        filter: {
          status: {
            eq: VoucherStatus.ACTIVE,
          },
          assignedUserId: {
            eq: userId,
          },
        },
      },
    });

    const {
      data: {
        searchVouchers: { items, nextToken: newNextToken },
      },
    } = await client.graphql({
      query: customListVouchers,
      variables: {
        filter: {
          status: {
            eq: VoucherStatus.ACTIVE,
          },
          assignedUserId: {
            eq: userId,
          },
        },
        sort: [
          {
            field: SearchableVoucherSortableFields.validUntil,
            direction: SearchableSortDirection.desc,
          },
        ],
      },
    });

    return {
      success: true,
      rows: items as unknown as LazyVoucher[],
      rowCount: recordCount.length,
      nextToken: newNextToken,
    };
  } catch (error) {
    console.log("🚀 ~ fetchAllEVouchers ~ error:", error);
    return {
      success: false,
      rows: [],
      rowCount: 0,
      nextToken: null,
    };
  }
};

const fetchAllEVouchersAdmin = async ({
  limit,
  nextToken,
}: {
  limit: number;
  nextToken: string | null | undefined;
}) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        searchVouchers: { items: recordCount },
      },
    } = await client.graphql({
      query: customListVouchers,
    });

    const {
      data: {
        searchVouchers: { items, nextToken: newNextToken },
      },
    } = await client.graphql({
      query: customListVouchers,
      variables: {
        sort: [
          {
            field: SearchableVoucherSortableFields.validUntil,
            direction: SearchableSortDirection.desc,
          },
        ],
      },
    });

    return {
      success: true,
      rows: items as unknown as LazyVoucher[],
      rowCount: recordCount.length,
      nextToken: newNextToken,
    };
  } catch (error) {
    console.log("🚀 ~ admin vouchers error:", error);
    return {
      success: false,
      rows: [],
      rowCount: 0,
      nextToken: null,
    };
  }
};

const fetchEVoucherTemplates = async () => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        searchVouchers: { items },
      },
    } = await client.graphql({
      query: customListVouchers,
      variables: {
        filter: {
          saveAsTemplate: {
            eq: true,
          },
        },
      },
    });

    return items as unknown as VoucherTemplate[];
  } catch (error) {
    console.log("🚀 ~ fetchEVoucherTemplates ~ error:", error);
    return [];
  }
};

const fetchEVoucherTemplatesForCurrentUser = async (userId: string) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        searchVouchers: { items },
      },
    } = await client.graphql({
      query: customListVouchers,
      variables: {
        filter: {
          saveAsTemplate: {
            eq: true,
          },
          assignedUserId: {
            eq: userId,
          },
        },
      },
    });

    return items as unknown as VoucherTemplate[];
  } catch (error) {
    console.log("🚀 ~ fetchEVoucherTemplates ~ error:", error);
    return [];
  }
};

const createEVoucher = async (voucher: EVoucher) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const createdVoucher = await client.graphql({
      query: createVoucher,
      variables: {
        input: voucher,
      },
    });

    await client.graphql({
      query: listVouchers,
    });

    return createdVoucher;
  } catch (error) {
    console.log("🚀 ~ createEVoucher ~ error:", error);
  }
};

const redeemEVoucher = async ({
  id,
  redeemAmount,
  voucherRedeems,
  _version,
}: {
  id: string;
  redeemAmount: number;
  voucherRedeems: VoucherRedeemData[];
  _version: number;
}) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    let newRemainingAmount = 0;

    let voucherStatus = VoucherStatus.ACTIVE;

    const existingVoucher = await client.graphql({
      query: getVoucher,
      variables: { id },
    });

    if (existingVoucher.data.getVoucher) {
      if (existingVoucher.data.getVoucher.remainingAmount < redeemAmount) {
        throw new Error("Invalid Amount");
      }

      newRemainingAmount =
        existingVoucher.data.getVoucher.remainingAmount - redeemAmount;

      if (newRemainingAmount === 0) {
        voucherStatus = VoucherStatus.EXHAUSTED;
      }
    } else {
      throw new Error("Invalid Voucher");
    }
    const updatedVoucher = await client.graphql({
      query: updateVoucher,
      variables: {
        input: {
          id,
          remainingAmount: newRemainingAmount,
          voucherRedeems: voucherRedeems,
          status: voucherStatus,
          _version,
        },
      },
    });

    return updatedVoucher;
  } catch (error) {
    console.log("🚀 ~ error:", error);
  }
};

const updateEVoucher = async ({
  id,
  assignedUserId,
  totalAmount,
  status,
  chequeReferenceNumber,
  purpose,
  saveAsTemplate,
  validUntil,
  templateName,
  remainingAmount,
  _version,
}: {
  id: string;
  assignedUserId: string;
  totalAmount: number;
  chequeReferenceNumber: string;
  purpose: string;
  saveAsTemplate: boolean;
  templateName: string;
  status: VoucherStatus;
  validUntil: string;
  remainingAmount: number;
  _version: number;
}) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const updatedVoucher = await client.graphql({
      query: updateVoucher,
      variables: {
        input: {
          id,
          assignedUserId,
          totalAmount,
          remainingAmount,
          chequeReferenceNumber,
          purpose,
          saveAsTemplate,
          templateName,
          status,
          validUntil,
          _version,
        },
      },
    });

    return updatedVoucher;
  } catch (error) {
    console.log("🚀 ~ updateEVoucher ~ error:", error);
  }
};

const getSummaryCardDetails = async () => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        searchVouchers: { items },
      },
    } = await client.graphql({
      query: searchVouchers,
      variables: {
        filter: {
          createdOn: {
            range: [
              moment().startOf("month").toISOString(),
              moment().endOf("month").toISOString(),
            ],
          },
        },
      },
    });

    const result = items.reduce(
      (prev, curr) => {
        prev["totalAllocationInMonth"] += curr.totalAmount;
        prev["totalExpenditureInMonth"] +=
          curr.totalAmount - curr.remainingAmount;
        prev["totalRemainingInMonth"] += curr.remainingAmount;

        return prev;
      },
      {
        totalAllocationInMonth: 0,
        totalExpenditureInMonth: 0,
        totalRemainingInMonth: 0,
      }
    );

    return result;
  } catch (error) {
    console.log("🚀 ~ getSummaryCardDetails ~ error:", error);
    return {
      totalAllocationInMonth: 0,
      totalExpenditureInMonth: 0,
      totalRemainingInMonth: 0,
    };
  }
};

const getSummaryCardDetailsForCurrentUser = async (userId: string) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        searchVouchers: { items },
      },
    } = await client.graphql({
      query: searchVouchers,
      variables: {
        filter: {
          createdOn: {
            range: [
              moment().startOf("month").toISOString(),
              moment().endOf("month").toISOString(),
            ],
          },
          assignedUserId: {
            eq: userId,
          },
        },
      },
    });

    const result = items.reduce(
      (prev, curr) => {
        prev["totalAllocationInMonth"] += curr.totalAmount;
        prev["totalExpenditureInMonth"] +=
          curr.totalAmount - curr.remainingAmount;
        prev["totalRemainingInMonth"] += curr.remainingAmount;

        return prev;
      },
      {
        totalAllocationInMonth: 0,
        totalExpenditureInMonth: 0,
        totalRemainingInMonth: 0,
      }
    );

    return result;
  } catch (error) {
    console.log("🚀 ~ getSummaryCardDetails ~ error:", error);
    return {
      totalAllocationInMonth: 0,
      totalExpenditureInMonth: 0,
      totalRemainingInMonth: 0,
    };
  }
};

const deleteEVoucherTemplate = async ({
  id,
  _version,
}: {
  id: string;
  _version: number;
}) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const deleteTemplate = await client.graphql({
      query: updateVoucher,
      variables: {
        input: {
          id,
          _version,
          saveAsTemplate: false,
          templateName: "",
        },
      },
    });

    return deleteTemplate;
  } catch (error) {
    console.log("🚀 ~ deleteEVoucherTemplate ~ error:", error);
  }
};

export {
  fetchAllEVouchers,
  createEVoucher,
  redeemEVoucher,
  updateEVoucher,
  getSummaryCardDetails,
  fetchEVoucherTemplates,
  deleteEVoucherTemplate,
  fetchAllEVouchersAdmin,
  fetchEVoucherTemplatesForCurrentUser,
  getSummaryCardDetailsForCurrentUser,
};
