import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const customListVouchers = /* GraphQL */ `query CustomListVouchers(
  $filter: SearchableVoucherFilterInput
  $sort: [SearchableVoucherSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableVoucherAggregationInput]
) {
  searchVouchers(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      voucherNumber
      assignedUserId
      assignedTo {
        firstName
        lastName
      }
      purpose
      totalAmount
      remainingAmount
      status
      createdOn
      updatedOn
      validUntil
      chequeReferenceNumber
      saveAsTemplate
      templateName
      voucherRedeems {
        amount
        remark
        redeemedByUsername
        redeemedOn
      }
      createdBy
      updatedBy
      deleted
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchVouchersQueryVariables,
  APITypes.SearchVouchersQuery
>;

export const customFetchSalaryRecordForTheMonth =
  `query ListCurrentMonthSalaryDetails($startOfMonth: String, $endOfMonth: String) {
  listSalaryDetails(filter: { paymentDate: { between: [$startOfMonth, $endOfMonth] } }) {
    items {
      id
      userId
      fullName
      position
      basicSalary
      bonus
      deductions
      paymentDate
      bankName
      branch
      accountNumber
      accountType
      currencyType
      advance
      advanceEVoucherRef
    }
  }
}
` as GeneratedQuery<
    APITypes.ListCurrentMonthSalaryDetailsQueryVariables,
    APITypes.ListCurrentMonthSalaryDetailsQuery
  >;

export const customListInvoices = /* GraphQL */ `query customListInvoices(
    $filter: SearchableInvoiceFilterInput
    $sort: [SearchableInvoiceSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInvoiceAggregationInput]
  ) {
    searchInvoices(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        invoiceNumber
        invoiceDate
        consignee
        consigneeAddress
        consigneeEmail
        consigneeTelephone
        consigneeCountry
        paymentDurations
        totalAmount
        totalAmountInWords
        bLNo
        localItems {
          fishType
          quantity
          unitPrice
          totalAmount
          __typename
        }
        exportItems {
          details
          quantity
          unitPrice
          totalAmount
          __typename
        }
        containerNo
        termsOfDelivery
        portOfLoadings
        portOfDischarge
        transitShipment
        partialShipment
        termsOfPayment
        createdOn
        updatedOn
        deliveryNoteNo
        poReference
        poValue
        dueDate
        saved
        invoiceType
        discount
        advance
        accountHolder
        accountNumber
        iban
        accountAddress
        accountCountry
        hideRecord
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
  ` as GeneratedQuery<
  APITypes.SearchInvoicesQueryVariables,
  APITypes.SearchInvoicesQuery
>;

export const customSearchVouchers = /* GraphQL */ `query customSearchVouchers(
  $id: ID
  $filter: ModelVoucherFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listVouchers(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      voucherNumber
      assignedUserId
      assignedTo {
        firstName
        lastName
      }
      purpose
      totalAmount
      remainingAmount
      status
      createdOn
      updatedOn
      validUntil
      chequeReferenceNumber
      saveAsTemplate
      templateName
      createdBy
      updatedBy
      deleted
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVouchersQueryVariables,
  APITypes.ListVouchersQuery
>;

export const customSearchInventories =
  /* GraphQL */ `query CustomSearchInventories(
  $filter: SearchableInventoryFilterInput
  $sort: [SearchableInventorySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableInventoryAggregationInput]
) {
  searchInventories(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      createdOn
      updatedOn
      inventoryItems
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
    APITypes.SearchInventoriesQueryVariables,
    APITypes.SearchInventoriesQuery
  >;
