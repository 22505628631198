import { InvoiceType, LazyCustomers } from "../../models";
import * as Yup from "yup";
import { FieldArray, Form, Formik, getIn } from "formik";
import moment from "moment";
import {
  countries,
  createInvoiceNumber,
  InvoiceTemplateValues,
} from "../../utils";
import ModalWrapper from "../Common/ModalWrapper";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import InputLabelWrapper from "../Common/InputLabelWrapper";
import MuiPhoneNumber from "material-ui-phone-number";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import { updateLocalInvoiceCountWithinDay } from "../../services/common.service";
import { createNewInvoiceFromTemplate } from "../../services";

type LocalInvoiceFromTemplateProps = {
  open: boolean;
  onClose: () => void;
  openPreview: () => void;
  fetchCreatedInvoice: (id: string) => Promise<void>;
  currentInvoiceNumber: number;
  fetchInvoiceMetaData: () => Promise<void>;
  customer: LazyCustomers | null;
};

const validationSchema = Yup.object().shape({
  invoiceDate: Yup.string().required("Required"),
  deliveryNoteNo: Yup.string().required("Required"),
  consignee: Yup.string().required("Required"),
  consigneeId: Yup.string().required("Required"),
  consigneeAddress: Yup.string().required("Required"),
  consigneeEmail: Yup.string().email().required("Required"),
  consigneeTelephone: Yup.string().required("Required"),
  paymentDurations: Yup.string().required("Required"),
  totalAmountInWords: Yup.string().required("Required"),
  localItems: Yup.array().of(
    Yup.object().shape({
      fishType: Yup.string().required("Required"),
      quantity: Yup.number().required("Required"),
      unitPrice: Yup.number().required("Required"),
      totalAmount: Yup.number().required("Required"),
    })
  ),
  totalAmount: Yup.number(),
  poReference: Yup.string().required("Required"),
  poValue: Yup.number().required("Required"),
  invoiceType: Yup.mixed()
    .oneOf(Object.values(InvoiceType), "Invalid Invoice Type")
    .required("Required"),
  saved: Yup.boolean().required("Required"),
  discount: Yup.number().required("Required"),
  advance: Yup.number().required("Required"),
  accountHolder: Yup.string().required("Required"),
  accountNumber: Yup.string().required("Required"),
  iban: Yup.string().required("Required"),
  accountAddress: Yup.string().required("Required"),
  accountCountry: Yup.string().required("Required"),
  invoiceNumber: Yup.string().required("Required"),
});

const LocalInvoiceFromTemplate = ({
  open,
  onClose,
  openPreview,
  fetchCreatedInvoice,
  currentInvoiceNumber,
  fetchInvoiceMetaData,
  customer,
}: LocalInvoiceFromTemplateProps) => {
  const initialValues = {
    invoiceDate: moment().format("YYYY-MM-DD"),
    deliveryNoteNo: "",
    consignee: customer?.customerName ?? "",
    consigneeId: customer?.id ?? "",
    consigneeCountry: customer?.customerCountry ?? "",
    consigneeAddress: customer?.customerAddress ?? "",
    consigneeEmail: customer?.customerEmail ?? "",
    consigneeTelephone: customer?.customerTelephone ?? "",
    paymentDurations: "",
    totalAmountInWords: "",
    localItems: [
      {
        fishType: "",
        quantity: 0,
        unitPrice: 0,
        totalAmount: 0,
      },
    ],
    totalAmount: 0,
    poReference: "",
    poValue: 0,
    invoiceType: (customer?.invoiceType as InvoiceType) ?? InvoiceType.DEFAULT,
    saved: false,
    dueDate: "",
    discount: 0,
    advance: 0,
    accountHolder: "",
    accountNumber: "",
    iban: "",
    accountAddress: "",
    accountCountry: "",
    invoiceNumber: createInvoiceNumber({
      invoiceType:
        (customer?.invoiceType as InvoiceType) ?? InvoiceType.DEFAULT,
      records: currentInvoiceNumber,
    }),
  };

  const createNewLocalInvoice = async (values: InvoiceTemplateValues) => {
    try {
      const { success, data } = await createNewInvoiceFromTemplate(values);

      if (success && data?.createInvoice) {
        return { success: true, id: data.createInvoice.id };
      } else throw new Error("Invoice Creation Error");
    } catch (error) {
      return { success: false, id: null };
    }
  };

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      title="Local Frozen & Fresh "
      subTitle="Create a New Invoice"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const total = values.localItems.reduce(
            (accumulator, item) => accumulator + item.totalAmount,
            0
          );

          values.totalAmount = total - values.advance - values.discount;

          const { success, id } = await createNewLocalInvoice(values);

          const { success: metaDataUpdateStatus } =
            await updateLocalInvoiceCountWithinDay(values.invoiceNumber);

          if (success && id && metaDataUpdateStatus) {
            fetchCreatedInvoice(id);
            fetchInvoiceMetaData();
            resetForm();
            onClose();
            openPreview();
          } else {
            setSubmitting(false);
          }
        }}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          isSubmitting,
          touched,
          dirty,
          resetForm,
          setFieldValue,
        }) => (
          <Form>
            <FieldArray name="localItems">
              {({ push, remove }) => (
                <DialogContent
                  sx={{
                    paddingX: "20px",
                    paddingTop: "40px",
                    paddingBottom: "40px",
                  }}
                >
                  <Grid container spacing={"10px"}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <InputLabelWrapper label="Invoice #">
                        <TextField
                          value={values.invoiceNumber}
                          name="invoiceNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.invoiceNumber && !!errors.invoiceNumber
                          }
                          fullWidth
                          size="small"
                          disabled
                        />
                      </InputLabelWrapper>
                      <InputLabelWrapper label="Delivery Note No">
                        <TextField
                          value={values.deliveryNoteNo}
                          name="deliveryNoteNo"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.deliveryNoteNo && !!errors.deliveryNoteNo
                          }
                          fullWidth
                          size="small"
                        />
                      </InputLabelWrapper>
                      <InputLabelWrapper label="Consignee Address">
                        <TextField
                          value={values.consigneeAddress}
                          name="consigneeAddress"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.consigneeAddress &&
                            !!errors.consigneeAddress
                          }
                          fullWidth
                          size="small"
                        />
                      </InputLabelWrapper>
                      <InputLabelWrapper label="Consignee Email">
                        <TextField
                          value={values.consigneeEmail}
                          name="consigneeEmail"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.consigneeEmail && !!errors.consigneeEmail
                          }
                          fullWidth
                          size="small"
                        />
                      </InputLabelWrapper>
                      <InputLabelWrapper label="PO Reference">
                        <TextField
                          value={values.poReference}
                          name="poReference"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.poReference && !!errors.poReference}
                          fullWidth
                          size="small"
                        />
                      </InputLabelWrapper>
                      <InputLabelWrapper label="Payment Duration">
                        <TextField
                          value={values.paymentDurations}
                          name="paymentDurations"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.paymentDurations &&
                            !!errors.paymentDurations
                          }
                          fullWidth
                          size="small"
                        />
                      </InputLabelWrapper>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <InputLabelWrapper label="Invoice Date">
                        <input
                          type="date"
                          name="invoiceDate"
                          value={values.invoiceDate}
                          placeholder="MM/DD/YYYY"
                          onChange={handleChange}
                          className="date-picker"
                          max={moment().toISOString()}
                        />
                      </InputLabelWrapper>
                      <InputLabelWrapper label="Consignee">
                        <TextField
                          value={values.consignee}
                          name="consignee"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.consignee && !!errors.consignee}
                          fullWidth
                          size="small"
                        />
                      </InputLabelWrapper>
                      <InputLabelWrapper label="Consignee Country">
                        <Select
                          value={values.consigneeCountry}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={"consigneeCountry"}
                          error={
                            touched.consigneeCountry &&
                            !!errors.consigneeCountry
                          }
                          fullWidth
                          size="small"
                        >
                          <MenuItem value="" disabled>
                            -Select-
                          </MenuItem>
                          {countries.map((country, index) => (
                            <MenuItem key={index} value={country}>
                              {country}
                            </MenuItem>
                          ))}
                        </Select>
                      </InputLabelWrapper>
                      <InputLabelWrapper label="Consignee Telephone">
                        <MuiPhoneNumber
                          defaultCountry={"sc"}
                          onChange={(value) => {
                            setFieldValue("consigneeTelephone", value);
                          }}
                          fullWidth
                          variant="outlined"
                          size="small"
                          onBlur={handleBlur}
                          value={values.consigneeTelephone}
                          name="consigneeTelephone"
                          error={
                            touched.consigneeTelephone &&
                            !!errors.consigneeTelephone
                          }
                          helperText={
                            touched.consigneeTelephone
                              ? errors.consigneeTelephone
                              : ""
                          }
                        />
                      </InputLabelWrapper>
                      <InputLabelWrapper label="PO Value">
                        <TextField
                          value={values.poValue}
                          name="poValue"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.poValue && !!errors.poValue}
                          fullWidth
                          size="small"
                        />
                      </InputLabelWrapper>
                      <InputLabelWrapper label="Due Date">
                        <input
                          type="date"
                          name="dueDate"
                          value={values.dueDate}
                          placeholder="MM/DD/YYYY"
                          onChange={handleChange}
                          className="date-picker"
                          max={moment().toISOString()}
                        />
                      </InputLabelWrapper>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        borderRadius: "10px",
                        border: "1px solid var(--Other-Other-Divider, #D6DCE1)",
                        p: "10px",
                        mt: "20px",
                      }}
                    >
                      <TableContainer sx={{ maxHeight: "250px" }}>
                        <Table stickyHeader sx={{ border: "none" }}>
                          <TableHead>
                            <TableRow>
                              {[
                                { header: "#", minWidth: "50px" },
                                { header: "Fish Type", minWidth: "250px" },
                                { header: "Quantity (Kg)", minWidth: "150px" },
                                {
                                  header: "Unit Price (SCR)",
                                  minWidth: "150px",
                                },
                                {
                                  header: "Total Amount (SCR)",
                                  minWidth: "200px",
                                },
                                { header: "", minWidth: "50px" },
                              ].map((head, index) => (
                                <TableCell
                                  key={index}
                                  style={{ minWidth: head.minWidth }}
                                >
                                  <Typography
                                    sx={{
                                      color:
                                        "var(--components-enabled-text-stroke-icon, #646D7C)",
                                      fontSize: "11px",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      lineHeight: "120%",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {head.header}
                                  </Typography>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.localItems.map((item, index) => {
                              const fishType = `localItems[${index}].fishType`;
                              const fishTypeTouched = getIn(touched, fishType);
                              const fishTypeErrors = getIn(errors, fishType);

                              const quantity = `localItems[${index}].quantity`;
                              const quantityTouched = getIn(touched, quantity);
                              const quantityErrors = getIn(errors, quantity);

                              const unitPrice = `localItems[${index}].unitPrice`;
                              const unitPriceTouched = getIn(
                                touched,
                                unitPrice
                              );
                              const unitPriceErrors = getIn(errors, unitPrice);

                              const totalAmount = `localItems[${index}].totalAmount`;
                              const totalAmountTouched = getIn(
                                touched,
                                totalAmount
                              );
                              const totalAmountErrors = getIn(
                                errors,
                                totalAmount
                              );

                              return (
                                <TableRow
                                  sx={{ overflow: "scroll" }}
                                  key={index}
                                >
                                  <TableCell>
                                    <Stack
                                      direction={"row"}
                                      justifyContent={"center"}
                                      alignItems={"center"}
                                      p="5px"
                                      sx={{
                                        borderRadius: "4px",
                                        border:
                                          "1px solid var(--components-enabled-text-stroke-icon, #646D7C)",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color:
                                            "var(--components-cta-text-icon, #1C2940)",
                                          fontSize: "11px",
                                          fontStyle: "normal",
                                          fontWeight: 600,
                                          lineHeight: "120%",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {index + 1}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell sx={{ width: "250px" }}>
                                    <TextField
                                      value={item.fishType}
                                      name={fishType}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={
                                        fishTypeTouched && !!fishTypeErrors
                                      }
                                      fullWidth
                                      size="small"
                                    />
                                  </TableCell>
                                  <TableCell sx={{ width: "150px" }}>
                                    <TextField
                                      value={item.quantity}
                                      name={quantity}
                                      onChange={(e) => {
                                        handleChange(e);

                                        const updatedQuantity =
                                          parseFloat(e.target.value) || 0;

                                        const updatedTotal =
                                          updatedQuantity *
                                          (item.unitPrice || 0);

                                        setFieldValue(
                                          totalAmount,
                                          updatedTotal,
                                          false
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      error={
                                        quantityTouched && !!quantityErrors
                                      }
                                      fullWidth
                                      size="small"
                                    />
                                  </TableCell>
                                  <TableCell sx={{ width: "150px" }}>
                                    <TextField
                                      value={item.unitPrice}
                                      name={unitPrice}
                                      onChange={(e) => {
                                        handleChange(e);

                                        const updatedPrice =
                                          parseFloat(e.target.value) | 0;

                                        const updatedTotal =
                                          updatedPrice * (item.quantity || 0);

                                        setFieldValue(
                                          totalAmount,
                                          updatedTotal,
                                          false
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      error={
                                        unitPriceTouched && !!unitPriceErrors
                                      }
                                      fullWidth
                                      size="small"
                                    />
                                  </TableCell>
                                  <TableCell sx={{ width: "150px" }}>
                                    <TextField
                                      value={item.totalAmount}
                                      name={totalAmount}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={
                                        totalAmountTouched &&
                                        !!totalAmountErrors
                                      }
                                      fullWidth
                                      size="small"
                                      disabled
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <IconButton
                                      onClick={() => {
                                        remove(index);
                                      }}
                                      sx={{
                                        display: index === 0 ? "none" : "auto",
                                      }}
                                    >
                                      <DeleteIcon
                                        sx={{
                                          width: "12px",
                                          height: "12px",
                                          color: "red",
                                        }}
                                      />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                        <Button
                          startIcon={
                            <AddIcon
                              sx={{
                                width: "12px",
                                height: "12px",
                                color: "#646D7C",
                                borderRadius: "50%",
                                border:
                                  "1px solid var(--components-enabled-text-stroke-icon, #646D7C)",
                              }}
                            />
                          }
                          sx={{
                            width: "100px",
                            border:
                              "1px solid var(--components-enabled-text-stroke-icon, #646D7C)",
                            borderRadius: "20px",
                            marginTop: "10px",
                          }}
                          onClick={() => {
                            push({
                              fishType: "",
                              quantity: 0,
                              unitPrice: 0,
                              totalAmount: 0,
                            });
                          }}
                        >
                          <Typography
                            sx={{
                              color:
                                "var(--components-enabled-text-stroke-icon, #646D7C)",
                              fontSize: "8px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              lineHeight: "150%",
                              textTransform: "capitalize",
                            }}
                          >
                            Add New Item
                          </Typography>
                        </Button>
                      </TableContainer>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        mt: "20px",
                      }}
                    >
                      <InputLabelWrapper label="Discount">
                        <TextField
                          value={values.discount}
                          name="discount"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.discount && !!errors.discount}
                          fullWidth
                          size="small"
                        />
                      </InputLabelWrapper>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        mt: "20px",
                      }}
                    >
                      <InputLabelWrapper label="Advance">
                        <TextField
                          value={values.advance}
                          name="advance"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.advance && !!errors.advance}
                          fullWidth
                          size="small"
                        />
                      </InputLabelWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabelWrapper label="Total Amount in Words">
                        <TextField
                          value={values.totalAmountInWords}
                          name="totalAmountInWords"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.totalAmountInWords &&
                            !!errors.totalAmountInWords
                          }
                          fullWidth
                          size="small"
                        />
                      </InputLabelWrapper>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <InputLabelWrapper label="Account Holder">
                        <TextField
                          value={values.accountHolder}
                          name="accountHolder"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.accountHolder && !!errors.accountHolder
                          }
                          fullWidth
                          size="small"
                        />
                      </InputLabelWrapper>
                      <InputLabelWrapper label="IBAN / Swift Code">
                        <TextField
                          value={values.iban}
                          name="iban"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.iban && !!errors.iban}
                          fullWidth
                          size="small"
                        />
                      </InputLabelWrapper>
                      <InputLabelWrapper label="Account Holder Country">
                        <Select
                          value={values.accountCountry}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={"accountCountry"}
                          error={
                            touched.accountCountry && !!errors.accountCountry
                          }
                          fullWidth
                          size="small"
                        >
                          <MenuItem value="" disabled>
                            -Select-
                          </MenuItem>
                          {countries.map((country, index) => (
                            <MenuItem key={index} value={country}>
                              {country}
                            </MenuItem>
                          ))}
                        </Select>
                      </InputLabelWrapper>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <InputLabelWrapper label="Account Number">
                        <TextField
                          value={values.accountNumber}
                          name="accountNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.accountNumber && !!errors.accountNumber
                          }
                          fullWidth
                          size="small"
                        />
                      </InputLabelWrapper>
                      <InputLabelWrapper label="Account Holder Address">
                        <TextField
                          value={values.accountAddress}
                          name="accountAddress"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.accountAddress && !!errors.accountAddress
                          }
                          fullWidth
                          size="small"
                        />
                      </InputLabelWrapper>
                    </Grid>
                  </Grid>
                </DialogContent>
              )}
            </FieldArray>
            <DialogActions
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "20px",
                gap: "10px",
              }}
            >
              <Button
                sx={{ width: "100px", height: "32px" }}
                onClick={() => {
                  resetForm();
                  onClose();
                }}
              >
                <Typography
                  sx={{
                    color:
                      "var(--components-enabled-text-stroke-icon, #646D7C)",
                    textAlign: "center",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  Cancel
                </Typography>
              </Button>
              <LoadingButton
                sx={{
                  borderRadius: "6px",
                  background: "var(--Components-CTA-BG, #4CE6FA)",
                  ":hover": {
                    background: "var(--Components-CTA-BG, #4CE6FA)",
                  },
                  padding: "10px",
                }}
                disabled={isSubmitting || !dirty || !isValid}
                loading={isSubmitting}
                onClick={() => {
                  handleSubmit();
                }}
              >
                <Typography
                  sx={{
                    color: "var(--components-cta-text-icon, #1C2940)",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  Generate Invoice
                </Typography>
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
};

export default LocalInvoiceFromTemplate;
