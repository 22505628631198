import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { GridColDef } from "@mui/x-data-grid";
import DataGridComponent from "../Common/DataGridComponent";
import { useEffect, useState } from "react";
import { employeeSalaryRecordReport } from "../../services";
import { LazySalaryDetails } from "../../models";
import DataGridColumnData from "../Common/DataGridColumnData";
import DataGridColumnHeader from "../Common/DataGridColumnHeader";
import FilterListIcon from "@mui/icons-material/FilterList";
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CancelIcon from "@mui/icons-material/Cancel";
import moment, { Moment } from "moment";
import { quarters } from "../../utils";
import SearchIcon from "@mui/icons-material/Search";

type EmployeeSalaryReportProps = {
  open: boolean;
  onClose: () => void;
};

const EmployeeSalaryReport = ({ open, onClose }: EmployeeSalaryReportProps) => {
  const columns: GridColDef[] = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "userId",
      headerName: "Employee ID",
      flex: 1,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Employee ID" />,
    },
    {
      field: "fullName",
      headerName: "Full Name",
      width: 200,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Full Name" />,
    },
    {
      field: "position",
      headerName: "Position",
      width: 200,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Position" />,
    },
    {
      field: "basicSalary",
      headerName: "Base Salary",
      flex: 1,
      renderCell: (params) => (
        <DataGridColumnData
          content={`$${params.value.toLocaleString("en-US")}`}
        />
      ),
      renderHeader: () => <DataGridColumnHeader headerName="Base Salary" />,
    },
    {
      field: "bonus",
      headerName: "Bonuses",
      flex: 1,
      valueFormatter: ({ value }) => `$${value.toLocaleString()}`,
      renderCell: (params) => (
        <DataGridColumnData
          content={`$${params.value.toLocaleString("en-US")}`}
        />
      ),
      renderHeader: () => <DataGridColumnHeader headerName="Bonuses" />,
    },
    {
      field: "deductions",
      headerName: "Deductions",
      flex: 1,
      renderCell: (params) => (
        <DataGridColumnData
          content={`$${params.value.toLocaleString("en-US")}`}
        />
      ),
      renderHeader: () => <DataGridColumnHeader headerName="Deductions" />,
    },
    {
      field: "totalSalary",
      headerName: "Total Salary",
      flex: 1,
      valueGetter: (params) => {
        const { basicSalary, bonus, deductions } = params.row;
        return basicSalary + bonus - deductions;
      },
      renderCell: (params) => (
        <DataGridColumnData
          content={`$${params.value.toLocaleString("en-US")}`}
        />
      ),
      renderHeader: () => <DataGridColumnHeader headerName="Total Salary" />,
    },
    {
      field: "paymentDate",
      headerName: "Payment Date",
      flex: 1,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Payment Date" />,
    },
  ];

  const [anchorElDuration, setAnchorElDuration] =
    React.useState<null | HTMLElement>(null);
  const [anchorElMonth, setAnchorElMonth] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElQuarter, setAnchorElQuarter] =
    React.useState<null | HTMLElement>(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [totalRows, setTotalRows] = useState(0);

  const [selectedDuration, setSelectedDuration] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedQuarter, setSelectedQuarter] = useState<{
    start: Moment | null;
    end: Moment | null;
    quarter: string;
  }>({
    start: null,
    end: null,
    quarter: "",
  });

  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);

  const openDuration = Boolean(anchorElDuration);
  const openMonth = Boolean(anchorElMonth);
  const openQuarter = Boolean(anchorElQuarter);

  const handleClickDuration = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElDuration(event.currentTarget);
  };
  const handleClickMonth = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElMonth(event.currentTarget);
  };
  const handleClickQuarter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElQuarter(event.currentTarget);
  };

  const handleCloseMonth = () => {
    setAnchorElMonth(null);
  };
  const handleCloseDuration = () => {
    setAnchorElDuration(null);
  };
  const handleCloseQuarter = () => {
    setAnchorElQuarter(null);
  };

  const [loadingReport, setLoadingReport] = useState(false);
  const [rows, setRows] = useState<LazySalaryDetails[]>([]);

  const fetchSalaryRecords = async () => {
    try {
      setLoadingReport(true);

      const { rows } = await employeeSalaryRecordReport({
        month: selectedMonth,
        quarter: { start: selectedQuarter.start, end: selectedQuarter.end },
        startDate: startDate,
        endDate: endDate,
      });

      setRows(rows);
      setTotalRows(rows.length);
    } catch (error) {
      console.log("🚀 ~ fetchSalaryRecords ~ error:", error);
    } finally {
      setLoadingReport(false);
    }
  };

  useEffect(() => {
    fetchSalaryRecords();
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          padding: "20px",
          background: "url(/background-images/modal-header.png)",
          mixBlendMode: "plus-darker",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          spacing={"10px"}
        >
          <Typography
            sx={{
              color: "var(--Text-On-Dark-Backgrounds-Main, #B4C3CE)",
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            Generate Report
          </Typography>
          <Typography
            sx={{
              color: "var(--Components-Enabled-BG, #FFF)",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              textTransform: "capitalize",
            }}
          >
            Employee Salary Record
          </Typography>
        </Stack>
        <IconButton
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon sx={{ width: "20px", height: "20px", color: "#FFFFFF" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} mb={"20px"} mt="30px">
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              spacing={"20px"}
            >
              <Stack
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                spacing={"5px"}
              >
                <IconButton>
                  <FilterListIcon
                    sx={{ color: "#8896A0", width: "16px", height: "16px" }}
                  />
                </IconButton>
                <Typography
                  sx={{
                    color:
                      "var(--components-enabled-text-stroke-icon, #646D7C)",
                    fontSize: "11px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  Filter
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack
                  direction="row"
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                >
                  <Typography
                    fontWeight={400}
                    fontSize={"11px"}
                    lineHeight={"100%"}
                    color={"#8896A0"}
                    textTransform={"capitalize"}
                  >
                    Duration
                  </Typography>
                  <Button
                    id="basic-button"
                    aria-controls={openDuration ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openDuration ? "true" : undefined}
                    onClick={handleClickDuration}
                    endIcon={
                      <ArrowDropDownIcon
                        sx={{
                          width: "16px",
                          height: "16px",
                          color: "#0D3C60",
                        }}
                      />
                    }
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "5px",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Tooltip
                      title={<Typography>{selectedDuration}</Typography>}
                    >
                      <Typography
                        sx={{
                          color: "#0D3C60",
                          maxWidth: "150px",
                          fontWeight: 700,
                        }}
                        fontSize={"11px"}
                        noWrap
                        textTransform={"none"}
                      >
                        {selectedDuration}
                      </Typography>
                    </Tooltip>
                  </Button>
                </Stack>
                <IconButton
                  sx={{ width: "11px", height: "11px" }}
                  onClick={() => setSelectedDuration("")}
                >
                  <CancelIcon
                    sx={{ width: "11px", height: "11px", color: "#0D3C60" }}
                  />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElDuration}
                  open={openDuration}
                  onClose={handleCloseDuration}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    sx: {
                      borderRadius: "10px",
                      background: "#fff",
                      boxShadow: "0px 8px 20px 0px rgba(13, 60, 96, 0.12)",
                      width: "196px",
                    },
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    sx={{
                      background: "#fff",
                      p: "10px",
                      borderBottom: "1px solid #E9EEF3",
                    }}
                  >
                    <Typography
                      fontWeight={700}
                      fontSize={"11px"}
                      color={"#5B7589"}
                    >
                      Durations
                    </Typography>
                  </Stack>
                  <Box p="10px">
                    {["Weekly", "Monthly", "Quarterly", "Yearly"].map(
                      (duration, index) => (
                        <MenuItem
                          onClick={() => {
                            setSelectedDuration(duration);
                          }}
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <ListItemText
                            sx={{
                              color: "#0D3C60",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              borderRadius: "12px",
                              [`& .MuiTypography-root`]: {
                                fontSize: "10px",
                                fontWeight: 700,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                columnGap: "4px",
                              },
                              px: "10px",
                            }}
                          >
                            {duration}
                          </ListItemText>
                        </MenuItem>
                      )
                    )}
                  </Box>
                </Menu>
              </Stack>
              {selectedDuration === "Quarterly" && (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                  >
                    <Typography
                      fontWeight={400}
                      fontSize={"11px"}
                      lineHeight={"100%"}
                      color={"#8896A0"}
                      textTransform={"capitalize"}
                    >
                      Quarter
                    </Typography>
                    <Button
                      id="basic-button"
                      aria-controls={openQuarter ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openQuarter ? "true" : undefined}
                      onClick={handleClickQuarter}
                      endIcon={
                        <ArrowDropDownIcon
                          sx={{
                            width: "16px",
                            height: "16px",
                            color: "#0D3C60",
                          }}
                        />
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        columnGap: "5px",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Tooltip
                        title={
                          <Typography>{selectedQuarter.quarter}</Typography>
                        }
                      >
                        <Typography
                          sx={{
                            color: "#0D3C60",
                            maxWidth: "150px",
                            fontWeight: 700,
                          }}
                          fontSize={"11px"}
                          noWrap
                          textTransform={"none"}
                        >
                          {selectedQuarter.quarter}
                        </Typography>
                      </Tooltip>
                    </Button>
                  </Stack>
                  <IconButton
                    sx={{ width: "11px", height: "11px" }}
                    onClick={() =>
                      setSelectedQuarter({
                        start: null,
                        end: null,
                        quarter: "",
                      })
                    }
                  >
                    <CancelIcon
                      sx={{ width: "11px", height: "11px", color: "#0D3C60" }}
                    />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElQuarter}
                    open={openQuarter}
                    onClose={handleCloseQuarter}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    slotProps={{
                      paper: {
                        sx: {
                          borderRadius: "10px",
                          background: "#fff",
                          boxShadow: "0px 8px 20px 0px rgba(13, 60, 96, 0.12)",
                          width: "196px",
                        },
                      },
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      sx={{
                        background: "#fff",
                        p: "10px",
                        borderBottom: "1px solid #E9EEF3",
                      }}
                    >
                      <Typography
                        fontWeight={700}
                        fontSize={"11px"}
                        color={"#5B7589"}
                      >
                        Quarters
                      </Typography>
                    </Stack>
                    <Box p="10px">
                      {quarters.map((quarter, index) => (
                        <MenuItem
                          onClick={() => {
                            setSelectedQuarter({
                              start: quarter.start,
                              end: quarter.end,
                              quarter: quarter.quarter,
                            });
                          }}
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <ListItemText
                            sx={{
                              color: "#0D3C60",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              borderRadius: "12px",
                              [`& .MuiTypography-root`]: {
                                fontSize: "10px",
                                fontWeight: 700,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                columnGap: "4px",
                              },
                              px: "10px",
                            }}
                          >
                            {quarter.quarter}
                          </ListItemText>
                        </MenuItem>
                      ))}
                    </Box>
                  </Menu>
                </Stack>
              )}
              {selectedDuration === "Monthly" && (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                  >
                    <Typography
                      fontWeight={400}
                      fontSize={"11px"}
                      lineHeight={"100%"}
                      color={"#8896A0"}
                      textTransform={"capitalize"}
                    >
                      Month
                    </Typography>
                    <Button
                      id="basic-button"
                      aria-controls={openMonth ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMonth ? "true" : undefined}
                      onClick={handleClickMonth}
                      endIcon={
                        <ArrowDropDownIcon
                          sx={{
                            width: "16px",
                            height: "16px",
                            color: "#0D3C60",
                          }}
                        />
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        columnGap: "5px",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Tooltip title={<Typography>{selectedMonth}</Typography>}>
                        <Typography
                          sx={{
                            color: "#0D3C60",
                            maxWidth: "150px",
                            fontWeight: 700,
                          }}
                          fontSize={"11px"}
                          noWrap
                          textTransform={"none"}
                        >
                          {selectedMonth}
                        </Typography>
                      </Tooltip>
                    </Button>
                  </Stack>
                  <IconButton
                    sx={{ width: "11px", height: "11px" }}
                    onClick={() => setSelectedMonth("")}
                  >
                    <CancelIcon
                      sx={{ width: "11px", height: "11px", color: "#0D3C60" }}
                    />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElMonth}
                    open={openMonth}
                    onClose={handleCloseMonth}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    PaperProps={{
                      sx: {
                        borderRadius: "10px",
                        background: "#fff",
                        boxShadow: "0px 8px 20px 0px rgba(13, 60, 96, 0.12)",
                        width: "196px",
                      },
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      sx={{
                        background: "#fff",
                        p: "10px",
                        borderBottom: "1px solid #E9EEF3",
                      }}
                    >
                      <Typography
                        fontWeight={700}
                        fontSize={"11px"}
                        color={"#5B7589"}
                      >
                        Month
                      </Typography>
                    </Stack>
                    <Box p="10px">
                      {[
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December",
                      ].map((month, index) => (
                        <MenuItem
                          onClick={() => {
                            setSelectedMonth(month);
                          }}
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <ListItemText
                            sx={{
                              color: "#0D3C60",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              borderRadius: "12px",
                              [`& .MuiTypography-root`]: {
                                fontSize: "10px",
                                fontWeight: 700,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                columnGap: "4px",
                              },
                              px: "10px",
                            }}
                          >
                            {month}
                          </ListItemText>
                        </MenuItem>
                      ))}
                    </Box>
                  </Menu>
                </Stack>
              )}
              {(selectedDuration === "Yearly" ||
                selectedDuration === "Weekly") && (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing="5px"
                  justifyContent={"space-between"}
                >
                  <Typography
                    fontSize={"11px"}
                    lineHeight={"100%"}
                    fontWeight={400}
                    color="#8896A0"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    Date
                  </Typography>
                  <TextField
                    value={startDate ? startDate.format("YYYY-MM-DD") : ""}
                    name="startDate"
                    size="small"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setStartDate(moment(e.target.value));
                    }}
                  />
                  <Typography
                    fontSize={"11px"}
                    lineHeight={"100%"}
                    fontWeight={400}
                    color="#8896A0"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    -
                  </Typography>
                  <TextField
                    value={endDate ? endDate.format("YYYY-MM-DD") : ""}
                    name="endDate"
                    size="small"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      console.log(
                        "🚀 ~ EmployeeSalaryReport ~ e:",
                        e.target.value
                      );
                      setEndDate(moment(e.target.value));
                    }}
                  />
                  <IconButton
                    sx={{ width: "11px", height: "11px" }}
                    onClick={() => {
                      setStartDate(null);
                      setEndDate(null);
                    }}
                  >
                    <CancelIcon
                      sx={{ width: "11px", height: "11px", color: "#0D3C60" }}
                    />
                  </IconButton>
                </Stack>
              )}
              <IconButton
                onClick={() => {
                  fetchSalaryRecords();
                }}
              >
                <SearchIcon sx={{ width: "16px", height: "16px" }} />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12} mb={"20px"} mt="30px">
            <DataGridComponent
              columns={columns}
              rows={rows}
              loading={loadingReport}
              totalRows={totalRows}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              checkboxSelection={false}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EmployeeSalaryReport;
