import { listUsers } from "../graphql/queries";
import { UserType } from "../models";
import { EmailData } from "../utils";
import { generateClient, post } from "aws-amplify/api";

const from = "info@datasagecorp.com";
const emailAPIName = "sendemail";
const emailPath = "/send-email";

const sendEmail = async ({ to, subject, body }: EmailData) => {
  try {
    const result = await post({
      apiName: emailAPIName,
      path: emailPath,
      options: {
        body: {
          to,
          subject,
          body,
          from,
        },
      },
    });

    const emailResponse = await result.response;

    if (emailResponse.statusCode === 200) {
      return { success: true, error: null };
    } else {
      throw new Error("Email sending error");
    }
  } catch (error) {
    return { success: false, error };
  }
};

const notifySuperAdmins = async ({
  subject,
  body,
}: {
  subject: string;
  body: string;
}) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        listUsers: { items },
      },
    } = await client.graphql({
      query: listUsers,
      variables: {
        filter: {
          userType: {
            eq: UserType.SUPER_ADMIN,
          },
        },
      },
    });

    const superAdminEmails = items.map((item) => item.email);

    const result = await post({
      apiName: emailAPIName,
      path: emailPath,
      options: {
        body: {
          to: superAdminEmails,
          subject,
          body,
          from,
        },
      },
    });

    const emailResponse = await result.response;

    if (emailResponse.statusCode === 200) {
      return { success: true, error: null };
    } else {
      throw new Error("Email sending error");
    }
  } catch (error) {
    return { success: false, error };
  }
};

const notifyUserByUserId = async ({
  userId,
  subject,
  body,
}: {
  userId: string;
  subject: string;
  body: string;
}) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        listUsers: { items },
      },
    } = await client.graphql({
      query: listUsers,
      variables: {
        filter: {
          userId: {
            eq: userId,
          },
        },
      },
    });

    const userEmail = items[0].email;

    const result = await post({
      apiName: emailAPIName,
      path: emailPath,
      options: {
        body: {
          to: [userEmail],
          subject,
          body,
          from,
        },
      },
    });

    const emailResponse = await result.response;

    if (emailResponse.statusCode === 200) {
      return { success: true, error: null };
    } else {
      throw new Error("Email sending error");
    }
  } catch (error) {
    return { success: false, error };
  }
};

export { sendEmail, notifySuperAdmins, notifyUserByUserId };
