import moment from "moment";

const accountCreationMail = (password: string, domain: string) => {
  return {
    subject: "Your Island Catch Trackify account has been created",
    body: `Hello,\n\n
    Your temporary password is: ${password}.\n\n
    Please use this password to log in to your account after confirming your email with the 6-digit OTP.\n\n
    To confirm your email, visit: ${domain}/confirm-email.\n\n
    If you have any questions or need assistance, feel free to contact our support team.\n\n
    Best regards,\n
    Island Catch - Trackify Team`,
  };
};

const notifyNewlyCreatedUserTiSuperAdmins = ({
  firstName,
  lastName,
  email,
}: {
  firstName: string;
  lastName: string;
  email: string;
}) => {
  return {
    subject: "New User is Added Islands Catch Trackify",
    body: `Hello Super Admins,\n\n
    We are pleased to inform you that ${firstName} ${lastName} (Email: ${email}) has been successfully added to Trackify.\n\n
    Thank you for keeping everything on track!\n\n
    Best regards,\n
    Island Catch - Trackify Team`,
  };
};

const eVoucherAllocation = ({
  totalAmount,
  purpose,
}: {
  totalAmount: number;
  purpose: string;
}) => {
  return {
    subject: "E Voucher Allocation",
    body: `Hello,\n\n
    You have received an E-Voucher worth $${totalAmount} for ${purpose}.\n\n
    Enjoy your voucher!\n\n
    Best regards,\n
    Island Catch - Trackify Team`,
  };
};

const notifyNewEVoucherAllocationToSuperAdmins = ({
  selectedUser,
  totalAmount,
  purpose,
}: {
  selectedUser: string;
  totalAmount: number;
  purpose: string;
}) => {
  return {
    subject: "New E Voucher Allocation",
    body: `Hello Super Admins,\n\n
    A new E-Voucher has been allocated to ${selectedUser}, worth $${totalAmount}, for ${purpose}.\n\n
    Thank you for keeping everything on track!\n\n
    Best regards,\n
    Island Catch - Trackify Team`,
  };
};

const editEVoucherNotification = ({
  totalAmount,
  purpose,
}: {
  totalAmount: number;
  purpose: string;
}) => {
  return {
    subject: "E Voucher Edit Event",
    body: `Hello,\n\n
    You have received an E-Voucher worth $${totalAmount} for ${purpose}.\n\n
    Enjoy your voucher!\n\n
    Best regards,\n
    Island Catch - Trackify Team`,
  };
};

const notifyEVoucherEditToSuperAdmins = ({
  voucherNumber,
}: {
  voucherNumber: string;
}) => {
  return {
    subject: "E Voucher Edit Event",
    body: `Hello Super Admins,\n\n
    The E-Voucher with number ${voucherNumber} has been successfully edited.\n\n
    Thank you for keeping everything on track!\n\n
    Best regards,\n
    Island Catch - Trackify Team`,
  };
};

const eVoucherRedeemNotification = ({
  amount,
  remark,
  voucherNumber,
}: {
  amount: number;
  remark: string;
  voucherNumber: string;
}) => {
  return {
    subject: `Successfully Redeemed E Voucher ${voucherNumber}`,
    body: `Hello,\n\n
    You have successfully redeemed $${amount} for ${remark} from the voucher ${voucherNumber}.\n\n
    Best regards,\n
    Island Catch - Trackify Team`,
  };
};

const notifyEVoucherRedeemToSuperAdmins = ({
  voucherNumber,
  amount,
  remark,
  redeemedBy,
}: {
  voucherNumber: string;
  amount: number;
  remark: string;
  redeemedBy: string;
}) => {
  return {
    subject: `Successfully Redeemed E Voucher ${voucherNumber}`,
    body: `Hello Super Admins,\n\n
    The E-Voucher with number ${voucherNumber} has been redeemed successfully.\n\n
    Details:\n
    - Amount: $${amount}\n
    - Purpose: ${remark}\n
    - Redeemed By: ${redeemedBy}\n\n
     Thank you for keeping everything on track!\n\n
     Best regards,\n
     Island Catch - Trackify Team`,
  };
};

const notifySignInToSuperAdmins = ({ name }: { name: string }) => {
  return {
    subject: `Sign In Event`,
    body: `Hello Super Admins,\n\n
    ${name} signed in to Trackify on ${moment().format(
      "YYYY-MM-DD HH:mm:ss"
    )}.\n\n
    Thank you for keeping everything on track!\n\n
    Best regards,\n
    Island Catch - Trackify Team`,
  };
};

const notifySignOutToSuperAdmins = ({ name }: { name: string }) => {
  return {
    subject: "Sign Out Event",
    body: `Hello Super Admins,\n\n
    ${name} signed out from Trackify on ${moment().format(
      "YYYY-MM-DD HH:mm:ss"
    )}.\n\n
    Thank you for keeping everything on track!\n\n
    Best regards,\n
    Island Catch - Trackify Team`,
  };
};

export {
  accountCreationMail,
  notifyNewlyCreatedUserTiSuperAdmins,
  eVoucherAllocation,
  notifyNewEVoucherAllocationToSuperAdmins,
  editEVoucherNotification,
  notifyEVoucherEditToSuperAdmins,
  eVoucherRedeemNotification,
  notifyEVoucherRedeemToSuperAdmins,
  notifySignInToSuperAdmins,
  notifySignOutToSuperAdmins,
};
