import React from "react";
import ModalWrapper from "../Common/ModalWrapper";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  DialogContent,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import InputLabelWrapper from "../Common/InputLabelWrapper";
import { LoadingButton } from "@mui/lab";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { updateCurrentPassword } from "../../services";
import Swal from "sweetalert2";
import { signOut } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";

type PasswordChangeProps = {
  open: boolean;
  onClose: () => void;
};

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Required"),
  newPassword: Yup.string()
    .required("Required")
    .notOneOf(
      [Yup.ref("oldPassword")],
      "New Password cannot be the same as the old password"
    )
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^])(?!.*\s)[A-Za-z\d@$!%*?&#^]{8,}$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and no spaces"
    ),
});

const PasswordChange = ({ open, onClose }: PasswordChangeProps) => {
  const navigate = useNavigate();

  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);

  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleMouseDownOldPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseDownNewPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseUpOldPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseUpNewPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      title={"Change Password"}
      subTitle={""}
    >
      <Formik
        initialValues={{ oldPassword: "", newPassword: "" }}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          const { success } = await updateCurrentPassword({
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          });

          if (success) {
            setSubmitting(false);
            resetForm();
            onClose();
            Swal.fire({
              title: "Password Update",
              text: "Password Successfully Updated",
              icon: "success",
            }).then(async (res) => {
              if (res.isConfirmed) {
                await signOut();
                navigate("/");
              }
            });
          } else {
            setSubmitting(false);
            Swal.fire({
              title: "Password Update",
              text: "Password Update Error",
              icon: "error",
            });
          }
        }}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          isValid,
          isSubmitting,
          touched,
          dirty,
          handleSubmit,
        }) => (
          <Form>
            <DialogContent
              sx={{
                paddingX: "20px",
                paddingTop: "40px",
                paddingBottom: "40px",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <InputLabelWrapper label="Current Password">
                    <OutlinedInput
                      type={showOldPassword ? "text" : "password"}
                      value={values.oldPassword}
                      name="oldPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.oldPassword && !!errors.oldPassword}
                      fullWidth
                      size="small"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowOldPassword}
                            onMouseDown={handleMouseDownOldPassword}
                            onMouseUp={handleMouseUpOldPassword}
                            edge="end"
                          >
                            {showOldPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText sx={{ color: "#FF0C3E" }}>
                      {errors.oldPassword}
                    </FormHelperText>
                  </InputLabelWrapper>
                  <InputLabelWrapper label="New Password">
                    <OutlinedInput
                      type={showNewPassword ? "text" : "password"}
                      value={values.newPassword}
                      name="newPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.newPassword && !!errors.newPassword}
                      fullWidth
                      size="small"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                            onMouseDown={handleMouseDownNewPassword}
                            onMouseUp={handleMouseUpNewPassword}
                            edge="end"
                          >
                            {showNewPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText sx={{ color: "#FF0C3E" }}>
                      {errors.newPassword}
                    </FormHelperText>
                  </InputLabelWrapper>
                  <LoadingButton
                    sx={{
                      borderRadius: "6px",
                      background: "var(--Components-CTA-BG, #4CE6FA)",
                      ":hover": {
                        background: "var(--Components-CTA-BG, #4CE6FA)",
                      },
                      padding: "10px",
                    }}
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={isSubmitting || !dirty || !isValid}
                    loading={isSubmitting}
                    fullWidth
                  >
                    <Typography
                      sx={{
                        color: "var(--components-cta-text-icon, #1C2940)",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "120%",
                        textTransform: "capitalize",
                      }}
                    >
                      Update Password
                    </Typography>
                  </LoadingButton>
                </Grid>
              </Grid>
            </DialogContent>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
};

export default PasswordChange;
