import { Grid, View } from "@aws-amplify/ui-react";
import LoginPageBanner from "../components/Auth/LoginPageBanner";
import SignInForm from "../components/Auth/SignInForm";

const SignIn = () => {
  return (
    <Grid
      templateColumns={{ base: "1fr", large: "1fr 1fr" }}
      height={"100vh"}
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <View height={"100%"}>
        <SignInForm />
      </View>
      <View height={"100%"}>
        <LoginPageBanner />
      </View>
    </Grid>
  );
};

export default SignIn;
