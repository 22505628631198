import React from "react";
import { useState } from "react";
import {
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Grid,
  Icon,
  Stack,
  Popover,
  Avatar,
  Button,
  Collapse,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
// icons
import LogoutIcon from "@mui/icons-material/Logout";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import PersonPinRoundedIcon from "@mui/icons-material/PersonPinRounded";
import PasswordRoundedIcon from "@mui/icons-material/PasswordRounded";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";

import PasswordChange from "../components/Auth/PasswordChange";
import Swal from "sweetalert2";
import { hideAllInvoices } from "../services";
import { useAuth } from "../context/AuthContext";
import { UserType } from "../models";

export interface NonTutorProfileLayoutProps {
  name: string;
}

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const UserLayout = ({
  children,
  fullName,
  email,
  location,
  sideMenu,
  logout,
  navigate,
  mode = "non-tutor",
  color = "#1C2940",
  drawerIconColor = "#ffffff",
  scrollMainContainer = "hidden",
  profilePicture,
}: {
  children?: any;
  fullName: string;
  email: string;
  location: string;
  sideMenu: {
    index: number;
    name: string;
    path: string;
    pathName?: string;
    elementId: string;
    newTab?: boolean;
    icon: any;
    subMenu?: {
      name: string;
      path: string;
      pathName?: string;
      elementId: string;
    }[];
  }[];
  logout: (() => void) | undefined;
  navigate: any;
  mode?: string;
  color?: string;
  drawerIconColor?: string;
  scrollMainContainer?: string;
  profilePicture?: string;
}) => {
  const { currentUser } = useAuth();

  const [open, setOpen] = useState(true);
  const [openSubMenu, setOpenSubMenu] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(100);

  const [openPasswordUpdate, setOpenPasswordUpdate] = useState(false);

  const [anchorElUserMenu, setAnchorElUserMenu] =
    useState<HTMLButtonElement | null>(null);

  const [selectedPath, setSelectedPath] = useState("");

  const openUserMenu = Boolean(anchorElUserMenu);
  const idUserMenu = openUserMenu ? "simple-popover" : undefined;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenSubMenu(false);
  };

  const scroll = (elementId: string) => {
    const section = document.querySelector(elementId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleClickUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUserMenu(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUserMenu(null);
  };

  return (
    <Box display={"flex"}>
      <CssBaseline />
      <AppBar
        sx={{
          background: " #FFFFFF",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.04)",
          width: "100%",
          position: "fixed",
        }}
      >
        <Toolbar
          sx={{
            height: "56px",
          }}
          variant="dense"
        >
          <Grid container>
            <Grid
              item
              container
              xs={12}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid
                item
                display={"flex"}
                alignItems={"center"}
                columnGap={"10px"}
              >
                <IconButton
                  sx={{
                    boxShadow: "none",
                    background: "none",
                    ":hover": { boxShadow: "none", background: "none" },
                    p: 0,
                    display: { xs: "none", md: "inline-flex" },
                  }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    src="/logo-ic.png"
                    alt="logo"
                    width="42px"
                    height="24px"
                  />
                </IconButton>
                <IconButton
                  sx={{
                    boxShadow: "none",
                    background: "none",
                    ":hover": { boxShadow: "none", background: "none" },
                    p: 0,
                    display: { xs: "inline-flex", md: "none" },
                  }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    src="/ic-small.png"
                    alt="logo"
                    width="42px"
                    height="24px"
                  />
                </IconButton>
                <Typography
                  sx={{
                    color: "var(--components-cta-text-icon, #1C2940)",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  Island Catch Trackify
                </Typography>
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={"10px"}>
                  <IconButton
                    sx={{ width: "24px", height: "24px" }}
                    onClick={handleClickUserMenu}
                  >
                    <Avatar
                      id="nt-profile-layout-profile-pic-avatar"
                      alt="Remy Sharp"
                      sx={{ height: "24px", width: "24px" }}
                      src={profilePicture ?? ""}
                    />
                  </IconButton>
                  <Popover
                    id={idUserMenu}
                    open={openUserMenu}
                    anchorEl={anchorElUserMenu}
                    onClose={handleCloseUserMenu}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    slotProps={{
                      paper: {
                        sx: {
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                          borderRadius: "5px 0px 15px 15px",
                        },
                      },
                    }}
                  >
                    <Box sx={{ borderRadius: "5px 0px 15px 15px" }}>
                      <Box>
                        <Button
                          sx={{ height: "56px", columnGap: "40px" }}
                          fullWidth
                          onClick={() => {
                            navigate("/");
                          }}
                        >
                          <Stack
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            sx={{ width: "100%", px: "40px" }}
                            spacing="40px"
                          >
                            <PersonPinRoundedIcon
                              sx={{
                                width: "20px",
                                height: "20px",
                                color: "#0D3C60",
                              }}
                            />
                            <Typography
                              fontWeight={700}
                              fontSize="12px"
                              lineHeight={"16px"}
                              color="#092941"
                              textTransform={"none"}
                            >
                              My Profile
                            </Typography>
                          </Stack>
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          sx={{ height: "56px", columnGap: "40px" }}
                          fullWidth
                          onClick={() => {
                            setOpenPasswordUpdate(true);
                          }}
                        >
                          <Stack
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            sx={{ width: "100%", px: "40px" }}
                            spacing="40px"
                          >
                            <PasswordRoundedIcon
                              sx={{
                                width: "20px",
                                height: "20px",
                                color: "#0D3C60",
                              }}
                            />
                            <Typography
                              fontWeight={700}
                              fontSize="12px"
                              lineHeight={"16px"}
                              color="#092941"
                              textTransform={"none"}
                            >
                              Change Password
                            </Typography>
                          </Stack>
                        </Button>
                      </Box>
                      {currentUser &&
                        currentUser.userType === UserType.SUPER_ADMIN && (
                          <Box>
                            <Button
                              sx={{ height: "56px", columnGap: "40px" }}
                              fullWidth
                              onClick={() => {
                                handleCloseUserMenu();
                                Swal.fire({
                                  title: "Are you sure?",
                                  text: "Do you want to reconnect the trackify application?",
                                  icon: "question",
                                  showCancelButton: true,
                                }).then(async (res) => {
                                  if (res.isConfirmed) {
                                    const { success } = await hideAllInvoices();

                                    if (success) {
                                      Swal.fire({
                                        title: "Application Reconnected",
                                        text: "Application Reconnected Successfully",
                                        icon: "success",
                                      });
                                    }
                                  }
                                });
                              }}
                            >
                              <Stack
                                direction={"row"}
                                justifyContent={"flex-start"}
                                alignItems={"center"}
                                sx={{ width: "100%", px: "40px" }}
                                spacing="40px"
                              >
                                <OnlinePredictionIcon
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    color: "#FF0C3E",
                                  }}
                                />
                                <Typography
                                  fontWeight={700}
                                  fontSize="12px"
                                  lineHeight={"16px"}
                                  color="#FF0C3E"
                                  textTransform={"none"}
                                >
                                  Reconnect
                                </Typography>
                              </Stack>
                            </Button>
                          </Box>
                        )}
                      <Box>
                        <Button
                          sx={{ height: "56px", columnGap: "40px" }}
                          fullWidth
                          onClick={() => {
                            logout && logout();
                          }}
                        >
                          <Stack
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            sx={{ width: "100%", px: "40px" }}
                            spacing="40px"
                          >
                            <LogoutIcon
                              sx={{
                                width: "20px",
                                height: "20px",
                                transform: "rotate(180deg)",
                                color: "#0D3C60",
                              }}
                            />
                            <Typography
                              fontWeight={700}
                              fontSize="12px"
                              lineHeight={"16px"}
                              color="#092941"
                              textTransform={"none"}
                            >
                              Sign out
                            </Typography>
                          </Stack>
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          background: "#E4F2FF",
                          borderRadius: "0px 0px 15px 15px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          px: "50px",
                          py: "24px",
                        }}
                      >
                        <Typography
                          fontWeight={600}
                          fontSize={"12px"}
                          lineHeight="16px"
                          color="rgba(13, 60, 96, 0.5)"
                          fontStyle={"italic"}
                        >
                          This account is managed by Island Catch.
                        </Typography>
                      </Box>
                    </Box>
                  </Popover>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box
        position="relative"
        sx={{ background: "#F1F5F8", display: { xs: "none", md: "block" } }}
      >
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              boxSizing: "border-box",
              overflow: "visible",
              background: "#F1F5F8",
            },
          }}
          PaperProps={{
            sx: {
              borderRight: "unset",
              mt: "76px",
            },
          }}
        >
          <Stack
            direction={"column"}
            justifyContent={"flex-start"}
            position="relative"
            sx={{
              height: "89%",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.04)",
              background: color,
              borderRadius: "0 10px 10px 0",
            }}
          >
            <Box
              bottom="10px"
              paddingLeft="20px"
              position="absolute"
              right="10px"
              zIndex="10000000000000"
            >
              {open ? (
                <IconButton
                  sx={{
                    background: drawerIconColor,
                    borderRadius: "7px",
                    width: "20px",
                    height: "20px",
                    filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.06))",
                  }}
                  onClick={handleDrawerClose}
                  disableRipple
                  disableFocusRipple
                >
                  <KeyboardTabIcon
                    sx={{
                      color: color,
                      width: "15px",
                      height: "15px",
                      transform: "rotate(180deg)",
                    }}
                  />
                </IconButton>
              ) : (
                <IconButton
                  color="primary"
                  onClick={handleDrawerOpen}
                  disableRipple
                  disableFocusRipple
                  sx={{
                    background: drawerIconColor,
                    borderRadius: "7px",
                    width: "20px",
                    height: "20px",
                    filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.06))",
                  }}
                >
                  <KeyboardTabIcon
                    sx={{ color: color, width: "15px", height: "15px" }}
                  />
                </IconButton>
              )}
            </Box>
            <List
              sx={{
                color: "rgba(0, 0, 0, 0.38)",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {sideMenu.map((item, index) => (
                <React.Fragment key={index}>
                  <ListItemButton
                    key={index}
                    onClick={() => {
                      if (item.subMenu && item.subMenu.length > 0) {
                        setOpenSubMenu(!openSubMenu);
                        setSelectedIndex(item.index);
                      } else {
                        if (item.newTab) {
                          setSelectedPath(item.path);
                          window.open(item.path);
                        } else {
                          setSelectedPath(item.path);
                          scroll(item.elementId);
                          navigate(item.path);
                        }
                      }
                    }}
                    sx={
                      (item.pathName && location.includes(item.pathName)) ||
                      (openSubMenu && index === selectedIndex)
                        ? {
                            backgroundColor: "#4CE6FA",
                            mx: "8px",
                            borderRadius: "8px",
                            ":hover": {
                              backgroundColor: "#4CE6FA",
                            },
                            justifyContent: "center",
                          }
                        : {
                            justifyContent: "center",
                            mx: "8px",
                          }
                    }
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1.5 : "auto",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Icon
                        sx={{
                          color:
                            item.pathName && location.includes(item.pathName)
                              ? "#1C2940"
                              : "rgba(255, 255, 255, 0.65)",
                          width: "30px",
                          height: "30px",
                        }}
                      >
                        {item.icon}
                      </Icon>
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        color:
                          item.pathName && location.includes(item.pathName)
                            ? "#1C2940"
                            : "rgba(255, 255, 255, 0.65)",
                        opacity: open ? 1 : 0,
                      }}
                      primaryTypographyProps={{
                        fontSize: "12.5px",
                      }}
                      primary={item.name}
                    />
                  </ListItemButton>
                  <Collapse
                    in={!open ? false : openSubMenu}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      sx={{
                        background: "#1C2940",
                        mx: "8px",
                        p: "8px",
                        height: "fit-content",
                        display: item.subMenu ? "block" : "none",
                        borderRadius: "4px",
                      }}
                    >
                      {item.subMenu?.map((item, index) => (
                        <ListItemButton
                          key={index}
                          onClick={() => navigate(item.path)}
                          sx={{
                            background:
                              item.pathName && location.includes(item.pathName)
                                ? "#4CE6FA"
                                : "unset",
                            borderRadius: "8px",
                            ":hover": {
                              background:
                                item.pathName &&
                                location.includes(item.pathName)
                                  ? "#4CE6FA"
                                  : "unset",
                            },
                          }}
                        >
                          <ListItemText
                            primary={item.name}
                            sx={{
                              color:
                                item.pathName &&
                                location.includes(item.pathName)
                                  ? "#ffffff"
                                  : "rgba(255,255,255, 0.65)",
                            }}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              ))}
            </List>
          </Stack>
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Box
          display="flex"
          position="relative"
          flexDirection="row"
          flexGrow={1}
          width="100%"
          sx={{ background: "#F1F5F8" }}
        >
          <Box
            height="100vh"
            width="100%"
            flexGrow={1}
            sx={{ background: "#F1F5F8" }}
          >
            <Box
              overflow={{ xs: "auto", xl: scrollMainContainer }}
              height="calc(100vh - 8px)"
              sx={{ background: "#F1F5F8" }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
      <PasswordChange
        open={openPasswordUpdate}
        onClose={() => {
          setOpenPasswordUpdate(false);
        }}
      />
    </Box>
  );
};

export default UserLayout;
