import { VoucherTemplate } from "../../utils";
import ModalWrapper from "../Common/ModalWrapper";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LazyUser, VoucherStatus } from "../../models";
import InputLabelWrapper from "../Common/InputLabelWrapper";
import { LoadingButton } from "@mui/lab";
import { createEVoucher } from "../../services";
import Swal from "sweetalert2";
import { AuthUser } from "aws-amplify/auth";

const validationSchema = Yup.object().shape({
  voucherNumber: Yup.string().required("Required"),
  assignedUserId: Yup.string().required("Required"),
  totalAmount: Yup.number().required("Required"),
  validUntil: Yup.string().required("Required"),
  chequeReferenceNumber: Yup.string().required("Required"),
  purpose: Yup.string().required("Required"),
  saveAsTemplate: Yup.boolean().required("Required"),
  templateName: Yup.string().when("saveAsTemplate", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  createdBy: Yup.string().when("saveAsTemplate", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const EVoucherTemplateView = ({
  data,
  open,
  onClose,
  users,
  fetchInitialData,
  setOpenVoucherTemplate,
  user,
}: {
  data: VoucherTemplate | null;
  open: boolean;
  onClose: () => void;
  title: string;
  users: LazyUser[];
  fetchInitialData: () => void;
  setOpenVoucherTemplate: React.Dispatch<React.SetStateAction<boolean>>;
  user: AuthUser;
}) => {
  const initialValues = {
    assignedUserId: data?.assignedUserId ?? "",
    totalAmount: data?.totalAmount ?? 0,
    validUntil: data?.validUntil ?? "",
    chequeReferenceNumber: data?.chequeReferenceNumber ?? "",
    purpose: data?.purpose ?? "",
    saveAsTemplate: false,
    templateName: "",
    createdBy: user.userId,
    voucherNumber: data?.voucherNumber ?? "",
  };

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      title={data?.templateName ?? ""}
      subTitle={
        "Add all the relevant information below to create new invoice from template"
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          const objectToSubmit = {
            ...values,
            status: VoucherStatus.ACTIVE,
            voucherRedeems: [],
            remainingAmount: values.totalAmount,
            templateName: values.saveAsTemplate ? values.templateName : "",
            createdBy: values.createdBy,
            updatedBy: user.userId,
            deleted: false,
          };

          const result = await createEVoucher(objectToSubmit);

          if (result) {
            resetForm();

            fetchInitialData();

            onClose();

            Swal.fire({
              title: "Voucher created",
              text: "Voucher Successfully Created",
              icon: "success",
            });
          } else {
            setSubmitting(false);

            onClose();

            Swal.fire({
              title: "Voucher creating Error",
              text: "Voucher creation Error",
              icon: "error",
            }).then((result) => {
              if (result.isConfirmed) {
                setOpenVoucherTemplate(true);
              }
            });
          }
        }}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          isSubmitting,
          touched,
          dirty,
          resetForm,
        }) => (
          <Form>
            <DialogContent
              sx={{
                paddingX: "20px",
                paddingTop: "40px",
                paddingBottom: "40px",
              }}
            >
              {data ? (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <InputLabelWrapper label="Assigned to">
                      <Select
                        value={values.assignedUserId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={"assignedUserId"}
                        error={
                          touched.assignedUserId && !!errors.assignedUserId
                        }
                        fullWidth
                        size="small"
                      >
                        <MenuItem value="" disabled>
                          -Select-
                        </MenuItem>
                        {users.map((user, index) => (
                          <MenuItem key={index} value={user.userId}>
                            {`${user.firstName + " " + user.lastName}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </InputLabelWrapper>
                    <InputLabelWrapper label="Amount">
                      <OutlinedInput
                        value={values.totalAmount}
                        name="totalAmount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.totalAmount && !!errors.totalAmount}
                        fullWidth
                        size="small"
                        type="number"
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                      />
                    </InputLabelWrapper>
                    <InputLabelWrapper label="Valid Until">
                      <TextField
                        value={values.validUntil}
                        name="validUntil"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.validUntil && !!errors.validUntil}
                        fullWidth
                        size="small"
                        type="date"
                      />
                    </InputLabelWrapper>
                    <InputLabelWrapper label="Cheque Reference Number">
                      <TextField
                        value={values.chequeReferenceNumber}
                        name="chequeReferenceNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.chequeReferenceNumber &&
                          !!errors.chequeReferenceNumber
                        }
                        fullWidth
                        size="small"
                      />
                    </InputLabelWrapper>
                    <InputLabelWrapper label="Purpose">
                      <TextField
                        value={values.purpose}
                        name="purpose"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.purpose && !!errors.purpose}
                        fullWidth
                        size="small"
                      />
                    </InputLabelWrapper>
                  </Grid>
                </Grid>
              ) : (
                <Grid container p="30px">
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "20px",
                gap: "10px",
              }}
            >
              <Button
                sx={{ width: "100px", height: "32px" }}
                onClick={() => {
                  resetForm();
                  onClose();
                }}
              >
                <Typography
                  sx={{
                    color:
                      "var(--components-enabled-text-stroke-icon, #646D7C)",
                    textAlign: "center",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  Cancel
                </Typography>
              </Button>
              <LoadingButton
                sx={{
                  borderRadius: "6px",
                  background: "var(--Components-CTA-BG, #4CE6FA)",
                  ":hover": {
                    background: "var(--Components-CTA-BG, #4CE6FA)",
                  },
                  padding: "10px",
                }}
                disabled={isSubmitting || !isValid}
                loading={isSubmitting}
                onClick={() => {
                  handleSubmit();
                }}
              >
                <Typography
                  sx={{
                    color: "var(--components-cta-text-icon, #1C2940)",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  Save E-Voucher
                </Typography>
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
};

export default EVoucherTemplateView;
