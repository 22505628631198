import { useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { updateExistingInvoice } from "../../services";
import { generatePDF, InvoiceExtended } from "../../utils";

type LocalInvoicePreviewProps = {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  data: InvoiceExtended | null;
  fetchInvoices: ({
    page,
    pageSize,
    nextToken,
  }: {
    page: number;
    pageSize: number;
    nextToken: null | string | undefined;
  }) => Promise<void>;
  paginationData: {
    page: number;
    pageSize: number;
    nextToken: null | string | undefined;
  };
};

const LocalInvoicePreview = ({
  open,
  onClose,
  loading,
  data,
  fetchInvoices,
  paginationData: { page, pageSize, nextToken },
}: LocalInvoicePreviewProps) => {
  const contentRef = useRef<HTMLDivElement | null>(null);

  const [savingInvoice, setSavingInvoice] = useState(false);

  const saveInvoice = async () => {
    try {
      setSavingInvoice(true);
      if (data) {
        const { success } = await updateExistingInvoice({
          id: data.id,
          _version: data._version,
        });

        if (success) {
          await fetchInvoices({ page, pageSize, nextToken });
          await generatePDF(contentRef, data.invoiceNumber);
          onClose();
        }
      } else {
        throw new Error("Saving Error");
      }
    } catch (error) {
      console.log("🚀 ~ saveInvoice ~ error:", error);
    } finally {
      setSavingInvoice(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "10px",
          width: "600px",
        },
      }}
    >
      <Box ref={contentRef}>
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
            background: "url(/background-images/modal-header.png)",
            mixBlendMode: "plus-darker",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            overflowY: "auto",
          }}
        >
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignContent={"flex-start"}
            spacing={"10px"}
          >
            <Typography
              sx={{
                color: "var(--Components-Enabled-BG, #FFF)",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
                textTransform: "capitalize",
              }}
            >
              {data?.invoiceNumber}
            </Typography>
            <Typography
              sx={{
                color: "var(--Text-On-Dark-Backgrounds-Main, #B4C3CE)",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              Invoice #
            </Typography>
          </Stack>
          <Box sx={{ height: "34px", width: "62px" }}>
            <img src="/logo-ic.png" alt="logo" width="100%" height="100%" />
          </Box>
        </DialogTitle>
        {loading ? (
          <DialogContent
            sx={{
              paddingX: "20px",
              paddingTop: "40px",
              paddingBottom: "40px",
            }}
          >
            <Grid container p="30px">
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Grid>
            </Grid>
          </DialogContent>
        ) : data ? (
          <DialogContent
            sx={{
              paddingX: "20px",
              paddingTop: "40px",
              paddingBottom: "40px",
            }}
          >
            <Grid container p="30px">
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  sx={{
                    color: "var(--Text-Header, #1C2940)",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    textTransform: "capitalize",
                  }}
                >
                  Island Catch Company Limited
                </Typography>
                <Typography
                  sx={{
                    color: "var(--Text-Main, #646D7C)",
                    fontSize: "11px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "135%",
                    textTransform: "capitalize",
                  }}
                >
                  Ile Du Port <br />
                  P.O.Box 202, <br />
                  Victoria Mahe, <br />
                  Seychelles
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  spacing={"10px"}
                  width="100%"
                >
                  <Typography
                    sx={{
                      color:
                        "var(--components-enabled-text-stroke-icon, #646D7C)",
                      textAlign: "right",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "120%",
                      textTransform: "capitalize",
                    }}
                  >
                    Date:
                  </Typography>
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    p="10px"
                  >
                    <Typography
                      sx={{
                        color: "var(--components-cta-text-icon, #1C2940)",
                        fontSize: "11px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "120%",
                        textTransform: "capitalize",
                      }}
                    >
                      {moment(data.invoiceDate).format("YYYY-MM-DD")}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  spacing={"10px"}
                  width="100%"
                >
                  <Typography
                    sx={{
                      color:
                        "var(--components-enabled-text-stroke-icon, #646D7C)",
                      textAlign: "right",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "120%",
                      textTransform: "capitalize",
                    }}
                  >
                    Invoice No:
                  </Typography>
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    p="10px"
                  >
                    <Typography
                      sx={{
                        color: "var(--components-cta-text-icon, #1C2940)",
                        fontSize: "11px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "120%",
                        textTransform: "capitalize",
                        textAlign: "end",
                      }}
                    >
                      {data.invoiceNumber}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  spacing={"10px"}
                  width="100%"
                >
                  <Typography
                    sx={{
                      color:
                        "var(--components-enabled-text-stroke-icon, #646D7C)",
                      textAlign: "right",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "120%",
                      textTransform: "capitalize",
                    }}
                  >
                    Delivery Note No:
                  </Typography>
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    p="10px"
                  >
                    <Typography
                      sx={{
                        color: "var(--components-cta-text-icon, #1C2940)",
                        fontSize: "11px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "120%",
                        textTransform: "capitalize",
                      }}
                    >
                      {data.deliveryNoteNo}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Grid container p="30px" spacing={"20px"}>
              <Grid item xs={12} md={6}>
                <Stack
                  direction={"column"}
                  sx={{
                    borderRadius: "6px",
                    background: "var(--Backgrounds-Table-Row, #F6F9FB)",
                  }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    p="10px"
                    sx={{
                      background:
                        "var(--components-enabled-text-stroke-icon, #646D7C)",
                      borderRadius: "6px 6px 0px 0px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--Components-Enabled-BG, #FFF)",
                        fontSize: "11px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "120%",
                        textTransform: "capitalize",
                      }}
                    >
                      Consignee
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems={"flex-start"}
                    p="10px"
                  >
                    <Typography
                      sx={{
                        color: "var(--Text-Main, #646D7C)",
                        fontSize: "11px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "135%",
                      }}
                    >
                      {data.consignee}
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--Text-Main, #646D7C)",
                        fontSize: "11px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "135%",
                      }}
                    >
                      {data.consigneeAddress} <br />
                      {data.consigneeCountry}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems={"flex-start"}
                    p="10px"
                  >
                    <Typography
                      sx={{
                        color: "var(--Text-Main, #646D7C)",
                        fontSize: "11px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "135%",
                      }}
                    >
                      Tel : {data.consigneeTelephone}
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--Text-Main, #646D7C)",
                        fontSize: "11px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "135%",
                      }}
                    >
                      Email: {data.consigneeEmail}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack
                  direction={"column"}
                  sx={{
                    borderRadius: "6px",
                    background: "var(--Backgrounds-Table-Row, #F6F9FB)",
                  }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    p="10px"
                    sx={{
                      background:
                        "var(--components-enabled-text-stroke-icon, #646D7C)",
                      borderRadius: "6px 6px 0px 0px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--Components-Enabled-BG, #FFF)",
                        fontSize: "11px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "120%",
                        textTransform: "capitalize",
                      }}
                    >
                      Account Details
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems={"flex-start"}
                    p="10px"
                  >
                    <Typography
                      sx={{
                        color: "var(--Text-Main, #646D7C)",
                        fontSize: "11px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "135%",
                      }}
                    >
                      {data.accountHolder} <br />
                      {data.accountNumber} <br />
                      {data.iban}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems={"flex-start"}
                    p="10px"
                  >
                    <Typography
                      sx={{
                        color: "var(--Text-Main, #646D7C)",
                        fontSize: "11px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "135%",
                      }}
                    >
                      {data.accountAddress}
                      <br />
                      {data.accountCountry}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Grid container px="30px">
              <Grid
                item
                xs={12}
                sx={{
                  borderRadius: "10px",
                  border: "1px solid var(--Other-Other-Divider, #D6DCE1)",
                  p: "10px",
                }}
              >
                <TableContainer>
                  <Table stickyHeader sx={{ border: "none" }}>
                    <TableHead>
                      <TableRow sx={{ borderBottom: "none" }}>
                        {[
                          { header: "#", minWidth: "50px" },
                          { header: "Fish Type", minWidth: "150px" },
                          { header: "Quantity (Kg)", minWidth: "150px" },
                          {
                            header: "Unit Price (SCR)",
                            minWidth: "150px",
                          },
                          {
                            header: "Total Amount (SCR)",
                            minWidth: "200px",
                          },
                        ].map((header, index) => (
                          <TableCell
                            sx={{ borderBottom: "none" }}
                            // style={{ minWidth: header.minWidth }}
                            key={index}
                          >
                            <Typography
                              sx={{
                                color:
                                  "var(--components-enabled-text-stroke-icon, #646D7C)",
                                fontSize: "11px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "120%",
                                textTransform: "capitalize",
                              }}
                            >
                              {header.header}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.localItems?.map((record, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography
                              sx={{
                                color:
                                  "var(--components-cta-text-icon, #1C2940)",
                                fontSize: "11px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "120%",
                                textTransform: "capitalize",
                              }}
                            >
                              {index + 1}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                color:
                                  "var(--components-cta-text-icon, #1C2940)",
                                fontSize: "11px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "120%",
                                textTransform: "capitalize",
                              }}
                            >
                              {record?.fishType}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                color:
                                  "var(--components-cta-text-icon, #1C2940)",
                                fontSize: "11px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "120%",
                                textTransform: "capitalize",
                              }}
                            >
                              {record?.quantity}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                color:
                                  "var(--components-cta-text-icon, #1C2940)",
                                fontSize: "11px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "120%",
                                textTransform: "capitalize",
                              }}
                            >
                              {record?.unitPrice.toLocaleString("en-US")}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                color:
                                  "var(--components-cta-text-icon, #1C2940)",
                                fontSize: "11px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "120%",
                                textTransform: "capitalize",
                              }}
                            >
                              {record?.totalAmount.toLocaleString("en-US")}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell colSpan={3} />
                        <TableCell>
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "11px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "120%",
                              textTransform: "capitalize",
                            }}
                          >
                            Discount
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "11px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "120%",
                              textTransform: "capitalize",
                            }}
                          >
                            {data.discount.toLocaleString("en-US")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={3} />
                        <TableCell>
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "11px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "120%",
                              textTransform: "capitalize",
                            }}
                          >
                            Advance
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "11px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "120%",
                              textTransform: "capitalize",
                            }}
                          >
                            {data.advance.toLocaleString("en-US")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={3} />
                        <TableCell sx={{ background: "#4CE6FA" }}>
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "11px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "120%",
                              textTransform: "capitalize",
                            }}
                          >
                            Total
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ background: "#4CE6FA" }}>
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "11px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "120%",
                              textTransform: "capitalize",
                            }}
                          >
                            {data.totalAmount.toLocaleString("en-US")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid container pt="30px">
                <Stack
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                >
                  <Typography
                    sx={{
                      color:
                        "var(--components-enabled-text-stroke-icon, #646D7C)",
                      fontSize: "11px",
                      fontStyle: "italic",
                      fontWeight: 400,
                      lineHeight: "120%",
                    }}
                  >
                    Total Amount in words:
                  </Typography>
                  <Typography
                    sx={{
                      color:
                        "var(--components-enabled-text-stroke-icon, #646D7C)",
                      fontSize: "11px",
                      fontStyle: "italic",
                      fontWeight: 600,
                      lineHeight: "120%",
                      textTransform: "capitalize",
                    }}
                  >
                    {data.totalAmountInWords}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <DialogContent
            sx={{
              paddingX: "20px",
              paddingTop: "40px",
              paddingBottom: "40px",
            }}
          >
            <Grid container p="30px">
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Typography>No Data Available</Typography>
              </Grid>
            </Grid>
          </DialogContent>
        )}
      </Box>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "20px",
          gap: "10px",
        }}
      >
        <Button
          sx={{ width: "100px", height: "32px" }}
          onClick={() => {
            onClose();
          }}
        >
          <Typography
            sx={{
              color: "var(--components-enabled-text-stroke-icon, #646D7C)",
              textAlign: "center",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "120%",
              textTransform: "capitalize",
            }}
          >
            Cancel
          </Typography>
        </Button>
        <LoadingButton
          sx={{
            borderRadius: "6px",
            background: "var(--Components-CTA-BG, #4CE6FA)",
            ":hover": {
              background: "var(--Components-CTA-BG, #4CE6FA)",
            },
            padding: "10px",
          }}
          loading={savingInvoice}
          onClick={() => {
            saveInvoice();
          }}
        >
          <Typography
            sx={{
              color: "var(--components-cta-text-icon, #1C2940)",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "120%",
              textTransform: "capitalize",
            }}
          >
            Save & Download PDF
          </Typography>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default LocalInvoicePreview;
