import { generateClient } from "aws-amplify/api";
import {
  getInvoice,
  listCustomers,
  listInvoices,
  searchInvoices,
} from "../graphql/queries";
import {
  createCustomers,
  createInvoice,
  deleteInvoice,
  updateInvoice,
} from "../graphql/mutations";
import {
  InvoiceDataType,
  InvoiceExtended,
  InvoiceTemplateValues,
} from "../utils";
import { customListInvoices } from "../graphql/customQueries";
import moment from "moment";
import {
  SearchableInvoiceSortableFields,
  SearchableSortDirection,
} from "../API";
import { LazyCustomers } from "../models";

const fetchAllInvoices = async ({
  limit,
  nextToken,
}: {
  limit: number;
  nextToken: string | null | undefined;
}) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        searchInvoices: { items: recordCount },
      },
    } = await client.graphql({
      query: customListInvoices,
      variables: {
        filter: {
          hideRecord: {
            eq: false,
          },
        },
      },
    });

    const {
      data: {
        searchInvoices: { items, nextToken: newNextToken },
      },
    } = await client.graphql({
      query: customListInvoices,
      variables: {
        filter: {
          hideRecord: {
            eq: false,
          },
        },
        limit,
        nextToken,
        sort: [
          {
            field: SearchableInvoiceSortableFields.dueDate,
            direction: SearchableSortDirection.asc,
          },
        ],
      },
    });

    return {
      success: true,
      rows: items as unknown as InvoiceExtended[],
      rowCount: recordCount.length,
      nextToken: newNextToken,
    };
  } catch (error) {
    console.log("🚀 ~ fetchAllInvoices ~ error:", error);
    return {
      success: false,
      rows: [],
      rowCount: 0,
    };
  }
};

const fetchInvoice = async (id: string) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: { getInvoice: invoice },
    } = await client.graphql({
      query: getInvoice,
      variables: {
        id,
      },
    });

    return invoice as unknown as InvoiceExtended;
  } catch (error) {
    console.log("🚀 ~ fetchInvoice ~ error:", error);
    return null;
  }
};

const fetchInvoicesWithinDay = async () => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        searchInvoices: { items },
      },
    } = await client.graphql({
      query: searchInvoices,
      variables: {
        filter: {
          createdOn: {
            range: [
              moment().startOf("day").toISOString(),
              moment().endOf("day").toISOString(),
            ],
          },
          hideRecord: {
            eq: false,
          },
        },
      },
    });

    return { success: true, records: items.length };
  } catch (error) {
    console.log("🚀 ~ invoiithinDay ~ error:", error);
    return { success: false, records: 0 };
  }
};

const createNewInvoice = async (invoice: InvoiceDataType) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const { data: customerData } = await client.graphql({
      query: createCustomers,
      variables: {
        input: {
          customerName: invoice.consignee,
          customerAddress: invoice.consigneeAddress,
          customerEmail: invoice.consigneeEmail,
          customerTelephone: invoice.consigneeTelephone,
          customerCountry: invoice.consigneeCountry,
          invoiceType: invoice.invoiceType,
        },
      },
    });

    const { data, errors } = await client.graphql({
      query: createInvoice,
      variables: {
        input: {
          ...invoice,
          hideRecord: false,
          consigneeId: customerData.createCustomers.id,
        },
      },
    });

    if (errors) {
      throw new Error("Invoice Creation Error");
    }

    return { success: true, data };
  } catch (error) {
    console.log("🚀 ~ createInvoice ~ error:", error);
    return { success: false };
  }
};

const updateExistingInvoice = async ({
  id,
  _version,
}: {
  id: string;
  _version: number;
}) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const { data, errors } = await client.graphql({
      query: updateInvoice,
      variables: {
        input: {
          id,
          _version,
          saved: true,
        },
      },
    });

    if (errors) {
      throw new Error("Invoice Save Error");
    }

    return { success: true, data: data.updateInvoice };
  } catch (error) {
    console.log("🚀 ~ updateInvoice ~ error:", error);
    return { success: false };
  }
};

const deleteExistingInvoice = async (id: string) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const deletedInvoice = await client.graphql({
      query: deleteInvoice,
      variables: {
        input: {
          id,
        },
      },
    });

    return deletedInvoice;
  } catch (error) {
    console.log("🚀 ~ deleteInvoice ~ error:", error);
    return { success: false };
  }
};

const hideAllInvoices = async () => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        listInvoices: { items },
      },
    } = await client.graphql({
      query: listInvoices,
    });

    await Promise.all(
      items.map(async (item) => {
        const {
          data: { updateInvoice: updateInvoiceResponse },
        } = await client.graphql({
          query: updateInvoice,
          variables: {
            input: {
              id: item.id,
              hideRecord: true,
              _version: item._version,
            },
          },
        });

        return updateInvoiceResponse;
      })
    );

    return { success: true };
  } catch (error) {
    console.log("🚀 ~ hideAllInvoices ~ error:", error);
    return { success: false };
  }
};

const fetchInvoiceCustomerTemplates = async () => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        listCustomers: { items },
      },
    } = await client.graphql({
      query: listCustomers,
    });

    return {
      success: true,
      templates: items as unknown as LazyCustomers[],
    };
  } catch (error) {
    console.log("🚀 ~ fetchInvoiceCustomerTemplates ~ error:", error);
    return {
      success: false,
      templates: [],
    };
  }
};

const createNewInvoiceFromTemplate = async (invoice: InvoiceTemplateValues) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const { data, errors } = await client.graphql({
      query: createInvoice,
      variables: {
        input: { ...invoice, hideRecord: false },
      },
    });

    if (errors) {
      throw new Error("Invoice Creation Error");
    }

    return { success: true, data };
  } catch (error) {
    console.log("🚀 ~ error:", error);
    return { success: false };
  }
};

export {
  fetchAllInvoices,
  fetchInvoice,
  createNewInvoice,
  updateExistingInvoice,
  deleteExistingInvoice,
  fetchInvoicesWithinDay,
  hideAllInvoices,
  fetchInvoiceCustomerTemplates,
  createNewInvoiceFromTemplate,
};
