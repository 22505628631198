import { Button, ButtonProps, Typography } from "@mui/material";

export interface UploadButtonProps extends ButtonProps {
  name: string;
  textColor: string;
}

const UploadButton = ({ name, textColor, ...props }: UploadButtonProps) => {
  return (
    <Button
      {...props}
      variant="outlined"
      size="small"
      sx={{
        border: "1px dashed rgba(88, 104, 116, 0.23)",
        background: "#ffffff",
        ":hover": {
          background: "#ffffff",
          border: "1px solid black",
          boxShadow: "none",
        },
        boxShadow: "none",
        textTransform: "none",
        height: "37.13px",
        borderRadius: "4px",
      }}
    >
      <Typography
        fontWeight={400}
        fontSize={"12px"}
        lineHeight={"161%"}
        letterSpacing={"0.15px"}
        color={textColor}
        noWrap
        maxWidth={"150px"}
      >
        {name}
      </Typography>
    </Button>
  );
};

export default UploadButton;
