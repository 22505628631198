import { generateClient, post } from "aws-amplify/api";
import moment from "moment";
import {
  SearchableInventorySortableFields,
  SearchableSortDirection,
} from "../API";
import { LazyInventory } from "../models";
import { customSearchInventories } from "../graphql/customQueries";

const inventoryApiName = "inventoryapi";
const inventoryApiPath = "/save-inventory";

const saveInventory = async (path: string) => {
  try {
    console.log("start executing inventory");
    const result = await post({
      apiName: inventoryApiName,
      path: inventoryApiPath,
      options: {
        body: {
          s3_url: path,
        },
      },
    })
      .response.then(() => {
        console.log("🚀 ~ saveInventory ~ result:", result);
      })
      .catch((err) => {
        throw new Error(`${err}`);
      });

    return { success: true };
  } catch (error) {
    console.log("🚀 ~ saveInventory ~ error:", error);
    return { success: false };
  }
};

const fetchAllInventoryItems = async () => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        searchInventories: { items },
      },
    } = await client.graphql({
      query: customSearchInventories,
      variables: {
        filter: {
          createdOn: {
            range: [
              moment().startOf("day").toISOString(),
              moment().endOf("day").toISOString(),
            ],
          },
        },
        sort: [
          {
            field: SearchableInventorySortableFields.createdOn,
            direction: SearchableSortDirection.desc,
          },
        ],
      },
    });

    const summaryCardDetails = (items as unknown as LazyInventory[]).reduce(
      (prev, curr) => {
        const totalItemWeight = curr.inventoryItems.reduce(
          (itemWeightSum, item) => itemWeightSum + (item?.weight || 0),
          0
        );
        prev["totalWeight"] += totalItemWeight;

        curr.inventoryItems.forEach((item) => {
          if (item) {
            const currentSpecieWeight = item.weight;

            if (item.specie === "unsorted" || item.specie === "Unsorted") {
              prev.unsortedStockTotalWeight += currentSpecieWeight;
            }

            if (currentSpecieWeight > prev.mostStock.weight) {
              prev.mostStock.specie = item.specie;
              prev.mostStock.weight = currentSpecieWeight;
            } else if (item.specie === prev.mostStock.specie) {
              prev.mostStock.weight += currentSpecieWeight;
            }
          }
        });

        return prev;
      },
      {
        totalWeight: 0,
        mostStock: {
          specie: "",
          weight: 0,
        },
        unsortedStockTotalWeight: 0,
      }
    );

    return {
      success: true,
      rows: items as unknown as LazyInventory[],
      rowCount: items.length,
      summaryCardDetails,
    };
  } catch (error) {
    return {
      success: false,
      rows: [],
      rowCount: 0,
      summaryCardDetails: {
        totalWeight: 0,
        mostStock: {
          specie: "",
          weight: 0,
        },
        unsortedStockTotalWeight: 0,
      },
    };
  }
};

export { fetchAllInventoryItems, saveInventory };
