import { Box } from "@mui/material";
import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

type DataGridProps = {
  columns: GridColDef[];
  rows: any[];
  loading: boolean;
  totalRows: number;
  page: number;
  setPage: (value: React.SetStateAction<number>) => void;
  pageSize: number;
  setPageSize: (value: React.SetStateAction<number>) => void;
  checkboxSelection: boolean;
};

const DataGridComponent = ({
  columns,
  rows,
  loading,
  totalRows,
  page,
  setPage,
  pageSize,
  setPageSize,
  checkboxSelection,
}: DataGridProps) => {
  return (
    <Box sx={{ height: "550px" }}>
      <DataGrid
        columns={columns}
        rows={rows}
        checkboxSelection={checkboxSelection}
        page={page}
        onPageSizeChange={(newPageSize: number) => {
          setPageSize(newPageSize);
        }}
        onPageChange={(newPage: number) => {
          setPage(newPage);
        }}
        experimentalFeatures={{ newEditingApi: true }}
        pageSize={pageSize}
        paginationMode="server"
        rowCount={totalRows}
        loading={loading}
        sx={{
          [`& .MuiDataGrid-row`]: {
            background: "#F1F5F8",
          },
          "& .MuiTablePagination-root": {
            display: "none",
          },
        }}
      />
    </Box>
  );
};

export default DataGridComponent;
