import { IconButton, Stack, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const MenuCard = ({
  cardTitle,
  cardSubTitle,
  openForm,
  backgroundColor,
}: {
  cardTitle: string;
  cardSubTitle: string;
  openForm: () => void;
  backgroundColor: string;
}) => {
  return (
    <Stack
      direction={"column"}
      justifyContent={"space-between"}
      alignItems={"flex-start"}
      sx={{
        borderRadius: "10px",
        backgroundColor: backgroundColor,
        height: "160px !important",
        padding: "20px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        cursor: "pointer",
      }}
      component={"div"}
      onClick={() => {
        openForm();
      }}
    >
      <Stack
        direction={"column"}
        width={"100%"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
      >
        <Typography
          sx={{
            color: "var(--Greys-All-White, #FFF)",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "120%",
          }}
        >
          {cardSubTitle}
        </Typography>
        <Typography
          sx={{
            color: "var(--Greys-All-White, #FFF)",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "120%",
            textTransform: "capitalize",
          }}
        >
          {cardTitle}
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        width={"100%"}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <IconButton
          sx={{
            background: "#ffffff",
            ":hover": {
              background: "#ffffff",
            },
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default MenuCard;
