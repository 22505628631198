import { Formik, Form } from "formik";
import { AuthUser } from "aws-amplify/auth";
import * as Yup from "yup";
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  editEVoucherNotification,
  notifyEVoucherEditToSuperAdmins,
  VoucherTemplate,
} from "../../utils";
import { LazyUser, VoucherStatus } from "../../models";
import ModalWrapper from "../Common/ModalWrapper";
import InputLabelWrapper from "../Common/InputLabelWrapper";
import { LoadingButton } from "@mui/lab";
import {
  notifySuperAdmins,
  notifyUserByUserId,
  updateEVoucher,
} from "../../services";
import Swal from "sweetalert2";

type EditEVoucherProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  authUser: AuthUser;
  data: VoucherTemplate | null;
  users: LazyUser[];
  fetchInitialData: () => void;
  onOpen: () => void;
};

const validationSchema = Yup.object().shape({
  voucherNumber: Yup.string().required("Required"),
  assignedUserId: Yup.string().required("Required"),
  totalAmount: Yup.number().required("Required"),
  validUntil: Yup.string().required("Required"),
  chequeReferenceNumber: Yup.string().required("Required"),
  purpose: Yup.string().required("Required"),
  saveAsTemplate: Yup.boolean().required("Required"),
  templateName: Yup.string().when("saveAsTemplate", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  createdBy: Yup.string().when("saveAsTemplate", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  status: Yup.mixed()
    .oneOf(Object.values(VoucherStatus), "Invalid Status")
    .required("Required"),
});

const EditEVoucher = ({
  open,
  onClose,
  title,
  authUser,
  data,
  users,
  fetchInitialData,
  onOpen,
}: EditEVoucherProps) => {
  const initialValues = {
    voucherNumber: data?.voucherNumber ?? "",
    assignedUserId: data?.assignedUserId ?? "",
    totalAmount: data?.totalAmount ?? 0,
    validUntil: data?.validUntil ?? "",
    chequeReferenceNumber: data?.chequeReferenceNumber ?? "",
    purpose: data?.purpose ?? "",
    saveAsTemplate: data?.saveAsTemplate ?? false,
    templateName: data?.templateName ?? "",
    createdBy: authUser.userId,
    status: (data?.status as VoucherStatus) ?? VoucherStatus.ACTIVE,
  };

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      title={title}
      subTitle={"Edit Voucher"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          const objectToSubmit = {
            ...values,
            status: values.status,
            voucherRedeems: [],
            remainingAmount: values.totalAmount,
            templateName: values.saveAsTemplate ? values.templateName : "",
            _version: data?._version ?? 0,
            id: data?.id ?? "",
            updatedBy: authUser.userId,
          };

          const result = await updateEVoucher(objectToSubmit);

          await notifyUserByUserId({
            userId: values.assignedUserId,
            ...editEVoucherNotification({
              totalAmount: values.totalAmount,
              purpose: values.purpose,
            }),
          });

          await notifySuperAdmins({
            ...notifyEVoucherEditToSuperAdmins({
              voucherNumber: values.voucherNumber,
            }),
          });

          if (result) {
            resetForm();

            fetchInitialData();

            onClose();

            Swal.fire({
              title: "Voucher Updated",
              text: "Voucher Successfully Updated",
              icon: "success",
            });
          } else {
            setSubmitting(false);

            Swal.fire({
              title: "Voucher Update Error",
              text: "Voucher Update Error",
              icon: "error",
            }).then((res) => {
              if (res.isConfirmed) {
                onOpen();
              }
            });
          }
        }}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          isSubmitting,
          touched,
          dirty,
          resetForm,
        }) => (
          <Form>
            <DialogContent
              sx={{
                paddingX: "20px",
                paddingTop: "40px",
                paddingBottom: "40px",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <InputLabelWrapper label="Voucher Number">
                  <TextField
                    value={values.voucherNumber}
                    name="voucherNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.voucherNumber && !!errors.voucherNumber}
                    fullWidth
                    size="small"
                    disabled
                  />
                  <FormHelperText sx={{ color: "#FF0C3E" }}>
                    {touched.voucherNumber &&
                      !!errors.voucherNumber &&
                      errors.voucherNumber}
                  </FormHelperText>
                </InputLabelWrapper>
                <InputLabelWrapper label="Assigned to">
                  <Select
                    value={values.assignedUserId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name={"assignedUserId"}
                    error={touched.assignedUserId && !!errors.assignedUserId}
                    fullWidth
                    size="small"
                  >
                    <MenuItem value="" disabled>
                      -Select-
                    </MenuItem>
                    {users.map((user, index) => (
                      <MenuItem key={index} value={user.id}>
                        {`${user.firstName + " " + user.lastName}`}
                      </MenuItem>
                    ))}
                  </Select>
                </InputLabelWrapper>
                <InputLabelWrapper label="Amount">
                  <OutlinedInput
                    value={values.totalAmount}
                    name="totalAmount"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.totalAmount && !!errors.totalAmount}
                    fullWidth
                    size="small"
                    type="number"
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                  />
                </InputLabelWrapper>
                <InputLabelWrapper label="Valid Until">
                  <TextField
                    value={values.validUntil}
                    name="validUntil"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.validUntil && !!errors.validUntil}
                    fullWidth
                    size="small"
                    type="date"
                  />
                </InputLabelWrapper>
                <InputLabelWrapper label="Cheque Reference Number">
                  <TextField
                    value={values.chequeReferenceNumber}
                    name="chequeReferenceNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.chequeReferenceNumber &&
                      !!errors.chequeReferenceNumber
                    }
                    fullWidth
                    size="small"
                  />
                </InputLabelWrapper>
                <InputLabelWrapper label="Purpose">
                  <TextField
                    value={values.purpose}
                    name="purpose"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.purpose && !!errors.purpose}
                    fullWidth
                    size="small"
                  />
                </InputLabelWrapper>
                <InputLabelWrapper label="Status">
                  <Select
                    value={values.status}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name={"status"}
                    error={touched.status && !!errors.status}
                    fullWidth
                    size="small"
                  >
                    <MenuItem value="" disabled>
                      -Select-
                    </MenuItem>
                    {[
                      { key: "Active", value: VoucherStatus.ACTIVE },
                      { key: "On Hold", value: VoucherStatus.ON_HOLD },
                      { key: "Exhausted", value: VoucherStatus.EXHAUSTED },
                    ].map((status, index) => (
                      <MenuItem key={index} value={status.value}>
                        {status.key}
                      </MenuItem>
                    ))}
                  </Select>
                </InputLabelWrapper>
                <Stack
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  width={"100%"}
                >
                  <Checkbox
                    checked={values.saveAsTemplate}
                    value={values.saveAsTemplate}
                    name="saveAsTemplate"
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Typography
                    sx={{
                      color: "var(--components-cta-text-icon, #1C2940)",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "120%",
                      textTransform: "capitalize",
                    }}
                  >
                    Save as a Template
                  </Typography>
                </Stack>
                {values.saveAsTemplate && (
                  <TextField
                    value={values.templateName}
                    name="templateName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Template Name"
                    error={touched.templateName && !!errors.templateName}
                    fullWidth
                    size="small"
                  />
                )}
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "20px",
                gap: "10px",
              }}
            >
              <Button
                sx={{ width: "100px", height: "32px" }}
                onClick={() => {
                  resetForm();
                  onClose();
                }}
              >
                <Typography
                  sx={{
                    color:
                      "var(--components-enabled-text-stroke-icon, #646D7C)",
                    textAlign: "center",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  Cancel
                </Typography>
              </Button>
              <LoadingButton
                sx={{
                  borderRadius: "6px",
                  background: "var(--Components-CTA-BG, #4CE6FA)",
                  ":hover": {
                    background: "var(--Components-CTA-BG, #4CE6FA)",
                  },
                  padding: "10px",
                }}
                disabled={isSubmitting || !dirty || !isValid}
                loading={isSubmitting}
                onClick={() => {
                  handleSubmit();
                }}
              >
                <Typography
                  sx={{
                    color: "var(--components-cta-text-icon, #1C2940)",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  Update E-Voucher
                </Typography>
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
};

export default EditEVoucher;
