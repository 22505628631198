/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "inventoryapi",
            "endpoint": "https://uq690vdmu0.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "sendemail",
            "endpoint": "https://hfqskteyg7.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://pfnp7xg32je6bjcffllbwqlm4i.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-tmzf44o5gvhyroelvyytag2qwa",
    "aws_cognito_identity_pool_id": "ap-southeast-1:59bf60c8-e90e-466f-9c5a-b268caa1995b",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_uKApsai5E",
    "aws_user_pools_web_client_id": "4hc0ms9mtfu4t5t4fi271qa5qc",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "islandcatchtrackifyd6efb0fcbd20546b6be395e34380a8f17-prod",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
