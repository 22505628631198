/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type SearchableVoucherFilterInput = {
  id?: SearchableIDFilterInput | null,
  voucherNumber?: SearchableStringFilterInput | null,
  assignedUserId?: SearchableIDFilterInput | null,
  purpose?: SearchableStringFilterInput | null,
  totalAmount?: SearchableFloatFilterInput | null,
  remainingAmount?: SearchableFloatFilterInput | null,
  createdOn?: SearchableStringFilterInput | null,
  updatedOn?: SearchableStringFilterInput | null,
  validUntil?: SearchableStringFilterInput | null,
  chequeReferenceNumber?: SearchableStringFilterInput | null,
  saveAsTemplate?: SearchableBooleanFilterInput | null,
  templateName?: SearchableStringFilterInput | null,
  createdBy?: SearchableIDFilterInput | null,
  updatedBy?: SearchableIDFilterInput | null,
  deleted?: SearchableBooleanFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  status?: SearchableStringFilterInput | null,
  and?: Array< SearchableVoucherFilterInput | null > | null,
  or?: Array< SearchableVoucherFilterInput | null > | null,
  not?: SearchableVoucherFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableFloatFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableVoucherSortInput = {
  field?: SearchableVoucherSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableVoucherSortableFields {
  id = "id",
  voucherNumber = "voucherNumber",
  assignedUserId = "assignedUserId",
  purpose = "purpose",
  totalAmount = "totalAmount",
  remainingAmount = "remainingAmount",
  createdOn = "createdOn",
  updatedOn = "updatedOn",
  validUntil = "validUntil",
  chequeReferenceNumber = "chequeReferenceNumber",
  saveAsTemplate = "saveAsTemplate",
  templateName = "templateName",
  createdBy = "createdBy",
  updatedBy = "updatedBy",
  deleted = "deleted",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableVoucherAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableVoucherAggregateField,
};

export enum SearchableAggregateType {
  terms = "terms",
  avg = "avg",
  min = "min",
  max = "max",
  sum = "sum",
  cardinality = "cardinality",
}


export enum SearchableVoucherAggregateField {
  id = "id",
  voucherNumber = "voucherNumber",
  assignedUserId = "assignedUserId",
  purpose = "purpose",
  totalAmount = "totalAmount",
  remainingAmount = "remainingAmount",
  status = "status",
  createdOn = "createdOn",
  updatedOn = "updatedOn",
  validUntil = "validUntil",
  chequeReferenceNumber = "chequeReferenceNumber",
  saveAsTemplate = "saveAsTemplate",
  templateName = "templateName",
  createdBy = "createdBy",
  updatedBy = "updatedBy",
  deleted = "deleted",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableVoucherConnection = {
  __typename: "SearchableVoucherConnection",
  items:  Array<Voucher | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type Voucher = {
  __typename: "Voucher",
  id: string,
  voucherNumber: string,
  assignedUserId: string,
  assignedTo: User,
  purpose: string,
  totalAmount: number,
  remainingAmount: number,
  status: VoucherStatus,
  createdOn: string,
  updatedOn: string,
  validUntil: string,
  chequeReferenceNumber: string,
  voucherRedeems:  Array<VoucherRedeemData | null >,
  saveAsTemplate: boolean,
  templateName: string,
  createdBy: string,
  createdByUserId: User,
  updatedBy: string,
  updatedByUserId: User,
  deleted: boolean,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type User = {
  __typename: "User",
  id: string,
  userId: string,
  firstName: string,
  lastName: string,
  email: string,
  designation: string,
  userType: UserType,
  birthDay: string,
  address: string,
  residingCountry: string,
  bio: string,
  profilePicture: string,
  contactNumber: string,
  authActivities:  Array<AuthenticationActivities | null >,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum UserType {
  ODIN = "ODIN",
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMIN = "ADMIN",
  USER = "USER",
  ACCOUNTANT = "ACCOUNTANT",
}


export type AuthenticationActivities = {
  __typename: "AuthenticationActivities",
  loginTime: string,
  logoutTime?: string | null,
};

export enum VoucherStatus {
  ACTIVE = "ACTIVE",
  ON_HOLD = "ON_HOLD",
  EXHAUSTED = "EXHAUSTED",
}


export type VoucherRedeemData = {
  __typename: "VoucherRedeemData",
  amount: number,
  remark: string,
  redeemedByUsername: string,
  redeemedOn: string,
};

export type SearchableAggregateResult = {
  __typename: "SearchableAggregateResult",
  name: string,
  result?: SearchableAggregateGenericResult | null,
};

export type SearchableAggregateGenericResult = SearchableAggregateScalarResult | SearchableAggregateBucketResult


export type SearchableAggregateScalarResult = {
  __typename: "SearchableAggregateScalarResult",
  value: number,
};

export type SearchableAggregateBucketResult = {
  __typename: "SearchableAggregateBucketResult",
  buckets?:  Array<SearchableAggregateBucketResultItem | null > | null,
};

export type SearchableAggregateBucketResultItem = {
  __typename: "SearchableAggregateBucketResultItem",
  key: string,
  doc_count: number,
};

export type ModelSalaryDetailsConnection = {
  __typename: "ModelSalaryDetailsConnection",
  items:  Array<SalaryDetails | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SalaryDetails = {
  __typename: "SalaryDetails",
  id: string,
  userId: string,
  fullName: string,
  position: string,
  basicSalary: number,
  bonus?: number | null,
  deductions: number,
  paymentDate: string,
  bankName: string,
  branch: string,
  accountNumber: string,
  accountType: string,
  currencyType: string,
  advance?: number | null,
  advanceEVoucherRef?: string | null,
  month: string,
  year: string,
  createdOn: string,
  updatedOn: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type SearchableInvoiceFilterInput = {
  id?: SearchableIDFilterInput | null,
  invoiceNumber?: SearchableStringFilterInput | null,
  invoiceDate?: SearchableStringFilterInput | null,
  consignee?: SearchableStringFilterInput | null,
  consigneeId?: SearchableIDFilterInput | null,
  consigneeAddress?: SearchableStringFilterInput | null,
  consigneeEmail?: SearchableStringFilterInput | null,
  consigneeTelephone?: SearchableStringFilterInput | null,
  consigneeCountry?: SearchableStringFilterInput | null,
  paymentDurations?: SearchableStringFilterInput | null,
  totalAmount?: SearchableFloatFilterInput | null,
  totalAmountInWords?: SearchableStringFilterInput | null,
  bLNo?: SearchableStringFilterInput | null,
  containerNo?: SearchableStringFilterInput | null,
  termsOfDelivery?: SearchableStringFilterInput | null,
  portOfLoadings?: SearchableStringFilterInput | null,
  portOfDischarge?: SearchableStringFilterInput | null,
  termsOfPayment?: SearchableStringFilterInput | null,
  createdOn?: SearchableStringFilterInput | null,
  updatedOn?: SearchableStringFilterInput | null,
  deliveryNoteNo?: SearchableStringFilterInput | null,
  poReference?: SearchableStringFilterInput | null,
  poValue?: SearchableFloatFilterInput | null,
  dueDate?: SearchableStringFilterInput | null,
  saved?: SearchableBooleanFilterInput | null,
  discount?: SearchableFloatFilterInput | null,
  advance?: SearchableFloatFilterInput | null,
  accountHolder?: SearchableStringFilterInput | null,
  accountNumber?: SearchableStringFilterInput | null,
  iban?: SearchableStringFilterInput | null,
  accountAddress?: SearchableStringFilterInput | null,
  accountCountry?: SearchableStringFilterInput | null,
  hideRecord?: SearchableBooleanFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  transitShipment?: SearchableStringFilterInput | null,
  partialShipment?: SearchableStringFilterInput | null,
  invoiceType?: SearchableStringFilterInput | null,
  and?: Array< SearchableInvoiceFilterInput | null > | null,
  or?: Array< SearchableInvoiceFilterInput | null > | null,
  not?: SearchableInvoiceFilterInput | null,
};

export type SearchableInvoiceSortInput = {
  field?: SearchableInvoiceSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableInvoiceSortableFields {
  id = "id",
  invoiceNumber = "invoiceNumber",
  invoiceDate = "invoiceDate",
  consignee = "consignee",
  consigneeId = "consigneeId",
  consigneeAddress = "consigneeAddress",
  consigneeEmail = "consigneeEmail",
  consigneeTelephone = "consigneeTelephone",
  consigneeCountry = "consigneeCountry",
  paymentDurations = "paymentDurations",
  totalAmount = "totalAmount",
  totalAmountInWords = "totalAmountInWords",
  bLNo = "bLNo",
  containerNo = "containerNo",
  termsOfDelivery = "termsOfDelivery",
  portOfLoadings = "portOfLoadings",
  portOfDischarge = "portOfDischarge",
  termsOfPayment = "termsOfPayment",
  createdOn = "createdOn",
  updatedOn = "updatedOn",
  deliveryNoteNo = "deliveryNoteNo",
  poReference = "poReference",
  poValue = "poValue",
  dueDate = "dueDate",
  saved = "saved",
  discount = "discount",
  advance = "advance",
  accountHolder = "accountHolder",
  accountNumber = "accountNumber",
  iban = "iban",
  accountAddress = "accountAddress",
  accountCountry = "accountCountry",
  hideRecord = "hideRecord",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableInvoiceAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableInvoiceAggregateField,
};

export enum SearchableInvoiceAggregateField {
  id = "id",
  invoiceNumber = "invoiceNumber",
  invoiceDate = "invoiceDate",
  consignee = "consignee",
  consigneeId = "consigneeId",
  consigneeAddress = "consigneeAddress",
  consigneeEmail = "consigneeEmail",
  consigneeTelephone = "consigneeTelephone",
  consigneeCountry = "consigneeCountry",
  paymentDurations = "paymentDurations",
  totalAmount = "totalAmount",
  totalAmountInWords = "totalAmountInWords",
  bLNo = "bLNo",
  containerNo = "containerNo",
  termsOfDelivery = "termsOfDelivery",
  portOfLoadings = "portOfLoadings",
  portOfDischarge = "portOfDischarge",
  transitShipment = "transitShipment",
  partialShipment = "partialShipment",
  termsOfPayment = "termsOfPayment",
  createdOn = "createdOn",
  updatedOn = "updatedOn",
  deliveryNoteNo = "deliveryNoteNo",
  poReference = "poReference",
  poValue = "poValue",
  dueDate = "dueDate",
  saved = "saved",
  invoiceType = "invoiceType",
  discount = "discount",
  advance = "advance",
  accountHolder = "accountHolder",
  accountNumber = "accountNumber",
  iban = "iban",
  accountAddress = "accountAddress",
  accountCountry = "accountCountry",
  hideRecord = "hideRecord",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableInvoiceConnection = {
  __typename: "SearchableInvoiceConnection",
  items:  Array<Invoice | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type Invoice = {
  __typename: "Invoice",
  id: string,
  invoiceNumber: string,
  invoiceDate: string,
  consignee: string,
  consigneeId?: string | null,
  consigneeAddress: string,
  consigneeEmail: string,
  consigneeTelephone: string,
  consigneeCountry?: string | null,
  paymentDurations: string,
  totalAmount: number,
  totalAmountInWords: string,
  localItems?:  Array<LocalInvoiceItem | null > | null,
  exportItems?:  Array<ExportInvoiceItem | null > | null,
  bLNo?: string | null,
  containerNo?: string | null,
  termsOfDelivery?: string | null,
  portOfLoadings?: string | null,
  portOfDischarge?: string | null,
  transitShipment?: ShipmentStatus | null,
  partialShipment?: ShipmentStatus | null,
  termsOfPayment?: string | null,
  createdOn: string,
  updatedOn: string,
  deliveryNoteNo?: string | null,
  poReference: string,
  poValue: number,
  dueDate: string,
  saved: boolean,
  invoiceType: InvoiceType,
  discount: number,
  advance: number,
  accountHolder: string,
  accountNumber: string,
  iban: string,
  accountAddress: string,
  accountCountry: string,
  hideRecord: boolean,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type LocalInvoiceItem = {
  __typename: "LocalInvoiceItem",
  fishType: string,
  quantity: number,
  unitPrice: number,
  totalAmount: number,
};

export type ExportInvoiceItem = {
  __typename: "ExportInvoiceItem",
  details: string,
  quantity: number,
  unitPrice: number,
  totalAmount: number,
};

export enum ShipmentStatus {
  ALLOWED = "ALLOWED",
  NOT_ALLOWED = "NOT_ALLOWED",
  INITIAL = "INITIAL",
}


export enum InvoiceType {
  LOCAL_FROZEN_AND_FRESH = "LOCAL_FROZEN_AND_FRESH",
  CHILL_EXPORT = "CHILL_EXPORT",
  FRESH_EXPORT = "FRESH_EXPORT",
  DRY_FISH = "DRY_FISH",
  DEFAULT = "DEFAULT",
}


export type ModelVoucherFilterInput = {
  id?: ModelIDInput | null,
  voucherNumber?: ModelStringInput | null,
  assignedUserId?: ModelIDInput | null,
  purpose?: ModelStringInput | null,
  totalAmount?: ModelFloatInput | null,
  remainingAmount?: ModelFloatInput | null,
  status?: ModelVoucherStatusInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  validUntil?: ModelStringInput | null,
  chequeReferenceNumber?: ModelStringInput | null,
  saveAsTemplate?: ModelBooleanInput | null,
  templateName?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedBy?: ModelIDInput | null,
  deleted?: ModelBooleanInput | null,
  and?: Array< ModelVoucherFilterInput | null > | null,
  or?: Array< ModelVoucherFilterInput | null > | null,
  not?: ModelVoucherFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelVoucherStatusInput = {
  eq?: VoucherStatus | null,
  ne?: VoucherStatus | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelVoucherConnection = {
  __typename: "ModelVoucherConnection",
  items:  Array<Voucher | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type CreateUserInput = {
  id?: string | null,
  userId: string,
  firstName: string,
  lastName: string,
  email: string,
  designation: string,
  userType: UserType,
  birthDay: string,
  address: string,
  residingCountry: string,
  bio: string,
  profilePicture: string,
  contactNumber: string,
  authActivities: Array< AuthenticationActivitiesInput | null >,
  _version?: number | null,
};

export type AuthenticationActivitiesInput = {
  loginTime: string,
  logoutTime?: string | null,
};

export type ModelUserConditionInput = {
  userId?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  designation?: ModelStringInput | null,
  userType?: ModelUserTypeInput | null,
  birthDay?: ModelStringInput | null,
  address?: ModelStringInput | null,
  residingCountry?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  profilePicture?: ModelStringInput | null,
  contactNumber?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelUserTypeInput = {
  eq?: UserType | null,
  ne?: UserType | null,
};

export type UpdateUserInput = {
  id: string,
  userId?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  designation?: string | null,
  userType?: UserType | null,
  birthDay?: string | null,
  address?: string | null,
  residingCountry?: string | null,
  bio?: string | null,
  profilePicture?: string | null,
  contactNumber?: string | null,
  authActivities?: Array< AuthenticationActivitiesInput | null > | null,
  _version?: number | null,
};

export type DeleteUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateVoucherInput = {
  id?: string | null,
  voucherNumber: string,
  assignedUserId: string,
  purpose: string,
  totalAmount: number,
  remainingAmount: number,
  status: VoucherStatus,
  createdOn?: string | null,
  updatedOn?: string | null,
  validUntil: string,
  chequeReferenceNumber: string,
  voucherRedeems: Array< VoucherRedeemDataInput | null >,
  saveAsTemplate: boolean,
  templateName: string,
  createdBy: string,
  updatedBy: string,
  deleted: boolean,
  _version?: number | null,
};

export type VoucherRedeemDataInput = {
  amount: number,
  remark: string,
  redeemedByUsername: string,
  redeemedOn: string,
};

export type ModelVoucherConditionInput = {
  voucherNumber?: ModelStringInput | null,
  assignedUserId?: ModelIDInput | null,
  purpose?: ModelStringInput | null,
  totalAmount?: ModelFloatInput | null,
  remainingAmount?: ModelFloatInput | null,
  status?: ModelVoucherStatusInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  validUntil?: ModelStringInput | null,
  chequeReferenceNumber?: ModelStringInput | null,
  saveAsTemplate?: ModelBooleanInput | null,
  templateName?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedBy?: ModelIDInput | null,
  deleted?: ModelBooleanInput | null,
  and?: Array< ModelVoucherConditionInput | null > | null,
  or?: Array< ModelVoucherConditionInput | null > | null,
  not?: ModelVoucherConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateVoucherInput = {
  id: string,
  voucherNumber?: string | null,
  assignedUserId?: string | null,
  purpose?: string | null,
  totalAmount?: number | null,
  remainingAmount?: number | null,
  status?: VoucherStatus | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  validUntil?: string | null,
  chequeReferenceNumber?: string | null,
  voucherRedeems?: Array< VoucherRedeemDataInput | null > | null,
  saveAsTemplate?: boolean | null,
  templateName?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  deleted?: boolean | null,
  _version?: number | null,
};

export type DeleteVoucherInput = {
  id: string,
  _version?: number | null,
};

export type CreateInvoiceInput = {
  id?: string | null,
  invoiceNumber: string,
  invoiceDate: string,
  consignee: string,
  consigneeId?: string | null,
  consigneeAddress: string,
  consigneeEmail: string,
  consigneeTelephone: string,
  consigneeCountry?: string | null,
  paymentDurations: string,
  totalAmount: number,
  totalAmountInWords: string,
  localItems?: Array< LocalInvoiceItemInput | null > | null,
  exportItems?: Array< ExportInvoiceItemInput | null > | null,
  bLNo?: string | null,
  containerNo?: string | null,
  termsOfDelivery?: string | null,
  portOfLoadings?: string | null,
  portOfDischarge?: string | null,
  transitShipment?: ShipmentStatus | null,
  partialShipment?: ShipmentStatus | null,
  termsOfPayment?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  deliveryNoteNo?: string | null,
  poReference: string,
  poValue: number,
  dueDate: string,
  saved: boolean,
  invoiceType: InvoiceType,
  discount: number,
  advance: number,
  accountHolder: string,
  accountNumber: string,
  iban: string,
  accountAddress: string,
  accountCountry: string,
  hideRecord: boolean,
  _version?: number | null,
};

export type LocalInvoiceItemInput = {
  fishType: string,
  quantity: number,
  unitPrice: number,
  totalAmount: number,
};

export type ExportInvoiceItemInput = {
  details: string,
  quantity: number,
  unitPrice: number,
  totalAmount: number,
};

export type ModelInvoiceConditionInput = {
  invoiceNumber?: ModelStringInput | null,
  invoiceDate?: ModelStringInput | null,
  consignee?: ModelStringInput | null,
  consigneeId?: ModelIDInput | null,
  consigneeAddress?: ModelStringInput | null,
  consigneeEmail?: ModelStringInput | null,
  consigneeTelephone?: ModelStringInput | null,
  consigneeCountry?: ModelStringInput | null,
  paymentDurations?: ModelStringInput | null,
  totalAmount?: ModelFloatInput | null,
  totalAmountInWords?: ModelStringInput | null,
  bLNo?: ModelStringInput | null,
  containerNo?: ModelStringInput | null,
  termsOfDelivery?: ModelStringInput | null,
  portOfLoadings?: ModelStringInput | null,
  portOfDischarge?: ModelStringInput | null,
  transitShipment?: ModelShipmentStatusInput | null,
  partialShipment?: ModelShipmentStatusInput | null,
  termsOfPayment?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  deliveryNoteNo?: ModelStringInput | null,
  poReference?: ModelStringInput | null,
  poValue?: ModelFloatInput | null,
  dueDate?: ModelStringInput | null,
  saved?: ModelBooleanInput | null,
  invoiceType?: ModelInvoiceTypeInput | null,
  discount?: ModelFloatInput | null,
  advance?: ModelFloatInput | null,
  accountHolder?: ModelStringInput | null,
  accountNumber?: ModelStringInput | null,
  iban?: ModelStringInput | null,
  accountAddress?: ModelStringInput | null,
  accountCountry?: ModelStringInput | null,
  hideRecord?: ModelBooleanInput | null,
  and?: Array< ModelInvoiceConditionInput | null > | null,
  or?: Array< ModelInvoiceConditionInput | null > | null,
  not?: ModelInvoiceConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelShipmentStatusInput = {
  eq?: ShipmentStatus | null,
  ne?: ShipmentStatus | null,
};

export type ModelInvoiceTypeInput = {
  eq?: InvoiceType | null,
  ne?: InvoiceType | null,
};

export type UpdateInvoiceInput = {
  id: string,
  invoiceNumber?: string | null,
  invoiceDate?: string | null,
  consignee?: string | null,
  consigneeId?: string | null,
  consigneeAddress?: string | null,
  consigneeEmail?: string | null,
  consigneeTelephone?: string | null,
  consigneeCountry?: string | null,
  paymentDurations?: string | null,
  totalAmount?: number | null,
  totalAmountInWords?: string | null,
  localItems?: Array< LocalInvoiceItemInput | null > | null,
  exportItems?: Array< ExportInvoiceItemInput | null > | null,
  bLNo?: string | null,
  containerNo?: string | null,
  termsOfDelivery?: string | null,
  portOfLoadings?: string | null,
  portOfDischarge?: string | null,
  transitShipment?: ShipmentStatus | null,
  partialShipment?: ShipmentStatus | null,
  termsOfPayment?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  deliveryNoteNo?: string | null,
  poReference?: string | null,
  poValue?: number | null,
  dueDate?: string | null,
  saved?: boolean | null,
  invoiceType?: InvoiceType | null,
  discount?: number | null,
  advance?: number | null,
  accountHolder?: string | null,
  accountNumber?: string | null,
  iban?: string | null,
  accountAddress?: string | null,
  accountCountry?: string | null,
  hideRecord?: boolean | null,
  _version?: number | null,
};

export type DeleteInvoiceInput = {
  id: string,
  _version?: number | null,
};

export type CreateSalaryDetailsInput = {
  id?: string | null,
  userId: string,
  fullName: string,
  position: string,
  basicSalary: number,
  bonus?: number | null,
  deductions: number,
  paymentDate: string,
  bankName: string,
  branch: string,
  accountNumber: string,
  accountType: string,
  currencyType: string,
  advance?: number | null,
  advanceEVoucherRef?: string | null,
  month: string,
  year: string,
  createdOn?: string | null,
  updatedOn?: string | null,
  _version?: number | null,
};

export type ModelSalaryDetailsConditionInput = {
  userId?: ModelStringInput | null,
  fullName?: ModelStringInput | null,
  position?: ModelStringInput | null,
  basicSalary?: ModelFloatInput | null,
  bonus?: ModelFloatInput | null,
  deductions?: ModelFloatInput | null,
  paymentDate?: ModelStringInput | null,
  bankName?: ModelStringInput | null,
  branch?: ModelStringInput | null,
  accountNumber?: ModelStringInput | null,
  accountType?: ModelStringInput | null,
  currencyType?: ModelStringInput | null,
  advance?: ModelFloatInput | null,
  advanceEVoucherRef?: ModelStringInput | null,
  month?: ModelStringInput | null,
  year?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelSalaryDetailsConditionInput | null > | null,
  or?: Array< ModelSalaryDetailsConditionInput | null > | null,
  not?: ModelSalaryDetailsConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateSalaryDetailsInput = {
  id: string,
  userId?: string | null,
  fullName?: string | null,
  position?: string | null,
  basicSalary?: number | null,
  bonus?: number | null,
  deductions?: number | null,
  paymentDate?: string | null,
  bankName?: string | null,
  branch?: string | null,
  accountNumber?: string | null,
  accountType?: string | null,
  currencyType?: string | null,
  advance?: number | null,
  advanceEVoucherRef?: string | null,
  month?: string | null,
  year?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  _version?: number | null,
};

export type DeleteSalaryDetailsInput = {
  id: string,
  _version?: number | null,
};

export type CreateMetaDataInput = {
  id?: string | null,
  recordType: MetaDataRecordType,
  date: string,
  count: number,
  createdOn?: string | null,
  updatedOn?: string | null,
  _version?: number | null,
};

export enum MetaDataRecordType {
  USER_RECORD = "USER_RECORD",
  E_VOUCHER_RECORD = "E_VOUCHER_RECORD",
  INVOICE_LOCAL_FROZEN_AND_FRESH = "INVOICE_LOCAL_FROZEN_AND_FRESH",
  INVOICE_FRESH_EXPORT = "INVOICE_FRESH_EXPORT",
  INVOICE_DRY_FISH = "INVOICE_DRY_FISH",
  INVOICE_CHILL_EXPORT = "INVOICE_CHILL_EXPORT",
}


export type ModelMetaDataConditionInput = {
  recordType?: ModelMetaDataRecordTypeInput | null,
  date?: ModelStringInput | null,
  count?: ModelFloatInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelMetaDataConditionInput | null > | null,
  or?: Array< ModelMetaDataConditionInput | null > | null,
  not?: ModelMetaDataConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelMetaDataRecordTypeInput = {
  eq?: MetaDataRecordType | null,
  ne?: MetaDataRecordType | null,
};

export type MetaData = {
  __typename: "MetaData",
  id: string,
  recordType: MetaDataRecordType,
  date: string,
  count: number,
  createdOn: string,
  updatedOn: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateMetaDataInput = {
  id: string,
  recordType?: MetaDataRecordType | null,
  date?: string | null,
  count?: number | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  _version?: number | null,
};

export type DeleteMetaDataInput = {
  id: string,
  _version?: number | null,
};

export type CreateEmployeeDirectoryInput = {
  id?: string | null,
  employeeId: string,
  userId?: string | null,
  company: string,
  createdOn?: string | null,
  updatedOn?: string | null,
  _version?: number | null,
};

export type ModelEmployeeDirectoryConditionInput = {
  employeeId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  company?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEmployeeDirectoryConditionInput | null > | null,
  or?: Array< ModelEmployeeDirectoryConditionInput | null > | null,
  not?: ModelEmployeeDirectoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type EmployeeDirectory = {
  __typename: "EmployeeDirectory",
  id: string,
  employeeId: string,
  userId?: string | null,
  userRecord?: User | null,
  company: string,
  createdOn: string,
  updatedOn: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateEmployeeDirectoryInput = {
  id: string,
  employeeId?: string | null,
  userId?: string | null,
  company?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  _version?: number | null,
};

export type DeleteEmployeeDirectoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateCustomersInput = {
  id?: string | null,
  customerName: string,
  customerAddress: string,
  customerEmail: string,
  customerTelephone: string,
  customerCountry: string,
  invoiceType: InvoiceType,
  _version?: number | null,
};

export type ModelCustomersConditionInput = {
  customerName?: ModelStringInput | null,
  customerAddress?: ModelStringInput | null,
  customerEmail?: ModelStringInput | null,
  customerTelephone?: ModelStringInput | null,
  customerCountry?: ModelStringInput | null,
  invoiceType?: ModelInvoiceTypeInput | null,
  and?: Array< ModelCustomersConditionInput | null > | null,
  or?: Array< ModelCustomersConditionInput | null > | null,
  not?: ModelCustomersConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
};

export type Customers = {
  __typename: "Customers",
  id: string,
  customerName: string,
  customerAddress: string,
  customerEmail: string,
  customerTelephone: string,
  customerCountry: string,
  invoiceType: InvoiceType,
  createdOn: string,
  updatedOn: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCustomersInput = {
  id: string,
  customerName?: string | null,
  customerAddress?: string | null,
  customerEmail?: string | null,
  customerTelephone?: string | null,
  customerCountry?: string | null,
  invoiceType?: InvoiceType | null,
  _version?: number | null,
};

export type DeleteCustomersInput = {
  id: string,
  _version?: number | null,
};

export type CreateInventoryInput = {
  id?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  inventoryItems: Array< InventoryItemInput | null >,
  _version?: number | null,
};

export type InventoryItemInput = {
  cccs: string,
  specie: string,
  scow: string,
  weight: number,
  client: string,
  boatCode: string,
  container: string,
  enteredCs: string,
  firstEnteredToCs: string,
  qaMark: string,
};

export type ModelInventoryConditionInput = {
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelInventoryConditionInput | null > | null,
  or?: Array< ModelInventoryConditionInput | null > | null,
  not?: ModelInventoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Inventory = {
  __typename: "Inventory",
  id: string,
  createdOn: string,
  updatedOn: string,
  inventoryItems:  Array<InventoryItem | null >,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type InventoryItem = {
  __typename: "InventoryItem",
  cccs: string,
  specie: string,
  scow: string,
  weight: number,
  client: string,
  boatCode: string,
  container: string,
  enteredCs: string,
  firstEnteredToCs: string,
  qaMark: string,
};

export type UpdateInventoryInput = {
  id: string,
  createdOn?: string | null,
  updatedOn?: string | null,
  inventoryItems?: Array< InventoryItemInput | null > | null,
  _version?: number | null,
};

export type DeleteInventoryInput = {
  id: string,
  _version?: number | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  designation?: ModelStringInput | null,
  userType?: ModelUserTypeInput | null,
  birthDay?: ModelStringInput | null,
  address?: ModelStringInput | null,
  residingCountry?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  profilePicture?: ModelStringInput | null,
  contactNumber?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SearchableUserFilterInput = {
  id?: SearchableIDFilterInput | null,
  userId?: SearchableIDFilterInput | null,
  firstName?: SearchableStringFilterInput | null,
  lastName?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  designation?: SearchableStringFilterInput | null,
  birthDay?: SearchableStringFilterInput | null,
  address?: SearchableStringFilterInput | null,
  residingCountry?: SearchableStringFilterInput | null,
  bio?: SearchableStringFilterInput | null,
  profilePicture?: SearchableStringFilterInput | null,
  contactNumber?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  userType?: SearchableStringFilterInput | null,
  and?: Array< SearchableUserFilterInput | null > | null,
  or?: Array< SearchableUserFilterInput | null > | null,
  not?: SearchableUserFilterInput | null,
};

export type SearchableUserSortInput = {
  field?: SearchableUserSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableUserSortableFields {
  id = "id",
  userId = "userId",
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  designation = "designation",
  birthDay = "birthDay",
  address = "address",
  residingCountry = "residingCountry",
  bio = "bio",
  profilePicture = "profilePicture",
  contactNumber = "contactNumber",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableUserAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableUserAggregateField,
};

export enum SearchableUserAggregateField {
  id = "id",
  userId = "userId",
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  designation = "designation",
  userType = "userType",
  birthDay = "birthDay",
  address = "address",
  residingCountry = "residingCountry",
  bio = "bio",
  profilePicture = "profilePicture",
  contactNumber = "contactNumber",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableUserConnection = {
  __typename: "SearchableUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelInvoiceFilterInput = {
  id?: ModelIDInput | null,
  invoiceNumber?: ModelStringInput | null,
  invoiceDate?: ModelStringInput | null,
  consignee?: ModelStringInput | null,
  consigneeId?: ModelIDInput | null,
  consigneeAddress?: ModelStringInput | null,
  consigneeEmail?: ModelStringInput | null,
  consigneeTelephone?: ModelStringInput | null,
  consigneeCountry?: ModelStringInput | null,
  paymentDurations?: ModelStringInput | null,
  totalAmount?: ModelFloatInput | null,
  totalAmountInWords?: ModelStringInput | null,
  bLNo?: ModelStringInput | null,
  containerNo?: ModelStringInput | null,
  termsOfDelivery?: ModelStringInput | null,
  portOfLoadings?: ModelStringInput | null,
  portOfDischarge?: ModelStringInput | null,
  transitShipment?: ModelShipmentStatusInput | null,
  partialShipment?: ModelShipmentStatusInput | null,
  termsOfPayment?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  deliveryNoteNo?: ModelStringInput | null,
  poReference?: ModelStringInput | null,
  poValue?: ModelFloatInput | null,
  dueDate?: ModelStringInput | null,
  saved?: ModelBooleanInput | null,
  invoiceType?: ModelInvoiceTypeInput | null,
  discount?: ModelFloatInput | null,
  advance?: ModelFloatInput | null,
  accountHolder?: ModelStringInput | null,
  accountNumber?: ModelStringInput | null,
  iban?: ModelStringInput | null,
  accountAddress?: ModelStringInput | null,
  accountCountry?: ModelStringInput | null,
  hideRecord?: ModelBooleanInput | null,
  and?: Array< ModelInvoiceFilterInput | null > | null,
  or?: Array< ModelInvoiceFilterInput | null > | null,
  not?: ModelInvoiceFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelInvoiceConnection = {
  __typename: "ModelInvoiceConnection",
  items:  Array<Invoice | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSalaryDetailsFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelStringInput | null,
  fullName?: ModelStringInput | null,
  position?: ModelStringInput | null,
  basicSalary?: ModelFloatInput | null,
  bonus?: ModelFloatInput | null,
  deductions?: ModelFloatInput | null,
  paymentDate?: ModelStringInput | null,
  bankName?: ModelStringInput | null,
  branch?: ModelStringInput | null,
  accountNumber?: ModelStringInput | null,
  accountType?: ModelStringInput | null,
  currencyType?: ModelStringInput | null,
  advance?: ModelFloatInput | null,
  advanceEVoucherRef?: ModelStringInput | null,
  month?: ModelStringInput | null,
  year?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelSalaryDetailsFilterInput | null > | null,
  or?: Array< ModelSalaryDetailsFilterInput | null > | null,
  not?: ModelSalaryDetailsFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type SearchableSalaryDetailsFilterInput = {
  id?: SearchableIDFilterInput | null,
  userId?: SearchableStringFilterInput | null,
  fullName?: SearchableStringFilterInput | null,
  position?: SearchableStringFilterInput | null,
  basicSalary?: SearchableFloatFilterInput | null,
  bonus?: SearchableFloatFilterInput | null,
  deductions?: SearchableFloatFilterInput | null,
  paymentDate?: SearchableStringFilterInput | null,
  bankName?: SearchableStringFilterInput | null,
  branch?: SearchableStringFilterInput | null,
  accountNumber?: SearchableStringFilterInput | null,
  accountType?: SearchableStringFilterInput | null,
  currencyType?: SearchableStringFilterInput | null,
  advance?: SearchableFloatFilterInput | null,
  advanceEVoucherRef?: SearchableStringFilterInput | null,
  month?: SearchableStringFilterInput | null,
  year?: SearchableStringFilterInput | null,
  createdOn?: SearchableStringFilterInput | null,
  updatedOn?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  and?: Array< SearchableSalaryDetailsFilterInput | null > | null,
  or?: Array< SearchableSalaryDetailsFilterInput | null > | null,
  not?: SearchableSalaryDetailsFilterInput | null,
};

export type SearchableSalaryDetailsSortInput = {
  field?: SearchableSalaryDetailsSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableSalaryDetailsSortableFields {
  id = "id",
  userId = "userId",
  fullName = "fullName",
  position = "position",
  basicSalary = "basicSalary",
  bonus = "bonus",
  deductions = "deductions",
  paymentDate = "paymentDate",
  bankName = "bankName",
  branch = "branch",
  accountNumber = "accountNumber",
  accountType = "accountType",
  currencyType = "currencyType",
  advance = "advance",
  advanceEVoucherRef = "advanceEVoucherRef",
  month = "month",
  year = "year",
  createdOn = "createdOn",
  updatedOn = "updatedOn",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableSalaryDetailsAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableSalaryDetailsAggregateField,
};

export enum SearchableSalaryDetailsAggregateField {
  id = "id",
  userId = "userId",
  fullName = "fullName",
  position = "position",
  basicSalary = "basicSalary",
  bonus = "bonus",
  deductions = "deductions",
  paymentDate = "paymentDate",
  bankName = "bankName",
  branch = "branch",
  accountNumber = "accountNumber",
  accountType = "accountType",
  currencyType = "currencyType",
  advance = "advance",
  advanceEVoucherRef = "advanceEVoucherRef",
  month = "month",
  year = "year",
  createdOn = "createdOn",
  updatedOn = "updatedOn",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableSalaryDetailsConnection = {
  __typename: "SearchableSalaryDetailsConnection",
  items:  Array<SalaryDetails | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelMetaDataFilterInput = {
  id?: ModelIDInput | null,
  recordType?: ModelMetaDataRecordTypeInput | null,
  date?: ModelStringInput | null,
  count?: ModelFloatInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelMetaDataFilterInput | null > | null,
  or?: Array< ModelMetaDataFilterInput | null > | null,
  not?: ModelMetaDataFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelMetaDataConnection = {
  __typename: "ModelMetaDataConnection",
  items:  Array<MetaData | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SearchableMetaDataFilterInput = {
  id?: SearchableIDFilterInput | null,
  date?: SearchableStringFilterInput | null,
  count?: SearchableFloatFilterInput | null,
  createdOn?: SearchableStringFilterInput | null,
  updatedOn?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  recordType?: SearchableStringFilterInput | null,
  and?: Array< SearchableMetaDataFilterInput | null > | null,
  or?: Array< SearchableMetaDataFilterInput | null > | null,
  not?: SearchableMetaDataFilterInput | null,
};

export type SearchableMetaDataSortInput = {
  field?: SearchableMetaDataSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableMetaDataSortableFields {
  id = "id",
  date = "date",
  count = "count",
  createdOn = "createdOn",
  updatedOn = "updatedOn",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableMetaDataAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableMetaDataAggregateField,
};

export enum SearchableMetaDataAggregateField {
  id = "id",
  recordType = "recordType",
  date = "date",
  count = "count",
  createdOn = "createdOn",
  updatedOn = "updatedOn",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableMetaDataConnection = {
  __typename: "SearchableMetaDataConnection",
  items:  Array<MetaData | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelEmployeeDirectoryFilterInput = {
  id?: ModelIDInput | null,
  employeeId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  company?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEmployeeDirectoryFilterInput | null > | null,
  or?: Array< ModelEmployeeDirectoryFilterInput | null > | null,
  not?: ModelEmployeeDirectoryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelEmployeeDirectoryConnection = {
  __typename: "ModelEmployeeDirectoryConnection",
  items:  Array<EmployeeDirectory | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCustomersFilterInput = {
  id?: ModelIDInput | null,
  customerName?: ModelStringInput | null,
  customerAddress?: ModelStringInput | null,
  customerEmail?: ModelStringInput | null,
  customerTelephone?: ModelStringInput | null,
  customerCountry?: ModelStringInput | null,
  invoiceType?: ModelInvoiceTypeInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelCustomersFilterInput | null > | null,
  or?: Array< ModelCustomersFilterInput | null > | null,
  not?: ModelCustomersFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCustomersConnection = {
  __typename: "ModelCustomersConnection",
  items:  Array<Customers | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelInventoryFilterInput = {
  id?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelInventoryFilterInput | null > | null,
  or?: Array< ModelInventoryFilterInput | null > | null,
  not?: ModelInventoryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelInventoryConnection = {
  __typename: "ModelInventoryConnection",
  items:  Array<Inventory | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SearchableInventoryFilterInput = {
  id?: SearchableIDFilterInput | null,
  createdOn?: SearchableStringFilterInput | null,
  updatedOn?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  and?: Array< SearchableInventoryFilterInput | null > | null,
  or?: Array< SearchableInventoryFilterInput | null > | null,
  not?: SearchableInventoryFilterInput | null,
};

export type SearchableInventorySortInput = {
  field?: SearchableInventorySortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableInventorySortableFields {
  id = "id",
  createdOn = "createdOn",
  updatedOn = "updatedOn",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableInventoryAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableInventoryAggregateField,
};

export enum SearchableInventoryAggregateField {
  id = "id",
  createdOn = "createdOn",
  updatedOn = "updatedOn",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableInventoryConnection = {
  __typename: "SearchableInventoryConnection",
  items:  Array<Inventory | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  designation?: ModelSubscriptionStringInput | null,
  userType?: ModelSubscriptionStringInput | null,
  birthDay?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  residingCountry?: ModelSubscriptionStringInput | null,
  bio?: ModelSubscriptionStringInput | null,
  profilePicture?: ModelSubscriptionStringInput | null,
  contactNumber?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionVoucherFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  voucherNumber?: ModelSubscriptionStringInput | null,
  assignedUserId?: ModelSubscriptionIDInput | null,
  purpose?: ModelSubscriptionStringInput | null,
  totalAmount?: ModelSubscriptionFloatInput | null,
  remainingAmount?: ModelSubscriptionFloatInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdOn?: ModelSubscriptionStringInput | null,
  updatedOn?: ModelSubscriptionStringInput | null,
  validUntil?: ModelSubscriptionStringInput | null,
  chequeReferenceNumber?: ModelSubscriptionStringInput | null,
  saveAsTemplate?: ModelSubscriptionBooleanInput | null,
  templateName?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionIDInput | null,
  updatedBy?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionVoucherFilterInput | null > | null,
  or?: Array< ModelSubscriptionVoucherFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionInvoiceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  invoiceNumber?: ModelSubscriptionStringInput | null,
  invoiceDate?: ModelSubscriptionStringInput | null,
  consignee?: ModelSubscriptionStringInput | null,
  consigneeId?: ModelSubscriptionIDInput | null,
  consigneeAddress?: ModelSubscriptionStringInput | null,
  consigneeEmail?: ModelSubscriptionStringInput | null,
  consigneeTelephone?: ModelSubscriptionStringInput | null,
  consigneeCountry?: ModelSubscriptionStringInput | null,
  paymentDurations?: ModelSubscriptionStringInput | null,
  totalAmount?: ModelSubscriptionFloatInput | null,
  totalAmountInWords?: ModelSubscriptionStringInput | null,
  bLNo?: ModelSubscriptionStringInput | null,
  containerNo?: ModelSubscriptionStringInput | null,
  termsOfDelivery?: ModelSubscriptionStringInput | null,
  portOfLoadings?: ModelSubscriptionStringInput | null,
  portOfDischarge?: ModelSubscriptionStringInput | null,
  transitShipment?: ModelSubscriptionStringInput | null,
  partialShipment?: ModelSubscriptionStringInput | null,
  termsOfPayment?: ModelSubscriptionStringInput | null,
  createdOn?: ModelSubscriptionStringInput | null,
  updatedOn?: ModelSubscriptionStringInput | null,
  deliveryNoteNo?: ModelSubscriptionStringInput | null,
  poReference?: ModelSubscriptionStringInput | null,
  poValue?: ModelSubscriptionFloatInput | null,
  dueDate?: ModelSubscriptionStringInput | null,
  saved?: ModelSubscriptionBooleanInput | null,
  invoiceType?: ModelSubscriptionStringInput | null,
  discount?: ModelSubscriptionFloatInput | null,
  advance?: ModelSubscriptionFloatInput | null,
  accountHolder?: ModelSubscriptionStringInput | null,
  accountNumber?: ModelSubscriptionStringInput | null,
  iban?: ModelSubscriptionStringInput | null,
  accountAddress?: ModelSubscriptionStringInput | null,
  accountCountry?: ModelSubscriptionStringInput | null,
  hideRecord?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionInvoiceFilterInput | null > | null,
  or?: Array< ModelSubscriptionInvoiceFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionSalaryDetailsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionStringInput | null,
  fullName?: ModelSubscriptionStringInput | null,
  position?: ModelSubscriptionStringInput | null,
  basicSalary?: ModelSubscriptionFloatInput | null,
  bonus?: ModelSubscriptionFloatInput | null,
  deductions?: ModelSubscriptionFloatInput | null,
  paymentDate?: ModelSubscriptionStringInput | null,
  bankName?: ModelSubscriptionStringInput | null,
  branch?: ModelSubscriptionStringInput | null,
  accountNumber?: ModelSubscriptionStringInput | null,
  accountType?: ModelSubscriptionStringInput | null,
  currencyType?: ModelSubscriptionStringInput | null,
  advance?: ModelSubscriptionFloatInput | null,
  advanceEVoucherRef?: ModelSubscriptionStringInput | null,
  month?: ModelSubscriptionStringInput | null,
  year?: ModelSubscriptionStringInput | null,
  createdOn?: ModelSubscriptionStringInput | null,
  updatedOn?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSalaryDetailsFilterInput | null > | null,
  or?: Array< ModelSubscriptionSalaryDetailsFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionMetaDataFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  recordType?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  count?: ModelSubscriptionFloatInput | null,
  createdOn?: ModelSubscriptionStringInput | null,
  updatedOn?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMetaDataFilterInput | null > | null,
  or?: Array< ModelSubscriptionMetaDataFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionEmployeeDirectoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  employeeId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  company?: ModelSubscriptionStringInput | null,
  createdOn?: ModelSubscriptionStringInput | null,
  updatedOn?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEmployeeDirectoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionEmployeeDirectoryFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCustomersFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  customerName?: ModelSubscriptionStringInput | null,
  customerAddress?: ModelSubscriptionStringInput | null,
  customerEmail?: ModelSubscriptionStringInput | null,
  customerTelephone?: ModelSubscriptionStringInput | null,
  customerCountry?: ModelSubscriptionStringInput | null,
  invoiceType?: ModelSubscriptionStringInput | null,
  createdOn?: ModelSubscriptionStringInput | null,
  updatedOn?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCustomersFilterInput | null > | null,
  or?: Array< ModelSubscriptionCustomersFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionInventoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdOn?: ModelSubscriptionStringInput | null,
  updatedOn?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInventoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionInventoryFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type CustomListVouchersQueryVariables = {
  filter?: SearchableVoucherFilterInput | null,
  sort?: Array< SearchableVoucherSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableVoucherAggregationInput | null > | null,
};

export type CustomListVouchersQuery = {
  searchVouchers?:  {
    __typename: "SearchableVoucherConnection",
    items:  Array< {
      __typename: "Voucher",
      id: string,
      voucherNumber: string,
      assignedUserId: string,
      assignedTo:  {
        __typename: "User",
        firstName: string,
        lastName: string,
      },
      purpose: string,
      totalAmount: number,
      remainingAmount: number,
      status: VoucherStatus,
      createdOn: string,
      updatedOn: string,
      validUntil: string,
      chequeReferenceNumber: string,
      saveAsTemplate: boolean,
      templateName: string,
      voucherRedeems:  Array< {
        __typename: "VoucherRedeemData",
        amount: number,
        remark: string,
        redeemedByUsername: string,
        redeemedOn: string,
      } | null >,
      createdBy: string,
      updatedBy: string,
      deleted: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type ListCurrentMonthSalaryDetailsQueryVariables = {
  startOfMonth?: string | null,
  endOfMonth?: string | null,
};

export type ListCurrentMonthSalaryDetailsQuery = {
  listSalaryDetails?:  {
    __typename: "ModelSalaryDetailsConnection",
    items:  Array< {
      __typename: "SalaryDetails",
      id: string,
      userId: string,
      fullName: string,
      position: string,
      basicSalary: number,
      bonus?: number | null,
      deductions: number,
      paymentDate: string,
      bankName: string,
      branch: string,
      accountNumber: string,
      accountType: string,
      currencyType: string,
      advance?: number | null,
      advanceEVoucherRef?: string | null,
    } | null >,
  } | null,
};

export type customListInvoicesQueryVariables = {
  filter?: SearchableInvoiceFilterInput | null,
  sort?: Array< SearchableInvoiceSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableInvoiceAggregationInput | null > | null,
};

export type customListInvoicesQuery = {
  searchInvoices?:  {
    __typename: "SearchableInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      invoiceNumber: string,
      invoiceDate: string,
      consignee: string,
      consigneeAddress: string,
      consigneeEmail: string,
      consigneeTelephone: string,
      consigneeCountry?: string | null,
      paymentDurations: string,
      totalAmount: number,
      totalAmountInWords: string,
      bLNo?: string | null,
      localItems?:  Array< {
        __typename: "LocalInvoiceItem",
        fishType: string,
        quantity: number,
        unitPrice: number,
        totalAmount: number,
      } | null > | null,
      exportItems?:  Array< {
        __typename: "ExportInvoiceItem",
        details: string,
        quantity: number,
        unitPrice: number,
        totalAmount: number,
      } | null > | null,
      containerNo?: string | null,
      termsOfDelivery?: string | null,
      portOfLoadings?: string | null,
      portOfDischarge?: string | null,
      transitShipment?: ShipmentStatus | null,
      partialShipment?: ShipmentStatus | null,
      termsOfPayment?: string | null,
      createdOn: string,
      updatedOn: string,
      deliveryNoteNo?: string | null,
      poReference: string,
      poValue: number,
      dueDate: string,
      saved: boolean,
      invoiceType: InvoiceType,
      discount: number,
      advance: number,
      accountHolder: string,
      accountNumber: string,
      iban: string,
      accountAddress: string,
      accountCountry: string,
      hideRecord: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type customSearchVouchersQueryVariables = {
  id?: string | null,
  filter?: ModelVoucherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type customSearchVouchersQuery = {
  listVouchers?:  {
    __typename: "ModelVoucherConnection",
    items:  Array< {
      __typename: "Voucher",
      id: string,
      voucherNumber: string,
      assignedUserId: string,
      assignedTo:  {
        __typename: "User",
        firstName: string,
        lastName: string,
      },
      purpose: string,
      totalAmount: number,
      remainingAmount: number,
      status: VoucherStatus,
      createdOn: string,
      updatedOn: string,
      validUntil: string,
      chequeReferenceNumber: string,
      saveAsTemplate: boolean,
      templateName: string,
      createdBy: string,
      updatedBy: string,
      deleted: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    designation: string,
    userType: UserType,
    birthDay: string,
    address: string,
    residingCountry: string,
    bio: string,
    profilePicture: string,
    contactNumber: string,
    authActivities:  Array< {
      __typename: "AuthenticationActivities",
      loginTime: string,
      logoutTime?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    designation: string,
    userType: UserType,
    birthDay: string,
    address: string,
    residingCountry: string,
    bio: string,
    profilePicture: string,
    contactNumber: string,
    authActivities:  Array< {
      __typename: "AuthenticationActivities",
      loginTime: string,
      logoutTime?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    designation: string,
    userType: UserType,
    birthDay: string,
    address: string,
    residingCountry: string,
    bio: string,
    profilePicture: string,
    contactNumber: string,
    authActivities:  Array< {
      __typename: "AuthenticationActivities",
      loginTime: string,
      logoutTime?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateVoucherMutationVariables = {
  input: CreateVoucherInput,
  condition?: ModelVoucherConditionInput | null,
};

export type CreateVoucherMutation = {
  createVoucher?:  {
    __typename: "Voucher",
    id: string,
    voucherNumber: string,
    assignedUserId: string,
    assignedTo:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    purpose: string,
    totalAmount: number,
    remainingAmount: number,
    status: VoucherStatus,
    createdOn: string,
    updatedOn: string,
    validUntil: string,
    chequeReferenceNumber: string,
    voucherRedeems:  Array< {
      __typename: "VoucherRedeemData",
      amount: number,
      remark: string,
      redeemedByUsername: string,
      redeemedOn: string,
    } | null >,
    saveAsTemplate: boolean,
    templateName: string,
    createdBy: string,
    createdByUserId:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    updatedBy: string,
    updatedByUserId:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    deleted: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateVoucherMutationVariables = {
  input: UpdateVoucherInput,
  condition?: ModelVoucherConditionInput | null,
};

export type UpdateVoucherMutation = {
  updateVoucher?:  {
    __typename: "Voucher",
    id: string,
    voucherNumber: string,
    assignedUserId: string,
    assignedTo:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    purpose: string,
    totalAmount: number,
    remainingAmount: number,
    status: VoucherStatus,
    createdOn: string,
    updatedOn: string,
    validUntil: string,
    chequeReferenceNumber: string,
    voucherRedeems:  Array< {
      __typename: "VoucherRedeemData",
      amount: number,
      remark: string,
      redeemedByUsername: string,
      redeemedOn: string,
    } | null >,
    saveAsTemplate: boolean,
    templateName: string,
    createdBy: string,
    createdByUserId:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    updatedBy: string,
    updatedByUserId:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    deleted: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteVoucherMutationVariables = {
  input: DeleteVoucherInput,
  condition?: ModelVoucherConditionInput | null,
};

export type DeleteVoucherMutation = {
  deleteVoucher?:  {
    __typename: "Voucher",
    id: string,
    voucherNumber: string,
    assignedUserId: string,
    assignedTo:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    purpose: string,
    totalAmount: number,
    remainingAmount: number,
    status: VoucherStatus,
    createdOn: string,
    updatedOn: string,
    validUntil: string,
    chequeReferenceNumber: string,
    voucherRedeems:  Array< {
      __typename: "VoucherRedeemData",
      amount: number,
      remark: string,
      redeemedByUsername: string,
      redeemedOn: string,
    } | null >,
    saveAsTemplate: boolean,
    templateName: string,
    createdBy: string,
    createdByUserId:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    updatedBy: string,
    updatedByUserId:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    deleted: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateInvoiceMutationVariables = {
  input: CreateInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type CreateInvoiceMutation = {
  createInvoice?:  {
    __typename: "Invoice",
    id: string,
    invoiceNumber: string,
    invoiceDate: string,
    consignee: string,
    consigneeId?: string | null,
    consigneeAddress: string,
    consigneeEmail: string,
    consigneeTelephone: string,
    consigneeCountry?: string | null,
    paymentDurations: string,
    totalAmount: number,
    totalAmountInWords: string,
    localItems?:  Array< {
      __typename: "LocalInvoiceItem",
      fishType: string,
      quantity: number,
      unitPrice: number,
      totalAmount: number,
    } | null > | null,
    exportItems?:  Array< {
      __typename: "ExportInvoiceItem",
      details: string,
      quantity: number,
      unitPrice: number,
      totalAmount: number,
    } | null > | null,
    bLNo?: string | null,
    containerNo?: string | null,
    termsOfDelivery?: string | null,
    portOfLoadings?: string | null,
    portOfDischarge?: string | null,
    transitShipment?: ShipmentStatus | null,
    partialShipment?: ShipmentStatus | null,
    termsOfPayment?: string | null,
    createdOn: string,
    updatedOn: string,
    deliveryNoteNo?: string | null,
    poReference: string,
    poValue: number,
    dueDate: string,
    saved: boolean,
    invoiceType: InvoiceType,
    discount: number,
    advance: number,
    accountHolder: string,
    accountNumber: string,
    iban: string,
    accountAddress: string,
    accountCountry: string,
    hideRecord: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateInvoiceMutationVariables = {
  input: UpdateInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type UpdateInvoiceMutation = {
  updateInvoice?:  {
    __typename: "Invoice",
    id: string,
    invoiceNumber: string,
    invoiceDate: string,
    consignee: string,
    consigneeId?: string | null,
    consigneeAddress: string,
    consigneeEmail: string,
    consigneeTelephone: string,
    consigneeCountry?: string | null,
    paymentDurations: string,
    totalAmount: number,
    totalAmountInWords: string,
    localItems?:  Array< {
      __typename: "LocalInvoiceItem",
      fishType: string,
      quantity: number,
      unitPrice: number,
      totalAmount: number,
    } | null > | null,
    exportItems?:  Array< {
      __typename: "ExportInvoiceItem",
      details: string,
      quantity: number,
      unitPrice: number,
      totalAmount: number,
    } | null > | null,
    bLNo?: string | null,
    containerNo?: string | null,
    termsOfDelivery?: string | null,
    portOfLoadings?: string | null,
    portOfDischarge?: string | null,
    transitShipment?: ShipmentStatus | null,
    partialShipment?: ShipmentStatus | null,
    termsOfPayment?: string | null,
    createdOn: string,
    updatedOn: string,
    deliveryNoteNo?: string | null,
    poReference: string,
    poValue: number,
    dueDate: string,
    saved: boolean,
    invoiceType: InvoiceType,
    discount: number,
    advance: number,
    accountHolder: string,
    accountNumber: string,
    iban: string,
    accountAddress: string,
    accountCountry: string,
    hideRecord: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteInvoiceMutationVariables = {
  input: DeleteInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type DeleteInvoiceMutation = {
  deleteInvoice?:  {
    __typename: "Invoice",
    id: string,
    invoiceNumber: string,
    invoiceDate: string,
    consignee: string,
    consigneeId?: string | null,
    consigneeAddress: string,
    consigneeEmail: string,
    consigneeTelephone: string,
    consigneeCountry?: string | null,
    paymentDurations: string,
    totalAmount: number,
    totalAmountInWords: string,
    localItems?:  Array< {
      __typename: "LocalInvoiceItem",
      fishType: string,
      quantity: number,
      unitPrice: number,
      totalAmount: number,
    } | null > | null,
    exportItems?:  Array< {
      __typename: "ExportInvoiceItem",
      details: string,
      quantity: number,
      unitPrice: number,
      totalAmount: number,
    } | null > | null,
    bLNo?: string | null,
    containerNo?: string | null,
    termsOfDelivery?: string | null,
    portOfLoadings?: string | null,
    portOfDischarge?: string | null,
    transitShipment?: ShipmentStatus | null,
    partialShipment?: ShipmentStatus | null,
    termsOfPayment?: string | null,
    createdOn: string,
    updatedOn: string,
    deliveryNoteNo?: string | null,
    poReference: string,
    poValue: number,
    dueDate: string,
    saved: boolean,
    invoiceType: InvoiceType,
    discount: number,
    advance: number,
    accountHolder: string,
    accountNumber: string,
    iban: string,
    accountAddress: string,
    accountCountry: string,
    hideRecord: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSalaryDetailsMutationVariables = {
  input: CreateSalaryDetailsInput,
  condition?: ModelSalaryDetailsConditionInput | null,
};

export type CreateSalaryDetailsMutation = {
  createSalaryDetails?:  {
    __typename: "SalaryDetails",
    id: string,
    userId: string,
    fullName: string,
    position: string,
    basicSalary: number,
    bonus?: number | null,
    deductions: number,
    paymentDate: string,
    bankName: string,
    branch: string,
    accountNumber: string,
    accountType: string,
    currencyType: string,
    advance?: number | null,
    advanceEVoucherRef?: string | null,
    month: string,
    year: string,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSalaryDetailsMutationVariables = {
  input: UpdateSalaryDetailsInput,
  condition?: ModelSalaryDetailsConditionInput | null,
};

export type UpdateSalaryDetailsMutation = {
  updateSalaryDetails?:  {
    __typename: "SalaryDetails",
    id: string,
    userId: string,
    fullName: string,
    position: string,
    basicSalary: number,
    bonus?: number | null,
    deductions: number,
    paymentDate: string,
    bankName: string,
    branch: string,
    accountNumber: string,
    accountType: string,
    currencyType: string,
    advance?: number | null,
    advanceEVoucherRef?: string | null,
    month: string,
    year: string,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSalaryDetailsMutationVariables = {
  input: DeleteSalaryDetailsInput,
  condition?: ModelSalaryDetailsConditionInput | null,
};

export type DeleteSalaryDetailsMutation = {
  deleteSalaryDetails?:  {
    __typename: "SalaryDetails",
    id: string,
    userId: string,
    fullName: string,
    position: string,
    basicSalary: number,
    bonus?: number | null,
    deductions: number,
    paymentDate: string,
    bankName: string,
    branch: string,
    accountNumber: string,
    accountType: string,
    currencyType: string,
    advance?: number | null,
    advanceEVoucherRef?: string | null,
    month: string,
    year: string,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMetaDataMutationVariables = {
  input: CreateMetaDataInput,
  condition?: ModelMetaDataConditionInput | null,
};

export type CreateMetaDataMutation = {
  createMetaData?:  {
    __typename: "MetaData",
    id: string,
    recordType: MetaDataRecordType,
    date: string,
    count: number,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMetaDataMutationVariables = {
  input: UpdateMetaDataInput,
  condition?: ModelMetaDataConditionInput | null,
};

export type UpdateMetaDataMutation = {
  updateMetaData?:  {
    __typename: "MetaData",
    id: string,
    recordType: MetaDataRecordType,
    date: string,
    count: number,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMetaDataMutationVariables = {
  input: DeleteMetaDataInput,
  condition?: ModelMetaDataConditionInput | null,
};

export type DeleteMetaDataMutation = {
  deleteMetaData?:  {
    __typename: "MetaData",
    id: string,
    recordType: MetaDataRecordType,
    date: string,
    count: number,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateEmployeeDirectoryMutationVariables = {
  input: CreateEmployeeDirectoryInput,
  condition?: ModelEmployeeDirectoryConditionInput | null,
};

export type CreateEmployeeDirectoryMutation = {
  createEmployeeDirectory?:  {
    __typename: "EmployeeDirectory",
    id: string,
    employeeId: string,
    userId?: string | null,
    userRecord?:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    company: string,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateEmployeeDirectoryMutationVariables = {
  input: UpdateEmployeeDirectoryInput,
  condition?: ModelEmployeeDirectoryConditionInput | null,
};

export type UpdateEmployeeDirectoryMutation = {
  updateEmployeeDirectory?:  {
    __typename: "EmployeeDirectory",
    id: string,
    employeeId: string,
    userId?: string | null,
    userRecord?:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    company: string,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteEmployeeDirectoryMutationVariables = {
  input: DeleteEmployeeDirectoryInput,
  condition?: ModelEmployeeDirectoryConditionInput | null,
};

export type DeleteEmployeeDirectoryMutation = {
  deleteEmployeeDirectory?:  {
    __typename: "EmployeeDirectory",
    id: string,
    employeeId: string,
    userId?: string | null,
    userRecord?:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    company: string,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCustomersMutationVariables = {
  input: CreateCustomersInput,
  condition?: ModelCustomersConditionInput | null,
};

export type CreateCustomersMutation = {
  createCustomers?:  {
    __typename: "Customers",
    id: string,
    customerName: string,
    customerAddress: string,
    customerEmail: string,
    customerTelephone: string,
    customerCountry: string,
    invoiceType: InvoiceType,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCustomersMutationVariables = {
  input: UpdateCustomersInput,
  condition?: ModelCustomersConditionInput | null,
};

export type UpdateCustomersMutation = {
  updateCustomers?:  {
    __typename: "Customers",
    id: string,
    customerName: string,
    customerAddress: string,
    customerEmail: string,
    customerTelephone: string,
    customerCountry: string,
    invoiceType: InvoiceType,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCustomersMutationVariables = {
  input: DeleteCustomersInput,
  condition?: ModelCustomersConditionInput | null,
};

export type DeleteCustomersMutation = {
  deleteCustomers?:  {
    __typename: "Customers",
    id: string,
    customerName: string,
    customerAddress: string,
    customerEmail: string,
    customerTelephone: string,
    customerCountry: string,
    invoiceType: InvoiceType,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateInventoryMutationVariables = {
  input: CreateInventoryInput,
  condition?: ModelInventoryConditionInput | null,
};

export type CreateInventoryMutation = {
  createInventory?:  {
    __typename: "Inventory",
    id: string,
    createdOn: string,
    updatedOn: string,
    inventoryItems:  Array< {
      __typename: "InventoryItem",
      cccs: string,
      specie: string,
      scow: string,
      weight: number,
      client: string,
      boatCode: string,
      container: string,
      enteredCs: string,
      firstEnteredToCs: string,
      qaMark: string,
    } | null >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateInventoryMutationVariables = {
  input: UpdateInventoryInput,
  condition?: ModelInventoryConditionInput | null,
};

export type UpdateInventoryMutation = {
  updateInventory?:  {
    __typename: "Inventory",
    id: string,
    createdOn: string,
    updatedOn: string,
    inventoryItems:  Array< {
      __typename: "InventoryItem",
      cccs: string,
      specie: string,
      scow: string,
      weight: number,
      client: string,
      boatCode: string,
      container: string,
      enteredCs: string,
      firstEnteredToCs: string,
      qaMark: string,
    } | null >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteInventoryMutationVariables = {
  input: DeleteInventoryInput,
  condition?: ModelInventoryConditionInput | null,
};

export type DeleteInventoryMutation = {
  deleteInventory?:  {
    __typename: "Inventory",
    id: string,
    createdOn: string,
    updatedOn: string,
    inventoryItems:  Array< {
      __typename: "InventoryItem",
      cccs: string,
      specie: string,
      scow: string,
      weight: number,
      client: string,
      boatCode: string,
      container: string,
      enteredCs: string,
      firstEnteredToCs: string,
      qaMark: string,
    } | null >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    designation: string,
    userType: UserType,
    birthDay: string,
    address: string,
    residingCountry: string,
    bio: string,
    profilePicture: string,
    contactNumber: string,
    authActivities:  Array< {
      __typename: "AuthenticationActivities",
      loginTime: string,
      logoutTime?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUsersQueryVariables = {
  id?: string | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUserByUserIdQuery = {
  getUserByUserId?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchUsersQueryVariables = {
  filter?: SearchableUserFilterInput | null,
  sort?: Array< SearchableUserSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableUserAggregationInput | null > | null,
};

export type SearchUsersQuery = {
  searchUsers?:  {
    __typename: "SearchableUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetVoucherQueryVariables = {
  id: string,
};

export type GetVoucherQuery = {
  getVoucher?:  {
    __typename: "Voucher",
    id: string,
    voucherNumber: string,
    assignedUserId: string,
    assignedTo:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    purpose: string,
    totalAmount: number,
    remainingAmount: number,
    status: VoucherStatus,
    createdOn: string,
    updatedOn: string,
    validUntil: string,
    chequeReferenceNumber: string,
    voucherRedeems:  Array< {
      __typename: "VoucherRedeemData",
      amount: number,
      remark: string,
      redeemedByUsername: string,
      redeemedOn: string,
    } | null >,
    saveAsTemplate: boolean,
    templateName: string,
    createdBy: string,
    createdByUserId:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    updatedBy: string,
    updatedByUserId:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    deleted: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListVouchersQueryVariables = {
  id?: string | null,
  filter?: ModelVoucherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListVouchersQuery = {
  listVouchers?:  {
    __typename: "ModelVoucherConnection",
    items:  Array< {
      __typename: "Voucher",
      id: string,
      voucherNumber: string,
      assignedUserId: string,
      purpose: string,
      totalAmount: number,
      remainingAmount: number,
      status: VoucherStatus,
      createdOn: string,
      updatedOn: string,
      validUntil: string,
      chequeReferenceNumber: string,
      saveAsTemplate: boolean,
      templateName: string,
      createdBy: string,
      updatedBy: string,
      deleted: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncVouchersQueryVariables = {
  filter?: ModelVoucherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncVouchersQuery = {
  syncVouchers?:  {
    __typename: "ModelVoucherConnection",
    items:  Array< {
      __typename: "Voucher",
      id: string,
      voucherNumber: string,
      assignedUserId: string,
      purpose: string,
      totalAmount: number,
      remainingAmount: number,
      status: VoucherStatus,
      createdOn: string,
      updatedOn: string,
      validUntil: string,
      chequeReferenceNumber: string,
      saveAsTemplate: boolean,
      templateName: string,
      createdBy: string,
      updatedBy: string,
      deleted: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type VouchersByValidUntilQueryVariables = {
  validUntil: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVoucherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VouchersByValidUntilQuery = {
  vouchersByValidUntil?:  {
    __typename: "ModelVoucherConnection",
    items:  Array< {
      __typename: "Voucher",
      id: string,
      voucherNumber: string,
      assignedUserId: string,
      purpose: string,
      totalAmount: number,
      remainingAmount: number,
      status: VoucherStatus,
      createdOn: string,
      updatedOn: string,
      validUntil: string,
      chequeReferenceNumber: string,
      saveAsTemplate: boolean,
      templateName: string,
      createdBy: string,
      updatedBy: string,
      deleted: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchVouchersQueryVariables = {
  filter?: SearchableVoucherFilterInput | null,
  sort?: Array< SearchableVoucherSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableVoucherAggregationInput | null > | null,
};

export type SearchVouchersQuery = {
  searchVouchers?:  {
    __typename: "SearchableVoucherConnection",
    items:  Array< {
      __typename: "Voucher",
      id: string,
      voucherNumber: string,
      assignedUserId: string,
      purpose: string,
      totalAmount: number,
      remainingAmount: number,
      status: VoucherStatus,
      createdOn: string,
      updatedOn: string,
      validUntil: string,
      chequeReferenceNumber: string,
      saveAsTemplate: boolean,
      templateName: string,
      createdBy: string,
      updatedBy: string,
      deleted: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetInvoiceQueryVariables = {
  id: string,
};

export type GetInvoiceQuery = {
  getInvoice?:  {
    __typename: "Invoice",
    id: string,
    invoiceNumber: string,
    invoiceDate: string,
    consignee: string,
    consigneeId?: string | null,
    consigneeAddress: string,
    consigneeEmail: string,
    consigneeTelephone: string,
    consigneeCountry?: string | null,
    paymentDurations: string,
    totalAmount: number,
    totalAmountInWords: string,
    localItems?:  Array< {
      __typename: "LocalInvoiceItem",
      fishType: string,
      quantity: number,
      unitPrice: number,
      totalAmount: number,
    } | null > | null,
    exportItems?:  Array< {
      __typename: "ExportInvoiceItem",
      details: string,
      quantity: number,
      unitPrice: number,
      totalAmount: number,
    } | null > | null,
    bLNo?: string | null,
    containerNo?: string | null,
    termsOfDelivery?: string | null,
    portOfLoadings?: string | null,
    portOfDischarge?: string | null,
    transitShipment?: ShipmentStatus | null,
    partialShipment?: ShipmentStatus | null,
    termsOfPayment?: string | null,
    createdOn: string,
    updatedOn: string,
    deliveryNoteNo?: string | null,
    poReference: string,
    poValue: number,
    dueDate: string,
    saved: boolean,
    invoiceType: InvoiceType,
    discount: number,
    advance: number,
    accountHolder: string,
    accountNumber: string,
    iban: string,
    accountAddress: string,
    accountCountry: string,
    hideRecord: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListInvoicesQueryVariables = {
  id?: string | null,
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListInvoicesQuery = {
  listInvoices?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      invoiceNumber: string,
      invoiceDate: string,
      consignee: string,
      consigneeId?: string | null,
      consigneeAddress: string,
      consigneeEmail: string,
      consigneeTelephone: string,
      consigneeCountry?: string | null,
      paymentDurations: string,
      totalAmount: number,
      totalAmountInWords: string,
      bLNo?: string | null,
      containerNo?: string | null,
      termsOfDelivery?: string | null,
      portOfLoadings?: string | null,
      portOfDischarge?: string | null,
      transitShipment?: ShipmentStatus | null,
      partialShipment?: ShipmentStatus | null,
      termsOfPayment?: string | null,
      createdOn: string,
      updatedOn: string,
      deliveryNoteNo?: string | null,
      poReference: string,
      poValue: number,
      dueDate: string,
      saved: boolean,
      invoiceType: InvoiceType,
      discount: number,
      advance: number,
      accountHolder: string,
      accountNumber: string,
      iban: string,
      accountAddress: string,
      accountCountry: string,
      hideRecord: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInvoicesQueryVariables = {
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInvoicesQuery = {
  syncInvoices?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      invoiceNumber: string,
      invoiceDate: string,
      consignee: string,
      consigneeId?: string | null,
      consigneeAddress: string,
      consigneeEmail: string,
      consigneeTelephone: string,
      consigneeCountry?: string | null,
      paymentDurations: string,
      totalAmount: number,
      totalAmountInWords: string,
      bLNo?: string | null,
      containerNo?: string | null,
      termsOfDelivery?: string | null,
      portOfLoadings?: string | null,
      portOfDischarge?: string | null,
      transitShipment?: ShipmentStatus | null,
      partialShipment?: ShipmentStatus | null,
      termsOfPayment?: string | null,
      createdOn: string,
      updatedOn: string,
      deliveryNoteNo?: string | null,
      poReference: string,
      poValue: number,
      dueDate: string,
      saved: boolean,
      invoiceType: InvoiceType,
      discount: number,
      advance: number,
      accountHolder: string,
      accountNumber: string,
      iban: string,
      accountAddress: string,
      accountCountry: string,
      hideRecord: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InvoicesByDueDateQueryVariables = {
  dueDate: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InvoicesByDueDateQuery = {
  invoicesByDueDate?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      invoiceNumber: string,
      invoiceDate: string,
      consignee: string,
      consigneeId?: string | null,
      consigneeAddress: string,
      consigneeEmail: string,
      consigneeTelephone: string,
      consigneeCountry?: string | null,
      paymentDurations: string,
      totalAmount: number,
      totalAmountInWords: string,
      bLNo?: string | null,
      containerNo?: string | null,
      termsOfDelivery?: string | null,
      portOfLoadings?: string | null,
      portOfDischarge?: string | null,
      transitShipment?: ShipmentStatus | null,
      partialShipment?: ShipmentStatus | null,
      termsOfPayment?: string | null,
      createdOn: string,
      updatedOn: string,
      deliveryNoteNo?: string | null,
      poReference: string,
      poValue: number,
      dueDate: string,
      saved: boolean,
      invoiceType: InvoiceType,
      discount: number,
      advance: number,
      accountHolder: string,
      accountNumber: string,
      iban: string,
      accountAddress: string,
      accountCountry: string,
      hideRecord: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchInvoicesQueryVariables = {
  filter?: SearchableInvoiceFilterInput | null,
  sort?: Array< SearchableInvoiceSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableInvoiceAggregationInput | null > | null,
};

export type SearchInvoicesQuery = {
  searchInvoices?:  {
    __typename: "SearchableInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      invoiceNumber: string,
      invoiceDate: string,
      consignee: string,
      consigneeId?: string | null,
      consigneeAddress: string,
      consigneeEmail: string,
      consigneeTelephone: string,
      consigneeCountry?: string | null,
      paymentDurations: string,
      totalAmount: number,
      totalAmountInWords: string,
      bLNo?: string | null,
      containerNo?: string | null,
      termsOfDelivery?: string | null,
      portOfLoadings?: string | null,
      portOfDischarge?: string | null,
      transitShipment?: ShipmentStatus | null,
      partialShipment?: ShipmentStatus | null,
      termsOfPayment?: string | null,
      createdOn: string,
      updatedOn: string,
      deliveryNoteNo?: string | null,
      poReference: string,
      poValue: number,
      dueDate: string,
      saved: boolean,
      invoiceType: InvoiceType,
      discount: number,
      advance: number,
      accountHolder: string,
      accountNumber: string,
      iban: string,
      accountAddress: string,
      accountCountry: string,
      hideRecord: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetSalaryDetailsQueryVariables = {
  id: string,
};

export type GetSalaryDetailsQuery = {
  getSalaryDetails?:  {
    __typename: "SalaryDetails",
    id: string,
    userId: string,
    fullName: string,
    position: string,
    basicSalary: number,
    bonus?: number | null,
    deductions: number,
    paymentDate: string,
    bankName: string,
    branch: string,
    accountNumber: string,
    accountType: string,
    currencyType: string,
    advance?: number | null,
    advanceEVoucherRef?: string | null,
    month: string,
    year: string,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSalaryDetailsQueryVariables = {
  filter?: ModelSalaryDetailsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSalaryDetailsQuery = {
  listSalaryDetails?:  {
    __typename: "ModelSalaryDetailsConnection",
    items:  Array< {
      __typename: "SalaryDetails",
      id: string,
      userId: string,
      fullName: string,
      position: string,
      basicSalary: number,
      bonus?: number | null,
      deductions: number,
      paymentDate: string,
      bankName: string,
      branch: string,
      accountNumber: string,
      accountType: string,
      currencyType: string,
      advance?: number | null,
      advanceEVoucherRef?: string | null,
      month: string,
      year: string,
      createdOn: string,
      updatedOn: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSalaryDetailsQueryVariables = {
  filter?: ModelSalaryDetailsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSalaryDetailsQuery = {
  syncSalaryDetails?:  {
    __typename: "ModelSalaryDetailsConnection",
    items:  Array< {
      __typename: "SalaryDetails",
      id: string,
      userId: string,
      fullName: string,
      position: string,
      basicSalary: number,
      bonus?: number | null,
      deductions: number,
      paymentDate: string,
      bankName: string,
      branch: string,
      accountNumber: string,
      accountType: string,
      currencyType: string,
      advance?: number | null,
      advanceEVoucherRef?: string | null,
      month: string,
      year: string,
      createdOn: string,
      updatedOn: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchSalaryDetailsQueryVariables = {
  filter?: SearchableSalaryDetailsFilterInput | null,
  sort?: Array< SearchableSalaryDetailsSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableSalaryDetailsAggregationInput | null > | null,
};

export type SearchSalaryDetailsQuery = {
  searchSalaryDetails?:  {
    __typename: "SearchableSalaryDetailsConnection",
    items:  Array< {
      __typename: "SalaryDetails",
      id: string,
      userId: string,
      fullName: string,
      position: string,
      basicSalary: number,
      bonus?: number | null,
      deductions: number,
      paymentDate: string,
      bankName: string,
      branch: string,
      accountNumber: string,
      accountType: string,
      currencyType: string,
      advance?: number | null,
      advanceEVoucherRef?: string | null,
      month: string,
      year: string,
      createdOn: string,
      updatedOn: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetMetaDataQueryVariables = {
  id: string,
};

export type GetMetaDataQuery = {
  getMetaData?:  {
    __typename: "MetaData",
    id: string,
    recordType: MetaDataRecordType,
    date: string,
    count: number,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMetaDataQueryVariables = {
  filter?: ModelMetaDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMetaDataQuery = {
  listMetaData?:  {
    __typename: "ModelMetaDataConnection",
    items:  Array< {
      __typename: "MetaData",
      id: string,
      recordType: MetaDataRecordType,
      date: string,
      count: number,
      createdOn: string,
      updatedOn: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMetaDataQueryVariables = {
  filter?: ModelMetaDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMetaDataQuery = {
  syncMetaData?:  {
    __typename: "ModelMetaDataConnection",
    items:  Array< {
      __typename: "MetaData",
      id: string,
      recordType: MetaDataRecordType,
      date: string,
      count: number,
      createdOn: string,
      updatedOn: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchMetaDataQueryVariables = {
  filter?: SearchableMetaDataFilterInput | null,
  sort?: Array< SearchableMetaDataSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableMetaDataAggregationInput | null > | null,
};

export type SearchMetaDataQuery = {
  searchMetaData?:  {
    __typename: "SearchableMetaDataConnection",
    items:  Array< {
      __typename: "MetaData",
      id: string,
      recordType: MetaDataRecordType,
      date: string,
      count: number,
      createdOn: string,
      updatedOn: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetEmployeeDirectoryQueryVariables = {
  id: string,
};

export type GetEmployeeDirectoryQuery = {
  getEmployeeDirectory?:  {
    __typename: "EmployeeDirectory",
    id: string,
    employeeId: string,
    userId?: string | null,
    userRecord?:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    company: string,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListEmployeeDirectoriesQueryVariables = {
  filter?: ModelEmployeeDirectoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEmployeeDirectoriesQuery = {
  listEmployeeDirectories?:  {
    __typename: "ModelEmployeeDirectoryConnection",
    items:  Array< {
      __typename: "EmployeeDirectory",
      id: string,
      employeeId: string,
      userId?: string | null,
      company: string,
      createdOn: string,
      updatedOn: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncEmployeeDirectoriesQueryVariables = {
  filter?: ModelEmployeeDirectoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncEmployeeDirectoriesQuery = {
  syncEmployeeDirectories?:  {
    __typename: "ModelEmployeeDirectoryConnection",
    items:  Array< {
      __typename: "EmployeeDirectory",
      id: string,
      employeeId: string,
      userId?: string | null,
      company: string,
      createdOn: string,
      updatedOn: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCustomersQueryVariables = {
  id: string,
};

export type GetCustomersQuery = {
  getCustomers?:  {
    __typename: "Customers",
    id: string,
    customerName: string,
    customerAddress: string,
    customerEmail: string,
    customerTelephone: string,
    customerCountry: string,
    invoiceType: InvoiceType,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCustomersQueryVariables = {
  filter?: ModelCustomersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomersQuery = {
  listCustomers?:  {
    __typename: "ModelCustomersConnection",
    items:  Array< {
      __typename: "Customers",
      id: string,
      customerName: string,
      customerAddress: string,
      customerEmail: string,
      customerTelephone: string,
      customerCountry: string,
      invoiceType: InvoiceType,
      createdOn: string,
      updatedOn: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCustomersQueryVariables = {
  filter?: ModelCustomersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCustomersQuery = {
  syncCustomers?:  {
    __typename: "ModelCustomersConnection",
    items:  Array< {
      __typename: "Customers",
      id: string,
      customerName: string,
      customerAddress: string,
      customerEmail: string,
      customerTelephone: string,
      customerCountry: string,
      invoiceType: InvoiceType,
      createdOn: string,
      updatedOn: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInventoryQueryVariables = {
  id: string,
};

export type GetInventoryQuery = {
  getInventory?:  {
    __typename: "Inventory",
    id: string,
    createdOn: string,
    updatedOn: string,
    inventoryItems:  Array< {
      __typename: "InventoryItem",
      cccs: string,
      specie: string,
      scow: string,
      weight: number,
      client: string,
      boatCode: string,
      container: string,
      enteredCs: string,
      firstEnteredToCs: string,
      qaMark: string,
    } | null >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListInventoriesQueryVariables = {
  filter?: ModelInventoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInventoriesQuery = {
  listInventories?:  {
    __typename: "ModelInventoryConnection",
    items:  Array< {
      __typename: "Inventory",
      id: string,
      createdOn: string,
      updatedOn: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInventoriesQueryVariables = {
  filter?: ModelInventoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInventoriesQuery = {
  syncInventories?:  {
    __typename: "ModelInventoryConnection",
    items:  Array< {
      __typename: "Inventory",
      id: string,
      createdOn: string,
      updatedOn: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchInventoriesQueryVariables = {
  filter?: SearchableInventoryFilterInput | null,
  sort?: Array< SearchableInventorySortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableInventoryAggregationInput | null > | null,
};

export type SearchInventoriesQuery = {
  searchInventories?:  {
    __typename: "SearchableInventoryConnection",
    items:  Array< {
      __typename: "Inventory",
      id: string,
      createdOn: string,
      updatedOn: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    designation: string,
    userType: UserType,
    birthDay: string,
    address: string,
    residingCountry: string,
    bio: string,
    profilePicture: string,
    contactNumber: string,
    authActivities:  Array< {
      __typename: "AuthenticationActivities",
      loginTime: string,
      logoutTime?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    designation: string,
    userType: UserType,
    birthDay: string,
    address: string,
    residingCountry: string,
    bio: string,
    profilePicture: string,
    contactNumber: string,
    authActivities:  Array< {
      __typename: "AuthenticationActivities",
      loginTime: string,
      logoutTime?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    designation: string,
    userType: UserType,
    birthDay: string,
    address: string,
    residingCountry: string,
    bio: string,
    profilePicture: string,
    contactNumber: string,
    authActivities:  Array< {
      __typename: "AuthenticationActivities",
      loginTime: string,
      logoutTime?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateVoucherSubscriptionVariables = {
  filter?: ModelSubscriptionVoucherFilterInput | null,
};

export type OnCreateVoucherSubscription = {
  onCreateVoucher?:  {
    __typename: "Voucher",
    id: string,
    voucherNumber: string,
    assignedUserId: string,
    assignedTo:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    purpose: string,
    totalAmount: number,
    remainingAmount: number,
    status: VoucherStatus,
    createdOn: string,
    updatedOn: string,
    validUntil: string,
    chequeReferenceNumber: string,
    voucherRedeems:  Array< {
      __typename: "VoucherRedeemData",
      amount: number,
      remark: string,
      redeemedByUsername: string,
      redeemedOn: string,
    } | null >,
    saveAsTemplate: boolean,
    templateName: string,
    createdBy: string,
    createdByUserId:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    updatedBy: string,
    updatedByUserId:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    deleted: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateVoucherSubscriptionVariables = {
  filter?: ModelSubscriptionVoucherFilterInput | null,
};

export type OnUpdateVoucherSubscription = {
  onUpdateVoucher?:  {
    __typename: "Voucher",
    id: string,
    voucherNumber: string,
    assignedUserId: string,
    assignedTo:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    purpose: string,
    totalAmount: number,
    remainingAmount: number,
    status: VoucherStatus,
    createdOn: string,
    updatedOn: string,
    validUntil: string,
    chequeReferenceNumber: string,
    voucherRedeems:  Array< {
      __typename: "VoucherRedeemData",
      amount: number,
      remark: string,
      redeemedByUsername: string,
      redeemedOn: string,
    } | null >,
    saveAsTemplate: boolean,
    templateName: string,
    createdBy: string,
    createdByUserId:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    updatedBy: string,
    updatedByUserId:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    deleted: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteVoucherSubscriptionVariables = {
  filter?: ModelSubscriptionVoucherFilterInput | null,
};

export type OnDeleteVoucherSubscription = {
  onDeleteVoucher?:  {
    __typename: "Voucher",
    id: string,
    voucherNumber: string,
    assignedUserId: string,
    assignedTo:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    purpose: string,
    totalAmount: number,
    remainingAmount: number,
    status: VoucherStatus,
    createdOn: string,
    updatedOn: string,
    validUntil: string,
    chequeReferenceNumber: string,
    voucherRedeems:  Array< {
      __typename: "VoucherRedeemData",
      amount: number,
      remark: string,
      redeemedByUsername: string,
      redeemedOn: string,
    } | null >,
    saveAsTemplate: boolean,
    templateName: string,
    createdBy: string,
    createdByUserId:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    updatedBy: string,
    updatedByUserId:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    deleted: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateInvoiceSubscriptionVariables = {
  filter?: ModelSubscriptionInvoiceFilterInput | null,
};

export type OnCreateInvoiceSubscription = {
  onCreateInvoice?:  {
    __typename: "Invoice",
    id: string,
    invoiceNumber: string,
    invoiceDate: string,
    consignee: string,
    consigneeId?: string | null,
    consigneeAddress: string,
    consigneeEmail: string,
    consigneeTelephone: string,
    consigneeCountry?: string | null,
    paymentDurations: string,
    totalAmount: number,
    totalAmountInWords: string,
    localItems?:  Array< {
      __typename: "LocalInvoiceItem",
      fishType: string,
      quantity: number,
      unitPrice: number,
      totalAmount: number,
    } | null > | null,
    exportItems?:  Array< {
      __typename: "ExportInvoiceItem",
      details: string,
      quantity: number,
      unitPrice: number,
      totalAmount: number,
    } | null > | null,
    bLNo?: string | null,
    containerNo?: string | null,
    termsOfDelivery?: string | null,
    portOfLoadings?: string | null,
    portOfDischarge?: string | null,
    transitShipment?: ShipmentStatus | null,
    partialShipment?: ShipmentStatus | null,
    termsOfPayment?: string | null,
    createdOn: string,
    updatedOn: string,
    deliveryNoteNo?: string | null,
    poReference: string,
    poValue: number,
    dueDate: string,
    saved: boolean,
    invoiceType: InvoiceType,
    discount: number,
    advance: number,
    accountHolder: string,
    accountNumber: string,
    iban: string,
    accountAddress: string,
    accountCountry: string,
    hideRecord: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateInvoiceSubscriptionVariables = {
  filter?: ModelSubscriptionInvoiceFilterInput | null,
};

export type OnUpdateInvoiceSubscription = {
  onUpdateInvoice?:  {
    __typename: "Invoice",
    id: string,
    invoiceNumber: string,
    invoiceDate: string,
    consignee: string,
    consigneeId?: string | null,
    consigneeAddress: string,
    consigneeEmail: string,
    consigneeTelephone: string,
    consigneeCountry?: string | null,
    paymentDurations: string,
    totalAmount: number,
    totalAmountInWords: string,
    localItems?:  Array< {
      __typename: "LocalInvoiceItem",
      fishType: string,
      quantity: number,
      unitPrice: number,
      totalAmount: number,
    } | null > | null,
    exportItems?:  Array< {
      __typename: "ExportInvoiceItem",
      details: string,
      quantity: number,
      unitPrice: number,
      totalAmount: number,
    } | null > | null,
    bLNo?: string | null,
    containerNo?: string | null,
    termsOfDelivery?: string | null,
    portOfLoadings?: string | null,
    portOfDischarge?: string | null,
    transitShipment?: ShipmentStatus | null,
    partialShipment?: ShipmentStatus | null,
    termsOfPayment?: string | null,
    createdOn: string,
    updatedOn: string,
    deliveryNoteNo?: string | null,
    poReference: string,
    poValue: number,
    dueDate: string,
    saved: boolean,
    invoiceType: InvoiceType,
    discount: number,
    advance: number,
    accountHolder: string,
    accountNumber: string,
    iban: string,
    accountAddress: string,
    accountCountry: string,
    hideRecord: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteInvoiceSubscriptionVariables = {
  filter?: ModelSubscriptionInvoiceFilterInput | null,
};

export type OnDeleteInvoiceSubscription = {
  onDeleteInvoice?:  {
    __typename: "Invoice",
    id: string,
    invoiceNumber: string,
    invoiceDate: string,
    consignee: string,
    consigneeId?: string | null,
    consigneeAddress: string,
    consigneeEmail: string,
    consigneeTelephone: string,
    consigneeCountry?: string | null,
    paymentDurations: string,
    totalAmount: number,
    totalAmountInWords: string,
    localItems?:  Array< {
      __typename: "LocalInvoiceItem",
      fishType: string,
      quantity: number,
      unitPrice: number,
      totalAmount: number,
    } | null > | null,
    exportItems?:  Array< {
      __typename: "ExportInvoiceItem",
      details: string,
      quantity: number,
      unitPrice: number,
      totalAmount: number,
    } | null > | null,
    bLNo?: string | null,
    containerNo?: string | null,
    termsOfDelivery?: string | null,
    portOfLoadings?: string | null,
    portOfDischarge?: string | null,
    transitShipment?: ShipmentStatus | null,
    partialShipment?: ShipmentStatus | null,
    termsOfPayment?: string | null,
    createdOn: string,
    updatedOn: string,
    deliveryNoteNo?: string | null,
    poReference: string,
    poValue: number,
    dueDate: string,
    saved: boolean,
    invoiceType: InvoiceType,
    discount: number,
    advance: number,
    accountHolder: string,
    accountNumber: string,
    iban: string,
    accountAddress: string,
    accountCountry: string,
    hideRecord: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSalaryDetailsSubscriptionVariables = {
  filter?: ModelSubscriptionSalaryDetailsFilterInput | null,
};

export type OnCreateSalaryDetailsSubscription = {
  onCreateSalaryDetails?:  {
    __typename: "SalaryDetails",
    id: string,
    userId: string,
    fullName: string,
    position: string,
    basicSalary: number,
    bonus?: number | null,
    deductions: number,
    paymentDate: string,
    bankName: string,
    branch: string,
    accountNumber: string,
    accountType: string,
    currencyType: string,
    advance?: number | null,
    advanceEVoucherRef?: string | null,
    month: string,
    year: string,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSalaryDetailsSubscriptionVariables = {
  filter?: ModelSubscriptionSalaryDetailsFilterInput | null,
};

export type OnUpdateSalaryDetailsSubscription = {
  onUpdateSalaryDetails?:  {
    __typename: "SalaryDetails",
    id: string,
    userId: string,
    fullName: string,
    position: string,
    basicSalary: number,
    bonus?: number | null,
    deductions: number,
    paymentDate: string,
    bankName: string,
    branch: string,
    accountNumber: string,
    accountType: string,
    currencyType: string,
    advance?: number | null,
    advanceEVoucherRef?: string | null,
    month: string,
    year: string,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSalaryDetailsSubscriptionVariables = {
  filter?: ModelSubscriptionSalaryDetailsFilterInput | null,
};

export type OnDeleteSalaryDetailsSubscription = {
  onDeleteSalaryDetails?:  {
    __typename: "SalaryDetails",
    id: string,
    userId: string,
    fullName: string,
    position: string,
    basicSalary: number,
    bonus?: number | null,
    deductions: number,
    paymentDate: string,
    bankName: string,
    branch: string,
    accountNumber: string,
    accountType: string,
    currencyType: string,
    advance?: number | null,
    advanceEVoucherRef?: string | null,
    month: string,
    year: string,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateMetaDataSubscriptionVariables = {
  filter?: ModelSubscriptionMetaDataFilterInput | null,
};

export type OnCreateMetaDataSubscription = {
  onCreateMetaData?:  {
    __typename: "MetaData",
    id: string,
    recordType: MetaDataRecordType,
    date: string,
    count: number,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMetaDataSubscriptionVariables = {
  filter?: ModelSubscriptionMetaDataFilterInput | null,
};

export type OnUpdateMetaDataSubscription = {
  onUpdateMetaData?:  {
    __typename: "MetaData",
    id: string,
    recordType: MetaDataRecordType,
    date: string,
    count: number,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMetaDataSubscriptionVariables = {
  filter?: ModelSubscriptionMetaDataFilterInput | null,
};

export type OnDeleteMetaDataSubscription = {
  onDeleteMetaData?:  {
    __typename: "MetaData",
    id: string,
    recordType: MetaDataRecordType,
    date: string,
    count: number,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateEmployeeDirectorySubscriptionVariables = {
  filter?: ModelSubscriptionEmployeeDirectoryFilterInput | null,
};

export type OnCreateEmployeeDirectorySubscription = {
  onCreateEmployeeDirectory?:  {
    __typename: "EmployeeDirectory",
    id: string,
    employeeId: string,
    userId?: string | null,
    userRecord?:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    company: string,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateEmployeeDirectorySubscriptionVariables = {
  filter?: ModelSubscriptionEmployeeDirectoryFilterInput | null,
};

export type OnUpdateEmployeeDirectorySubscription = {
  onUpdateEmployeeDirectory?:  {
    __typename: "EmployeeDirectory",
    id: string,
    employeeId: string,
    userId?: string | null,
    userRecord?:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    company: string,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteEmployeeDirectorySubscriptionVariables = {
  filter?: ModelSubscriptionEmployeeDirectoryFilterInput | null,
};

export type OnDeleteEmployeeDirectorySubscription = {
  onDeleteEmployeeDirectory?:  {
    __typename: "EmployeeDirectory",
    id: string,
    employeeId: string,
    userId?: string | null,
    userRecord?:  {
      __typename: "User",
      id: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      designation: string,
      userType: UserType,
      birthDay: string,
      address: string,
      residingCountry: string,
      bio: string,
      profilePicture: string,
      contactNumber: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    company: string,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCustomersSubscriptionVariables = {
  filter?: ModelSubscriptionCustomersFilterInput | null,
};

export type OnCreateCustomersSubscription = {
  onCreateCustomers?:  {
    __typename: "Customers",
    id: string,
    customerName: string,
    customerAddress: string,
    customerEmail: string,
    customerTelephone: string,
    customerCountry: string,
    invoiceType: InvoiceType,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCustomersSubscriptionVariables = {
  filter?: ModelSubscriptionCustomersFilterInput | null,
};

export type OnUpdateCustomersSubscription = {
  onUpdateCustomers?:  {
    __typename: "Customers",
    id: string,
    customerName: string,
    customerAddress: string,
    customerEmail: string,
    customerTelephone: string,
    customerCountry: string,
    invoiceType: InvoiceType,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCustomersSubscriptionVariables = {
  filter?: ModelSubscriptionCustomersFilterInput | null,
};

export type OnDeleteCustomersSubscription = {
  onDeleteCustomers?:  {
    __typename: "Customers",
    id: string,
    customerName: string,
    customerAddress: string,
    customerEmail: string,
    customerTelephone: string,
    customerCountry: string,
    invoiceType: InvoiceType,
    createdOn: string,
    updatedOn: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateInventorySubscriptionVariables = {
  filter?: ModelSubscriptionInventoryFilterInput | null,
};

export type OnCreateInventorySubscription = {
  onCreateInventory?:  {
    __typename: "Inventory",
    id: string,
    createdOn: string,
    updatedOn: string,
    inventoryItems:  Array< {
      __typename: "InventoryItem",
      cccs: string,
      specie: string,
      scow: string,
      weight: number,
      client: string,
      boatCode: string,
      container: string,
      enteredCs: string,
      firstEnteredToCs: string,
      qaMark: string,
    } | null >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateInventorySubscriptionVariables = {
  filter?: ModelSubscriptionInventoryFilterInput | null,
};

export type OnUpdateInventorySubscription = {
  onUpdateInventory?:  {
    __typename: "Inventory",
    id: string,
    createdOn: string,
    updatedOn: string,
    inventoryItems:  Array< {
      __typename: "InventoryItem",
      cccs: string,
      specie: string,
      scow: string,
      weight: number,
      client: string,
      boatCode: string,
      container: string,
      enteredCs: string,
      firstEnteredToCs: string,
      qaMark: string,
    } | null >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteInventorySubscriptionVariables = {
  filter?: ModelSubscriptionInventoryFilterInput | null,
};

export type OnDeleteInventorySubscription = {
  onDeleteInventory?:  {
    __typename: "Inventory",
    id: string,
    createdOn: string,
    updatedOn: string,
    inventoryItems:  Array< {
      __typename: "InventoryItem",
      cccs: string,
      specie: string,
      scow: string,
      weight: number,
      client: string,
      boatCode: string,
      container: string,
      enteredCs: string,
      firstEnteredToCs: string,
      qaMark: string,
    } | null >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
