import {
  FormControl,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import MuiPhoneNumber from "material-ui-phone-number";
import { FormikErrors } from "formik";
import { UserType } from "../../models";
import { countries } from "../../utils";
import UploadButton from "../Common/UploadButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useState } from "react";
import UploadDialog from "../Common/UploadDialog";

type SingleUserUpdatePopOverType = {
  id: string | undefined;
  open: boolean;
  anchorElement: HTMLButtonElement | null;
  handleClose: () => void;
  label: string;
  values: any;
  handleBlur: any;
  handleChange: any;
  fieldType:
    | "text"
    | "select"
    | "date"
    | "country"
    | "multiline"
    | "mobile"
    | "file";
  recordId: string;
  _version: number;
  updateUser: ({
    dataFields,
    id,
    _version,
    handleClose,
  }: {
    dataFields: {
      fieldName: string;
      value: string;
    }[];
    id: string;
    _version: number;
    handleClose: () => void;
  }) => Promise<void>;
  fieldData: { name: string; value: any }[];
  selectOptions?: Array<{ label: string; value: any }>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<{
    firstName: string | undefined;
    lastName: string | undefined;
    designation: string | undefined;
    bio: string | undefined;
    birthDay: string | undefined;
    address: string | undefined;
    residingCountry: string | undefined;
    userType: UserType | "SUPER_ADMIN" | "ADMIN" | "USER" | undefined;
    profilePicture: string | undefined;
    email: string | undefined;
    contactNumber: string | undefined;
  }>>;
};

const SingleUserUpdatePopOver = ({
  id,
  open,
  anchorElement,
  handleClose,
  label,
  values,
  handleBlur,
  handleChange,
  fieldType,
  recordId,
  _version,
  updateUser,
  fieldData,
  selectOptions,
  setFieldValue,
}: SingleUserUpdatePopOverType) => {
  const [openDropZone, setOpenDropZone] = useState(false);
  const [dropZoneName, setDropZoneName] = useState("");
  const [selectedFieldName, setSelectedFieldName] = useState("");
  const [acceptedFiledTypes, setAcceptedFiledTypes] = useState([
    ".jpeg",
    ".png",
    ".jpg",
    ".pdf",
  ]);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorElement}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      slotProps={{
        paper: { sx: { width: "300px", padding: "10px" } },
      }}
    >
      <Stack direction={"column"} width="100%" spacing={"10px"}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              color: "var(--components-cta-text-icon, #1C2940)",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "120%",
            }}
          >
            {label}
          </Typography>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={"10px"}
          >
            <IconButton
              sx={{ border: "1px solid var(--Other-Other-Divider, #D6DCE1)" }}
              onClick={() => {
                let temArr: { fieldName: string; value: any }[] = [];
                fieldData.map((data) => {
                  temArr.push({
                    fieldName: data.name,
                    value: values[data.name],
                  });
                });

                updateUser({
                  dataFields: temArr,
                  id: recordId,
                  _version,
                  handleClose,
                });
              }}
            >
              <DoneIcon
                sx={{
                  width: "14px",
                  height: "14px",
                  color: "#646D7C",
                }}
              />
            </IconButton>
            <IconButton
              onClick={() => {
                handleClose();
              }}
              sx={{ border: "1px solid var(--Other-Other-Divider, #D6DCE1)" }}
            >
              <CloseIcon
                sx={{ width: "14px", height: "14px", color: "#646D7C" }}
              />
            </IconButton>
          </Stack>
        </Stack>
        {fieldType === "text" &&
          fieldData.map((field, index) => (
            <TextField
              key={index}
              value={values[field.name]}
              name={field.name}
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          ))}
        {fieldType === "select" &&
          fieldData.map((field, index) => (
            <FormControl fullWidth size="small" key={index}>
              <Select
                value={values[field.name]}
                onChange={handleChange}
                onBlur={handleBlur}
                name={field.name}
                fullWidth
                label={label}
                size="small"
              >
                <MenuItem value="" disabled>
                  -Select-
                </MenuItem>
                {selectOptions &&
                  selectOptions.map((type, index) => (
                    <MenuItem key={index} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          ))}
        {fieldType === "date" &&
          fieldData.map((field, index) => (
            <input
              type="date"
              value={values[field.name]}
              name={field.name}
              placeholder="MM/DD/YYYY"
              onChange={handleChange}
              onBlur={handleBlur}
              className="date-picker"
              max={moment().toISOString()}
            />
          ))}
        {fieldType === "mobile" &&
          fieldData.map((field, index) => (
            <MuiPhoneNumber
              key={index}
              defaultCountry={"sc"}
              onChange={(value) => {
                setFieldValue(field.name, value);
              }}
              fullWidth
              variant="outlined"
              size="small"
              onBlur={handleBlur}
              value={values[field.name]}
              label={label}
              name={field.name}
            />
          ))}
        {fieldType === "country" &&
          fieldData.map((field, index) => (
            <FormControl fullWidth size="small" key={index}>
              <Select
                value={values[field.name]}
                onChange={handleChange}
                onBlur={handleBlur}
                name={field.name}
                fullWidth
                size="small"
              >
                <MenuItem value="" disabled>
                  -Select-
                </MenuItem>
                {countries.map((country, index) => (
                  <MenuItem key={index} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}
        {fieldType === "multiline" &&
          fieldData.map((field, index) => (
            <TextField
              value={values[field.name]}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              size="small"
              name={field.name}
              multiline
              minRows={2}
              maxRows={2}
            />
          ))}
        {fieldType === "file" &&
          fieldData.map((field, index) => (
            <Stack
              sx={{ height: "60px" }}
              direction={"column"}
              spacing="10px"
              key={index}
            >
              <UploadButton
                name={
                  !values.profilePicture
                    ? "Attach Profile Picture"
                    : typeof values.profilePicture === "object" &&
                      values.profilePicture !== null
                    ? (values.profilePicture as File).name // Safely handle the file name
                    : values.profilePicture // Handle it as a string if it's a URL or text
                }
                startIcon={
                  <CloudUploadIcon
                    sx={{
                      width: "16px",
                      height: "16px",
                      color:
                        values.profilePicture !== ""
                          ? "#0D3C60"
                          : "rgba(88, 104, 116, 0.54)",
                    }}
                  />
                }
                fullWidth
                onClick={() => {
                  setDropZoneName("Attach Profile Picture");
                  setSelectedFieldName("profilePicture");
                  setAcceptedFiledTypes([".jpeg", ".png", ".jpg"]);
                  setOpenDropZone(true);
                }}
                textColor={
                  values.profilePicture === ""
                    ? "rgba(88, 104, 116, 0.54)"
                    : "#0D3C60"
                }
              />
            </Stack>
          ))}
      </Stack>
      <UploadDialog
        open={openDropZone}
        setOpen={setOpenDropZone}
        title={dropZoneName}
        save={(file: File) => {
          setFieldValue(selectedFieldName, file, true);
          setOpenDropZone(false);
        }}
        acceptedFiles={acceptedFiledTypes}
      />
    </Popover>
  );
};

export default SingleUserUpdatePopOver;
