import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { GridColDef } from "@mui/x-data-grid";
import DataGridComponent from "../Common/DataGridComponent";
import { useEffect, useState } from "react";
import { employeeSummaryReport } from "../../services";
import { LazyUser } from "../../models";
import DataGridColumnData from "../Common/DataGridColumnData";
import DataGridColumnHeader from "../Common/DataGridColumnHeader";
import FilterListIcon from "@mui/icons-material/FilterList";
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CancelIcon from "@mui/icons-material/Cancel";
import { UserType } from "../../API";
import SearchIcon from "@mui/icons-material/Search";

type EmployeeSummaryReportProps = {
  open: boolean;
  onClose: () => void;
};

const EmployeeSummaryReport = ({
  open,
  onClose,
}: EmployeeSummaryReportProps) => {
  const columns: GridColDef[] = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "userId",
      headerName: "Employee ID",
      width: 250,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Employee ID" />,
    },
    {
      field: "userType",
      headerName: "User Type",
      width: 150,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="User Type" />,
    },
    {
      field: "firstName",
      headerName: "Full Name",
      width: 200,
      renderCell: (params) => (
        <DataGridColumnData
          content={`${params.row.firstName + " " + params.row.lastName}`}
        />
      ),
      renderHeader: () => <DataGridColumnHeader headerName="Full Name" />,
    },
    {
      field: "designation",
      headerName: "Position",
      width: 150,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Position" />,
    },
    {
      field: "email",
      headerName: "Work Email",
      width: 250,
      valueFormatter: ({ value }) => `$${value.toLocaleString()}`,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Work Email" />,
    },
    {
      field: "contactNumber",
      headerName: "Mobile #",
      width: 150,
      valueFormatter: ({ value }) => `$${value.toLocaleString()}`,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Mobile #" />,
    },
    {
      field: "address",
      headerName: "Current Address",
      width: 150,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Current Address" />,
    },
    {
      field: "residingCountry",
      headerName: "Resident Country",
      width: 150,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => (
        <DataGridColumnHeader headerName="Resident Country" />
      ),
    },
    {
      field: "birthDay",
      headerName: "Birth Day",
      width: 150,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Birth Day" />,
    },
  ];

  const [anchorElUserType, setAnchorElUserType] =
    React.useState<null | HTMLElement>(null);
  const [anchorElDesignation, setAnchorElDesignation] =
    React.useState<null | HTMLElement>(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [totalRows, setTotalRows] = useState(0);

  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [userType, setUserType] = useState<{
    key: string;
    value: UserType;
  } | null>(null);
  const [designation, setDesignation] = useState<string | null>(null);

  const openUserType = Boolean(anchorElUserType);
  const openDesignation = Boolean(anchorElDesignation);

  const handleClickUserType = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUserType(event.currentTarget);
  };
  const handleClickDesignation = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElDesignation(event.currentTarget);
  };

  const handleCloseUserTpe = () => {
    setAnchorElUserType(null);
  };

  const handleCloseDesignation = () => {
    setAnchorElDesignation(null);
  };

  const [loadingReport, setLoadingReport] = useState(false);
  const [rows, setRows] = useState<LazyUser[]>([]);

  const fetchUserRecords = async () => {
    try {
      setLoadingReport(true);

      const { rows } = await employeeSummaryReport({
        email: userEmail,
        userType: userType?.value ?? null,
        designation,
      });

      setRows(rows);
      setTotalRows(rows.length);
    } catch (error) {
      console.log("🚀 ~ fetchSalaryRecords ~ error:", error);
    } finally {
      setLoadingReport(false);
    }
  };

  useEffect(() => {
    fetchUserRecords();
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          padding: "20px",
          background: "url(/background-images/modal-header.png)",
          mixBlendMode: "plus-darker",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          spacing={"10px"}
        >
          <Typography
            sx={{
              color: "var(--Text-On-Dark-Backgrounds-Main, #B4C3CE)",
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            Generate Report
          </Typography>
          <Typography
            sx={{
              color: "var(--Components-Enabled-BG, #FFF)",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              textTransform: "capitalize",
            }}
          >
            Employee Summary Report
          </Typography>
        </Stack>
        <IconButton
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon sx={{ width: "20px", height: "20px", color: "#FFFFFF" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} mb={"20px"} mt="30px">
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              spacing={"20px"}
            >
              <Stack
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                spacing={"5px"}
              >
                <IconButton>
                  <FilterListIcon
                    sx={{ color: "#8896A0", width: "16px", height: "16px" }}
                  />
                </IconButton>
                <Typography
                  sx={{
                    color:
                      "var(--components-enabled-text-stroke-icon, #646D7C)",
                    fontSize: "11px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  Filter
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                spacing={"10px"}
              >
                <TextField
                  value={userEmail ?? ""}
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                  }}
                  size="small"
                  placeholder="Email"
                />
                <IconButton
                  sx={{ width: "11px", height: "11px" }}
                  onClick={() => setUserEmail(null)}
                >
                  <CancelIcon
                    sx={{ width: "11px", height: "11px", color: "#0D3C60" }}
                  />
                </IconButton>
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack
                  direction="row"
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                >
                  <Typography
                    fontWeight={400}
                    fontSize={"11px"}
                    lineHeight={"100%"}
                    color={"#8896A0"}
                    textTransform={"capitalize"}
                  >
                    User Type
                  </Typography>
                  <Button
                    id="basic-button"
                    aria-controls={openUserType ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openUserType ? "true" : undefined}
                    onClick={handleClickUserType}
                    endIcon={
                      <ArrowDropDownIcon
                        sx={{
                          width: "16px",
                          height: "16px",
                          color: "#0D3C60",
                        }}
                      />
                    }
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "5px",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Tooltip
                      title={<Typography>{userType?.key ?? ""}</Typography>}
                    >
                      <Typography
                        sx={{
                          color: "#0D3C60",
                          maxWidth: "150px",
                          fontWeight: 700,
                        }}
                        fontSize={"11px"}
                        noWrap
                        textTransform={"none"}
                      >
                        {userType?.key ?? ""}
                      </Typography>
                    </Tooltip>
                  </Button>
                </Stack>
                <IconButton
                  sx={{ width: "11px", height: "11px" }}
                  onClick={() => setUserType(null)}
                >
                  <CancelIcon
                    sx={{ width: "11px", height: "11px", color: "#0D3C60" }}
                  />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElUserType}
                  open={openUserType}
                  onClose={handleCloseUserTpe}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    sx: {
                      borderRadius: "10px",
                      background: "#fff",
                      boxShadow: "0px 8px 20px 0px rgba(13, 60, 96, 0.12)",
                      width: "196px",
                    },
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    sx={{
                      background: "#fff",
                      p: "10px",
                      borderBottom: "1px solid #E9EEF3",
                    }}
                  >
                    <Typography
                      fontWeight={700}
                      fontSize={"11px"}
                      color={"#5B7589"}
                    >
                      User Type
                    </Typography>
                  </Stack>
                  <Box p="10px">
                    {[
                      { key: "Super Admin", value: UserType.SUPER_ADMIN },
                      { key: "Admin", value: UserType.ADMIN },
                      { key: "User", value: UserType.USER },
                    ].map((type, index) => (
                      <MenuItem
                        onClick={() => {
                          setUserType({
                            key: type.key,
                            value: type.value,
                          });
                        }}
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ListItemText
                          sx={{
                            color: "#0D3C60",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            borderRadius: "12px",
                            [`& .MuiTypography-root`]: {
                              fontSize: "10px",
                              fontWeight: 700,
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              columnGap: "4px",
                            },
                            px: "10px",
                          }}
                        >
                          {type.key}
                        </ListItemText>
                      </MenuItem>
                    ))}
                  </Box>
                </Menu>
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack
                  direction="row"
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                >
                  <Typography
                    fontWeight={400}
                    fontSize={"11px"}
                    lineHeight={"100%"}
                    color={"#8896A0"}
                    textTransform={"capitalize"}
                  >
                    Designation
                  </Typography>
                  <Button
                    id="basic-button"
                    aria-controls={openDesignation ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openDesignation ? "true" : undefined}
                    onClick={handleClickDesignation}
                    endIcon={
                      <ArrowDropDownIcon
                        sx={{
                          width: "16px",
                          height: "16px",
                          color: "#0D3C60",
                        }}
                      />
                    }
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "5px",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Tooltip
                      title={<Typography>{designation ?? ""}</Typography>}
                    >
                      <Typography
                        sx={{
                          color: "#0D3C60",
                          maxWidth: "150px",
                          fontWeight: 700,
                        }}
                        fontSize={"11px"}
                        noWrap
                        textTransform={"none"}
                      >
                        {designation ?? ""}
                      </Typography>
                    </Tooltip>
                  </Button>
                </Stack>
                <IconButton
                  sx={{ width: "11px", height: "11px" }}
                  onClick={() => setDesignation(null)}
                >
                  <CancelIcon
                    sx={{ width: "11px", height: "11px", color: "#0D3C60" }}
                  />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElDesignation}
                  open={openDesignation}
                  onClose={handleCloseDesignation}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    sx: {
                      borderRadius: "10px",
                      background: "#fff",
                      boxShadow: "0px 8px 20px 0px rgba(13, 60, 96, 0.12)",
                      width: "196px",
                    },
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    sx={{
                      background: "#fff",
                      p: "10px",
                      borderBottom: "1px solid #E9EEF3",
                    }}
                  >
                    <Typography
                      fontWeight={700}
                      fontSize={"11px"}
                      color={"#5B7589"}
                    >
                      Designation
                    </Typography>
                  </Stack>
                  <Box p="10px">
                    {["Chairman", "Chief Executive Officer", "Manager"].map(
                      (type, index) => (
                        <MenuItem
                          onClick={() => {
                            setDesignation(type);
                          }}
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <ListItemText
                            sx={{
                              color: "#0D3C60",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              borderRadius: "12px",
                              [`& .MuiTypography-root`]: {
                                fontSize: "10px",
                                fontWeight: 700,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                columnGap: "4px",
                              },
                              px: "10px",
                            }}
                          >
                            {type}
                          </ListItemText>
                        </MenuItem>
                      )
                    )}
                  </Box>
                </Menu>
              </Stack>
              <IconButton
                onClick={() => {
                  fetchUserRecords();
                }}
              >
                <SearchIcon sx={{ width: "16px", height: "16px" }} />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={"20px"} mt="30px">
          <DataGridComponent
            columns={columns}
            rows={rows}
            loading={loadingReport}
            totalRows={totalRows}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            checkboxSelection={false}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EmployeeSummaryReport;
