import { generateClient } from "aws-amplify/api";
import { listMetaData } from "../graphql/queries";
import { InvoiceType, MetaDataRecordType } from "../API";
import moment from "moment";
import { createMetaData, updateMetaData } from "../graphql/mutations";

const fetchUserNumber = async () => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        listMetaData: { items },
      },
    } = await client.graphql({
      query: listMetaData,
      variables: {
        filter: {
          recordType: {
            eq: MetaDataRecordType.USER_RECORD,
          },
        },
      },
    });

    return { success: true, numberOfUsers: items[0].count };
  } catch (error) {
    console.log("🚀 ~ fetchUserNumber ~ error:", error);
    return { success: false, numberOfUsers: 0 };
  }
};

const updateUserCount = async (userId: string) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    if (userId === "IC/001") {
      await client.graphql({
        query: createMetaData,
        variables: {
          input: {
            recordType: MetaDataRecordType.USER_RECORD,
            date: moment().format("YYYY-MM-DD"),
            count: 1,
          },
        },
      });
    } else {
      const {
        data: {
          listMetaData: { items: existingRecord },
        },
      } = await client.graphql({
        query: listMetaData,
        variables: {
          filter: {
            recordType: {
              eq: MetaDataRecordType.USER_RECORD,
            },
          },
        },
      });

      await client.graphql({
        query: updateMetaData,
        variables: {
          input: {
            id: existingRecord[0].id,
            _version: existingRecord[0]._version,
            count: existingRecord[0].count + 1,
          },
        },
      });
    }

    return { success: true };
  } catch (error) {
    console.log("🚀 ~ updateUserCount ~ error:", error);
    return { success: false };
  }
};

const fetchVoucherNumber = async () => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        listMetaData: { items },
      },
    } = await client.graphql({
      query: listMetaData,
      variables: {
        filter: {
          recordType: {
            eq: MetaDataRecordType.E_VOUCHER_RECORD,
          },
        },
      },
    });

    return { success: true, numberOfVouchers: items[0].count };
  } catch (error) {
    console.log("🚀 ~ fetchUserNumber ~ error:", error);
    return { success: false, numberOfVouchers: 0 };
  }
};

const updateVoucherCount = async (voucherId: string) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    if (voucherId === "IC/VOUCHER/001") {
      await client.graphql({
        query: createMetaData,
        variables: {
          input: {
            recordType: MetaDataRecordType.E_VOUCHER_RECORD,
            date: moment().format("YYYY-MM-DD"),
            count: 1,
          },
        },
      });
    } else {
      const {
        data: {
          listMetaData: { items: existingRecord },
        },
      } = await client.graphql({
        query: listMetaData,
        variables: {
          filter: {
            recordType: {
              eq: MetaDataRecordType.E_VOUCHER_RECORD,
            },
          },
        },
      });

      await client.graphql({
        query: updateMetaData,
        variables: {
          input: {
            id: existingRecord[0].id,
            _version: existingRecord[0]._version,
            count: existingRecord[0].count + 1,
          },
        },
      });
    }

    return { success: true };
  } catch (error) {
    console.log("🚀 ~ updateVoucherCount ~ error:", error);
    return { success: false };
  }
};

const fetchLocalInvoicesCountWithinDay = async () => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        listMetaData: { items },
      },
    } = await client.graphql({
      query: listMetaData,
      variables: {
        filter: {
          date: {
            eq: moment().format("YYYY-MM-DD"),
          },
          recordType: {
            eq: MetaDataRecordType.INVOICE_LOCAL_FROZEN_AND_FRESH,
          },
        },
      },
    });

    return { success: false, localInvoiceCount: items[0].count };
  } catch (error) {
    console.log("🚀 ~ fetchLocalInvoicesCountWithinDay ~ error:", error);

    return { success: false, localInvoiceCount: 0 };
  }
};

const updateLocalInvoiceCountWithinDay = async (invoiceId: string) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    if (
      invoiceId ===
      `IC/LOC/FRZ/${moment().format("YY")}/${moment().format(
        "MM"
      )}/${moment().format("DD")}-001`
    ) {
      await client.graphql({
        query: createMetaData,
        variables: {
          input: {
            recordType: MetaDataRecordType.INVOICE_LOCAL_FROZEN_AND_FRESH,
            date: moment().format("YYYY-MM-DD"),
            count: 1,
          },
        },
      });
    } else {
      const {
        data: {
          listMetaData: { items: existingRecord },
        },
      } = await client.graphql({
        query: listMetaData,
        variables: {
          filter: {
            recordType: {
              eq: MetaDataRecordType.INVOICE_LOCAL_FROZEN_AND_FRESH,
            },
            date: {
              eq: moment().format("YYYY-MM-DD"),
            },
          },
        },
      });

      await client.graphql({
        query: updateMetaData,
        variables: {
          input: {
            id: existingRecord[0].id,
            _version: existingRecord[0]._version,
            count: existingRecord[0].count + 1,
          },
        },
      });
    }

    return { success: true };
  } catch (error) {
    console.log("🚀 ~ updateLocalInvoiceCountWithinDay ~ error:", error);
    return { success: false };
  }
};

const fetchFreshExportInvoicesCountWithinDay = async () => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        listMetaData: { items },
      },
    } = await client.graphql({
      query: listMetaData,
      variables: {
        filter: {
          recordType: {
            eq: MetaDataRecordType.INVOICE_FRESH_EXPORT,
          },
          date: {
            eq: moment().format("YYYY-MM-DD"),
          },
        },
      },
    });

    return { success: false, freshExportCount: items[0].count };
  } catch (error) {
    console.log("🚀 ~ fetchFreshExportInvoicesCountWithinDay ~ error:", error);
    return { success: false, freshExportCount: 0 };
  }
};

const fetchDryFishExportCountWithinDay = async () => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        listMetaData: { items },
      },
    } = await client.graphql({
      query: listMetaData,
      variables: {
        filter: {
          recordType: {
            eq: MetaDataRecordType.INVOICE_DRY_FISH,
          },
          date: {
            eq: moment().format("YYYY-MM-DD"),
          },
        },
      },
    });

    return { success: false, dryFishExportCount: items[0].count };
  } catch (error) {
    console.log("🚀 ~ fetchDryFishExportCountWithinDay ~ error:", error);
    return { success: false, dryFishExportCount: 0 };
  }
};

const fetchChillExportWithinDay = async () => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        listMetaData: { items },
      },
    } = await client.graphql({
      query: listMetaData,
      variables: {
        filter: {
          recordType: {
            eq: MetaDataRecordType.INVOICE_CHILL_EXPORT,
          },
          date: {
            eq: moment().format("YYYY-MM-DD"),
          },
        },
      },
    });

    return { success: false, chillExportCount: items[0].count };
  } catch (error) {
    console.log("🚀 ~ fetchChillExportWithinDay ~ error:", error);
    return { success: false, chillExportCount: 0 };
  }
};

const updateExportInvoiceWithinDay = async (
  invoiceId: string,
  invoiceType: InvoiceType
) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    let firstIdOfDay = "";
    let recordType = MetaDataRecordType.INVOICE_FRESH_EXPORT;

    switch (invoiceType) {
      case InvoiceType.FRESH_EXPORT:
        firstIdOfDay = `IC/FE/${moment().format("YY/MM/DD")}-001`;
        recordType = MetaDataRecordType.INVOICE_FRESH_EXPORT;
        break;
      case InvoiceType.DRY_FISH:
        firstIdOfDay = `IC/DF/${moment().format("YY/MM/DD")}-001`;
        recordType = MetaDataRecordType.INVOICE_DRY_FISH;
        break;
      case InvoiceType.CHILL_EXPORT:
        firstIdOfDay = `IC/CE/${moment().format("YY/MM/DD")}-001`;
        recordType = MetaDataRecordType.INVOICE_CHILL_EXPORT;
        break;
      default:
        break;
    }

    if (invoiceId === firstIdOfDay) {
      await client.graphql({
        query: createMetaData,
        variables: {
          input: {
            recordType,
            date: moment().format("YYYY-MM-DD"),
            count: 1,
          },
        },
      });
    } else {
      const {
        data: {
          listMetaData: { items: existingRecord },
        },
      } = await client.graphql({
        query: listMetaData,
        variables: {
          filter: {
            recordType: {
              eq: recordType,
            },
            date: {
              eq: moment().format("YYYY-MM-DD"),
            },
          },
        },
      });

      await client.graphql({
        query: updateMetaData,
        variables: {
          input: {
            id: existingRecord[0].id,
            _version: existingRecord[0]._version,
            count: existingRecord[0].count + 1,
          },
        },
      });
    }

    return { success: true };
  } catch (error) {
    console.log("🚀 ~ updateExportInvoiceWithinDay ~ error:", error);
    return { success: false };
  }
};

export {
  fetchUserNumber,
  updateUserCount,
  fetchVoucherNumber,
  updateVoucherCount,
  fetchLocalInvoicesCountWithinDay,
  fetchFreshExportInvoicesCountWithinDay,
  fetchDryFishExportCountWithinDay,
  fetchChillExportWithinDay,
  updateLocalInvoiceCountWithinDay,
  updateExportInvoiceWithinDay,
};
