import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { SalaryExtended } from "../../utils";
import ModalWrapper from "../Common/ModalWrapper";

interface SalaryDetailsModalProps {
  open: boolean;
  onClose: () => void;
  salaryData: SalaryExtended;
  onEdit: () => void;
}

const SalaryDetailsModal: React.FC<SalaryDetailsModalProps> = ({
  open,
  onClose,
  salaryData,
  onEdit,
}) => {
  const {
    position,
    basicSalary,
    bonus,
    deductions,
    paymentDate,
    bankName,
    branch,
    accountNumber,
    accountType,
    currencyType,
  } = salaryData;

  return (
    <ModalWrapper
      title="Salary Details"
      open={open}
      onClose={onClose}
      subTitle=""
    >
      <React.Fragment>
        <DialogContent
          sx={{
            paddingX: "20px",
            paddingTop: "30px !important",
            paddingBottom: "30px",
          }}
        >
          <Stack direction="column" spacing="10px">
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography sx={{ color: "#5D7C94", fontWeight: 400 }}>
                      Position
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: 600 }}>{position}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography sx={{ color: "#5D7C94", fontWeight: 400 }}>
                      Basic Salary
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: 600 }}>
                      {basicSalary}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography sx={{ color: "#5D7C94", fontWeight: 400 }}>
                      Bonus
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: 600 }}>{bonus}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography sx={{ color: "#5D7C94", fontWeight: 400 }}>
                      Deductions
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: 600 }}>
                      {deductions}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography sx={{ color: "#5D7C94", fontWeight: 400 }}>
                      Payment Date
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: 600 }}>
                      {paymentDate}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography sx={{ color: "#5D7C94", fontWeight: 400 }}>
                      Bank Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: 600 }}>{bankName}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography sx={{ color: "#5D7C94", fontWeight: 400 }}>
                      Branch
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: 600 }}>{branch}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography sx={{ color: "#5D7C94", fontWeight: 400 }}>
                      Account Number
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: 600 }}>
                      {accountNumber}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography sx={{ color: "#5D7C94", fontWeight: 400 }}>
                      Account Type
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: 600 }}>
                      {accountType}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography sx={{ color: "#5D7C94", fontWeight: 400 }}>
                      Currency Type
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: 600 }}>
                      {currencyType}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "20px",
          }}
        >
          <Button
            sx={{
              borderRadius: "10px 10px 10px 10px",
              background: "#F1F5F8",
            }}
            onClick={() => {
              onEdit();
              onClose();
            }}
          >
            <Typography
              sx={{
                color: "var(--components-enabled-text-stroke-icon, #646D7C)",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "120%",
                textTransform: "capitalize",
              }}
            >
              Edit Record
            </Typography>
          </Button>
        </DialogActions>
      </React.Fragment>
    </ModalWrapper>
  );
};

export default SalaryDetailsModal;
