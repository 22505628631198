import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { notifySuperAdmins, notifyUserByUserId, redeemEVoucher } from "../../services";
import Swal from "sweetalert2";
import { AuthUser } from "aws-amplify/auth";
import InputLabelWrapper from "../Common/InputLabelWrapper";
import moment from "moment";
import {
  eVoucherRedeemNotification,
  notifyEVoucherRedeemToSuperAdmins,
  VoucherTemplate,
} from "../../utils";
import { useAuth } from "../../context/AuthContext";

type VoucherRedeemModalProps = {
  open: boolean;
  onClose: () => void;
  data: VoucherTemplate | null;
  fetchInitialData: () => void;
  user: AuthUser;
  onOpen: () => void;
};

const validationSchema = Yup.object({
  amount: Yup.number().required("Required"),
  remark: Yup.string().required("Required"),
});

const VoucherRedeemModal = ({
  open,
  onClose,
  data,
  fetchInitialData,
  user,
  onOpen,
}: VoucherRedeemModalProps) => {
  const { currentUser } = useAuth();

  const initialValues = {
    amount: 0,
    remark: "",
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { borderRadius: "10px", width: { xs: "100%", md: "600px" } },
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          const valuesToSubmit = data
            ? {
                id: data.id,
                redeemAmount: values.amount,
                voucherRedeems: [
                  ...data.voucherRedeems,
                  {
                    amount: values.amount,
                    remark: values.remark,
                    redeemedByUsername: user.username,
                    redeemedOn: moment().format("YYYY-MM-DD"),
                  },
                ].filter((redeem) => redeem !== null),
                _version: data._version,
                updatedBy: user.userId,
              }
            : {
                id: "",
                redeemAmount: values.amount,
                voucherRedeems: [],
                _version: 0,
              };

          const updatedVoucher = await redeemEVoucher(valuesToSubmit);

          await notifyUserByUserId({
            userId: user.userId,
            ...eVoucherRedeemNotification({
              voucherNumber: data?.voucherNumber ?? "",
              remark: values.remark,
              amount: values.amount,
            }),
          });

          await notifySuperAdmins({
            ...notifyEVoucherRedeemToSuperAdmins({
              voucherNumber: data?.voucherNumber ?? "",
              amount: values.amount,
              remark: values.remark,
              redeemedBy: `${
                currentUser?.firstName + " " + currentUser?.lastName
              }`,
            }),
          });

          if (updatedVoucher) {
            resetForm();

            fetchInitialData();

            onClose();

            Swal.fire({
              title: "Voucher Redeemed",
              text: "Voucher Successfully Redeemed",
              icon: "success",
            });
          } else {
            onClose();

            Swal.fire({
              title: "Voucher Redeem Error",
              text: "Voucher Redeem Error",
              icon: "error",
            }).then((result) => {
              if (result.isConfirmed) {
                onOpen();
              }
            });
          }
        }}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          isSubmitting,
          touched,
          dirty,
          resetForm,
        }) => (
          <Form>
            <DialogTitle
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
                background: "url(/background-images/modal-header.png)",
                mixBlendMode: "plus-darker",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <Stack
                direction={"column"}
                justifyContent={"center"}
                alignContent={"flex-start"}
                spacing={"10px"}
              >
                <Typography
                  sx={{
                    color: "var(--Text-On-Dark-Backgrounds-Main, #B4C3CE)",
                    fontFeatureSettings: "'liga' off, 'clig' off",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Voucher Code:
                </Typography>
                <Typography
                  sx={{
                    color: "var(--Components-Enabled-BG, #FFF)",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                    textTransform: "capitalize",
                  }}
                >
                  {data?.voucherNumber}
                </Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  onClose();
                }}
              >
                <CloseIcon
                  sx={{ width: "20px", height: "20px", color: "#FFFFFF" }}
                />
              </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: "30px !important" }}>
              <Grid container spacing={"10px"}>
                <Grid item xs={12} md={4}>
                  <Stack
                    direction={"column"}
                    sx={{
                      borderRadius: "10px",
                      background: "var(--Backgrounds-Filter-Bar, #F1F5F8)",
                      width: "100%",
                      height: "90px",
                      padding: "10px",
                    }}
                    justifyContent={"space-between"}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Typography
                        sx={{
                          color: "var(--Status-Info-Dark, #1C56AE)",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "120%",
                          textTransform: "capitalize",
                        }}
                      >
                        Total
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Typography
                        sx={{
                          color: "var(--Status-Info-Main, #2779F5)",
                          textAlign: "right",
                          fontSize: "20px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "120%",
                          textTransform: "capitalize",
                        }}
                      >
                        {data?.totalAmount}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack
                    direction={"column"}
                    sx={{
                      width: "100%",
                      borderRadius: "10px",
                      background: "var(--Status-Warning-Light, #FEF2E6)",
                      height: "90px",
                      padding: "10px",
                    }}
                    justifyContent={"space-between"}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Typography
                        sx={{
                          color: "var(--Status-Warning-Dark, #874400)",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "120%",
                          textTransform: "capitalize",
                        }}
                      >
                        Spent
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Typography
                        sx={{
                          color: "var(--Status-Warning-Main, #F57C00)",
                          textAlign: "right",
                          fontSize: "20px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "120%",
                          textTransform: "capitalize",
                        }}
                      >
                        {data ? data.totalAmount - data.remainingAmount : 0}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack
                    direction={"column"}
                    sx={{
                      width: "100%",
                      borderRadius: "10px",
                      background: "var(--Status-Success-Light, #E6F8EF)",
                      height: "90px",
                      padding: "10px",
                    }}
                    justifyContent={"space-between"}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Typography
                        sx={{
                          color: "var(--Status-Success-Dark, #006736)",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "120%",
                          textTransform: "capitalize",
                        }}
                      >
                        Remaining
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Typography
                        sx={{
                          color: "var(--Status-Success-Main, #00BC62)",
                          textAlign: "right",
                          fontSize: "20px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "120%",
                          textTransform: "capitalize",
                        }}
                      >
                        {data?.remainingAmount ?? 0}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Stack
                direction={"column"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                spacing={"15px"}
                sx={{ marginTop: "30px !important" }}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  spacing={"10px"}
                  width="100%"
                  sx={{ marginTop: "30px !important" }}
                >
                  <Typography
                    sx={{
                      color:
                        "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      textTransform: "capitalize",
                    }}
                  >
                    Purpose:
                  </Typography>
                  <Typography
                    sx={{
                      color:
                        "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "120%",
                      textTransform: "capitalize",
                    }}
                  >
                    {data?.purpose}
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  spacing={"10px"}
                  width="100%"
                >
                  <Typography
                    sx={{
                      color:
                        "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      textTransform: "capitalize",
                    }}
                  >
                    Cheque Reference #:
                  </Typography>
                  <Typography
                    sx={{
                      color:
                        "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "120%",
                      textTransform: "capitalize",
                    }}
                  >
                    {data?.chequeReferenceNumber}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                direction={"column"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                spacing={"15px"}
                sx={{ marginTop: "30px !important" }}
                width="100%"
              >
                <Typography
                  sx={{
                    color:
                      "var(--components-enabled-text-stroke-icon, #646D7C)",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "120%",
                    mb: "10px !important",
                  }}
                >
                  Add relevant details below to redeem.
                </Typography>
                <InputLabelWrapper label="Add how much you want to spend">
                  <OutlinedInput
                    value={values.amount}
                    name="amount"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.amount && !!errors.amount}
                    fullWidth
                    size="small"
                    type="number"
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    inputProps={{ min: 0 }}
                  />
                </InputLabelWrapper>
                <InputLabelWrapper label="Remarks">
                  <TextField
                    value={values.remark}
                    name="remark"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.remark && !!errors.remark}
                    fullWidth
                    size="small"
                  />
                </InputLabelWrapper>
              </Stack>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "20px",
              }}
            >
              <Button
                sx={{ width: "100px", height: "32px" }}
                onClick={() => {
                  resetForm();
                  onClose();
                }}
              >
                <Typography
                  sx={{
                    color:
                      "var(--components-enabled-text-stroke-icon, #646D7C)",
                    textAlign: "center",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  Cancel
                </Typography>
              </Button>
              <LoadingButton
                sx={{
                  borderRadius: "6px",
                  background: "var(--Components-CTA-BG, #4CE6FA)",
                  ":hover": {
                    background: "var(--Components-CTA-BG, #4CE6FA)",
                  },
                  padding: "10px",
                }}
                disabled={isSubmitting || !dirty || !isValid}
                loading={isSubmitting}
                onClick={() => {
                  handleSubmit();
                }}
              >
                <Typography
                  sx={{
                    color: "var(--components-cta-text-icon, #1C2940)",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  Redeem
                </Typography>
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default VoucherRedeemModal;
