import { View, Text } from "@aws-amplify/ui-react";

const LoginPageBanner = () => {
  return (
    <View
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "url(/background-images/home-bg-3.jpg)",
        gap: "16px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Text
        style={{
          color: "var(--Components-Disabled-BG, #F2F2F2)",
          textAlign: "center",
          fontSize: "40px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "normal",
        }}
      >
        Island Catch Trackify
      </Text>
      <Text
        style={{
          color: "var(--Components-Selected-BG, #FFF)",
          textAlign: "center",
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          letterSpacing: "0.15px",
        }}
      >
        One stop shop to track all your daily work.
      </Text>
    </View>
  );
};

export default LoginPageBanner;
