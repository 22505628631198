import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { UserType, VoucherStatus } from "../../models";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { AuthUser } from "aws-amplify/auth";
import { VoucherTemplate } from "../../utils";
import React from "react";

type EVoucherPreviewModalProps = {
  open: boolean;
  onClose: () => void;
  openRedeemDialog: () => void;
  data: VoucherTemplate | null;
  user: AuthUser;
  userType: UserType;
  setOpenEditVoucher: React.Dispatch<React.SetStateAction<boolean>>;
  setEditVoucherData: React.Dispatch<
    React.SetStateAction<VoucherTemplate | null>
  >;
  assignedTo: string;
};

const EVoucherPreviewModal = ({
  open,
  onClose,
  openRedeemDialog,
  data,
  user,
  userType,
  setEditVoucherData,
  setOpenEditVoucher,
  assignedTo,
}: EVoucherPreviewModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "10px", width: "600px" } }}
    >
      {data && (
        <React.Fragment>
          <DialogTitle
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
              background: "url(/background-images/modal-header.png)",
              mixBlendMode: "plus-darker",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignContent={"flex-start"}
              spacing={"10px"}
            >
              <Typography
                sx={{
                  color: "var(--Text-On-Dark-Backgrounds-Main, #B4C3CE)",
                  fontFeatureSettings: "'liga' off, 'clig' off",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
              >
                Voucher Code:
              </Typography>
              <Typography
                sx={{
                  color: "var(--Components-Enabled-BG, #FFF)",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                  textTransform: "capitalize",
                }}
              >
                {data?.voucherNumber ?? ""}
              </Typography>
            </Stack>
            <IconButton
              onClick={() => {
                onClose();
              }}
            >
              <CloseIcon
                sx={{ width: "20px", height: "20px", color: "#FFFFFF" }}
              />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              paddingX: "20px",
              paddingTop: "30px !important",
              paddingBottom: "30px",
            }}
          >
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"flex-start"}
              spacing={"10px"}
            >
              <TableContainer>
                <Table sx={{ border: "none" }}>
                  <TableBody>
                    <TableRow sx={{ borderBottom: "none" }}>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textTransform: "capitalize",
                          }}
                        >
                          Status
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Stack
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          spacing="5px"
                          sx={{
                            borderRadius: "15px",
                            background:
                              data.status === VoucherStatus.ACTIVE
                                ? "#E6F8EF"
                                : data.status === VoucherStatus.ON_HOLD
                                ? "#FEF2E6"
                                : "#F2F2F2",
                          }}
                          width="100px"
                          p="8px"
                        >
                          <CircleIcon
                            sx={{
                              width: "12px",
                              height: "12px",
                              color:
                                data.status === VoucherStatus.ACTIVE
                                  ? "#00BC62"
                                  : data.status === VoucherStatus.ON_HOLD
                                  ? "#F57C00"
                                  : "#999",
                            }}
                          />
                          <Typography
                            sx={{
                              color:
                                data.status === VoucherStatus.ACTIVE
                                  ? "#00BC62"
                                  : data.status === VoucherStatus.ON_HOLD
                                  ? "#F57C00"
                                  : "#999",
                              fontSize: "11px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "120%",
                              textTransform: "capitalize",
                            }}
                          >
                            {data.status === VoucherStatus.ACTIVE
                              ? "Active"
                              : data.status === VoucherStatus.ON_HOLD
                              ? "On Hold"
                              : "Exhausted"}
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ borderBottom: "none" }}>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textTransform: "capitalize",
                          }}
                        >
                          Assigned To:
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "120%",
                            textTransform: "capitalize",
                          }}
                        >
                          {assignedTo}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ borderBottom: "none" }}>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textTransform: "capitalize",
                          }}
                        >
                          Purpose:
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "120%",
                            textTransform: "capitalize",
                          }}
                        >
                          {data.purpose}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ borderBottom: "none" }}>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textTransform: "capitalize",
                          }}
                        >
                          Total Amount:
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "120%",
                            textTransform: "capitalize",
                          }}
                        >
                          {data.totalAmount}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ borderBottom: "none" }}>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textTransform: "capitalize",
                          }}
                        >
                          Spent Amount:
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "120%",
                            textTransform: "capitalize",
                          }}
                        >
                          {data.totalAmount - data.remainingAmount}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ borderBottom: "none" }}>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textTransform: "capitalize",
                          }}
                        >
                          Remaining Amount:
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "120%",
                            textTransform: "capitalize",
                          }}
                        >
                          {data.remainingAmount}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ borderBottom: "none" }}>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textTransform: "capitalize",
                          }}
                        >
                          Expiry Date:
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "120%",
                            textTransform: "capitalize",
                          }}
                        >
                          {moment(data.validUntil).format("YYYY/MM/DD")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ borderBottom: "none" }}>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textTransform: "capitalize",
                          }}
                        >
                          Created Date:
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "120%",
                            textTransform: "capitalize",
                          }}
                        >
                          {moment(data.createdOn).format("YYYY/MM/DD")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ borderBottom: "none" }}>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textTransform: "capitalize",
                          }}
                        >
                          Cheque Reference #:
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "120%",
                            textTransform: "capitalize",
                          }}
                        >
                          {data.chequeReferenceNumber}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "10px",
              padding: "20px",
            }}
          >
            {userType === UserType.SUPER_ADMIN &&
              data.totalAmount - data.remainingAmount === 0 &&
              data.status === VoucherStatus.ACTIVE && (
                <Button
                  sx={{
                    borderRadius: "0px 0px 10px 10px",
                    background: "#F1F5F8",
                  }}
                  onClick={() => {
                    setEditVoucherData(data);
                    setOpenEditVoucher(true);
                    onClose();
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        "var(--components-enabled-text-stroke-icon, #646D7C)",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "120%",
                      textTransform: "capitalize",
                    }}
                  >
                    Edit Voucher
                  </Typography>
                </Button>
              )}
            {data.status === VoucherStatus.ACTIVE &&
              data.assignedUserId === user.userId && (
                <Button
                  sx={{
                    borderRadius: "6px",
                    background: "var(--Components-CTA-BG, #4CE6FA)",
                    ":hover": {
                      background: "var(--Components-CTA-BG, #4CE6FA)",
                    },
                    padding: "10px",
                  }}
                  onClick={() => {
                    openRedeemDialog();
                    onClose();
                  }}
                >
                  <Typography
                    sx={{
                      color: "var(--components-cta-text-icon, #1C2940)",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "120%",
                      textTransform: "capitalize",
                    }}
                  >
                    Redeem Voucher
                  </Typography>
                </Button>
              )}
          </DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  );
};

export default EVoucherPreviewModal;
