import React from "react";
import ModalWrapper from "../Common/ModalWrapper";
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { LazyCustomers } from "../../models";
import { InvoiceType } from "../../models";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { getInvoiceTypeLabel } from "../../utils";

type CustomerTemplatesProps = {
  open: boolean;
  onClose: () => void;
  customers: LazyCustomers[];
  openLocalInvoiceForm: () => void;
  openExportInvoiceForm: () => void;
  setSelectedCustomerTemplate: React.Dispatch<
    React.SetStateAction<LazyCustomers | null>
  >;
};

const CustomerTemplates = ({
  open,
  onClose,
  customers,
  openLocalInvoiceForm,
  openExportInvoiceForm,
  setSelectedCustomerTemplate,
}: CustomerTemplatesProps) => {
  const tableHeaders = ["Customer Name", "Invoice Type", "Action"];

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      title="Customer Invoice Templates"
      subTitle="Please select a template to create an invoice"
    >
      <Grid container>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header, index) => (
                    <TableCell align="left" key={index}>
                      <Typography
                        sx={{
                          color:
                            "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                          fontSize: "11px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "120%",
                          textTransform: "capitalize",
                        }}
                      >
                        {header}
                      </Typography>
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.map((customer, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography
                        sx={{
                          color:
                            "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                          fontSize: "11px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "120%",
                          textTransform: "capitalize",
                        }}
                      >
                        {customer.customerName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          color:
                            "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                          fontSize: "11px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "120%",
                          textTransform: "capitalize",
                        }}
                      >
                        {getInvoiceTypeLabel(
                          customer.invoiceType as InvoiceType
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          setSelectedCustomerTemplate(customer);

                          if (
                            customer.invoiceType ===
                            InvoiceType.LOCAL_FROZEN_AND_FRESH
                          ) {
                            openLocalInvoiceForm();
                          } else {
                            openExportInvoiceForm();
                          }
                          onClose();
                        }}
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

export default CustomerTemplates;
