import {
  Authenticator,
  Button,
  Heading,
  useAuthenticator,
  useTheme,
  View,
  Text,
  Image,
} from "@aws-amplify/ui-react";
import UserManagement from "../../pages/UserManagement";
import { notifySignInToSuperAdmins } from "../../utils";
import { useAuth } from "../../context/AuthContext";
import { notifySuperAdmins } from "../../services";

const sendLoginNotificationToSuperAdmins = async (name: string) => {
  try {
    await notifySuperAdmins({
      ...notifySignInToSuperAdmins({ name }),
    });
  } catch (error) {
    console.log("🚀 ~ sendLoginNotificationToSuperAdmins ~ error:", error);
  }
};

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Amplify logo"
          src="logo-ic.png"
          style={{ width: "200px", height: "100px" }}
        />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text fontSize={"14px"} fontWeight={600} color={"#646D7C"}>
          Encountering login issues?
        </Text>
        <View textAlign="center" padding={tokens.space.medium}>
          <Button
            fontWeight="normal"
            size="small"
            variation="link"
            direction={"row"}
            gap={"5px"}
            onClick={() => {
              window.open(
                "https://island-catch.atlassian.net/servicedesk/customer/portals",
                "_blank"
              );
            }}
          >
            <Text fontSize={"11px"} fontWeight={400} color={"#646D7C"}>
              Please raise a ticket{" "}
            </Text>
            <Text fontSize={"11px"} fontWeight={400} color={"#00C9E3"}>
              here.
            </Text>
          </Button>
          <Text fontSize={"11px"} fontWeight={600} color={"#646D7C"}>
            One of our support assistants will be in touch with you shortly.
          </Text>
        </View>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center">
          <Heading
            padding={`${tokens.space.xl} 0 0 0`}
            level={3}
            style={{
              textAlign: "center",
              color: "#1C2940",
              fontWeight: 700,
              fontSize: "24px",
            }}
          >
            Welcome!
          </Heading>
          <Heading
            padding={`${tokens.space.small} 0 0 0`}
            level={6}
            style={{
              textAlign: "center",
              fontWeight: "400",
              fontSize: "14px",
              color: "#646D7C",
            }}
          >
            Enter your login details to get started.
          </Heading>
        </View>
      );
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator();

      return (
        <View textAlign="right" margin={"20px"}>
          <Button
            fontWeight="normal"
            onClick={toForgotPassword}
            size="small"
            variation="link"
            style={{ fontSize: "12px", color: "#646D7C", fontWeight: 400 }}
          >
            Forgot password?
          </Button>
        </View>
      );
    },
  },

  SetupTotp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Setup TOTP
        </Heading>
      );
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Confirm Sign In
        </Heading>
      );
    },
  },
  ForgotPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Heading
            padding={`${tokens.space.xl} 0 0 0`}
            level={3}
            style={{
              color: "var(--Text-Header, #1C2940)",
              textAlign: "center",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
            }}
          >
            Reset Password
          </Heading>
          <Text
            style={{
              color: "var(--Text-Main, #646D7C)",
              textAlign: "center",
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              letterSpacing: "0.15px",
            }}
          >
            Enter the email address associated with your Island Catch Trackify
            account.
          </Text>
        </View>
      );
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Confirm Reset Password
        </Heading>
      );
    },
  },
};

const formFields = {
  signIn: {
    username: {
      placeholder: "Enter your email",
    },
  },
  signUp: {
    password: {
      label: "Password:",
      placeholder: "Enter your Password:",
      isRequired: false,
      order: 2,
    },
    confirm_password: {
      label: "Confirm Password:",
      order: 1,
    },
  },
  forceNewPassword: {
    password: {
      placeholder: "Enter your Password:",
    },
  },
  forgotPassword: {
    username: {
      placeholder: "Enter your email:",
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: "Enter your Confirmation Code:",
      label: "Confirmation Code",
      isRequired: false,
    },
    confirm_password: {
      placeholder: "Enter your Password Please:",
    },
  },
  setupTotp: {
    QR: {
      totpIssuer: "test issuer",
      totpUsername: "amplify_qr_test_user",
    },
    confirmation_code: {
      label: "Confirmation Code",
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: "Confirmation Code",
      placeholder: "Enter your code (Using Authenticator App):",
      isRequired: false,
    },
  },
};

const SignInForm = () => {
  const { route } = useAuthenticator((context) => [context.route]);

  const { currentUser } = useAuth();

  if (route === "transition") {
    sendLoginNotificationToSuperAdmins(
      `${currentUser?.firstName + " " + currentUser?.lastName}`
    );
  }

  return (
    <Authenticator
      formFields={formFields}
      components={components}
      hideSignUp
      className="authenticator-container"
    >
      {() => <UserManagement />}
    </Authenticator>
  );
};

export default SignInForm;
