import { Typography } from "@mui/material";
import React from "react";

const DataGridColumnHeader = ({ headerName }: { headerName: string }) => {
  return (
    <Typography
      sx={{
        color: "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "120%",
        textTransform: "capitalize",
      }}
    >
      {headerName}
    </Typography>
  );
};

export default DataGridColumnHeader;
