/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    userId
    firstName
    lastName
    email
    designation
    userType
    birthDay
    address
    residingCountry
    bio
    profilePicture
    contactNumber
    authActivities {
      loginTime
      logoutTime
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    userId
    firstName
    lastName
    email
    designation
    userType
    birthDay
    address
    residingCountry
    bio
    profilePicture
    contactNumber
    authActivities {
      loginTime
      logoutTime
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    userId
    firstName
    lastName
    email
    designation
    userType
    birthDay
    address
    residingCountry
    bio
    profilePicture
    contactNumber
    authActivities {
      loginTime
      logoutTime
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createVoucher = /* GraphQL */ `mutation CreateVoucher(
  $input: CreateVoucherInput!
  $condition: ModelVoucherConditionInput
) {
  createVoucher(input: $input, condition: $condition) {
    id
    voucherNumber
    assignedUserId
    assignedTo {
      id
      userId
      firstName
      lastName
      email
      designation
      userType
      birthDay
      address
      residingCountry
      bio
      profilePicture
      contactNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    purpose
    totalAmount
    remainingAmount
    status
    createdOn
    updatedOn
    validUntil
    chequeReferenceNumber
    voucherRedeems {
      amount
      remark
      redeemedByUsername
      redeemedOn
      __typename
    }
    saveAsTemplate
    templateName
    createdBy
    createdByUserId {
      id
      userId
      firstName
      lastName
      email
      designation
      userType
      birthDay
      address
      residingCountry
      bio
      profilePicture
      contactNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedBy
    updatedByUserId {
      id
      userId
      firstName
      lastName
      email
      designation
      userType
      birthDay
      address
      residingCountry
      bio
      profilePicture
      contactNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    deleted
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVoucherMutationVariables,
  APITypes.CreateVoucherMutation
>;
export const updateVoucher = /* GraphQL */ `mutation UpdateVoucher(
  $input: UpdateVoucherInput!
  $condition: ModelVoucherConditionInput
) {
  updateVoucher(input: $input, condition: $condition) {
    id
    voucherNumber
    assignedUserId
    assignedTo {
      id
      userId
      firstName
      lastName
      email
      designation
      userType
      birthDay
      address
      residingCountry
      bio
      profilePicture
      contactNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    purpose
    totalAmount
    remainingAmount
    status
    createdOn
    updatedOn
    validUntil
    chequeReferenceNumber
    voucherRedeems {
      amount
      remark
      redeemedByUsername
      redeemedOn
      __typename
    }
    saveAsTemplate
    templateName
    createdBy
    createdByUserId {
      id
      userId
      firstName
      lastName
      email
      designation
      userType
      birthDay
      address
      residingCountry
      bio
      profilePicture
      contactNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedBy
    updatedByUserId {
      id
      userId
      firstName
      lastName
      email
      designation
      userType
      birthDay
      address
      residingCountry
      bio
      profilePicture
      contactNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    deleted
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVoucherMutationVariables,
  APITypes.UpdateVoucherMutation
>;
export const deleteVoucher = /* GraphQL */ `mutation DeleteVoucher(
  $input: DeleteVoucherInput!
  $condition: ModelVoucherConditionInput
) {
  deleteVoucher(input: $input, condition: $condition) {
    id
    voucherNumber
    assignedUserId
    assignedTo {
      id
      userId
      firstName
      lastName
      email
      designation
      userType
      birthDay
      address
      residingCountry
      bio
      profilePicture
      contactNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    purpose
    totalAmount
    remainingAmount
    status
    createdOn
    updatedOn
    validUntil
    chequeReferenceNumber
    voucherRedeems {
      amount
      remark
      redeemedByUsername
      redeemedOn
      __typename
    }
    saveAsTemplate
    templateName
    createdBy
    createdByUserId {
      id
      userId
      firstName
      lastName
      email
      designation
      userType
      birthDay
      address
      residingCountry
      bio
      profilePicture
      contactNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedBy
    updatedByUserId {
      id
      userId
      firstName
      lastName
      email
      designation
      userType
      birthDay
      address
      residingCountry
      bio
      profilePicture
      contactNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    deleted
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVoucherMutationVariables,
  APITypes.DeleteVoucherMutation
>;
export const createInvoice = /* GraphQL */ `mutation CreateInvoice(
  $input: CreateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  createInvoice(input: $input, condition: $condition) {
    id
    invoiceNumber
    invoiceDate
    consignee
    consigneeId
    consigneeAddress
    consigneeEmail
    consigneeTelephone
    consigneeCountry
    paymentDurations
    totalAmount
    totalAmountInWords
    localItems {
      fishType
      quantity
      unitPrice
      totalAmount
      __typename
    }
    exportItems {
      details
      quantity
      unitPrice
      totalAmount
      __typename
    }
    bLNo
    containerNo
    termsOfDelivery
    portOfLoadings
    portOfDischarge
    transitShipment
    partialShipment
    termsOfPayment
    createdOn
    updatedOn
    deliveryNoteNo
    poReference
    poValue
    dueDate
    saved
    invoiceType
    discount
    advance
    accountHolder
    accountNumber
    iban
    accountAddress
    accountCountry
    hideRecord
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInvoiceMutationVariables,
  APITypes.CreateInvoiceMutation
>;
export const updateInvoice = /* GraphQL */ `mutation UpdateInvoice(
  $input: UpdateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  updateInvoice(input: $input, condition: $condition) {
    id
    invoiceNumber
    invoiceDate
    consignee
    consigneeId
    consigneeAddress
    consigneeEmail
    consigneeTelephone
    consigneeCountry
    paymentDurations
    totalAmount
    totalAmountInWords
    localItems {
      fishType
      quantity
      unitPrice
      totalAmount
      __typename
    }
    exportItems {
      details
      quantity
      unitPrice
      totalAmount
      __typename
    }
    bLNo
    containerNo
    termsOfDelivery
    portOfLoadings
    portOfDischarge
    transitShipment
    partialShipment
    termsOfPayment
    createdOn
    updatedOn
    deliveryNoteNo
    poReference
    poValue
    dueDate
    saved
    invoiceType
    discount
    advance
    accountHolder
    accountNumber
    iban
    accountAddress
    accountCountry
    hideRecord
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInvoiceMutationVariables,
  APITypes.UpdateInvoiceMutation
>;
export const deleteInvoice = /* GraphQL */ `mutation DeleteInvoice(
  $input: DeleteInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  deleteInvoice(input: $input, condition: $condition) {
    id
    invoiceNumber
    invoiceDate
    consignee
    consigneeId
    consigneeAddress
    consigneeEmail
    consigneeTelephone
    consigneeCountry
    paymentDurations
    totalAmount
    totalAmountInWords
    localItems {
      fishType
      quantity
      unitPrice
      totalAmount
      __typename
    }
    exportItems {
      details
      quantity
      unitPrice
      totalAmount
      __typename
    }
    bLNo
    containerNo
    termsOfDelivery
    portOfLoadings
    portOfDischarge
    transitShipment
    partialShipment
    termsOfPayment
    createdOn
    updatedOn
    deliveryNoteNo
    poReference
    poValue
    dueDate
    saved
    invoiceType
    discount
    advance
    accountHolder
    accountNumber
    iban
    accountAddress
    accountCountry
    hideRecord
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInvoiceMutationVariables,
  APITypes.DeleteInvoiceMutation
>;
export const createSalaryDetails = /* GraphQL */ `mutation CreateSalaryDetails(
  $input: CreateSalaryDetailsInput!
  $condition: ModelSalaryDetailsConditionInput
) {
  createSalaryDetails(input: $input, condition: $condition) {
    id
    userId
    fullName
    position
    basicSalary
    bonus
    deductions
    paymentDate
    bankName
    branch
    accountNumber
    accountType
    currencyType
    advance
    advanceEVoucherRef
    month
    year
    createdOn
    updatedOn
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSalaryDetailsMutationVariables,
  APITypes.CreateSalaryDetailsMutation
>;
export const updateSalaryDetails = /* GraphQL */ `mutation UpdateSalaryDetails(
  $input: UpdateSalaryDetailsInput!
  $condition: ModelSalaryDetailsConditionInput
) {
  updateSalaryDetails(input: $input, condition: $condition) {
    id
    userId
    fullName
    position
    basicSalary
    bonus
    deductions
    paymentDate
    bankName
    branch
    accountNumber
    accountType
    currencyType
    advance
    advanceEVoucherRef
    month
    year
    createdOn
    updatedOn
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSalaryDetailsMutationVariables,
  APITypes.UpdateSalaryDetailsMutation
>;
export const deleteSalaryDetails = /* GraphQL */ `mutation DeleteSalaryDetails(
  $input: DeleteSalaryDetailsInput!
  $condition: ModelSalaryDetailsConditionInput
) {
  deleteSalaryDetails(input: $input, condition: $condition) {
    id
    userId
    fullName
    position
    basicSalary
    bonus
    deductions
    paymentDate
    bankName
    branch
    accountNumber
    accountType
    currencyType
    advance
    advanceEVoucherRef
    month
    year
    createdOn
    updatedOn
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSalaryDetailsMutationVariables,
  APITypes.DeleteSalaryDetailsMutation
>;
export const createMetaData = /* GraphQL */ `mutation CreateMetaData(
  $input: CreateMetaDataInput!
  $condition: ModelMetaDataConditionInput
) {
  createMetaData(input: $input, condition: $condition) {
    id
    recordType
    date
    count
    createdOn
    updatedOn
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMetaDataMutationVariables,
  APITypes.CreateMetaDataMutation
>;
export const updateMetaData = /* GraphQL */ `mutation UpdateMetaData(
  $input: UpdateMetaDataInput!
  $condition: ModelMetaDataConditionInput
) {
  updateMetaData(input: $input, condition: $condition) {
    id
    recordType
    date
    count
    createdOn
    updatedOn
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMetaDataMutationVariables,
  APITypes.UpdateMetaDataMutation
>;
export const deleteMetaData = /* GraphQL */ `mutation DeleteMetaData(
  $input: DeleteMetaDataInput!
  $condition: ModelMetaDataConditionInput
) {
  deleteMetaData(input: $input, condition: $condition) {
    id
    recordType
    date
    count
    createdOn
    updatedOn
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMetaDataMutationVariables,
  APITypes.DeleteMetaDataMutation
>;
export const createEmployeeDirectory = /* GraphQL */ `mutation CreateEmployeeDirectory(
  $input: CreateEmployeeDirectoryInput!
  $condition: ModelEmployeeDirectoryConditionInput
) {
  createEmployeeDirectory(input: $input, condition: $condition) {
    id
    employeeId
    userId
    userRecord {
      id
      userId
      firstName
      lastName
      email
      designation
      userType
      birthDay
      address
      residingCountry
      bio
      profilePicture
      contactNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    company
    createdOn
    updatedOn
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEmployeeDirectoryMutationVariables,
  APITypes.CreateEmployeeDirectoryMutation
>;
export const updateEmployeeDirectory = /* GraphQL */ `mutation UpdateEmployeeDirectory(
  $input: UpdateEmployeeDirectoryInput!
  $condition: ModelEmployeeDirectoryConditionInput
) {
  updateEmployeeDirectory(input: $input, condition: $condition) {
    id
    employeeId
    userId
    userRecord {
      id
      userId
      firstName
      lastName
      email
      designation
      userType
      birthDay
      address
      residingCountry
      bio
      profilePicture
      contactNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    company
    createdOn
    updatedOn
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEmployeeDirectoryMutationVariables,
  APITypes.UpdateEmployeeDirectoryMutation
>;
export const deleteEmployeeDirectory = /* GraphQL */ `mutation DeleteEmployeeDirectory(
  $input: DeleteEmployeeDirectoryInput!
  $condition: ModelEmployeeDirectoryConditionInput
) {
  deleteEmployeeDirectory(input: $input, condition: $condition) {
    id
    employeeId
    userId
    userRecord {
      id
      userId
      firstName
      lastName
      email
      designation
      userType
      birthDay
      address
      residingCountry
      bio
      profilePicture
      contactNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    company
    createdOn
    updatedOn
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEmployeeDirectoryMutationVariables,
  APITypes.DeleteEmployeeDirectoryMutation
>;
export const createCustomers = /* GraphQL */ `mutation CreateCustomers(
  $input: CreateCustomersInput!
  $condition: ModelCustomersConditionInput
) {
  createCustomers(input: $input, condition: $condition) {
    id
    customerName
    customerAddress
    customerEmail
    customerTelephone
    customerCountry
    invoiceType
    createdOn
    updatedOn
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomersMutationVariables,
  APITypes.CreateCustomersMutation
>;
export const updateCustomers = /* GraphQL */ `mutation UpdateCustomers(
  $input: UpdateCustomersInput!
  $condition: ModelCustomersConditionInput
) {
  updateCustomers(input: $input, condition: $condition) {
    id
    customerName
    customerAddress
    customerEmail
    customerTelephone
    customerCountry
    invoiceType
    createdOn
    updatedOn
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomersMutationVariables,
  APITypes.UpdateCustomersMutation
>;
export const deleteCustomers = /* GraphQL */ `mutation DeleteCustomers(
  $input: DeleteCustomersInput!
  $condition: ModelCustomersConditionInput
) {
  deleteCustomers(input: $input, condition: $condition) {
    id
    customerName
    customerAddress
    customerEmail
    customerTelephone
    customerCountry
    invoiceType
    createdOn
    updatedOn
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCustomersMutationVariables,
  APITypes.DeleteCustomersMutation
>;
export const createInventory = /* GraphQL */ `mutation CreateInventory(
  $input: CreateInventoryInput!
  $condition: ModelInventoryConditionInput
) {
  createInventory(input: $input, condition: $condition) {
    id
    createdOn
    updatedOn
    inventoryItems {
      cccs
      specie
      scow
      weight
      client
      boatCode
      container
      enteredCs
      firstEnteredToCs
      qaMark
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInventoryMutationVariables,
  APITypes.CreateInventoryMutation
>;
export const updateInventory = /* GraphQL */ `mutation UpdateInventory(
  $input: UpdateInventoryInput!
  $condition: ModelInventoryConditionInput
) {
  updateInventory(input: $input, condition: $condition) {
    id
    createdOn
    updatedOn
    inventoryItems {
      cccs
      specie
      scow
      weight
      client
      boatCode
      container
      enteredCs
      firstEnteredToCs
      qaMark
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInventoryMutationVariables,
  APITypes.UpdateInventoryMutation
>;
export const deleteInventory = /* GraphQL */ `mutation DeleteInventory(
  $input: DeleteInventoryInput!
  $condition: ModelInventoryConditionInput
) {
  deleteInventory(input: $input, condition: $condition) {
    id
    createdOn
    updatedOn
    inventoryItems {
      cccs
      specie
      scow
      weight
      client
      boatCode
      container
      enteredCs
      firstEnteredToCs
      qaMark
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInventoryMutationVariables,
  APITypes.DeleteInventoryMutation
>;
