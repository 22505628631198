import { LazyUser } from "../models";
import { CurrentUserType, UserData } from "../utils";
import { generateClient } from "aws-amplify/api";
import { createUser, updateUser } from "../graphql/mutations";
import { getUser, listUsers } from "../graphql/queries";

const fetchUsers = async () => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        listUsers: { items },
      },
    } = await client.graphql({
      query: listUsers,
    });

    return items as unknown as LazyUser[];
  } catch (error) {
    console.log("🚀 ~ fetchUsers ~ error:", error);
    return [];
  }
};

const fetchUser = async (id: string) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: { getUser: userData },
    } = await client.graphql({
      query: getUser,
      variables: {
        id,
      },
    });

    return userData as CurrentUserType;
  } catch (error) {
    console.log("🚀 ~ fetchUser ~ error:", error);
    return null;
  }
};

const createNewUser = async (user: UserData) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const createdUser = await client.graphql({
      query: createUser,
      variables: { input: user },
    });

    await client.graphql({
      query: listUsers,
    });

    return createdUser;
  } catch (error) {
    console.log("🚀 ~ createUser ~ error:", error);
  }
};

const updateSingleField = async ({
  id,
  _version,
  key,
  value,
}: {
  id: string;
  _version: number;
  key: string;
  value: string;
}) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const response = await client.graphql({
      query: updateUser,
      variables: {
        input: {
          id,
          _version,
          [key]: value,
        },
      },
    });

    if (response.data) {
      return { success: true, data: response.data };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.log("🚀 ~ error:", error);
    return { success: false };
  }
};

export { fetchUsers, createNewUser, fetchUser, updateSingleField };
