// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const UserType = {
  "ODIN": "ODIN",
  "SUPER_ADMIN": "SUPER_ADMIN",
  "ADMIN": "ADMIN",
  "USER": "USER",
  "ACCOUNTANT": "ACCOUNTANT"
};

const VoucherStatus = {
  "ACTIVE": "ACTIVE",
  "ON_HOLD": "ON_HOLD",
  "EXHAUSTED": "EXHAUSTED"
};

const ShipmentStatus = {
  "ALLOWED": "ALLOWED",
  "NOT_ALLOWED": "NOT_ALLOWED",
  "INITIAL": "INITIAL"
};

const InvoiceType = {
  "LOCAL_FROZEN_AND_FRESH": "LOCAL_FROZEN_AND_FRESH",
  "CHILL_EXPORT": "CHILL_EXPORT",
  "FRESH_EXPORT": "FRESH_EXPORT",
  "DRY_FISH": "DRY_FISH",
  "DEFAULT": "DEFAULT"
};

const MetaDataRecordType = {
  "USER_RECORD": "USER_RECORD",
  "E_VOUCHER_RECORD": "E_VOUCHER_RECORD",
  "INVOICE_LOCAL_FROZEN_AND_FRESH": "INVOICE_LOCAL_FROZEN_AND_FRESH",
  "INVOICE_FRESH_EXPORT": "INVOICE_FRESH_EXPORT",
  "INVOICE_DRY_FISH": "INVOICE_DRY_FISH",
  "INVOICE_CHILL_EXPORT": "INVOICE_CHILL_EXPORT"
};

const { User, Voucher, Invoice, SalaryDetails, MetaData, EmployeeDirectory, Customers, Inventory, VoucherRedeemData, LocalInvoiceItem, ExportInvoiceItem, AuthenticationActivities, InventoryItem } = initSchema(schema);

export {
  User,
  Voucher,
  Invoice,
  SalaryDetails,
  MetaData,
  EmployeeDirectory,
  Customers,
  Inventory,
  UserType,
  VoucherStatus,
  ShipmentStatus,
  InvoiceType,
  MetaDataRecordType,
  VoucherRedeemData,
  LocalInvoiceItem,
  ExportInvoiceItem,
  AuthenticationActivities,
  InventoryItem
};