import { Box, Typography, Stack } from "@mui/material";

const ProfileRightPanel = () => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        background: "url(/background-images/right-panel-bg.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "10px",
        padding: "40px",
      }}
    >
      <Stack
        direction={"column"}
        justifyContent={"flex-end"}
        alignItems={"flex-start"}
        spacing={"20px"}
        sx={{ height: "100%" }}
      >
        <Box>
          <Typography
            sx={{
              color: "var(--Components-Enabled-BG, #FFF)",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "120%",
              textTransform: "capitalize",
            }}
          >
            Welcome to
          </Typography>
          <Typography
            sx={{
              color: "var(--Components-CTA-BG, #4CE6FA)",
              fontFamily: "Roboto",
              fontSize: "60px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "120%",
              textTransform: "capitalize",
            }}
          >
            Trackify
          </Typography>
        </Box>
        <Typography
          sx={{
            color: "var(--Components-Enabled-BG, #FFF)",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "161%",
          }}
        >
          Trackify is a secure tracking system offering effective financial
          tracking, accurate inventory management, and streamlined employee and
          salary administration. It ensures robust financial oversight, precise
          inventory details, and efficient management of employee records and
          payroll. By integrating these capabilities into one secure platform,
          Trackify enhances business productivity and operational efficiency,
          ensuring data security and informed decision-making.
        </Typography>
      </Stack>
    </Box>
  );
};

export default ProfileRightPanel;
