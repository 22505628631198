import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchUser, updateSingleField } from "../services";
import { CurrentUserType, getSignedUrl } from "../utils";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import moment from "moment";
import ProfileRightPanel from "../components/Common/ProfileRightPanel";
import { Form, Formik } from "formik";
import SingleUserUpdatePopOver from "../components/UserManagement/SingleUserUpdatePopOver";
import { UserType } from "../models";
import { uploadData } from "aws-amplify/storage";
import { AuthContext } from "../context/AuthContext";

const SingleUser = () => {
  const { userId } = useParams();

  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState<CurrentUserType | null>(null);
  const [userLoading, setUserLoading] = useState(false);

  const [anchorElNamePopOver, setAnchorElNamePopOver] =
    useState<HTMLButtonElement | null>(null);

  const [anchorElDesignationPopOver, setAnchorElDesignationPopOver] =
    useState<HTMLButtonElement | null>(null);

  const [anchorElUserTypePopOver, setAnchorElUserTypePopOver] =
    useState<HTMLButtonElement | null>(null);

  const [anchorElBirthDayPopOver, setAnchorElBirthDayPopOver] =
    useState<HTMLButtonElement | null>(null);

  const [anchorElContactNumberPopOver, setAnchorElContactNumberPopOver] =
    useState<HTMLButtonElement | null>(null);

  const [anchorElAddressPopOver, setAnchorElAddressPopOver] =
    useState<HTMLButtonElement | null>(null);

  const [anchorElCountryPopOver, setAnchorElCountryPopOver] =
    useState<HTMLButtonElement | null>(null);

  const [anchorElBioPopOver, setAnchorElBioPopOver] =
    useState<HTMLButtonElement | null>(null);

  const [anchorElProfilePicturePopOver, setAnchorElProfilePicturePopOver] =
    useState<HTMLButtonElement | null>(null);

  const handleClickNamePopOver = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElNamePopOver(event.currentTarget);
  };

  const handleClickDesignationPopOver = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElDesignationPopOver(event.currentTarget);
  };

  const handleClickUserTypePopOver = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElUserTypePopOver(event.currentTarget);
  };

  const handleClickBirthDayPopOver = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElBirthDayPopOver(event.currentTarget);
  };

  const handleClickContactNumberPopOver = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElContactNumberPopOver(event.currentTarget);
  };

  const handleClickAddressPopOver = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElAddressPopOver(event.currentTarget);
  };

  const handleClickCountryPopOver = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElCountryPopOver(event.currentTarget);
  };

  const handleClickBioPopOver = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElBioPopOver(event.currentTarget);
  };

  const handleClickProfilePicturePopOver = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElProfilePicturePopOver(event.currentTarget);
  };

  const handleCloseNamePopOver = () => {
    setAnchorElNamePopOver(null);
  };

  const handleCloseDesignationPopOver = () => {
    setAnchorElDesignationPopOver(null);
  };

  const handleCloseUserTypePopOver = () => {
    setAnchorElUserTypePopOver(null);
  };

  const handleCloseBirthDayPopOver = () => {
    setAnchorElBirthDayPopOver(null);
  };

  const handleCloseContactNumberPopOver = () => {
    setAnchorElContactNumberPopOver(null);
  };

  const handleCloseAddressPopOver = () => {
    setAnchorElAddressPopOver(null);
  };

  const handleCloseCountryPopOver = () => {
    setAnchorElCountryPopOver(null);
  };

  const handleCloseBioPopOver = () => {
    setAnchorElBioPopOver(null);
  };

  const handleCloseProfilePicturePopOver = () => {
    setAnchorElProfilePicturePopOver(null);
  };

  const openNamePopOver = Boolean(anchorElNamePopOver);
  const idNamePopOver = openNamePopOver ? "simple-popover" : undefined;

  const openDesignationPopOver = Boolean(anchorElDesignationPopOver);
  const idDesignationPopOver = openDesignationPopOver
    ? "simple-popover"
    : undefined;

  const openUserTypePopOver = Boolean(anchorElUserTypePopOver);
  const idUserTypePopOver = openUserTypePopOver ? "simple-popover" : undefined;

  const openBirthDayPopOver = Boolean(anchorElBirthDayPopOver);
  const idBirthDayPopOver = openBirthDayPopOver ? "simple-popover" : undefined;

  const openContactNumberPopOver = Boolean(anchorElContactNumberPopOver);
  const idContactNumberPopOver = openContactNumberPopOver
    ? "simple-popover"
    : undefined;

  const openAddressPopOver = Boolean(anchorElAddressPopOver);
  const idAddressPopOver = openAddressPopOver ? "simple-popover" : undefined;

  const openCountryPopOver = Boolean(anchorElCountryPopOver);
  const idCountryPopOver = openCountryPopOver ? "simple-popover" : undefined;

  const openBioPopOver = Boolean(anchorElBioPopOver);
  const idBioPopOver = openBioPopOver ? "simple-popover" : undefined;

  const openProfilePicturePopOver = Boolean(anchorElProfilePicturePopOver);
  const idProfilePicturePopOver = openProfilePicturePopOver
    ? "simple-popover"
    : undefined;

  const updateUser = async ({
    dataFields,
    id,
    _version,
    handleClose,
  }: {
    dataFields: { fieldName: string; value: string }[];
    id: string;
    _version: number;
    handleClose: () => void;
  }) => {
    try {
      handleClose();

      if (dataFields[0].fieldName === "profilePicture") {
        const profilePicturePath = `public/profile_pictures/${currentUser?.email}.png`;

        const { path } = await uploadData({
          path: profilePicturePath,
          data: dataFields[0].value,
        }).result;

        await updateSingleField({
          id,
          _version,
          key: "profilePicture",
          value: path,
        });
      } else {
        await Promise.all(
          dataFields.map(async (field, index) => {
            const response = await updateSingleField({
              id,
              _version: _version + index,
              key: field.fieldName,
              value: field.value,
            });

            return response;
          })
        );
      }
    } catch (error) {
      console.log("🚀 ~ updateUser ~ error:", error);
    } finally {
      fetchUserDetails();
    }
  };

  const fetchUserDetails = async () => {
    try {
      setUserLoading(true);
      if (userId) {
        const user = await fetchUser(userId);

        if (user) {
          const signedPpUrl = await fetchSignedUrl(user.profilePicture);

          setCurrentUser({ ...user, profilePicture: signedPpUrl });
        }

        setUserLoading(false);
      }
    } catch (error) {
      console.log("🚀 ~ fetchUserDetails ~ error:", error);
    }
  };

  const fetchSignedUrl = async (profilePicture: string) => {
    const signedUrl = await getSignedUrl(profilePicture);
    return signedUrl;
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  return (
    <AuthContext.Consumer>
      {({ currentUser: loggedInUser }) => (
        <Box px={{ xs: "none", md: "20px" }}>
          <Formik
            initialValues={{
              firstName: currentUser?.firstName,
              lastName: currentUser?.lastName,
              email: currentUser?.email,
              designation: currentUser?.designation,
              bio: currentUser?.bio,
              birthDay: currentUser?.birthDay,
              address: currentUser?.address,
              residingCountry: currentUser?.residingCountry,
              userType: currentUser?.userType,
              profilePicture: currentUser?.profilePicture,
              contactNumber: currentUser?.contactNumber,
            }}
            onSubmit={(values) => {
              return;
            }}
            enableReinitialize
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <Form>
                <Container maxWidth="xl" disableGutters sx={{ mt: "76px" }}>
                  {userLoading ? (
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{ height: "87vh" }}
                      >
                        <CircularProgress />
                      </Grid>
                    </Grid>
                  ) : currentUser ? (
                    <Grid container>
                      <Grid item xs={12} p="10px">
                        <Stack
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                          spacing={"20px"}
                        >
                          <IconButton
                            sx={{
                              border:
                                "1px solid var(--components-cta-text-icon, #1C2940)",
                            }}
                            onClick={() => {
                              navigate("../user-management");
                            }}
                          >
                            <ArrowBackIosIcon
                              sx={{
                                width: "14px",
                                height: "14px",
                                color: "#1C2940",
                              }}
                            />
                          </IconButton>
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "24px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              lineHeight: "120%",
                              textTransform: "capitalize",
                            }}
                          >
                            {currentUser?.userType === UserType.SUPER_ADMIN
                              ? "Admin Details"
                              : "user Details"}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={3.5} p="10px">
                        <Box
                          sx={{
                            height: "264px",
                            width: "264px",
                            position: "relative",
                          }}
                        >
                          <Avatar
                            sx={{
                              height: "100%",
                              width: "100%",
                              borderRadius: 0,
                            }}
                            alt={currentUser?.firstName}
                            src={currentUser?.profilePicture}
                          />
                          <IconButton
                            sx={{ position: "absolute", top: 0, right: 0 }}
                            onClick={handleClickProfilePicturePopOver}
                            disabled={
                              loggedInUser?.userType !== UserType.SUPER_ADMIN
                            }
                          >
                            <EditIcon
                              sx={{
                                width: "14px",
                                height: "14px",
                                color: "#646D7C",
                              }}
                            />
                          </IconButton>
                          <SingleUserUpdatePopOver
                            id={idProfilePicturePopOver}
                            open={openProfilePicturePopOver}
                            anchorElement={anchorElProfilePicturePopOver}
                            handleClose={handleCloseProfilePicturePopOver}
                            label={"Profile Picture"}
                            values={values}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            fieldType={"file"}
                            recordId={currentUser.id}
                            _version={currentUser._version}
                            updateUser={updateUser}
                            fieldData={[
                              {
                                name: "profilePicture",
                                value: currentUser.firstName,
                              },
                            ]}
                            setFieldValue={setFieldValue}
                          />
                        </Box>
                        <Stack
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"flex-start"}
                          spacing={"5px"}
                          p="20px"
                          width="100%"
                          mt="10px"
                          sx={{
                            borderRadius: "10px",
                            background: "#4CE6FA",
                            position: "relative",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "120%",
                            }}
                          >
                            Name
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "20px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              lineHeight: "120%",
                              textTransform: "capitalize",
                            }}
                          >{`${
                            currentUser?.firstName + " " + currentUser?.lastName
                          }`}</Typography>
                          <IconButton
                            sx={{ position: "absolute", top: 0, right: 0 }}
                            onClick={handleClickNamePopOver}
                            disabled={
                              loggedInUser?.userType !== UserType.SUPER_ADMIN
                            }
                          >
                            <EditIcon
                              sx={{
                                width: "14px",
                                height: "14px",
                                color: "#646D7C",
                              }}
                            />
                          </IconButton>
                          <SingleUserUpdatePopOver
                            id={idNamePopOver}
                            open={openNamePopOver}
                            anchorElement={anchorElNamePopOver}
                            handleClose={handleCloseNamePopOver}
                            label={"Name"}
                            values={values}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            fieldType={"text"}
                            recordId={currentUser.id}
                            _version={currentUser._version}
                            updateUser={updateUser}
                            fieldData={[
                              {
                                name: "firstName",
                                value: currentUser.firstName,
                              },
                              {
                                name: "lastName",
                                value: currentUser.lastName,
                              },
                            ]}
                            setFieldValue={setFieldValue}
                          />
                        </Stack>
                        <Stack
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"flex-start"}
                          spacing={"5px"}
                          p="20px"
                          width="100%"
                          mt="10px"
                          sx={{
                            borderRadius: "10px",
                            position: "relative",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "120%",
                            }}
                          >
                            Designation
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              lineHeight: "120%",
                              textTransform: "capitalize",
                            }}
                          >
                            {currentUser?.designation}
                          </Typography>
                          <IconButton
                            sx={{ position: "absolute", top: 0, right: 0 }}
                            onClick={handleClickDesignationPopOver}
                            disabled={
                              loggedInUser?.userType !== UserType.SUPER_ADMIN
                            }
                          >
                            <EditIcon
                              sx={{
                                width: "14px",
                                height: "14px",
                                color: "#646D7C",
                              }}
                            />
                          </IconButton>
                          <SingleUserUpdatePopOver
                            id={idDesignationPopOver}
                            open={openDesignationPopOver}
                            anchorElement={anchorElDesignationPopOver}
                            handleClose={handleCloseDesignationPopOver}
                            label={"Designation"}
                            values={values}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            fieldType={"select"}
                            recordId={currentUser.id}
                            _version={currentUser._version}
                            updateUser={updateUser}
                            fieldData={[
                              {
                                name: "designation",
                                value: currentUser.designation,
                              },
                            ]}
                            selectOptions={[
                              { label: "Chairman", value: "Chairman" },
                              {
                                label: "Chief Executive Officer",
                                value: "Chief Executive Officer",
                              },
                              {
                                label: "Manager",
                                value: "Manager",
                              },
                            ]}
                            setFieldValue={setFieldValue}
                          />
                        </Stack>
                        <Stack
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"flex-start"}
                          spacing={"5px"}
                          p="20px"
                          width="100%"
                          mt="10px"
                          sx={{
                            borderRadius: "10px",
                            position: "relative",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "120%",
                            }}
                          >
                            Email
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              lineHeight: "120%",
                            }}
                          >
                            {currentUser?.email}
                          </Typography>
                        </Stack>
                        <Stack
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"flex-start"}
                          spacing={"5px"}
                          p="20px"
                          width="100%"
                          mt="10px"
                          sx={{
                            borderRadius: "10px",
                            position: "relative",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "120%",
                            }}
                          >
                            User Type
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              lineHeight: "120%",
                              textTransform: "capitalize",
                            }}
                          >
                            {currentUser?.userType}
                          </Typography>
                          <IconButton
                            sx={{ position: "absolute", top: 0, right: 0 }}
                            onClick={handleClickUserTypePopOver}
                            disabled={
                              loggedInUser?.userType !== UserType.SUPER_ADMIN
                            }
                          >
                            <EditIcon
                              sx={{
                                width: "14px",
                                height: "14px",
                                color: "#646D7C",
                              }}
                            />
                          </IconButton>
                          <SingleUserUpdatePopOver
                            id={idUserTypePopOver}
                            open={openUserTypePopOver}
                            anchorElement={anchorElUserTypePopOver}
                            handleClose={handleCloseUserTypePopOver}
                            label={"userType"}
                            values={values}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            fieldType={"select"}
                            recordId={currentUser.id}
                            _version={currentUser._version}
                            updateUser={updateUser}
                            fieldData={[
                              {
                                name: "userType",
                                value: currentUser.userType,
                              },
                            ]}
                            selectOptions={[
                              {
                                label: "Super Admin",
                                value: UserType.SUPER_ADMIN,
                              },
                              { label: "Admin", value: UserType.ADMIN },
                              { label: "User", value: UserType.USER },
                            ]}
                            setFieldValue={setFieldValue}
                          />
                        </Stack>
                        <Stack
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"flex-start"}
                          spacing={"5px"}
                          p="20px"
                          width="100%"
                          mt="10px"
                          sx={{
                            borderRadius: "10px",
                            position: "relative",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "120%",
                            }}
                          >
                            User ID
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              lineHeight: "120%",
                              textTransform: "uppercase",
                            }}
                          >
                            {currentUser?.userId}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={3.5} p="10px">
                        <Stack
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"flex-start"}
                          spacing={"5px"}
                          p="20px"
                          width="100%"
                          mt="10px"
                          sx={{
                            borderRadius: "10px",
                            position: "relative",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "120%",
                            }}
                          >
                            Birth Day
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              lineHeight: "120%",
                              textTransform: "capitalize",
                            }}
                          >
                            {currentUser?.birthDay
                              ? moment(currentUser.birthDay).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Typography>
                          <IconButton
                            sx={{ position: "absolute", top: 0, right: 0 }}
                            onClick={handleClickBirthDayPopOver}
                            disabled={
                              loggedInUser?.userType !== UserType.SUPER_ADMIN
                            }
                          >
                            <EditIcon
                              sx={{
                                width: "14px",
                                height: "14px",
                                color: "#646D7C",
                              }}
                            />
                          </IconButton>
                          <SingleUserUpdatePopOver
                            id={idBirthDayPopOver}
                            open={openBirthDayPopOver}
                            anchorElement={anchorElBirthDayPopOver}
                            handleClose={handleCloseBirthDayPopOver}
                            label={"Birth Day"}
                            values={values}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            fieldType={"date"}
                            recordId={currentUser.id}
                            _version={currentUser._version}
                            updateUser={updateUser}
                            fieldData={[
                              {
                                name: "birthDay",
                                value: currentUser.birthDay,
                              },
                            ]}
                            setFieldValue={setFieldValue}
                          />
                        </Stack>
                        <Stack
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"flex-start"}
                          spacing={"5px"}
                          p="20px"
                          width="100%"
                          mt="10px"
                          sx={{
                            borderRadius: "10px",
                            position: "relative",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "120%",
                            }}
                          >
                            Mobile #
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              lineHeight: "120%",
                              textTransform: "capitalize",
                            }}
                          >
                            {currentUser?.contactNumber}
                          </Typography>
                          <IconButton
                            sx={{ position: "absolute", top: 0, right: 0 }}
                            onClick={handleClickContactNumberPopOver}
                            disabled={
                              loggedInUser?.userType !== UserType.SUPER_ADMIN
                            }
                          >
                            <EditIcon
                              sx={{
                                width: "14px",
                                height: "14px",
                                color: "#646D7C",
                              }}
                            />
                          </IconButton>
                          <SingleUserUpdatePopOver
                            id={idContactNumberPopOver}
                            open={openContactNumberPopOver}
                            anchorElement={anchorElContactNumberPopOver}
                            handleClose={handleCloseContactNumberPopOver}
                            label={"Mobile #"}
                            values={values}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            fieldType={"mobile"}
                            recordId={currentUser.id}
                            _version={currentUser._version}
                            updateUser={updateUser}
                            fieldData={[
                              {
                                name: "contactNumber",
                                value: currentUser.contactNumber,
                              },
                            ]}
                            setFieldValue={setFieldValue}
                          />
                        </Stack>
                        <Stack
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"flex-start"}
                          spacing={"5px"}
                          p="20px"
                          width="100%"
                          mt="10px"
                          sx={{
                            borderRadius: "10px",
                            position: "relative",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "120%",
                            }}
                          >
                            Current Address
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              lineHeight: "120%",
                              textTransform: "capitalize",
                            }}
                          >
                            {currentUser?.address}
                          </Typography>
                          <IconButton
                            sx={{ position: "absolute", top: 0, right: 0 }}
                            onClick={handleClickAddressPopOver}
                            disabled={
                              loggedInUser?.userType !== UserType.SUPER_ADMIN
                            }
                          >
                            <EditIcon
                              sx={{
                                width: "14px",
                                height: "14px",
                                color: "#646D7C",
                              }}
                            />
                          </IconButton>
                          <SingleUserUpdatePopOver
                            id={idAddressPopOver}
                            open={openAddressPopOver}
                            anchorElement={anchorElAddressPopOver}
                            handleClose={handleCloseAddressPopOver}
                            label={"Address"}
                            values={values}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            fieldType={"text"}
                            recordId={currentUser.id}
                            _version={currentUser._version}
                            updateUser={updateUser}
                            fieldData={[
                              {
                                name: "address",
                                value: currentUser.address,
                              },
                            ]}
                            setFieldValue={setFieldValue}
                          />
                        </Stack>
                        <Stack
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"flex-start"}
                          spacing={"5px"}
                          p="20px"
                          width="100%"
                          mt="10px"
                          sx={{
                            borderRadius: "10px",
                            position: "relative",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "120%",
                            }}
                          >
                            Resident Country
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              lineHeight: "120%",
                              textTransform: "capitalize",
                            }}
                          >
                            {currentUser?.residingCountry}
                          </Typography>
                          <IconButton
                            sx={{ position: "absolute", top: 0, right: 0 }}
                            onClick={handleClickCountryPopOver}
                            disabled={
                              loggedInUser?.userType !== UserType.SUPER_ADMIN
                            }
                          >
                            <EditIcon
                              sx={{
                                width: "14px",
                                height: "14px",
                                color: "#646D7C",
                              }}
                            />
                          </IconButton>
                          <SingleUserUpdatePopOver
                            id={idCountryPopOver}
                            open={openCountryPopOver}
                            anchorElement={anchorElCountryPopOver}
                            handleClose={handleCloseCountryPopOver}
                            label={"Country"}
                            values={values}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            fieldType={"country"}
                            recordId={currentUser.id}
                            _version={currentUser._version}
                            updateUser={updateUser}
                            fieldData={[
                              {
                                name: "residingCountry",
                                value: currentUser.residingCountry,
                              },
                            ]}
                            setFieldValue={setFieldValue}
                          />
                        </Stack>
                        <Stack
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"flex-start"}
                          spacing={"5px"}
                          p="20px"
                          width="100%"
                          mt="10px"
                          sx={{
                            borderRadius: "10px",
                            position: "relative",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "120%",
                            }}
                          >
                            Bio
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--components-cta-text-icon, #1C2940)",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              lineHeight: "120%",
                              textTransform: "capitalize",
                            }}
                          >
                            {currentUser?.bio}
                          </Typography>
                          <IconButton
                            sx={{ position: "absolute", top: 0, right: 0 }}
                            onClick={handleClickBioPopOver}
                            disabled={
                              loggedInUser?.userType !== UserType.SUPER_ADMIN
                            }
                          >
                            <EditIcon
                              sx={{
                                width: "14px",
                                height: "14px",
                                color: "#646D7C",
                              }}
                            />
                          </IconButton>
                          <SingleUserUpdatePopOver
                            id={idBioPopOver}
                            open={openBioPopOver}
                            anchorElement={anchorElBioPopOver}
                            handleClose={handleCloseBioPopOver}
                            label={"Bio"}
                            values={values}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            fieldType={"multiline"}
                            recordId={currentUser.id}
                            _version={currentUser._version}
                            updateUser={updateUser}
                            fieldData={[
                              {
                                name: "bio",
                                value: currentUser.bio,
                              },
                            ]}
                            setFieldValue={setFieldValue}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={5} sx={{ height: "83vh" }}>
                        <ProfileRightPanel />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{ height: "87vh" }}
                      >
                        <Typography
                          sx={{
                            color:
                              "var(--components-enabled-text-stroke-icon, #646D7C)",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "120%",
                          }}
                        >
                          User Not Found
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Container>
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </AuthContext.Consumer>
  );
};

export default SingleUser;
