import { generateClient } from "aws-amplify/api";
import {
  searchInventories,
  searchInvoices,
  searchSalaryDetails,
  searchUsers,
  searchVouchers,
} from "../graphql/queries";
import moment from "moment";
import {
  LazyInventory,
  LazyInvoice,
  LazySalaryDetails,
  LazyUser,
  LazyVoucher,
} from "../models";
import { customSearchVouchers } from "../graphql/customQueries";
import { FiltersFields } from "../utils";
import { UserType } from "../API";

const eVoucherDataForReports = async () => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        searchVouchers: { items },
      },
    } = await client.graphql({
      query: searchVouchers,
      variables: {
        filter: {
          createdOn: {
            range: [
              moment().startOf("month").toISOString(),
              moment().endOf("month").toISOString(),
            ],
          },
          createdBy: { exists: true },
          updatedBy: { exists: true },
          deleted: {
            eq: false,
          },
        },
      },
    });

    const result = items.reduce(
      (prev, curr) => {
        prev["totalAllocation"] += curr.totalAmount;
        prev["totalExpenditure"] += curr.totalAmount - curr.remainingAmount;
        prev["remainingBalance"] += curr.remainingAmount;

        return prev;
      },
      {
        totalAllocation: 0,
        totalExpenditure: 0,
        remainingBalance: 0,
      }
    );

    return { success: true, data: result };
  } catch (error) {
    console.log("🚀 ~ eVoucherDataForReports ~ error:", error);
    return {
      success: false,
      data: { totalAllocation: 0, totalExpenditure: 0, remainingBalance: 0 },
    };
  }
};

const salaryDataForReports = async () => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        searchSalaryDetails: { items },
      },
    } = await client.graphql({
      query: searchSalaryDetails,
      variables: {
        filter: {
          createdOn: {
            range: [
              moment().startOf("month").toISOString(),
              moment().endOf("month").toISOString(),
            ],
          },
        },
      },
    });

    const result = items.reduce(
      (prev, curr) => {
        prev["totalExpenditure"] += curr.basicSalary + (curr.bonus ?? 0);
        prev["totalBonuses"] += curr.bonus ?? 0;
        prev["totalAdvances"] += curr.advance ?? 0;

        return prev;
      },
      {
        totalExpenditure: 0,
        totalBonuses: 0,
        totalAdvances: 0,
      }
    );

    return { success: true, data: result };
  } catch (error) {
    console.log("🚀 ~ salaryDataForReports ~ error:", error);
    return {
      success: false,
      data: { totalExpenditure: 0, totalBonuses: 0, totalAdvances: 0 },
    };
  }
};

const inventoryDataFroReports = async () => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        searchInventories: { items },
      },
    } = await client.graphql({
      query: searchInventories,
      variables: {
        filter: {
          createdOn: {
            range: [
              moment().startOf("month").toISOString(),
              moment().endOf("month").toISOString(),
            ],
          },
        },
      },
    });

    const summaryCardDetails = (items as unknown as LazyInventory[]).reduce(
      (prev, curr) => {
        const totalItemWeight = curr.inventoryItems.reduce(
          (itemWeightSum, item) => itemWeightSum + (item?.weight || 0),
          0
        );
        prev["totalWeight"] += totalItemWeight;

        curr.inventoryItems.forEach((item) => {
          if (item) {
            const currentSpecieWeight = item.weight;

            if (item.specie === "unsorted" || item.specie === "Unsorted") {
              prev.unsortedStockTotalWeight += currentSpecieWeight;
            }

            if (currentSpecieWeight > prev.mostStock.weight) {
              prev.mostStock.specie = item.specie;
              prev.mostStock.weight = currentSpecieWeight;
            } else if (item.specie === prev.mostStock.specie) {
              prev.mostStock.weight += currentSpecieWeight;
            }
          }
        });

        return prev;
      },
      {
        totalWeight: 0,
        mostStock: {
          specie: "",
          weight: 0,
        },
        unsortedStockTotalWeight: 0,
      }
    );

    return { success: true, summaryCardDetails };
  } catch (error) {
    console.log("🚀 ~ inventoryDataFroReports ~ error:", error);
    return {
      success: false,
      summaryCardDetails: {
        totalWeight: 0,
        mostStock: {
          specie: "",
          weight: 0,
        },
        unsortedStockTotalWeight: 0,
      },
    };
  }
};

const employeeSalaryRecordReport = async ({
  month,
  quarter,
  startDate,
  endDate,
}: FiltersFields) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const filter: any = {};

    if (month !== "") {
      filter.paymentDate = {
        range: [
          moment(`${month} 01`, "MMMM DD").startOf("month").toISOString(),
          moment(`${month} 01`, "MMMM DD").endOf("month").toISOString(),
        ],
      };
    }

    if (quarter?.start && quarter?.end) {
      filter.paymentDate = {
        range: [quarter.start.toISOString(), quarter.end.toISOString()],
      };
    }

    if (startDate && endDate) {
      filter.paymentDate = {
        range: [startDate.toISOString(), endDate.toISOString()],
      };
    }

    const {
      data: {
        searchSalaryDetails: { items },
      },
    } = await client.graphql({
      query: searchSalaryDetails,
      variables: {
        filter,
      },
    });

    return { success: true, rows: items as unknown as LazySalaryDetails[] };
  } catch (error) {
    console.log("🚀 ~ employeeSalaryRecordReport ~ error:", error);
    return { success: false, rows: [] };
  }
};

const eVoucherSummaryReport = async ({
  month,
  quarter,
  startDate,
  endDate,
}: FiltersFields) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const filter: any = {
      deleted: {
        eq: false,
      },
    };

    if (month !== "") {
      filter.validUntil = {
        range: [
          moment(`${month} 01`, "MMMM DD").startOf("month").toISOString(),
          moment(`${month} 01`, "MMMM DD").endOf("month").toISOString(),
        ],
      };
    }

    if (quarter?.start && quarter?.end) {
      filter.validUntil = {
        range: [quarter.start.toISOString(), quarter.end.toISOString()],
      };
    }

    if (startDate && endDate) {
      filter.validUntil = {
        range: [startDate.toISOString(), endDate.toISOString()],
      };
    }

    const {
      data: {
        listVouchers: { items },
      },
    } = await client.graphql({
      query: customSearchVouchers,
      variables: {
        filter,
      },
    });

    return { success: true, rows: items as unknown as LazyVoucher[] };
  } catch (error) {
    console.log("🚀 ~ eVoucherSummaryReport ~ error:", error);
    return { success: false, rows: [] };
  }
};

const employeeSummaryReport = async ({
  email,
  userType,
  designation,
}: {
  email: string | null;
  userType: UserType | null;
  designation: string | null;
}) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const filter: any = {};

    if (email) {
      filter.email = {
        eq: email,
      };
    }

    if (userType) {
      filter.userType = {
        eq: userType,
      };
    }

    if (designation) {
      filter.designation = {
        eq: designation,
      };
    }

    const {
      data: {
        searchUsers: { items },
      },
    } = await client.graphql({
      query: searchUsers,
      variables: {
        filter,
      },
    });

    return { success: true, rows: items as unknown as LazyUser[] };
  } catch (error) {
    console.log("🚀 ~ employeeSummaryReport ~ error:", error);
    return { success: false, rows: [] };
  }
};

const pnlSummaryReport = async ({
  month,
  quarter,
  startDate,
  endDate,
}: FiltersFields) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const filterInvoice: any = {};
    const filterVoucher: any = {};

    if (month !== "") {
      filterInvoice.invoiceDate = {
        range: [
          moment(`${month} 01`, "MMMM DD").startOf("month").toISOString(),
          moment(`${month} 01`, "MMMM DD").endOf("month").toISOString(),
        ],
      };
    }
    if (month !== "") {
      filterVoucher.validUntil = {
        range: [
          moment(`${month} 01`, "MMMM DD").startOf("month").toISOString(),
          moment(`${month} 01`, "MMMM DD").endOf("month").toISOString(),
        ],
      };
    }

    if (quarter?.start && quarter?.end) {
      filterInvoice.invoiceDate = {
        range: [quarter.start.toISOString(), quarter.end.toISOString()],
      };
    }

    if (quarter?.start && quarter?.end) {
      filterVoucher.validUntil = {
        range: [quarter.start.toISOString(), quarter.end.toISOString()],
      };
    }

    if (startDate && endDate) {
      filterInvoice.invoiceDate = {
        range: [startDate.toISOString(), endDate.toISOString()],
      };
    }

    if (startDate && endDate) {
      filterVoucher.validUntil = {
        range: [startDate.toISOString(), endDate.toISOString()],
      };
    }

    const {
      data: {
        searchInvoices: { items: incomeData },
      },
    } = await client.graphql({
      query: searchInvoices,
      variables: {
        filter: filterInvoice,
      },
    });

    const {
      data: {
        searchVouchers: { items: expensesData },
      },
    } = await client.graphql({
      query: searchVouchers,
      variables: {
        filter: filterVoucher,
      },
    });

    const incomeArray = incomeData as unknown as LazyInvoice[];
    const expensesArray = expensesData as unknown as LazyVoucher[];

    const incomes = incomeArray.reduce(
      (prev, curr) => {
        prev.rows.push({
          invoiceId: curr.invoiceNumber,
          invoiceDate: curr.invoiceDate,
          clientAmount: curr.totalAmount,
        });

        prev.total += curr.totalAmount;

        return prev;
      },
      {
        total: 0,
        rows: [] as {
          invoiceId: string;
          invoiceDate: string;
          clientAmount: number;
        }[],
      }
    );

    const expenses = expensesArray.reduce(
      (prev, curr) => {
        prev.rows.push({
          voucherCode: curr.voucherNumber,
          voucherPurpose: curr.purpose,
          voucherAmount: curr.totalAmount,
        });

        prev.total += curr.totalAmount;

        return prev;
      },
      {
        total: 0,
        rows: [] as {
          voucherCode: string;
          voucherPurpose: string;
          voucherAmount: number;
        }[],
      }
    );

    return {
      success: true,
      data: {
        incomes,
        expenses,
      },
    };
  } catch (error) {
    console.log("🚀 ~ pnlSummaryReport ~ error:", error);
    return {
      success: false,
      data: {
        incomes: {
          total: 0,
          rows: [],
        },
        expenses: {
          total: 0,
          rows: [],
        },
      },
    };
  }
};

const inventorySummaryReport = async ({
  month,
  quarter,
  startDate,
  endDate,
}: FiltersFields) => {
  try {
    const client = generateClient({ authMode: "userPool" });

    const filter: any = {};

    if (month !== "") {
      filter.validUntil = {
        range: [
          moment(`${month} 01`, "MMMM DD").startOf("month").toISOString(),
          moment(`${month} 01`, "MMMM DD").endOf("month").toISOString(),
        ],
      };
    }

    if (quarter?.start && quarter?.end) {
      filter.validUntil = {
        range: [quarter.start.toISOString(), quarter.end.toISOString()],
      };
    }

    if (startDate && endDate) {
      filter.validUntil = {
        range: [startDate.toISOString(), endDate.toISOString()],
      };
    }

    const {
      data: {
        searchInventories: { items },
      },
    } = await client.graphql({
      query: searchInventories,
      variables: {
        filter,
      },
    });

    return { success: true, rows: items as unknown as LazyInventory[] };
  } catch (error) {
    console.log("🚀 ~ inventorySummaryReport ~ error:", error);
    return { success: false, rows: [] };
  }
};

export {
  eVoucherDataForReports,
  salaryDataForReports,
  employeeSalaryRecordReport,
  eVoucherSummaryReport,
  employeeSummaryReport,
  pnlSummaryReport,
  inventorySummaryReport,
  inventoryDataFroReports,
};
