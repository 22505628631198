import { useEffect, useState } from "react";
import { Box, Container, Grid, Button, Stack, Typography } from "@mui/material";
import UserManagementModal from "../components/UserManagement/UserManagementModal";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import UserCard from "../components/UserManagement/UserCard";
import { LazyUser } from "../models";
import { fetchUsers } from "../services";
import { fetchUserNumber } from "../services/common.service";

const UserManagement = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const [users, setUsers] = useState<LazyUser[]>([]);
  const [currentUserIdNumber, setCurrentUserIdNumber] = useState(0);

  const getUsers = async () => {
    const result = await fetchUsers();
    setUsers(result);
  };

  const fetchCurrentUserNumber = async () => {
    try {
      const { numberOfUsers } = await fetchUserNumber();

      setCurrentUserIdNumber(numberOfUsers);
    } catch (error) {
      console.log("🚀 ~ fetchCurrentUserNumber ~ error:", error);
    }
  };

  useEffect(() => {
    getUsers();
    fetchCurrentUserNumber();
  }, []);

  return (
    <Box px={{ xs: "none", md: "20px" }}>
      <Container maxWidth="xl" disableGutters sx={{ mt: "76px" }}>
        <Grid container sx={{ overflow: "auto" }}>
          <Grid item xs={12}>
            <Stack
              direction={"row"}
              width={"100%"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <Typography
                sx={{
                  color: "var(--components-cta-text-icon, #1C2940)",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "120%",
                  textTransform: "capitalize",
                }}
              >
                Users
              </Typography>
              <Button
                onClick={() => {
                  setOpenDialog(true);
                }}
                sx={{
                  borderRadius: "6px",
                  background: "var(--Components-CTA-BG, #4CE6FA)",
                  ":hover": {
                    background: "var(--Components-CTA-BG, #4CE6FA)",
                  },
                  padding: "10px",
                }}
                startIcon={<PersonAddAltIcon sx={{ color: "#1C2940" }} />}
              >
                <Typography
                  sx={{
                    color: "var(--components-cta-text-icon, #1C2940)",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  Add New User
                </Typography>
              </Button>
            </Stack>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sx={{
              borderRadius: "10px",
              background: "var(--primary-contrast-text, #FFF)",
              boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.04)",
              height: "100%",
              padding: "20px",
              gap: "10px",
            }}
            spacing={"10px"}
          >
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                mb={"15px"}
              >
                <Typography
                  sx={{
                    color:
                      "var(--components-enabled-text-stroke-icon, #646D7C)",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  Super Admins
                </Typography>
              </Stack>
            </Grid>
            {users
              .filter((user) => user.userType === "SUPER_ADMIN")
              .map((user, index) => (
                <Grid item xs={12} md={2.9} key={index}>
                  <UserCard
                    name={`${user.firstName + " " + user.lastName}`}
                    profilePicture={user.profilePicture}
                    designation={user.designation}
                    userId={user.userId}
                    email={user.email}
                    id={user.id}
                  />
                </Grid>
              ))}
          </Grid>
          <Grid
            item
            container
            xs={12}
            sx={{
              borderRadius: "10px",
              background: "var(--primary-contrast-text, #FFF)",
              boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.04)",
              height: "100%",
              padding: "20px",
              gap: "10px",
            }}
          >
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                mb={"15px"}
              >
                <Typography
                  sx={{
                    color:
                      "var(--components-enabled-text-stroke-icon, #646D7C)",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  Admins
                </Typography>
              </Stack>
            </Grid>
            {users
              .filter((user) => user.userType === "ADMIN")
              .map((user, index) => (
                <Grid item xs={12} md={2.9} key={index}>
                  <UserCard
                    name={`${user.firstName + " " + user.lastName}`}
                    profilePicture={user.profilePicture}
                    designation={user.designation}
                    userId={user.userId}
                    email={user.email}
                    id={user.id}
                  />
                </Grid>
              ))}
          </Grid>
          <Grid
            item
            container
            xs={12}
            sx={{
              borderRadius: "10px",
              background: "var(--primary-contrast-text, #FFF)",
              boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.04)",
              height: "100%",
              padding: "20px",
              gap: "10px",
            }}
          >
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                mb={"15px"}
              >
                <Typography
                  sx={{
                    color:
                      "var(--components-enabled-text-stroke-icon, #646D7C)",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  Users
                </Typography>
              </Stack>
            </Grid>
            {users
              .filter((user) => user.userType === "USER")
              .map((user, index) => (
                <Grid item xs={12} md={2.9} key={index}>
                  <UserCard
                    name={`${user.firstName + " " + user.lastName}`}
                    profilePicture={user.profilePicture}
                    designation={user.designation}
                    userId={user.userId}
                    email={user.email}
                    id={user.id}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Container>
      <UserManagementModal
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onOpen={() => setOpenDialog(true)}
        getUsers={getUsers}
        currentUserIdNumber={currentUserIdNumber}
      />
    </Box>
  );
};

export default UserManagement;
