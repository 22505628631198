import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  eVoucherDataForReports,
  inventoryDataFroReports,
  salaryDataForReports,
} from "../services";
import moment from "moment";
import DetailsCard from "../components/Common/DetailsCard";
import MenuCard from "../components/Common/MenuCards";
import EmployeeSalaryReport from "../components/Reports/EmployeeSalaryReport";
import EmployeeSummaryReport from "../components/Reports/EmployeeSummaryReport";
import EVoucherSummaryReport from "../components/Reports/EVoucherSummaryReport";
import PNLReport from "../components/Reports/PNLReport";
import InventorySummaryReport from "../components/Reports/InventorySummaryReport";

const ReportsManagement = () => {
  const [eVoucherData, setEVoucherData] = useState<{
    totalAllocation: number;
    totalExpenditure: number;
    remainingBalance: number;
  }>({
    totalAllocation: 0,
    totalExpenditure: 0,
    remainingBalance: 0,
  });

  const [salaryData, setSalaryData] = useState<{
    totalExpenditure: number;
    totalBonuses: number;
    totalAdvances: number;
  }>({
    totalExpenditure: 0,
    totalBonuses: 0,
    totalAdvances: 0,
  });

  const [summaryCardData, setSummaryCardData] = useState<{
    totalWeight: number;
    mostStock: {
      specie: string;
      weight: number;
    };
    unsortedStockTotalWeight: number;
  }>({
    totalWeight: 0,
    mostStock: {
      specie: "",
      weight: 0,
    },
    unsortedStockTotalWeight: 0,
  });

  const [openEmployeeSalaryRecords, setOpenEmployeeSalaryRecords] =
    useState(false);
  const [openEmployeeReport, setOpenEmployeeReport] = useState(false);
  const [openEVoucherReport, setOpenEVoucherReport] = useState(false);
  const [openPNLReport, setOpenPNLReport] = useState(false);
  const [openInventoryReport, setOpenInventoryReport] = useState(false);

  const fetchEVoucherData = async () => {
    try {
      const { data } = await eVoucherDataForReports();

      setEVoucherData(data);
    } catch (error) {
      console.log("🚀 ~ fetchEVoucherData ~ error:", error);
    }
  };

  const fetchSalaryData = async () => {
    try {
      const { data } = await salaryDataForReports();

      setSalaryData(data);
    } catch (error) {
      console.log("🚀 ~ fetchSalaryData ~ error:", error);
    }
  };

  const fetchInventorySummaryCardDetails = async () => {
    try {
      const { summaryCardDetails } = await inventoryDataFroReports();

      setSummaryCardData(summaryCardDetails);
    } catch (error) {
      console.log("🚀 ~ fetchInventorySummaryCardDetails ~ error:", error);
    }
  };

  useEffect(() => {
    fetchEVoucherData();
    fetchSalaryData();
    fetchInventorySummaryCardDetails();
  }, []);

  return (
    <Box px={{ xs: "none", md: "20px" }}>
      <Container maxWidth="xl" disableGutters sx={{ mt: "76px" }}>
        <Grid container spacing={"10px"}>
          <Grid item xs={12}>
            <Stack
              direction={"row"}
              width={"100%"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <Typography
                sx={{
                  color: "var(--components-cta-text-icon, #1C2940)",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "120%",
                  textTransform: "capitalize",
                }}
              >
                Dashboards & Reports
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            container
            sx={{
              padding: "20px",
              borderRadius: "10px",
              background: "var(--primary-contrast-text, #FFF)",
              boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.04)",
            }}
          >
            <Grid item xs={12} container spacing={"10px"}>
              <Grid item xs={12} mb="20px">
                <Typography
                  sx={{
                    color: "var(--components-cta-text-icon, #1C2940)",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  E- Vouchers Info
                </Typography>
              </Grid>
              {[
                {
                  cardTitle: "Total Allocation",
                  subTitle: `on ${moment().format("MMMM")}`,
                  lastUpdatedDate: `${moment().format("YYYY-MM-DD HH:mm")}`,
                  amount: `$ ${eVoucherData.totalAllocation.toLocaleString(
                    "USD"
                  )}`,
                },
                {
                  cardTitle: "Total Expenditure",
                  subTitle: `on ${moment().format("MMMM")}`,
                  lastUpdatedDate: `${moment().format("YYYY-MM-DD HH:mm")}`,
                  amount: `$ ${eVoucherData.totalExpenditure.toLocaleString(
                    "USD"
                  )}`,
                },
                {
                  cardTitle: "Remaining Balance",
                  subTitle: `on ${moment().format("MMMM")}`,
                  lastUpdatedDate: `${moment().format("YYYY-MM-DD HH:mm")}`,
                  amount: `$ ${eVoucherData.remainingBalance.toLocaleString(
                    "USD"
                  )}`,
                },
              ].map((data, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <DetailsCard
                    cardTitle={data.cardTitle}
                    subTitle={data.subTitle}
                    lastUpdatedDate={data.lastUpdatedDate}
                    amount={data.amount}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} container spacing={"10px"}>
              <Grid item xs={12} mb="20px">
                <Typography
                  sx={{
                    color: "var(--components-cta-text-icon, #1C2940)",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  Inventory Info
                </Typography>
              </Grid>
              {[
                {
                  cardTitle: "Total Weight",
                  subTitle: `on ${moment().format("MMMM")}`,
                  lastUpdatedDate: `${moment().format("YYYY-MM-DD HH:mm")}`,
                  amount: `${summaryCardData.totalWeight} Kg`,
                },
                {
                  cardTitle: `${summaryCardData.mostStock.specie}`,
                  subTitle: `Most Stocks`,
                  lastUpdatedDate: `${moment().format("YYYY-MM-DD HH:mm")}`,
                  amount: `${summaryCardData.mostStock.weight} Kg`,
                },
                {
                  cardTitle: "Un Sorted",
                  subTitle: `on ${moment().format("MMMM")}`,
                  lastUpdatedDate: `${moment().format("YYYY-MM-DD HH:mm")}`,
                  amount: `${summaryCardData.unsortedStockTotalWeight} Kg`,
                },
              ].map((data, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <DetailsCard
                    cardTitle={data.cardTitle}
                    subTitle={data.subTitle}
                    lastUpdatedDate={data.lastUpdatedDate}
                    amount={data.amount}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} container spacing={"10px"}>
              <Grid item xs={12} mb="20px">
                <Typography
                  sx={{
                    color: "var(--components-cta-text-icon, #1C2940)",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  Salary Info
                </Typography>
              </Grid>
              {[
                {
                  cardTitle: "Total Expenditure",
                  subTitle: `on ${moment().format("MMMM")}`,
                  lastUpdatedDate: `${moment().format("YYYY-MM-DD HH:mm")}`,
                  amount: `$ ${salaryData.totalExpenditure.toLocaleString(
                    "USD"
                  )}`,
                },
                {
                  cardTitle: "Bonuses Given",
                  subTitle: `on ${moment().format("MMMM")}`,
                  lastUpdatedDate: `${moment().format("YYYY-MM-DD HH:mm")}`,
                  amount: `$ ${salaryData.totalBonuses.toLocaleString("USD")}`,
                },
                {
                  cardTitle: "Advances Given",
                  subTitle: `on ${moment().format("MMMM")}`,
                  lastUpdatedDate: `${moment().format("YYYY-MM-DD HH:mm")}`,
                  amount: `$ ${salaryData.totalAdvances.toLocaleString("USD")}`,
                },
              ].map((data, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <DetailsCard
                    cardTitle={data.cardTitle}
                    subTitle={data.subTitle}
                    lastUpdatedDate={data.lastUpdatedDate}
                    amount={data.amount}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} container spacing={"10px"}>
            {[
              {
                cardTitle: "Employee Salary Record",
                cardSubTitle: "Generate an report",
                openForm: () => {
                  setOpenEmployeeSalaryRecords(true);
                },
                backgroundColor: "#00C9E3",
              },
              {
                cardTitle: "Employee Summary",
                cardSubTitle: "Generate an report",
                openForm: () => {
                  setOpenEmployeeReport(true);
                },
                backgroundColor: "#198794",
              },
              {
                cardTitle: "E Voucher Summary",
                cardSubTitle: "Generate an report",
                openForm: () => {
                  setOpenEVoucherReport(true);
                },
                backgroundColor: "#2779F5",
              },
              {
                cardTitle: "Inventory Summary",
                cardSubTitle: "Generate an report",
                openForm: () => {
                  setOpenInventoryReport(true);
                },
                backgroundColor: "#3D6380",
              },
              {
                cardTitle: "P&L Summary",
                cardSubTitle: "Generate an report",
                openForm: () => {
                  setOpenPNLReport(true);
                },
                backgroundColor: "#1C2940",
              },
            ].map((card, index) => (
              <Grid item xs={12} key={index}>
                <MenuCard
                  cardTitle={card.cardTitle}
                  cardSubTitle={card.cardSubTitle}
                  openForm={card.openForm}
                  backgroundColor={card.backgroundColor}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <EmployeeSalaryReport
          open={openEmployeeSalaryRecords}
          onClose={() => {
            setOpenEmployeeSalaryRecords(false);
          }}
        />
        <EmployeeSummaryReport
          open={openEmployeeReport}
          onClose={() => {
            setOpenEmployeeReport(false);
          }}
        />
        <EVoucherSummaryReport
          open={openEVoucherReport}
          onClose={() => {
            setOpenEVoucherReport(false);
          }}
        />
        <PNLReport
          open={openPNLReport}
          onClose={() => {
            setOpenPNLReport(false);
          }}
        />
        <InventorySummaryReport
          onClose={() => {
            setOpenInventoryReport(false);
          }}
          open={openInventoryReport}
        />
      </Container>
    </Box>
  );
};

export default ReportsManagement;
