/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    userId
    firstName
    lastName
    email
    designation
    userType
    birthDay
    address
    residingCountry
    bio
    profilePicture
    contactNumber
    authActivities {
      loginTime
      logoutTime
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $id: ID
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUsers(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      userId
      firstName
      lastName
      email
      designation
      userType
      birthDay
      address
      residingCountry
      bio
      profilePicture
      contactNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const syncUsers = /* GraphQL */ `query SyncUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      userId
      firstName
      lastName
      email
      designation
      userType
      birthDay
      address
      residingCountry
      bio
      profilePicture
      contactNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncUsersQueryVariables, APITypes.SyncUsersQuery>;
export const getUserByUserId = /* GraphQL */ `query GetUserByUserId(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  getUserByUserId(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      firstName
      lastName
      email
      designation
      userType
      birthDay
      address
      residingCountry
      bio
      profilePicture
      contactNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserByUserIdQueryVariables,
  APITypes.GetUserByUserIdQuery
>;
export const searchUsers = /* GraphQL */ `query SearchUsers(
  $filter: SearchableUserFilterInput
  $sort: [SearchableUserSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableUserAggregationInput]
) {
  searchUsers(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      userId
      firstName
      lastName
      email
      designation
      userType
      birthDay
      address
      residingCountry
      bio
      profilePicture
      contactNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchUsersQueryVariables,
  APITypes.SearchUsersQuery
>;
export const getVoucher = /* GraphQL */ `query GetVoucher($id: ID!) {
  getVoucher(id: $id) {
    id
    voucherNumber
    assignedUserId
    assignedTo {
      id
      userId
      firstName
      lastName
      email
      designation
      userType
      birthDay
      address
      residingCountry
      bio
      profilePicture
      contactNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    purpose
    totalAmount
    remainingAmount
    status
    createdOn
    updatedOn
    validUntil
    chequeReferenceNumber
    voucherRedeems {
      amount
      remark
      redeemedByUsername
      redeemedOn
      __typename
    }
    saveAsTemplate
    templateName
    createdBy
    createdByUserId {
      id
      userId
      firstName
      lastName
      email
      designation
      userType
      birthDay
      address
      residingCountry
      bio
      profilePicture
      contactNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedBy
    updatedByUserId {
      id
      userId
      firstName
      lastName
      email
      designation
      userType
      birthDay
      address
      residingCountry
      bio
      profilePicture
      contactNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    deleted
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVoucherQueryVariables,
  APITypes.GetVoucherQuery
>;
export const listVouchers = /* GraphQL */ `query ListVouchers(
  $id: ID
  $filter: ModelVoucherFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listVouchers(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      voucherNumber
      assignedUserId
      purpose
      totalAmount
      remainingAmount
      status
      createdOn
      updatedOn
      validUntil
      chequeReferenceNumber
      saveAsTemplate
      templateName
      createdBy
      updatedBy
      deleted
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVouchersQueryVariables,
  APITypes.ListVouchersQuery
>;
export const syncVouchers = /* GraphQL */ `query SyncVouchers(
  $filter: ModelVoucherFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncVouchers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      voucherNumber
      assignedUserId
      purpose
      totalAmount
      remainingAmount
      status
      createdOn
      updatedOn
      validUntil
      chequeReferenceNumber
      saveAsTemplate
      templateName
      createdBy
      updatedBy
      deleted
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncVouchersQueryVariables,
  APITypes.SyncVouchersQuery
>;
export const vouchersByValidUntil = /* GraphQL */ `query VouchersByValidUntil(
  $validUntil: AWSDate!
  $sortDirection: ModelSortDirection
  $filter: ModelVoucherFilterInput
  $limit: Int
  $nextToken: String
) {
  vouchersByValidUntil(
    validUntil: $validUntil
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      voucherNumber
      assignedUserId
      purpose
      totalAmount
      remainingAmount
      status
      createdOn
      updatedOn
      validUntil
      chequeReferenceNumber
      saveAsTemplate
      templateName
      createdBy
      updatedBy
      deleted
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VouchersByValidUntilQueryVariables,
  APITypes.VouchersByValidUntilQuery
>;
export const searchVouchers = /* GraphQL */ `query SearchVouchers(
  $filter: SearchableVoucherFilterInput
  $sort: [SearchableVoucherSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableVoucherAggregationInput]
) {
  searchVouchers(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      voucherNumber
      assignedUserId
      purpose
      totalAmount
      remainingAmount
      status
      createdOn
      updatedOn
      validUntil
      chequeReferenceNumber
      saveAsTemplate
      templateName
      createdBy
      updatedBy
      deleted
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchVouchersQueryVariables,
  APITypes.SearchVouchersQuery
>;
export const getInvoice = /* GraphQL */ `query GetInvoice($id: ID!) {
  getInvoice(id: $id) {
    id
    invoiceNumber
    invoiceDate
    consignee
    consigneeId
    consigneeAddress
    consigneeEmail
    consigneeTelephone
    consigneeCountry
    paymentDurations
    totalAmount
    totalAmountInWords
    localItems {
      fishType
      quantity
      unitPrice
      totalAmount
      __typename
    }
    exportItems {
      details
      quantity
      unitPrice
      totalAmount
      __typename
    }
    bLNo
    containerNo
    termsOfDelivery
    portOfLoadings
    portOfDischarge
    transitShipment
    partialShipment
    termsOfPayment
    createdOn
    updatedOn
    deliveryNoteNo
    poReference
    poValue
    dueDate
    saved
    invoiceType
    discount
    advance
    accountHolder
    accountNumber
    iban
    accountAddress
    accountCountry
    hideRecord
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvoiceQueryVariables,
  APITypes.GetInvoiceQuery
>;
export const listInvoices = /* GraphQL */ `query ListInvoices(
  $id: ID
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listInvoices(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      invoiceNumber
      invoiceDate
      consignee
      consigneeId
      consigneeAddress
      consigneeEmail
      consigneeTelephone
      consigneeCountry
      paymentDurations
      totalAmount
      totalAmountInWords
      bLNo
      containerNo
      termsOfDelivery
      portOfLoadings
      portOfDischarge
      transitShipment
      partialShipment
      termsOfPayment
      createdOn
      updatedOn
      deliveryNoteNo
      poReference
      poValue
      dueDate
      saved
      invoiceType
      discount
      advance
      accountHolder
      accountNumber
      iban
      accountAddress
      accountCountry
      hideRecord
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvoicesQueryVariables,
  APITypes.ListInvoicesQuery
>;
export const syncInvoices = /* GraphQL */ `query SyncInvoices(
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncInvoices(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      invoiceNumber
      invoiceDate
      consignee
      consigneeId
      consigneeAddress
      consigneeEmail
      consigneeTelephone
      consigneeCountry
      paymentDurations
      totalAmount
      totalAmountInWords
      bLNo
      containerNo
      termsOfDelivery
      portOfLoadings
      portOfDischarge
      transitShipment
      partialShipment
      termsOfPayment
      createdOn
      updatedOn
      deliveryNoteNo
      poReference
      poValue
      dueDate
      saved
      invoiceType
      discount
      advance
      accountHolder
      accountNumber
      iban
      accountAddress
      accountCountry
      hideRecord
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncInvoicesQueryVariables,
  APITypes.SyncInvoicesQuery
>;
export const invoicesByDueDate = /* GraphQL */ `query InvoicesByDueDate(
  $dueDate: AWSDate!
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  invoicesByDueDate(
    dueDate: $dueDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      invoiceNumber
      invoiceDate
      consignee
      consigneeId
      consigneeAddress
      consigneeEmail
      consigneeTelephone
      consigneeCountry
      paymentDurations
      totalAmount
      totalAmountInWords
      bLNo
      containerNo
      termsOfDelivery
      portOfLoadings
      portOfDischarge
      transitShipment
      partialShipment
      termsOfPayment
      createdOn
      updatedOn
      deliveryNoteNo
      poReference
      poValue
      dueDate
      saved
      invoiceType
      discount
      advance
      accountHolder
      accountNumber
      iban
      accountAddress
      accountCountry
      hideRecord
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvoicesByDueDateQueryVariables,
  APITypes.InvoicesByDueDateQuery
>;
export const searchInvoices = /* GraphQL */ `query SearchInvoices(
  $filter: SearchableInvoiceFilterInput
  $sort: [SearchableInvoiceSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableInvoiceAggregationInput]
) {
  searchInvoices(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      invoiceNumber
      invoiceDate
      consignee
      consigneeId
      consigneeAddress
      consigneeEmail
      consigneeTelephone
      consigneeCountry
      paymentDurations
      totalAmount
      totalAmountInWords
      bLNo
      containerNo
      termsOfDelivery
      portOfLoadings
      portOfDischarge
      transitShipment
      partialShipment
      termsOfPayment
      createdOn
      updatedOn
      deliveryNoteNo
      poReference
      poValue
      dueDate
      saved
      invoiceType
      discount
      advance
      accountHolder
      accountNumber
      iban
      accountAddress
      accountCountry
      hideRecord
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchInvoicesQueryVariables,
  APITypes.SearchInvoicesQuery
>;
export const getSalaryDetails = /* GraphQL */ `query GetSalaryDetails($id: ID!) {
  getSalaryDetails(id: $id) {
    id
    userId
    fullName
    position
    basicSalary
    bonus
    deductions
    paymentDate
    bankName
    branch
    accountNumber
    accountType
    currencyType
    advance
    advanceEVoucherRef
    month
    year
    createdOn
    updatedOn
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSalaryDetailsQueryVariables,
  APITypes.GetSalaryDetailsQuery
>;
export const listSalaryDetails = /* GraphQL */ `query ListSalaryDetails(
  $filter: ModelSalaryDetailsFilterInput
  $limit: Int
  $nextToken: String
) {
  listSalaryDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      fullName
      position
      basicSalary
      bonus
      deductions
      paymentDate
      bankName
      branch
      accountNumber
      accountType
      currencyType
      advance
      advanceEVoucherRef
      month
      year
      createdOn
      updatedOn
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSalaryDetailsQueryVariables,
  APITypes.ListSalaryDetailsQuery
>;
export const syncSalaryDetails = /* GraphQL */ `query SyncSalaryDetails(
  $filter: ModelSalaryDetailsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSalaryDetails(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      userId
      fullName
      position
      basicSalary
      bonus
      deductions
      paymentDate
      bankName
      branch
      accountNumber
      accountType
      currencyType
      advance
      advanceEVoucherRef
      month
      year
      createdOn
      updatedOn
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSalaryDetailsQueryVariables,
  APITypes.SyncSalaryDetailsQuery
>;
export const searchSalaryDetails = /* GraphQL */ `query SearchSalaryDetails(
  $filter: SearchableSalaryDetailsFilterInput
  $sort: [SearchableSalaryDetailsSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableSalaryDetailsAggregationInput]
) {
  searchSalaryDetails(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      userId
      fullName
      position
      basicSalary
      bonus
      deductions
      paymentDate
      bankName
      branch
      accountNumber
      accountType
      currencyType
      advance
      advanceEVoucherRef
      month
      year
      createdOn
      updatedOn
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchSalaryDetailsQueryVariables,
  APITypes.SearchSalaryDetailsQuery
>;
export const getMetaData = /* GraphQL */ `query GetMetaData($id: ID!) {
  getMetaData(id: $id) {
    id
    recordType
    date
    count
    createdOn
    updatedOn
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMetaDataQueryVariables,
  APITypes.GetMetaDataQuery
>;
export const listMetaData = /* GraphQL */ `query ListMetaData(
  $filter: ModelMetaDataFilterInput
  $limit: Int
  $nextToken: String
) {
  listMetaData(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      recordType
      date
      count
      createdOn
      updatedOn
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMetaDataQueryVariables,
  APITypes.ListMetaDataQuery
>;
export const syncMetaData = /* GraphQL */ `query SyncMetaData(
  $filter: ModelMetaDataFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMetaData(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      recordType
      date
      count
      createdOn
      updatedOn
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMetaDataQueryVariables,
  APITypes.SyncMetaDataQuery
>;
export const searchMetaData = /* GraphQL */ `query SearchMetaData(
  $filter: SearchableMetaDataFilterInput
  $sort: [SearchableMetaDataSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableMetaDataAggregationInput]
) {
  searchMetaData(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      recordType
      date
      count
      createdOn
      updatedOn
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchMetaDataQueryVariables,
  APITypes.SearchMetaDataQuery
>;
export const getEmployeeDirectory = /* GraphQL */ `query GetEmployeeDirectory($id: ID!) {
  getEmployeeDirectory(id: $id) {
    id
    employeeId
    userId
    userRecord {
      id
      userId
      firstName
      lastName
      email
      designation
      userType
      birthDay
      address
      residingCountry
      bio
      profilePicture
      contactNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    company
    createdOn
    updatedOn
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEmployeeDirectoryQueryVariables,
  APITypes.GetEmployeeDirectoryQuery
>;
export const listEmployeeDirectories = /* GraphQL */ `query ListEmployeeDirectories(
  $filter: ModelEmployeeDirectoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listEmployeeDirectories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      employeeId
      userId
      company
      createdOn
      updatedOn
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEmployeeDirectoriesQueryVariables,
  APITypes.ListEmployeeDirectoriesQuery
>;
export const syncEmployeeDirectories = /* GraphQL */ `query SyncEmployeeDirectories(
  $filter: ModelEmployeeDirectoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncEmployeeDirectories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      employeeId
      userId
      company
      createdOn
      updatedOn
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncEmployeeDirectoriesQueryVariables,
  APITypes.SyncEmployeeDirectoriesQuery
>;
export const getCustomers = /* GraphQL */ `query GetCustomers($id: ID!) {
  getCustomers(id: $id) {
    id
    customerName
    customerAddress
    customerEmail
    customerTelephone
    customerCountry
    invoiceType
    createdOn
    updatedOn
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomersQueryVariables,
  APITypes.GetCustomersQuery
>;
export const listCustomers = /* GraphQL */ `query ListCustomers(
  $filter: ModelCustomersFilterInput
  $limit: Int
  $nextToken: String
) {
  listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      customerName
      customerAddress
      customerEmail
      customerTelephone
      customerCountry
      invoiceType
      createdOn
      updatedOn
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCustomersQueryVariables,
  APITypes.ListCustomersQuery
>;
export const syncCustomers = /* GraphQL */ `query SyncCustomers(
  $filter: ModelCustomersFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCustomers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      customerName
      customerAddress
      customerEmail
      customerTelephone
      customerCountry
      invoiceType
      createdOn
      updatedOn
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCustomersQueryVariables,
  APITypes.SyncCustomersQuery
>;
export const getInventory = /* GraphQL */ `query GetInventory($id: ID!) {
  getInventory(id: $id) {
    id
    createdOn
    updatedOn
    inventoryItems {
      cccs
      specie
      scow
      weight
      client
      boatCode
      container
      enteredCs
      firstEnteredToCs
      qaMark
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInventoryQueryVariables,
  APITypes.GetInventoryQuery
>;
export const listInventories = /* GraphQL */ `query ListInventories(
  $filter: ModelInventoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listInventories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdOn
      updatedOn
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInventoriesQueryVariables,
  APITypes.ListInventoriesQuery
>;
export const syncInventories = /* GraphQL */ `query SyncInventories(
  $filter: ModelInventoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncInventories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      createdOn
      updatedOn
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncInventoriesQueryVariables,
  APITypes.SyncInventoriesQuery
>;
export const searchInventories = /* GraphQL */ `query SearchInventories(
  $filter: SearchableInventoryFilterInput
  $sort: [SearchableInventorySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableInventoryAggregationInput]
) {
  searchInventories(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      createdOn
      updatedOn
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchInventoriesQueryVariables,
  APITypes.SearchInventoriesQuery
>;
