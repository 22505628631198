import { updatePassword } from "aws-amplify/auth";

const updateCurrentPassword = async ({
  oldPassword,
  newPassword,
}: {
  oldPassword: string;
  newPassword: string;
}) => {
  try {
    await updatePassword({ oldPassword, newPassword });

    return { success: true };
  } catch (error) {
    console.log("🚀 ~ error:", error);
    return { success: false };
  }
};

export { updateCurrentPassword };
