import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { GridColDef } from "@mui/x-data-grid";
import DataGridColumnData from "../components/Common/DataGridColumnData";
import DataGridColumnHeader from "../components/Common/DataGridColumnHeader";
import CircleIcon from "@mui/icons-material/Circle";
import { LazyUser, LazyVoucher, VoucherStatus } from "../models";
import DataGridComponent from "../components/Common/DataGridComponent";
import DetailsCard from "../components/Common/DetailsCard";
import { Navigation, Grid as SwiperGrid } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import VisibilityIcon from "@mui/icons-material/Visibility";

import "swiper/css";
import "swiper/css/navigation";
import { useIsWidthDown, VoucherTemplate } from "../utils";
import CreateEVoucher from "../components/EVouchers/CreateEVoucher";
import EVoucherPreviewModal from "../components/EVouchers/EVoucherPreviewModal";
import VoucherRedeemModal from "../components/EVouchers/VoucherRedeemModal";
import {
  fetchAllEVouchers,
  fetchAllEVouchersAdmin,
  fetchEVoucherTemplates,
  fetchEVoucherTemplatesForCurrentUser,
  fetchUsers,
  getSummaryCardDetails,
  getSummaryCardDetailsForCurrentUser,
} from "../services";
import moment from "moment";
import VoucherTemplatesView from "../components/EVouchers/VoucherTemplatesView";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useAuth } from "../context/AuthContext";
import { UserType } from "../models";
import EVoucherTemplateView from "../components/EVouchers/EVoucherTemplateView";
import EditEVoucher from "../components/EVouchers/EditEVoucher";
import { fetchVoucherNumber } from "../services/common.service";

type SummaryCardDetailsType = {
  totalAllocationInMonth: number;
  totalExpenditureInMonth: number;
  totalRemainingInMonth: number;
};

const EVoucherManagement = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  const { currentUser } = useAuth();

  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [nextToken, setNextToken] = useState<string | null | undefined>(null);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedRow, setSelectedRow] = useState<VoucherTemplate | null>(null);
  const [selectedRowUser, setSelectedRowUser] = useState("");
  const [openRowPreview, setOpenRowPreview] = useState(false);
  const [openRedeemDialog, setOpenRedeemDialog] = useState(false);

  const [allUsers, setAllUsers] = useState<LazyUser[]>([]);
  const [currentVoucherNumber, setCurrentVoucherNumber] = useState(0);

  const [allVouchers, setAllVouchers] = useState<LazyVoucher[]>([]);
  const [loadingEVouchers, setLoadingEVouchers] = useState(false);

  const [cardData, setCardData] = useState<SummaryCardDetailsType>({
    totalAllocationInMonth: 0,
    totalExpenditureInMonth: 0,
    totalRemainingInMonth: 0,
  });

  const [loadingEVoucherTemplates, setLoadingEVoucherTemplates] =
    useState(false);
  const [voucherTemplates, setVoucherTemplates] = useState<VoucherTemplate[]>(
    []
  );

  const [anchorElEVoucherMore, setAnchorElEVoucherMore] =
    useState<HTMLButtonElement | null>(null);

  const [openEVoucherTemplates, setOpenEVoucherTemplates] = useState(false);

  const [selectedTemplate, setSelectedTemplate] =
    useState<VoucherTemplate | null>(null);
  const [openVoucherTemplate, setOpenVoucherTemplate] = useState(false);

  const [editVoucherData, setEditVoucherData] =
    useState<VoucherTemplate | null>(null);
  const [openEditVoucher, setOpenEditVoucher] = useState(false);

  const openAnchorElEVoucherMore = Boolean(anchorElEVoucherMore);

  const handleCloseEVoucherMore = () => {
    setAnchorElEVoucherMore(null);
  };

  const handleClickEVoucherMore = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElEVoucherMore(event.currentTarget);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "voucherNumber",
      headerName: "Voucher Code",
      sortable: true,
      disableColumnMenu: false,
      flex: 1,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Voucher Code" />,
    },
    {
      field: "assignedUserId",
      headerName: "Assigned To",
      sortable: true,
      disableColumnMenu: false,
      flex: 1,
      renderCell: (params) => (
        <DataGridColumnData
          content={`${
            params.row["assignedTo"]["firstName"] +
            " " +
            params.row["assignedTo"]["lastName"]
          }`}
        />
      ),
      renderHeader: () => <DataGridColumnHeader headerName="Assigned To" />,
    },
    {
      field: "purpose",
      headerName: "Purpose",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Purpose" />,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      sortable: true,
      disableColumnMenu: false,
      flex: 1,
      renderCell: (params) => (
        <DataGridColumnData content={params.value.toLocaleString("en-US")} />
      ),
      renderHeader: () => <DataGridColumnHeader headerName="Total Amount" />,
    },
    {
      field: "remainingAmount",
      headerName: "Remaining Amount",
      sortable: true,
      disableColumnMenu: false,
      flex: 1,
      renderCell: (params) => (
        <DataGridColumnData content={params.value.toLocaleString("en-US")} />
      ),
      renderHeader: () => (
        <DataGridColumnHeader headerName="Remaining Amount" />
      ),
    },
    {
      field: "validUntil",
      headerName: "Valid Until",
      sortable: true,
      disableColumnMenu: false,
      flex: 1,
      renderCell: (params) => (
        <DataGridColumnData
          content={moment(params.value).format("YYYY-MM-DD")}
        />
      ),
      renderHeader: () => <DataGridColumnHeader headerName="Valid Until" />,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      disableColumnMenu: false,
      flex: 1,
      renderHeader: () => <DataGridColumnHeader headerName="Status" />,
      renderCell: (params) => {
        return (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width="100%"
          >
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={"5px"}
              p={"5px"}
              sx={{
                background:
                  params.value === VoucherStatus.ACTIVE
                    ? "#E6F8EF"
                    : params.value === VoucherStatus.ON_HOLD
                    ? "#FEF2E6"
                    : "#F2F2F2",
                borderRadius: "9px",
              }}
            >
              <CircleIcon
                sx={{
                  width: "12px",
                  height: "12px",
                  color:
                    params.value === VoucherStatus.ACTIVE
                      ? "#00BC62"
                      : params.value === VoucherStatus.ON_HOLD
                      ? "#F57C00"
                      : "#999",
                }}
              />
              <Typography
                sx={{
                  color:
                    params.value === VoucherStatus.ACTIVE
                      ? "#00BC62"
                      : params.value === VoucherStatus.ON_HOLD
                      ? "#F57C00"
                      : "#999",
                  fontSize: "8px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "150%",
                  textTransform: "capitalize",
                }}
              >
                {params.value === VoucherStatus.ACTIVE
                  ? "Active"
                  : params.value === VoucherStatus.ON_HOLD
                  ? "On Hold"
                  : "Exhausted"}
              </Typography>
            </Stack>
            <IconButton
              onClick={() => {
                setSelectedRow(params.row);

                setSelectedRowUser(
                  `${
                    params.row["assignedTo"]["firstName"] +
                    " " +
                    params.row["assignedTo"]["lastName"]
                  }`
                );

                setOpenRowPreview(true);
              }}
            >
              <VisibilityIcon
                sx={{ width: "16px", height: "16px", color: "#646D7C" }}
              />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const fetchAllVouchers = async () => {
    try {
      setLoadingEVouchers(true);

      if (currentUser?.userType === UserType.SUPER_ADMIN) {
        const {
          rows,
          rowCount,
          nextToken: newNextToken,
        } = await fetchAllEVouchersAdmin({
          limit: pageSize,
          nextToken: nextToken,
        });
        setAllVouchers(rows);
        setTotalRows(rowCount);
        setNextToken(newNextToken);
      } else {
        const {
          rows,
          rowCount,
          nextToken: newNextToken,
        } = await fetchAllEVouchers({
          userId: user.userId,
          limit: pageSize,
          nextToken: nextToken,
        });
        setAllVouchers(rows);
        setTotalRows(rowCount);
        setNextToken(newNextToken);
      }

      setLoadingEVouchers(false);
    } catch (error) {
      console.log("🚀 ~ fetchAllVouchers ~ error:", error);
    }
  };

  const fetchAllUsers = async () => {
    try {
      const users = await fetchUsers();

      setAllUsers(users);
    } catch (error) {
      console.log("🚀 ~ fetchAllUsers ~ error:", error);
    }
  };

  const fetchSummaryCardDetails = async () => {
    try {
      if (currentUser?.userType === UserType.SUPER_ADMIN) {
        const summaryData = await getSummaryCardDetails();

        setCardData(summaryData);
      } else {
        const summaryData = await getSummaryCardDetailsForCurrentUser(
          user.userId
        );
        setCardData(summaryData);
      }
    } catch (error) {
      console.log("🚀 ~ getSummaryCardDetails ~ error:", error);
    }
  };

  const fetchTemplates = async () => {
    try {
      setLoadingEVoucherTemplates(true);

      if (currentUser?.userType === UserType.SUPER_ADMIN) {
        const templates = await fetchEVoucherTemplates();

        setVoucherTemplates(templates);
      } else {
        const templates = await fetchEVoucherTemplatesForCurrentUser(
          user.userId
        );

        setVoucherTemplates(templates);
      }

      setLoadingEVoucherTemplates(false);
    } catch (error) {
      console.log("🚀 ~ fetchTemplates ~ error:", error);
    }
  };

  const fetchCurrentEVoucherNumber = async () => {
    try {
      const { numberOfVouchers } = await fetchVoucherNumber();
      setCurrentVoucherNumber(numberOfVouchers);
    } catch (error) {
      console.log("🚀 ~ fetchCurrentEVoucherNumber ~ error:", error);
    }
  };

  const fetchInitialData = () => {
    fetchAllUsers();
    fetchAllVouchers();
    fetchSummaryCardDetails();
    fetchTemplates();
    fetchCurrentEVoucherNumber();
  };

  useEffect(() => {
    fetchInitialData();
  }, [currentUser]);

  return (
    <Box px={{ xs: "none", md: "20px" }}>
      <Container maxWidth="xl" disableGutters sx={{ mt: "76px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Stack
              direction={"row"}
              width={"100%"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <Typography
                sx={{
                  color: "var(--components-cta-text-icon, #1C2940)",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "120%",
                  textTransform: "capitalize",
                }}
              >
                E-Vouchers
              </Typography>
              {currentUser?.userType === UserType.SUPER_ADMIN && (
                <React.Fragment>
                  <ButtonGroup
                    variant="contained"
                    aria-label="Button group with a nested menu"
                  >
                    <Button
                      onClick={() => {
                        setOpenDialog(true);
                      }}
                      sx={{
                        borderRadius: "6px",
                        background: "var(--Components-CTA-BG, #4CE6FA)",
                        ":hover": {
                          background: "var(--Components-CTA-BG, #4CE6FA)",
                        },
                        padding: "10px",
                      }}
                      startIcon={<CardGiftcardIcon sx={{ color: "#1C2940" }} />}
                    >
                      <Typography
                        sx={{
                          color: "var(--components-cta-text-icon, #1C2940)",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "120%",
                          textTransform: "capitalize",
                        }}
                      >
                        Create New E- Voucher
                      </Typography>
                    </Button>
                    <Button
                      size="small"
                      aria-label="select merge strategy"
                      aria-haspopup="menu"
                      sx={{
                        background: "var(--Components-CTA-BG, #4CE6FA)",
                        ":hover": {
                          background: "var(--Components-CTA-BG, #4CE6FA)",
                        },
                      }}
                      onClick={handleClickEVoucherMore}
                    >
                      <ArrowDropDownIcon sx={{ color: "#1C2940" }} />
                    </Button>
                  </ButtonGroup>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElEVoucherMore}
                    open={openAnchorElEVoucherMore}
                    onClose={handleCloseEVoucherMore}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setOpenEVoucherTemplates(true);
                        handleCloseEVoucherMore();
                      }}
                      sx={{
                        ":hover": {
                          color: "white",
                          boxShadow: "none",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--components-cta-text-icon, #1C2940)",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "120%",
                          textTransform: "capitalize",
                        }}
                      >
                        View E Voucher Templates
                      </Typography>
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} mb={"20px"} mt="30px">
            <Swiper
              navigation={true}
              grid={{
                rows: 1,
              }}
              modules={[Navigation, SwiperGrid]}
              className="mySwiper"
              slidesPerView={useIsWidthDown("md") ? 1 : 3.3}
            >
              {[
                {
                  cardTitle: "Total Allocation",
                  subTitle: `on ${moment().format("MMMM")}`,
                  lastUpdatedDate: `${moment().format("YYYY-MM-DD HH:mm")}`,
                  amount: `$ ${cardData.totalAllocationInMonth.toLocaleString(
                    "USD"
                  )}`,
                },
                {
                  cardTitle: "Total Expenditure",
                  subTitle: `on ${moment().format("MMMM")}`,
                  lastUpdatedDate: `${moment().format("YYYY-MM-DD HH:mm")}`,
                  amount: `$ ${cardData.totalExpenditureInMonth.toLocaleString(
                    "USD"
                  )}`,
                },
                {
                  cardTitle: "Remaining Balance",
                  subTitle: `on ${moment().format("MMMM")}`,
                  lastUpdatedDate: `${moment().format("YYYY-MM-DD HH:mm")}`,
                  amount: `$ ${cardData.totalRemainingInMonth.toLocaleString(
                    "USD"
                  )}`,
                },
              ].map((data, index) => (
                <SwiperSlide
                  style={{ maxWidth: "calc(100% / 3 - 20px)" }}
                  key={index}
                >
                  <DetailsCard
                    cardTitle={data.cardTitle}
                    subTitle={data.subTitle}
                    lastUpdatedDate={data.lastUpdatedDate}
                    amount={data.amount}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
          <Grid item xs={12}>
            <DataGridComponent
              columns={columns}
              rows={allVouchers}
              loading={loadingEVouchers}
              totalRows={totalRows}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              checkboxSelection={false}
            />
          </Grid>
        </Grid>
      </Container>
      <CreateEVoucher
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        users={allUsers}
        fetchInitialData={fetchInitialData}
        user={user}
        numberOfVouchers={currentVoucherNumber}
      />
      <EVoucherPreviewModal
        open={openRowPreview}
        onClose={() => {
          setOpenRowPreview(false);
        }}
        data={selectedRow}
        assignedTo={selectedRowUser}
        openRedeemDialog={() => {
          setOpenRedeemDialog(true);
        }}
        user={user}
        userType={(currentUser?.userType as UserType) ?? UserType.USER}
        setOpenEditVoucher={setOpenEditVoucher}
        setEditVoucherData={setEditVoucherData}
      />
      <VoucherRedeemModal
        open={openRedeemDialog}
        onClose={() => {
          setOpenRedeemDialog(false);
        }}
        data={selectedRow}
        fetchInitialData={fetchInitialData}
        user={user}
        onOpen={() => {
          setOpenRedeemDialog(true);
        }}
      />
      <VoucherTemplatesView
        open={openEVoucherTemplates}
        onClose={() => {
          setOpenEVoucherTemplates(false);
        }}
        voucherTemplates={voucherTemplates}
        loadingEVoucherTemplates={loadingEVoucherTemplates}
        setSelectedTemplate={setSelectedTemplate}
        setOpenVoucherTemplate={setOpenVoucherTemplate}
      />
      <EVoucherTemplateView
        open={openVoucherTemplate}
        onClose={() => {
          setOpenVoucherTemplate(false);
        }}
        data={selectedTemplate}
        title={selectedTemplate?.templateName ?? ""}
        users={allUsers}
        fetchInitialData={fetchInitialData}
        setOpenVoucherTemplate={setOpenVoucherTemplate}
        user={user}
      />
      <EditEVoucher
        open={openEditVoucher}
        onClose={() => {
          setOpenEditVoucher(false);
        }}
        title={editVoucherData?.voucherNumber ?? ""}
        authUser={user}
        data={editVoucherData}
        users={allUsers}
        fetchInitialData={fetchInitialData}
        onOpen={() => {
          setOpenEditVoucher(true);
        }}
      />
    </Box>
  );
};

export default EVoucherManagement;
