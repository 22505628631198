import { signOut } from "aws-amplify/auth";
import { useEffect, useRef, useCallback } from "react";

const useSessionTimeout = (timeoutDuration: number = 15 * 60 * 1000) => {
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const resetTimeout = useCallback(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    // Set a new timeout to sign out the user after 15 minutes of inactivity
    timeoutIdRef.current = setTimeout(async () => {
      try {
        await signOut();
        window.location.reload(); // Optional: Reload to redirect to login
      } catch (error) {
        console.error("Error signing out:", error);
      }
    }, timeoutDuration);
  }, [timeoutDuration]);

  // Attach event listeners to reset the timeout on user activity
  useEffect(() => {
    const events = [
      "mousemove",
      "mousedown",
      "keypress",
      "touchstart",
      "scroll",
    ];

    events.forEach((event) => window.addEventListener(event, resetTimeout));

    // Cleanup listeners on unmount
    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, resetTimeout)
      );
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
    };
  }, [resetTimeout]);

  return { resetTimeout };
};

export default useSessionTimeout;
