import config from "./aws-exports";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import SignIn from "./pages/SignIn";
import { useAuthenticator, View } from "@aws-amplify/ui-react";
import UserManagement from "./pages/UserManagement";
import { signOut, getCurrentUser } from "aws-amplify/auth";
import UserLayout from "./layouts/UserLayout";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import EventNoteIcon from "@mui/icons-material/EventNote";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import SavingsIcon from "@mui/icons-material/Savings";
import SummarizeIcon from "@mui/icons-material/Summarize";
import EVoucherManagement from "./pages/EVoucherManagement";
import InventoryManagement from "./pages/InventoryManagement";
import Home from "./pages/Home";
import Salary from "./pages/salary";
import { useAuth } from "./context/AuthContext";
import SingleUser from "./pages/SingleUser";
import InvoiceManagement from "./pages/InvoiceManagement";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReportsManagement from "./pages/ReportsManagement";
import ConfirmEmail from "./pages/ConfirmEmail";
import { UserType } from "./models";
import { notifySignOutToSuperAdmins } from "./utils";
import { notifySuperAdmins } from "./services";
import useSessionTimeout from "./hooks/useSessionTimeout";
import { useEffect } from "react";
import { DataStore } from "@aws-amplify/datastore";

Amplify.configure(config, {
  ssr: true,
});

const sideMenu = [
  {
    index: 0,
    name: "E Vouchers",
    path: "../e-voucher-management",
    pathName: "e-voucher-management",
    elementId: "#section-01",
    newTab: false,
    icon: <CardGiftcardIcon />,
    subMenu: [],
  },
  {
    index: 1,
    name: "Inventory",
    path: "./inventory-management",
    pathName: "inventory-management",
    elementId: "#section-01",
    newTab: false,
    icon: <EventNoteIcon />,
    subMenu: [],
  },
  {
    index: 2,
    name: "Salary",
    path: "./salary",
    pathName: "salary",
    elementId: "#section-01",
    newTab: false,
    icon: <SavingsIcon />,
    subMenu: [],
  },
  {
    index: 3,
    name: "Dashboards and Report",
    path: "./dashboards-reports",
    pathName: "dashboards-reports",
    elementId: "#section-01",
    newTab: false,
    icon: <SummarizeIcon />,
    subMenu: [],
  },
  {
    index: 4,
    name: "User Management",
    path: "./user-management",
    pathName: "user-management",
    elementId: "#section-01",
    newTab: false,
    icon: <PeopleAltRoundedIcon />,
    subMenu: [],
  },
  {
    index: 5,
    name: "Invoices",
    path: "./invoice-management",
    pathName: "invoice-management",
    elementId: "#section-01",
    newTab: false,
    icon: <ReceiptIcon />,
    subMenu: [],
  },
];

const App = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  const { resetTimeout } = useSessionTimeout(10 * 60 * 1000);

  const { currentUser } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const logout = async () => {
    await signOut();
    navigate("/");
    await notifySuperAdmins({
      ...notifySignOutToSuperAdmins({
        name: `${currentUser?.firstName + " " + currentUser?.lastName}`,
      }),
    });
  };

  useEffect(() => {
    const checkSession = async () => {
      try {
        await getCurrentUser();
        resetTimeout();
      } catch (error) {
        if (location.pathname !== "/") {
          window.location.href = "/";
        }
      }
    };

    checkSession();
  }, [resetTimeout]);

  return (
    <View height={"100%"}>
      {user ? (
        <Routes>
          <Route path="" element={<Navigate to="/profile" />} />
          <Route
            path="user-management"
            element={
              <UserLayout
                fullName={""}
                email={""}
                location={location.pathname}
                sideMenu={
                  currentUser?.userType === UserType.SUPER_ADMIN
                    ? sideMenu
                    : currentUser?.userType === UserType.ACCOUNTANT
                    ? sideMenu.filter(
                        (menu) => ![0, 1, 2, 3, 4].includes(menu.index)
                      )
                    : sideMenu.filter(
                        (menu) => ![1, 2, 4, 5].includes(menu.index)
                      )
                }
                logout={logout}
                navigate={navigate}
                profilePicture={currentUser?.profilePicture}
              >
                <UserManagement />
              </UserLayout>
            }
          />
          <Route
            path="profile"
            element={
              <UserLayout
                fullName={""}
                email={""}
                location={location.pathname}
                sideMenu={
                  currentUser?.userType === UserType.SUPER_ADMIN
                    ? sideMenu
                    : currentUser?.userType === UserType.ACCOUNTANT
                    ? sideMenu.filter(
                        (menu) => ![0, 1, 2, 3, 4].includes(menu.index)
                      )
                    : sideMenu.filter(
                        (menu) => ![1, 2, 4, 5].includes(menu.index)
                      )
                }
                logout={logout}
                navigate={navigate}
                profilePicture={currentUser?.profilePicture}
              >
                <Home />
              </UserLayout>
            }
          />
          <Route
            path="e-voucher-management"
            element={
              <UserLayout
                fullName={""}
                email={""}
                location={location.pathname}
                sideMenu={
                  currentUser?.userType === UserType.SUPER_ADMIN
                    ? sideMenu
                    : currentUser?.userType === UserType.ACCOUNTANT
                    ? sideMenu.filter(
                        (menu) => ![0, 1, 2, 3, 4].includes(menu.index)
                      )
                    : sideMenu.filter(
                        (menu) => ![1, 2, 4, 5].includes(menu.index)
                      )
                }
                logout={logout}
                navigate={navigate}
                profilePicture={currentUser?.profilePicture}
              >
                <EVoucherManagement />
              </UserLayout>
            }
          />
          <Route
            path="inventory-management"
            element={
              <UserLayout
                fullName={""}
                email={""}
                location={location.pathname}
                sideMenu={
                  currentUser?.userType === UserType.SUPER_ADMIN
                    ? sideMenu
                    : currentUser?.userType === UserType.ACCOUNTANT
                    ? sideMenu.filter(
                        (menu) => ![0, 1, 2, 3, 4].includes(menu.index)
                      )
                    : sideMenu.filter(
                        (menu) => ![1, 2, 4, 5].includes(menu.index)
                      )
                }
                logout={logout}
                navigate={navigate}
                profilePicture={currentUser?.profilePicture}
              >
                <InventoryManagement />
              </UserLayout>
            }
          />
          <Route
            path="invoice-management"
            element={
              <UserLayout
                fullName={""}
                email={""}
                location={location.pathname}
                sideMenu={
                  currentUser?.userType === UserType.SUPER_ADMIN
                    ? sideMenu
                    : currentUser?.userType === UserType.ACCOUNTANT
                    ? sideMenu.filter(
                        (menu) => ![0, 1, 2, 3, 4].includes(menu.index)
                      )
                    : sideMenu.filter(
                        (menu) => ![1, 2, 4, 5].includes(menu.index)
                      )
                }
                logout={logout}
                navigate={navigate}
                profilePicture={currentUser?.profilePicture}
              >
                <InvoiceManagement />
              </UserLayout>
            }
          />
          <Route
            path="user-management/:userId"
            element={
              <UserLayout
                fullName={""}
                email={""}
                location={location.pathname}
                sideMenu={
                  currentUser?.userType === UserType.SUPER_ADMIN
                    ? sideMenu
                    : currentUser?.userType === UserType.ACCOUNTANT
                    ? sideMenu.filter(
                        (menu) => ![0, 1, 2, 3, 4].includes(menu.index)
                      )
                    : sideMenu.filter(
                        (menu) => ![1, 2, 4, 5].includes(menu.index)
                      )
                }
                logout={logout}
                navigate={navigate}
                profilePicture={currentUser?.profilePicture}
              >
                <SingleUser />
              </UserLayout>
            }
          />
          <Route
            path="dashboards-reports"
            element={
              <UserLayout
                fullName={""}
                email={""}
                location={location.pathname}
                sideMenu={
                  currentUser?.userType === UserType.SUPER_ADMIN
                    ? sideMenu
                    : currentUser?.userType === UserType.ACCOUNTANT
                    ? sideMenu.filter(
                        (menu) => ![0, 1, 2, 3, 4].includes(menu.index)
                      )
                    : sideMenu.filter(
                        (menu) => ![1, 2, 4, 5].includes(menu.index)
                      )
                }
                logout={logout}
                navigate={navigate}
                profilePicture={currentUser?.profilePicture}
              >
                <ReportsManagement />
              </UserLayout>
            }
          />
          <Route
            path="salary"
            element={
              <UserLayout
                fullName={""}
                email={""}
                location={location.pathname}
                sideMenu={
                  currentUser?.userType === UserType.SUPER_ADMIN
                    ? sideMenu
                    : currentUser?.userType === UserType.ACCOUNTANT
                    ? sideMenu.filter(
                        (menu) => ![0, 1, 2, 3, 4].includes(menu.index)
                      )
                    : sideMenu.filter(
                        (menu) => ![1, 2, 4, 5].includes(menu.index)
                      )
                }
                logout={logout}
                navigate={navigate}
                profilePicture={currentUser?.profilePicture}
              >
                <Salary />
              </UserLayout>
            }
          />
        </Routes>
      ) : (
        <Routes>
          <Route path="" element={<SignIn />} />
          <Route path="/confirm-email" element={<ConfirmEmail />} />
        </Routes>
      )}
    </View>
  );
};

export default App;
