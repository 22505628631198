import { useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import {
  createNewSalaryDetail,
  updateSalaryDetail,
} from "../..//services/salary.service";
import { months, SalaryExtended, SalaryType } from "../../utils";
import ModalWrapper from "../Common/ModalWrapper";
import InputLabelWrapper from "../Common/InputLabelWrapper";
import moment from "moment";
import Swal from "sweetalert2";

type SalaryDetailsModalProps = {
  open: boolean;
  onClose: () => void;
  salaryData?: SalaryType;
  onCreated: () => void;
};

const validationSchema = Yup.object({
  userId: Yup.string().required("User ID is required"),
  fullName: Yup.string().required("Full Name is required"),
  position: Yup.string().required("Position is required"),
  basicSalary: Yup.number().required("Basic Salary is required"),
  bonus: Yup.number().optional(),
  deductions: Yup.number().required("Deductions are required"),
  paymentDate: Yup.string().required("Payment Date is required"),
  bankName: Yup.string().required("Bank Name is required"),
  branch: Yup.string().required("Branch is required"),
  accountNumber: Yup.string().required("Account Number is required"),
  accountType: Yup.string().required("Account Type is required"),
  currencyType: Yup.string().required("Currency Type is required"),
  advance: Yup.number().optional(),
  advanceEVoucherRef: Yup.string().optional(),
});

const initSalaryData = {
  userId: "",
  fullName: "",
  position: "",
  basicSalary: 0,
  bonus: 0,
  deductions: 0,
  paymentDate: "",
  bankName: "",
  branch: "",
  accountNumber: "",
  accountType: "",
  currencyType: "",
  advance: 0,
  advanceEVoucherRef: "",
  month: "",
  year: moment().year().toString(),
  createdOn: moment().format("YYYY-MM-DD"),
  updatedOn: moment().format("YYYY-MM-DD"),
};

// Helper function to generate random user ID in the format ICE####
const generateRandomUserId = () => {
  const randomNum = Math.floor(Math.random() * 100000) + 1; // Generate a number between 1 and 100000
  return `ICE${randomNum}`;
};

const SalaryDetailsModal = ({
  open,
  onClose,
  onCreated,
  salaryData = { ...initSalaryData },
}: SalaryDetailsModalProps) => {
  const [loading, setLoading] = useState(false);

  // if (!salaryData.userId) salaryData.userId = generateRandomUserId();

  const handleSave = async (values: SalaryExtended) => {
    setLoading(true);
    try {
      if (values.id) {
        const {
          __typename,
          _deleted,
          _lastChangedAt,
          createdAt,
          updatedAt,
          ...rest
        } = values as any;
        const response = await updateSalaryDetail(rest);

        if (response) {
          Swal.fire({
            title: "Salary Record Updated",
            text: "Salary Successfully Updated",
            icon: "success",
          });
        }
      } else {
        const response = await createNewSalaryDetail(values);

        if (response) {
          Swal.fire({
            title: "Salary Record Updated",
            text: "Salary Successfully Created",
            icon: "success",
          });
        }
      }
      onClose();
      onCreated();
    } catch (error) {
      console.error("Error saving salary details:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      title="Create"
      subTitle="New Salary Record"
    >
      <Formik
        initialValues={salaryData || {}}
        onSubmit={async (values, { resetForm }) => {
          await handleSave(values as SalaryExtended);
          resetForm();
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          isSubmitting,
          touched,
          dirty,
          resetForm,
        }) => {
          // Calculate total salary
          const totalSalary = () => {
            const basicSalary = values.basicSalary || 0;
            const bonus = values.bonus || 0;
            const deductions = values.deductions || 0;
            return basicSalary + bonus - deductions;
          };

          return (
            <Form>
              <DialogContent
                sx={{
                  paddingX: "20px",
                  paddingTop: "40px",
                  paddingBottom: "40px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <InputLabelWrapper label="Employee ID">
                      <TextField
                        value={values.userId}
                        name="userId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.userId && !!errors.userId}
                        fullWidth
                        size="small"
                      />
                    </InputLabelWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabelWrapper label="Full Name">
                      <TextField
                        value={values.fullName}
                        name="fullName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.fullName && !!errors.fullName}
                        fullWidth
                        size="small"
                      />
                    </InputLabelWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabelWrapper label="Position">
                      <TextField
                        value={values.position}
                        name="position"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.position && !!errors.position}
                        fullWidth
                        size="small"
                      />
                    </InputLabelWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabelWrapper label="Basic Salary">
                      <OutlinedInput
                        value={values.basicSalary}
                        name="basicSalary"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.basicSalary && !!errors.basicSalary}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        inputProps={{ min: 0 }}
                        fullWidth
                        size="small"
                        type="number"
                      />
                    </InputLabelWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabelWrapper label="Bonus (Optional)">
                      <TextField
                        value={values.bonus ?? ""}
                        name="bonus"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.bonus && !!errors.bonus}
                        fullWidth
                        size="small"
                        type="number"
                      />
                    </InputLabelWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabelWrapper label="Deductions">
                      <TextField
                        value={values.deductions}
                        name="deductions"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.deductions && !!errors.deductions}
                        fullWidth
                        size="small"
                        type="number"
                      />
                    </InputLabelWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabelWrapper label="Payment Date">
                      <TextField
                        value={values.paymentDate}
                        name="paymentDate"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.paymentDate && !!errors.paymentDate}
                        fullWidth
                        size="small"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </InputLabelWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabelWrapper label="Salary Month">
                      <Select
                        value={values.month}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={"month"}
                        error={touched.month && !!errors.month}
                        fullWidth
                        size="small"
                      >
                        <MenuItem value="" disabled>
                          -Select-
                        </MenuItem>
                        {months.map((month, index) => (
                          <MenuItem key={index} value={month}>
                            {month}
                          </MenuItem>
                        ))}
                      </Select>
                    </InputLabelWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabelWrapper label="Bank Name">
                      <TextField
                        value={values.bankName}
                        name="bankName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.bankName && !!errors.bankName}
                        fullWidth
                        size="small"
                      />
                    </InputLabelWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabelWrapper label="Branch">
                      <TextField
                        value={values.branch}
                        name="branch"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.branch && !!errors.branch}
                        fullWidth
                        size="small"
                      />
                    </InputLabelWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabelWrapper label="Account Number">
                      <TextField
                        value={values.accountNumber}
                        name="accountNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.accountNumber && !!errors.accountNumber}
                        fullWidth
                        size="small"
                      />
                    </InputLabelWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabelWrapper label="Account Type">
                      <TextField
                        value={values.accountType}
                        name="accountType"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.accountType && !!errors.accountType}
                        fullWidth
                        size="small"
                      />
                    </InputLabelWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabelWrapper label="Currency Type">
                      <Select
                        value={values.currencyType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={"currencyType"}
                        error={touched.currencyType && !!errors.currencyType}
                        fullWidth
                        size="small"
                      >
                        <MenuItem value="" disabled>
                          -Select-
                        </MenuItem>
                        {[
                          { key: "GBP - British Pound", value: "GBP" },
                          { key: "LKR - Sri Lankan Rupee", value: "LKR" },
                          { key: "SCR - Seychelles Rupee", value: "SCR" },
                          { key: "USD - US Dollar", value: "USD" },
                          { key: "EUR - Euro", value: "EUR" },
                          { key: "AUD - Australian Dollar", value: "AUD" },
                        ].map((currency, index) => (
                          <MenuItem key={index} value={currency.value}>
                            {currency.key}
                          </MenuItem>
                        ))}
                      </Select>
                    </InputLabelWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabelWrapper label="Advance (Optional)">
                      <TextField
                        value={values.advance ?? ""}
                        name="advance"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.advance && !!errors.advance}
                        fullWidth
                        size="small"
                        type="number"
                      />
                    </InputLabelWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabelWrapper label="Advance E Voucher Reference (Optional)">
                      <TextField
                        value={values.advanceEVoucherRef ?? ""}
                        name="advanceEVoucherRef"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.advanceEVoucherRef &&
                          !!errors.advanceEVoucherRef
                        }
                        fullWidth
                        size="small"
                      />
                    </InputLabelWrapper>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "20px",
                  backgroundColor: "#F1F5F8",
                }}
              >
                <Stack>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 100,
                      color: "#46D7C",
                    }}
                  >
                    Total Salary:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                      color: "#1C2940",
                    }}
                  >
                    {totalSalary().toFixed(2)}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Button
                    sx={{ width: "100px", height: "32px" }}
                    disabled={isSubmitting || !dirty}
                    onClick={() => resetForm()}
                  >
                    <Typography
                      sx={{
                        color:
                          "var(--components-enabled-text-stroke-icon, #646D7C)",
                        textAlign: "center",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "120%",
                        textTransform: "capitalize",
                      }}
                    >
                      Reset
                    </Typography>
                  </Button>
                  <LoadingButton
                    sx={{
                      borderRadius: "6px",
                      background: "var(--Components-CTA-BG, #4CE6FA)",
                      ":hover": {
                        background: "var(--Components-CTA-BG, #4CE6FA)",
                      },
                      padding: "10px",
                    }}
                    disabled={isSubmitting || !dirty || !isValid || loading}
                    loading={loading}
                    onClick={() => handleSubmit()}
                  >
                    <Typography
                      sx={{
                        color: "var(--components-cta-text-icon, #1C2940)",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "120%",
                        textTransform: "capitalize",
                      }}
                    >
                      Save Salary Record
                    </Typography>
                  </LoadingButton>
                </Stack>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </ModalWrapper>
  );
};

export default SalaryDetailsModal;
