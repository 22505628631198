import { Box, Container, Grid } from "@mui/material";
import React from "react";
import LoginPageBanner from "../components/Auth/LoginPageBanner";
import ConfirmEmailForm from "../components/Auth/ConfirmEmailForm";

const ConfirmEmail = () => {
  return (
    <Box>
      <Container maxWidth="xl" disableGutters>
        <Grid container p="20px">
          <Grid item xs={12} md={6}>
            <ConfirmEmailForm />
          </Grid>
          <Grid
            item
            md={6}
            sx={{
              display: { xs: "none", md: "block" },
              height: "calc(100vh - 40px)",
            }}
          >
            <LoginPageBanner />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ConfirmEmail;
