import { Button, Stack, TextField, Typography } from "@mui/material";
import { confirmSignUp, ConfirmSignUpInput } from "aws-amplify/auth";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Image } from "@aws-amplify/ui-react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  confirmationCode: Yup.string().required("Required"),
});

const ConfirmEmailForm = () => {
  const navigate = useNavigate();

  const initialValues: ConfirmSignUpInput = {
    username: "",
    confirmationCode: "",
  };

  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100%"}
      width={"100%"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            const { isSignUpComplete } = await confirmSignUp({
              username: values.username,
              confirmationCode: values.confirmationCode,
            });

            if (isSignUpComplete) {
              setSubmitting(false);
              resetForm();

              Swal.fire({
                title: "Email Confirmation Successful",
                text: "Email Confirmation Successful",
                icon: "success",
                showConfirmButton: true,
                confirmButtonText: "Login",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("../");
                }
              });
            }
          } catch (error) {
            setSubmitting(false);
            Swal.fire({
              title: "Email Confirming Error",
              text: "Email Confirming Error",
              icon: "error",
            });
          }
        }}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          isSubmitting,
          touched,
          dirty,
        }) => (
          <Form>
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              padding={"40px"}
              spacing={"20px"}
              sx={{
                background: "#fff",
                boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                borderRadius: "20px",
                minWidth: { xs: "100%", md: "500px" },
              }}
            >
              <Image
                alt="Amplify logo"
                src="logo-ic.png"
                style={{ width: "200px", height: "100px" }}
              />
              <Stack
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                p="30px"
                sx={{ border: "1px solid #1C2940", borderRadius: "10px" }}
                spacing={"20px"}
                width={"100%"}
              >
                <Stack
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  spacing={"10px"}
                  width="100%"
                >
                  <Typography
                    sx={{
                      color: "var(--Text-Header, #1C2940)",
                      textAlign: "center",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "normal",
                    }}
                  >
                    Welcome
                  </Typography>
                  <Typography
                    sx={{
                      color: "var(--Text-Main, #646D7C)",
                      textAlign: "center",
                      fontFeatureSettings: "'liga' off, 'clig' off",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      letterSpacing: "0.15px",
                    }}
                  >
                    Enter your confirmation code to activate your account.
                  </Typography>
                </Stack>
                <Stack
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  spacing={"10px"}
                  width={"100%"}
                  mt="30px !important"
                >
                  <TextField
                    value={values.username}
                    name="username"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Username"
                    error={touched.username && !!errors.username}
                    fullWidth
                    size="small"
                    helperText="Enter Your Island Catch Email Address"
                  />
                  <TextField
                    value={values.confirmationCode}
                    name="confirmationCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Confirmation Code"
                    error={
                      touched.confirmationCode && !!errors.confirmationCode
                    }
                    fullWidth
                    size="small"
                  />
                  <LoadingButton
                    sx={{
                      mt: "30px !important",
                      background: "#047D95",
                      padding: "10px",
                      ":hover": {
                        background: "#047D95",
                      },
                    }}
                    fullWidth
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={!dirty || isSubmitting || !isValid}
                    loading={isSubmitting}
                  >
                    <Typography
                      sx={{
                        color: "var(--components-cta-text-icon, #1C2940)",
                        textAlign: "center",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "120%",
                        textTransform: "capitalize",
                      }}
                    >
                      Confirm Email
                    </Typography>
                  </LoadingButton>
                </Stack>
              </Stack>
              <Stack
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                spacing={"20px"}
                width={"100%"}
              >
                <Typography
                  sx={{
                    color:
                      "var(--components-enabled-text-stroke-icon, #646D7C)",
                    textAlign: "center",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "100%",
                  }}
                >
                  Encountering issues?
                </Typography>
                <Stack
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"100%"}
                >
                  <Button
                    size="small"
                    onClick={() => {
                      window.open(
                        "https://island-catch.atlassian.net/servicedesk/customer/portals",
                        "_blank"
                      );
                    }}
                    sx={{
                      textTransform: "none",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Typography
                      fontSize={"11px"}
                      fontWeight={400}
                      color={"#646D7C"}
                    >
                      Please raise a ticket
                    </Typography>
                    <Typography
                      fontSize={"11px"}
                      fontWeight={400}
                      color={"#00C9E3"}
                    >
                      here.
                    </Typography>
                  </Button>
                  <Typography
                    fontSize={"11px"}
                    fontWeight={600}
                    color={"#646D7C"}
                  >
                    One of our support assistants will be in touch with you
                    shortly.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};

export default ConfirmEmailForm;
