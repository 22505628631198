import ModalWrapper from "../Common/ModalWrapper";
import {
  Box,
  CircularProgress,
  DialogContent,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteEVoucherTemplate } from "../../services";
import { VoucherTemplate } from "../../utils";

type VoucherTemplatesViewProps = {
  open: boolean;
  onClose: () => void;
  voucherTemplates: VoucherTemplate[];
  loadingEVoucherTemplates: boolean;
  setSelectedTemplate: React.Dispatch<
    React.SetStateAction<VoucherTemplate | null>
  >;
  setOpenVoucherTemplate: React.Dispatch<React.SetStateAction<boolean>>;
};

const VoucherTemplatesView = ({
  open,
  onClose,
  voucherTemplates,
  loadingEVoucherTemplates,
  setSelectedTemplate,
  setOpenVoucherTemplate,
}: VoucherTemplatesViewProps) => {
  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      title="View E-Voucher Templates"
      subTitle="View All Available E Voucher Templates"
    >
      <DialogContent
        sx={{
          paddingX: "20px",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        {loadingEVoucherTemplates ? (
          <Stack
            sx={{
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <Stack
            sx={{
              height: "100%",
              width: "100%",
              direction: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {voucherTemplates.length === 0 ? (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  p: "20px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: "20px",
                }}
              >
                <Typography
                  sx={{
                    color:
                      "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  No Templates Available
                </Typography>
              </Box>
            ) : (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "120%",
                            textTransform: "capitalize",
                          }}
                        >
                          Template Name
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "120%",
                            textTransform: "capitalize",
                          }}
                        >
                          Actions
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {voucherTemplates.map((template, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Typography
                            sx={{
                              color:
                                "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                              fontSize: "11px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "120%",
                              textTransform: "capitalize",
                            }}
                          >
                            {template.templateName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Stack
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            spacing={"5px"}
                          >
                            <Tooltip title="View">
                              <IconButton
                                onClick={() => {
                                  setSelectedTemplate(template);
                                  setOpenVoucherTemplate(true);
                                  onClose();
                                }}
                              >
                                <VisibilityIcon
                                  sx={{
                                    width: "16px",
                                    height: "16px",
                                    color: "#646D7C",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={async () => {
                                  console.log("template : ", template);
                                  await deleteEVoucherTemplate({
                                    id: template.id,
                                    _version: template._version,
                                  });
                                }}
                              >
                                <DeleteIcon
                                  sx={{
                                    width: "16px",
                                    height: "16px",
                                    color: "#646D7C",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Stack>
        )}
      </DialogContent>
    </ModalWrapper>
  );
};

export default VoucherTemplatesView;
