import { Stack, Typography } from "@mui/material";

type VoucherDetailsCardProps = {
  cardTitle: string;
  subTitle: string;
  lastUpdatedDate: string;
  amount: string;
};

const DetailsCard = ({
  cardTitle,
  subTitle,
  lastUpdatedDate,
  amount,
}: VoucherDetailsCardProps) => {
  return (
    <Stack
      direction={"column"}
      justifyContent={"space-between"}
      alignItems={"flex-start"}
      sx={{
        borderRadius: "10px",
        background: "url(/background-images/voucher-details-bg.png)",
        height: "160px !important",
        padding: "20px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Stack
        direction={"column"}
        width={"100%"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
      >
        <Typography
          sx={{
            color: "var(--components-enabled-text-stroke-icon, #646D7C)",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "120%",
            textTransform: "capitalize",
          }}
        >
          {cardTitle}
        </Typography>
        <Typography
          sx={{
            color: "var(--components-enabled-text-stroke-icon, #646D7C)",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "120%",
          }}
        >
          {subTitle}
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        width={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          sx={{
            color: "var(--components-enabled-text-stroke-icon, #646D7C)",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "120%",
          }}
        >
          Last Updated on: {lastUpdatedDate}
        </Typography>
        <Typography
          sx={{
            color: "var(--components-cta-text-icon, #1C2940)",
            textAlign: "right",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "120%",
            textTransform: "capitalize",
          }}
        >
          {amount}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default DetailsCard;
