import { Stack, Typography } from "@mui/material";
import React from "react";

const InputLabelWrapper = ({
  label,
  children,
}: {
  label: string;
  children: any;
}) => {
  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      alignItems={"flex-start"}
      spacing={"5px"}
      width="100%"
    >
      <Typography
        sx={{
          color: "var(--components-disabled-text-icon, #AAA)",
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "120%",
        }}
      >
        {label}
      </Typography>
      {children}
    </Stack>
  );
};

export default InputLabelWrapper;
