import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DetailsCard from "../../components/Common/DetailsCard";
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataGridComponent from "../../components/Common/DataGridComponent";
import { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import SalaryDetailsModal from "../../components/Salary/SalaryPreviewModal";
import SalaryCreateModal from "../../components/Salary/CreateSalary";
import {
  calculateSalaryMetrics,
  fetchAllSalaryDetails,
} from "../../services/salary.service";
import { SalaryExtended, SalaryType } from "../../utils";
import { useAuth } from "../../context/AuthContext";
import { UserType } from "../../models";
import DataGridColumnData from "../../components/Common/DataGridColumnData";
import DataGridColumnHeader from "../../components/Common/DataGridColumnHeader";
import moment from "moment";

const Salary = () => {
  const { currentUser } = useAuth();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [nextToken, setNextToken] = useState<string | null | undefined>(null);

  const [isViewModalOpen, setViewModalOpen] = useState(false);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [allSalaryRecords, setSalaryRecords] = useState<SalaryExtended[]>([]);
  const [activeSalaryRecord, setActiveSalaryRecord] = useState<
    SalaryExtended | undefined
  >();
  const [loading, setLoading] = useState(true); // Add loading state
  const [metrics, setMetrics] = useState({
    totBonus: 0,
    totAdvance: 0,
    TotExpenditure: 0,
  });

  useEffect(() => {
    fetchSalaryRecords();
  }, [page, pageSize]);

  const fetchSalaryRecords = async () => {
    try {
      calculateSalaryMetrics().then((metrics) => {
        setMetrics(metrics);
      });
      setLoading(true); // Set loading to true when starting to fetch
      const {
        rowCount,
        rows,
        nextToken: newNextToken,
      } = await fetchAllSalaryDetails({
        limit: pageSize,
        nextToken,
      });

      setSalaryRecords(rows);
      setTotalRows(rowCount);
      setNextToken(newNextToken);
    } catch (error) {
      console.error("Error fetching salary details:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "userId",
      headerName: "Employee ID",
      flex: 1,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Employee ID" />,
    },
    {
      field: "fullName",
      headerName: "Full Name",
      flex: 1,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Full Name" />,
    },
    {
      field: "position",
      headerName: "Position",
      flex: 1,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Position" />,
    },
    {
      field: "month",
      headerName: "Month",
      flex: 1,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Month" />,
    },
    {
      field: "basicSalary",
      headerName: "Base Salary",
      flex: 1,
      valueFormatter: ({ value }) => `$${value.toLocaleString()}`,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Base Salary" />,
    },
    {
      field: "bonus",
      headerName: "Bonuses",
      flex: 1,
      valueFormatter: ({ value }) => `$${value.toLocaleString()}`,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Bonuses" />,
    },
    {
      field: "deductions",
      headerName: "Deductions",
      flex: 1,
      valueFormatter: ({ value }) => `$${value.toLocaleString()}`,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Deductions" />,
    },
    {
      field: "totalSalary",
      headerName: "Total Salary",
      flex: 1,
      valueGetter: (params) => {
        const { basicSalary, bonus, deductions } = params.row;
        return basicSalary + bonus - deductions;
      },
      valueFormatter: ({ value }) => `$${value.toLocaleString()}`,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Total Salary" />,
    },
    {
      field: "paymentDate",
      headerName: "Payment Date",
      flex: 1,
      renderCell: (params) => <DataGridColumnData content={params.value} />,
      renderHeader: () => <DataGridColumnHeader headerName="Payment Date" />,
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-style",
      renderCell: (params) => (
        <IconButton
          aria-label="view"
          onClick={() => handleOpenModal(params.row)}
        >
          <VisibilityIcon />
        </IconButton>
      ),
    },
  ];

  const handleOpenModal = (record: SalaryExtended) => {
    setActiveSalaryRecord(record);
    setViewModalOpen(true);
  };

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleCreateOpenModal = () => {
    setCreateModalOpen(true);
  };

  const handleCreateCloseModal = () => {
    setCreateModalOpen(false);
  };

  return (
    <Box px={{ xs: "none", md: "20px" }}>
      <Container maxWidth="xl" disableGutters sx={{ mt: "76px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Stack
              direction={"row"}
              width={"100%"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "10px",
                paddingBottom: "10px",
                borderRadius: "5px",
              }}
            >
              <Typography
                sx={{
                  color: "var(--components-cta-text-icon, #1C2940)",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "120%",
                  textTransform: "capitalize",
                }}
              >
                Salary
              </Typography>
              {currentUser?.userType === UserType.SUPER_ADMIN && (
                <Button
                  onClick={handleCreateOpenModal}
                  sx={{
                    borderRadius: "6px",
                    background: "var(--Components-CTA-BG, #4CE6FA)",
                    ":hover": {
                      background: "var(--Components-CTA-BG, #4CE6FA)",
                    },
                    padding: "10px",
                  }}
                  startIcon={
                    <AttachMoneyIcon
                      sx={{
                        background: "#1C2940",
                        borderRadius: 12,
                        color: "var(--Components-CTA-BG, #4CE6FA)",
                      }}
                    />
                  }
                >
                  <Typography
                    sx={{
                      color: "var(--components-cta-text-icon, #1C2940)",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "120%",
                      textTransform: "capitalize",
                    }}
                  >
                    Add New Salary Record
                  </Typography>
                </Button>
              )}
            </Stack>
          </Grid>
          <Grid container xs={12} spacing={4} paddingTop={2}>
            <Grid item xs={12} md={4}>
              <DetailsCard
                cardTitle={"Total Expenditure"}
                subTitle={`On ${moment().format("MMMM")}`}
                lastUpdatedDate={moment().format("YYYY-MM-DD HH:mm")}
                amount={`$${metrics.TotExpenditure.toLocaleString("USD")}`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DetailsCard
                cardTitle={"Bonuses Given"}
                subTitle={`On ${moment().format("MMMM")}`}
                lastUpdatedDate={moment().format("YYYY-MM-DD HH:mm")}
                amount={`$${metrics.totBonus.toLocaleString("USD")}`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DetailsCard
                cardTitle={"Advances Given"}
                subTitle={`On ${moment().format("MMMM")}`}
                lastUpdatedDate={moment().format("YYYY-MM-DD HH:mm")}
                amount={`$${metrics.totAdvance.toLocaleString("USD")}`}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} paddingTop={5}>
            <DataGridComponent
              columns={columns}
              rows={allSalaryRecords}
              loading={loading}
              totalRows={totalRows}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              checkboxSelection={false}
            />
          </Grid>
        </Grid>
        {activeSalaryRecord && (
          <SalaryDetailsModal
            open={isViewModalOpen}
            onClose={handleCloseModal}
            salaryData={activeSalaryRecord}
            onEdit={() => {
              handleCloseModal();
              setEditModalOpen(true);
            }}
          />
        )}
        {isCreateModalOpen && (
          <SalaryCreateModal
            open={isCreateModalOpen}
            onClose={handleCreateCloseModal}
            onCreated={() => fetchSalaryRecords()}
          />
        )}

        {isEditModalOpen && (
          <SalaryCreateModal
            open={isEditModalOpen}
            onClose={() => setEditModalOpen(false)}
            salaryData={activeSalaryRecord as SalaryType}
            onCreated={() => fetchSalaryRecords()}
          />
        )}
      </Container>
    </Box>
  );
};

export default Salary;
