import { createContext, useContext, useEffect, useState } from "react";
import { getUser } from "../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { getSignedUrl, UserExtendType } from "../utils";

export const AuthContext = createContext<{
  currentUser: UserExtendType | null;
  fetchUserDetails: () => Promise<void>;
}>({
  currentUser: null,
  fetchUserDetails: async () => {
    return;
  },
});

export function useAuth() {
  return useContext(AuthContext);
}

const AuthProvider = ({ children }: any) => {
  const client = generateClient({ authMode: "userPool" });

  const { user } = useAuthenticator((context) => [context.user]);

  const [currentUser, setCurrentUser] = useState<UserExtendType | null>(null);

  const fetchUserDetails = async () => {
    try {
      const { data } = await client.graphql({
        query: getUser,
        variables: { id: user.userId },
      });

      if (data.getUser) {
        const profilePictureUrl = await getSignedUrl(
          data.getUser.profilePicture
        );

        const userData = {
          ...data.getUser,
          profilePicture: profilePictureUrl,
        };

        setCurrentUser(userData as unknown as UserExtendType);
      } else {
        setCurrentUser(null);
      }
    } catch (error) {
      console.log("🚀 ~ fetchUserDetails ~ error:", error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchUserDetails();
    }
  }, [user]);

  return (
    <AuthContext.Provider value={{ currentUser, fetchUserDetails }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
