import { useState } from "react";
import {
  accountCreationMail,
  countries,
  generatePassword,
  notifyNewlyCreatedUserTiSuperAdmins,
} from "../../utils";
import { UserType } from "../../models";
import { signUp } from "aws-amplify/auth";
import {
  Button,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikState } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import UploadButton from "../Common/UploadButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import UploadDialog from "../Common/UploadDialog";
import { createNewUser, notifySuperAdmins, sendEmail } from "../../services";
import Swal from "sweetalert2";
import ModalWrapper from "../Common/ModalWrapper";
import { uploadData } from "aws-amplify/storage";
import MuiPhoneNumber from "material-ui-phone-number";
import InputLabelWrapper from "../Common/InputLabelWrapper";
import { updateUserCount } from "../../services/common.service";

const validationSchema = Yup.object({
  userId: Yup.string().required("Required"),
  firstName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Only allow letters and spaces only")
    .required("Required"),
  lastName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Only allow letters and spaces only")
    .required("Required"),
  email: Yup.string().email().required("Required"),
  designation: Yup.string().required("Required"),
  bio: Yup.string().required("Required"),
  birthDay: Yup.string().required("Required"),
  residingCountry: Yup.string().required("Required"),
  userType: Yup.mixed()
    .oneOf(Object.values(UserType), "Invalid User Type")
    .required("Required"),
  profilePicture: Yup.mixed()
    .required("Required")
    .test("fileSize", "File size is too large", (value) => {
      // Typecast value to ensure TypeScript understands it as a file
      return value && (value as File).size <= 5 * 1024 * 1024; // 5MB
    })
    .test("fileType", "Unsupported File Format", (value) => {
      // Typecast value to ensure TypeScript understands it as a file
      return (
        value && ["image/jpeg", "image/png"].includes((value as File).type)
      );
    }),
  contactNumber: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
});

type CreateUserType = {
  firstName: string;
  lastName: string;
  designation: string;
  bio: string;
  birthDay: string;
  address: string;
  residingCountry: string;
  userType: UserType;
  profilePicture: File | null;
  email: string;
  contactNumber: string;
  userId: string;
};

const UserManagementModal = ({
  open,
  onClose,
  onOpen,
  getUsers,
  currentUserIdNumber,
}: {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  getUsers: () => Promise<void>;
  currentUserIdNumber: number;
}) => {
  const initialValues = {
    userId: `IC/00${currentUserIdNumber + 1}`,
    firstName: "",
    lastName: "",
    email: "",
    designation: "",
    bio: "",
    birthDay: "",
    address: "",
    residingCountry: "",
    userType: UserType.USER,
    profilePicture: null,
    contactNumber: "",
  };

  const [openDropZone, setOpenDropZone] = useState(false);
  const [dropZoneName, setDropZoneName] = useState("");
  const [selectedFieldName, setSelectedFieldName] = useState("");
  const [acceptedFiledTypes, setAcceptedFiledTypes] = useState([
    ".jpeg",
    ".png",
    ".jpg",
    ".pdf",
  ]);

  const createUser = async (
    values: CreateUserType,
    resetForm: (
      nextState?:
        | Partial<
            FormikState<{
              firstName: string;
              lastName: string;
              email: string;
              designation: string;
              bio: string;
              birthDay: string;
              address: string;
              residingCountry: string;
              userType: UserType;
              profilePicture: null;
              contactNumber: string;
              userId: string;
            }>
          >
        | undefined
    ) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const password = generatePassword();

      const profilePicturePath = `public/profile_pictures/${values.email}.png`;

      if (values.profilePicture) {
        const { path } = await uploadData({
          path: profilePicturePath,
          data: values.profilePicture,
        }).result;

        const { userId } = await signUp({
          username: values.email,
          password,
          options: {
            userAttributes: {
              email: values.email,
              name: `${values.firstName} ${values.lastName}`,
              picture: path,
              given_name: `${values.firstName} ${values.lastName}`,
            },
          },
        });

        const domain = window.location.origin;

        await sendEmail({
          to: [values.email],
          ...accountCreationMail(password, domain),
        });

        await notifySuperAdmins({
          ...notifyNewlyCreatedUserTiSuperAdmins({
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
          }),
        });

        if (userId) {
          const userDataToCreate = {
            ...values,
            profilePicture: path,
            id: userId,
            authActivities: [],
          };

          const result = await createNewUser(userDataToCreate);

          const { success } = await updateUserCount(userDataToCreate.userId);

          if (result && success) {
            resetForm();

            onClose();

            Swal.fire({
              title: "User created",
              text: "User Successfully Created",
              icon: "success",
            });
          } else {
            onClose();

            Swal.fire({
              title: "User creating Error",
              text: "User creation Error",
              icon: "error",
            });
          }
        }
      }
    } catch (error) {
      console.log("🚀 ~ createUser ~ error:", error);
      setSubmitting(false);
      onClose();
      Swal.fire({
        title: "User creating Error",
        text: "User creation Error Please check your email and try again",
        icon: "error",
      }).then((result) => {
        if (result.isConfirmed) {
          onOpen();
        }
      });
    } finally {
      getUsers();
    }
  };

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      title="Add New User"
      subTitle="Add all the relevant information below."
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          createUser(values, resetForm, setSubmitting);
        }}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          touched,
          isSubmitting,
          handleReset,
          dirty,
          setFieldValue,
        }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={"10px"}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <InputLabelWrapper label="User ID">
                    <TextField
                      value={values.userId}
                      name="userId"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.userId && !!errors.userId}
                      fullWidth
                      size="small"
                      disabled
                    />
                    <FormHelperText sx={{ color: "#FF0C3E" }}>
                      {touched.userId && !!errors.userId && errors.userId}
                    </FormHelperText>
                  </InputLabelWrapper>
                  <InputLabelWrapper label="Last Name">
                    <TextField
                      value={values.lastName}
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.lastName && !!errors.lastName}
                      fullWidth
                      size="small"
                    />
                    <FormHelperText sx={{ color: "#FF0C3E" }}>
                      {touched.lastName && !!errors.lastName && errors.lastName}
                    </FormHelperText>
                  </InputLabelWrapper>
                  <InputLabelWrapper label="User Type">
                    <Select
                      value={values.userType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={"userType"}
                      error={touched.userType && !!errors.userType}
                      fullWidth
                      size="small"
                    >
                      <MenuItem value="" disabled>
                        -Select-
                      </MenuItem>
                      {[
                        { label: "Super Admin", value: UserType.SUPER_ADMIN },
                        { label: "Admin", value: UserType.ADMIN },
                        { label: "Accountant", value: UserType.ACCOUNTANT },
                        { label: "User", value: UserType.USER },
                      ].map((type, index) => (
                        <MenuItem key={index} value={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: "#FF0C3E" }}>
                      {touched.userType && !!errors.userType && errors.userType}
                    </FormHelperText>
                  </InputLabelWrapper>
                  <InputLabelWrapper label="Contact Number">
                    <MuiPhoneNumber
                      defaultCountry={"sc"}
                      onChange={(value) => {
                        setFieldValue("contactNumber", value);
                      }}
                      fullWidth
                      variant="outlined"
                      size="small"
                      onBlur={handleBlur}
                      value={values.contactNumber}
                      name="contactNumber"
                      error={touched.contactNumber && !!errors.contactNumber}
                    />
                    <FormHelperText sx={{ color: "#FF0C3E" }}>
                      {touched.contactNumber &&
                        !!errors.contactNumber &&
                        errors.contactNumber}
                    </FormHelperText>
                  </InputLabelWrapper>
                  <InputLabelWrapper label="Resident Country">
                    <Select
                      value={values.residingCountry}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={"residingCountry"}
                      error={
                        touched.residingCountry && !!errors.residingCountry
                      }
                      fullWidth
                      size="small"
                    >
                      <MenuItem value="" disabled>
                        -Select-
                      </MenuItem>
                      {countries.map((country, index) => (
                        <MenuItem key={index} value={country}>
                          {country}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: "#FF0C3E" }}>
                      {touched.residingCountry &&
                        !!errors.residingCountry &&
                        errors.residingCountry}
                    </FormHelperText>
                  </InputLabelWrapper>
                  <InputLabelWrapper label="Designation">
                    <Select
                      value={values.designation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={"designation"}
                      error={touched.designation && !!errors.designation}
                      fullWidth
                      size="small"
                    >
                      <MenuItem value="" disabled>
                        -Select-
                      </MenuItem>
                      {["Chairman", "Chief Executive Officer", "Manager"].map(
                        (value, index) => (
                          <MenuItem key={index} value={value}>
                            {value}
                          </MenuItem>
                        )
                      )}
                    </Select>
                    <FormHelperText sx={{ color: "#FF0C3E" }}>
                      {touched.designation &&
                        !!errors.designation &&
                        errors.designation}
                    </FormHelperText>
                  </InputLabelWrapper>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <InputLabelWrapper label="First Name">
                    <TextField
                      value={values.firstName}
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.firstName && !!errors.firstName}
                      fullWidth
                      size="small"
                    />
                    <FormHelperText sx={{ color: "#FF0C3E" }}>
                      {touched.firstName &&
                        !!errors.firstName &&
                        errors.firstName}
                    </FormHelperText>
                  </InputLabelWrapper>
                  <InputLabelWrapper label="Work Email">
                    <TextField
                      value={values.email}
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && !!errors.email}
                      fullWidth
                      size="small"
                    />
                    <FormHelperText sx={{ color: "#FF0C3E" }}>
                      {touched.email && !!errors.email && errors.email}
                    </FormHelperText>
                  </InputLabelWrapper>
                  <InputLabelWrapper label="Current Address">
                    <TextField
                      value={values.address}
                      name="address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.address && !!errors.address}
                      fullWidth
                      size="small"
                    />
                    <FormHelperText sx={{ color: "#FF0C3E" }}>
                      {touched.address && !!errors.address && errors.address}
                    </FormHelperText>
                  </InputLabelWrapper>
                  <InputLabelWrapper label="Birth Day">
                    <TextField
                      type="date"
                      name="birthDay"
                      value={values.birthDay}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.birthDay && !!errors.birthDay}
                      fullWidth
                      size="small"
                    />
                    <FormHelperText sx={{ color: "#FF0C3E" }}>
                      {touched.birthDay && !!errors.birthDay && errors.birthDay}
                    </FormHelperText>
                  </InputLabelWrapper>
                  <Stack
                    sx={{ height: "60px" }}
                    direction={"column"}
                    spacing="10px"
                  >
                    <InputLabelWrapper label="Profile Picture">
                      <UploadButton
                        name={
                          !values.profilePicture
                            ? "Attach Profile Picture"
                            : typeof values.profilePicture === "object" &&
                              values.profilePicture !== null
                            ? (values.profilePicture as File).name // Safely handle the file name
                            : values.profilePicture // Handle it as a string if it's a URL or text
                        }
                        startIcon={
                          <CloudUploadIcon
                            sx={{
                              width: "16px",
                              height: "16px",
                              color:
                                values.profilePicture !== ""
                                  ? "#0D3C60"
                                  : "rgba(88, 104, 116, 0.54)",
                            }}
                          />
                        }
                        fullWidth
                        onClick={() => {
                          setDropZoneName("Attach Profile Picture");
                          setSelectedFieldName("profilePicture");
                          setAcceptedFiledTypes([".jpeg", ".png", ".jpg"]);
                          setOpenDropZone(true);
                        }}
                        textColor={
                          values.profilePicture === ""
                            ? "rgba(88, 104, 116, 0.54)"
                            : "#0D3C60"
                        }
                      />
                      <FormHelperText
                        sx={{
                          color: "#d32f2f",
                          display: "flex",
                          mt: 0,
                          ml: "14px !important",
                        }}
                      >
                        {errors.profilePicture}
                      </FormHelperText>
                    </InputLabelWrapper>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    mt: "10px",
                  }}
                >
                  <InputLabelWrapper label="Bio">
                    <TextField
                      multiline
                      minRows={2}
                      maxRows={2}
                      value={values.bio}
                      name="bio"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.bio && !!errors.bio}
                      fullWidth
                      size="small"
                    />
                    <FormHelperText sx={{ color: "#FF0C3E" }}>
                      {touched.bio && !!errors.bio && errors.bio}
                    </FormHelperText>
                  </InputLabelWrapper>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
                padding: "20px",
              }}
            >
              <Button
                onClick={() => {
                  handleReset();
                  onClose();
                }}
                sx={{
                  height: "32px",
                  borderRadius: "6px",
                  padding: "8px 10px",
                }}
              >
                <Typography
                  sx={{
                    color:
                      "var(--components-enabled-text-stroke-icon, #646D7C)",
                    textAlign: "center",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  Cancel
                </Typography>
              </Button>
              <LoadingButton
                onClick={() => {
                  handleSubmit();
                }}
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                sx={{
                  height: "32px",
                  borderRadius: "6px",
                  background: "var(--Components-CTA-BG, #4CE6FA)",
                  ":hover": {
                    background: "var(--Components-CTA-BG, #4CE6FA)",
                  },
                  padding: "8px 10px",
                }}
              >
                <Typography
                  sx={{
                    color: "var(--components-cta-text-icon, #1C2940)",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                    textTransform: "capitalize",
                  }}
                >
                  Create User
                </Typography>
              </LoadingButton>
            </DialogActions>
            <UploadDialog
              open={openDropZone}
              setOpen={setOpenDropZone}
              title={dropZoneName}
              save={(file: File) => {
                setFieldValue(selectedFieldName, file, true);
                setOpenDropZone(false);
              }}
              acceptedFiles={acceptedFiledTypes}
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
};

export default UserManagementModal;
