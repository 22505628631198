import {
  IconButton,
  Collapse,
  Box,
  Typography,
  TableRow,
  TableCell,
  Table,
  TableBody,
} from "@mui/material";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { LazyInventory } from "../../models";
import moment from "moment";

const InventoryManagementTableBody = ({ row }: { row: LazyInventory }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ width: "100%" }}>
        <TableCell align="left" style={{ width: "150px" }}>
          <Typography
            sx={{
              color: "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
              fontSize: "11px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "120%",
              textTransform: "capitalize",
            }}
          >
            {row.inventoryItems[0]?.cccs}
          </Typography>
        </TableCell>
        <TableCell align="left" style={{ width: "200px" }}>
          <Typography
            sx={{
              color: "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
              fontSize: "11px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "120%",
              textTransform: "capitalize",
            }}
          >
            {row?.inventoryItems[0]?.specie}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            sx={{
              color: "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
              fontSize: "11px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "120%",
              textTransform: "capitalize",
            }}
          >
            {row?.inventoryItems[0]?.scow}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            sx={{
              color: "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
              fontSize: "11px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "120%",
              textTransform: "capitalize",
            }}
          >
            {row?.inventoryItems[0]?.weight}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            sx={{
              color: "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
              fontSize: "11px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "120%",
              textTransform: "capitalize",
            }}
          >
            {row?.inventoryItems[0]?.client}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            sx={{
              color: "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
              fontSize: "11px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "120%",
              textTransform: "capitalize",
            }}
          >
            {row?.inventoryItems[0]?.boatCode}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            sx={{
              color: "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
              fontSize: "11px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "120%",
              textTransform: "capitalize",
            }}
          >
            {row?.inventoryItems[0]?.container}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            sx={{
              color: "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
              fontSize: "11px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "120%",
              textTransform: "capitalize",
            }}
          >
            {row?.inventoryItems[0]?.enteredCs}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            sx={{
              color: "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
              fontSize: "11px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "120%",
              textTransform: "capitalize",
            }}
          >
            {row?.inventoryItems[0]?.firstEnteredToCs}
          </Typography>
        </TableCell>
        <TableCell style={{ width: "85px" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingLeft: 0, paddingRight: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {row.inventoryItems.map((subRow, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ width: "150px" }} />
                      <TableCell style={{ width: "200px" }}>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "120%",
                            textTransform: "capitalize",
                          }}
                        >
                          {subRow?.specie}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "120%",
                            textTransform: "capitalize",
                          }}
                        >
                          {subRow?.scow}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "120%",
                            textTransform: "capitalize",
                          }}
                        >
                          {subRow?.weight}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "120%",
                            textTransform: "capitalize",
                          }}
                        >
                          {subRow?.client}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "120%",
                            textTransform: "capitalize",
                          }}
                        >
                          {subRow?.boatCode}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "120%",
                            textTransform: "capitalize",
                          }}
                        >
                          {subRow?.container}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "120%",
                            textTransform: "capitalize",
                          }}
                        >
                          {subRow?.enteredCs
                            ? moment(subRow.enteredCs).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color:
                              "var(--comp-behaviour-enabled-text-stroke-icon, #5D7C94)",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "120%",
                            textTransform: "capitalize",
                          }}
                        >
                          {subRow?.firstEnteredToCs
                            ? moment(subRow.firstEnteredToCs).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ width: "85px" }} />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default InventoryManagementTableBody;
