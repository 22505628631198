import { SalaryExtended, SalaryType } from "../utils";
import { generateClient } from "aws-amplify/api";
import {
  createSalaryDetails,
  deleteSalaryDetails,
  updateSalaryDetails,
} from "../graphql/mutations";
import { searchSalaryDetails } from "../graphql/queries";
import moment from "moment";
import {
  SearchableAggregateType,
  SearchableSalaryDetailsAggregateField,
  SearchableSalaryDetailsSortableFields,
  SearchableSortDirection,
} from "../API";

// Fetch all salary details, either online or offline

const fetchAllSalaryDetails = async ({
  limit,
  nextToken,
}: {
  limit: number;
  nextToken: string | null | undefined;
}) => {
  try {
    const client = generateClient({ authMode: "userPool" });
    // const salaryDetails = await DataStore.query(SalaryDetails);
    // return salaryDetails;
    const {
      data: {
        searchSalaryDetails: { items: rowCount },
      },
    } = await client.graphql({
      query: searchSalaryDetails,
    });
    const {
      data: {
        searchSalaryDetails: { items, nextToken: newNextToken },
      },
    } = await client.graphql({
      query: searchSalaryDetails,
      variables: {
        sort: [
          {
            field: SearchableSalaryDetailsSortableFields.year,
            direction: SearchableSortDirection.asc,
          },
          {
            field: SearchableSalaryDetailsSortableFields.month,
            direction: SearchableSortDirection.asc,
          },
        ],
        aggregates: [
          {
            name: "groupByUserId",
            type: SearchableAggregateType.terms,
            field: SearchableSalaryDetailsAggregateField.userId,
          },
        ],
      },
    });

    const latestSalaryRecords = Object.values(
      (items as SalaryExtended[]).reduce(
        (acc: Record<string, SalaryExtended>, curr: SalaryExtended) => {
          if (
            !acc[curr.userId] ||
            moment(curr.createdOn).isAfter(moment(acc[curr.userId].createdOn))
          ) {
            acc[curr.userId] = curr;
          }
          return acc;
        },
        {}
      )
    );

    return {
      success: true,
      rows: latestSalaryRecords as unknown as SalaryExtended[],
      rowCount: rowCount.length,
      nextToken: newNextToken,
    };
  } catch (error) {
    console.log("🚀 ~ fetchAllSalaryDetails ~ error:", error);
    return {
      success: false,
      rows: [],
      rowCount: 0,
      nextToken: null,
    };
  }
};

// Create a new salary detail and sync it when online
const createNewSalaryDetail = async (salaryDetail: SalaryType) => {
  try {
    const client = generateClient({ authMode: "userPool" });
    // const newSalaryDetail = await DataStore.save(
    //   new SalaryDetails({
    //     ...salaryDetail,
    //   })
    // );

    const newSalaryDetail = await client.graphql({
      query: createSalaryDetails,
      variables: {
        input: salaryDetail,
      },
    });
    return newSalaryDetail;
  } catch (error) {
    console.log("🚀 ~ createSalaryDetail ~ error:", error);
  }
};

// Update an existing salary detail and sync changes when online
const updateSalaryDetail = async (salaryDetail: SalaryExtended) => {
  try {
    // if (!salaryDetail.id) throw "ID not found";

    // const originalSalaryDetail = await DataStore.query(
    //   SalaryDetails,
    //   salaryDetail.id
    // );

    // if (originalSalaryDetail) {
    //   const updatedSalaryDetail = await DataStore.save(
    //     SalaryDetails.copyOf(originalSalaryDetail, (updated) => {
    //       Object.assign(updated, salaryDetail);
    //     })
    //   );
    //   return updatedSalaryDetail;
    // }

    const client = generateClient({ authMode: "userPool" });

    if (salaryDetail.id) {
      const { data } = await client.graphql({
        query: updateSalaryDetails,
        variables: {
          input: {
            ...salaryDetail,
            _version: salaryDetail._version,
            id: salaryDetail.id,
          },
        },
      });

      return data.updateSalaryDetails;
    }
  } catch (error) {
    console.log("🚀 ~ updateSalaryDetail ~ error:", error);
  }
};

// Delete a salary detail and sync the deletion when online
const deleteSalaryDetail = async (id: string) => {
  try {
    // const salaryDetailToDelete = await DataStore.query(SalaryDetails, id);
    // if (salaryDetailToDelete) {
    //   await DataStore.delete(salaryDetailToDelete);
    // }

    const client = generateClient({ authMode: "userPool" });

    const response = await client.graphql({
      query: deleteSalaryDetails,
      variables: {
        input: {
          id: id,
        },
      },
    });

    if (response.errors) {
      return {
        success: false,
      };
    } else {
      return { success: true };
    }
  } catch (error) {
    console.log("🚀 ~ deleteSalaryDetail ~ error:", error);
    return {
      success: false,
    };
  }
};

// Fetch salary details for the current month
const fetchCurrentMonthSalaryDetails = async () => {
  try {
    // const startOfMonth = new Date();
    // startOfMonth.setDate(1);
    // const endOfMonth = new Date(
    //   startOfMonth.getFullYear(),
    //   startOfMonth.getMonth() + 1,
    //   0
    // );

    // const salaryDetails = await DataStore.query(SalaryDetails, (salary) =>
    //   salary.paymentDate.between(
    //     startOfMonth.toISOString(),
    //     endOfMonth.toISOString()
    //   )
    // );
    const client = generateClient({ authMode: "userPool" });

    const {
      data: {
        searchSalaryDetails: { items },
      },
    } = await client.graphql({
      query: searchSalaryDetails,
      variables: {
        filter: {
          paymentDate: {
            range: [
              moment().startOf("month").toISOString(),
              moment().endOf("month").toISOString(),
            ],
          },
        },
      },
    });

    return items;
  } catch (error) {
    console.log("🚀 ~ fetchCurrentMonthSalaryDetails ~ error:", error);
    return [];
  }
};

// Calculate salary metrics based on the current month's salary details
const calculateSalaryMetrics = async () => {
  const salaries = await fetchCurrentMonthSalaryDetails();
  const metrics = {
    totBonus: 0,
    totAdvance: 0,
    TotExpenditure: 0,
  };

  salaries.forEach((salary) => {
    metrics.totBonus += salary.bonus || 0;
    metrics.totAdvance += salary.advance || 0;
    metrics.TotExpenditure += metrics.totBonus + salary.basicSalary;
  });

  return metrics;
};

export {
  fetchAllSalaryDetails,
  createNewSalaryDetail,
  updateSalaryDetail,
  deleteSalaryDetail,
  fetchCurrentMonthSalaryDetails,
  calculateSalaryMetrics,
};
